import segmentTrackedEvents from 'Constants/SegmentTrackEvents'


const validateSegmentTrackEvent = (eventName) => {
    
    // ensure event names are on the list
    if (!segmentTrackedEvents.includes(eventName)){
        console.error('segment event name ' + eventName + ' is not on segmentTrackedEvents.')
        return false
    }

    return true
}

export default validateSegmentTrackEvent