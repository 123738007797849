import {useState} from 'react'
import useWindow from './useWindow'

const AcquisitionTypes = {
    direct: "direct",
    organic: "organic",
    paid: "paid"
}

const OrganicDomains = [
    "google",
    "yahoo",
    "bing",
    "duckduckgo",
    "aol"
]

const PaidUrlParams = [
    "gad_source",
    "gclid",
    "utm_source",
    "utm_campaign",
    "utm_medium",
    "msclkid"
]

const GoogleSpecificPaidUrlParams = [
    "gad_source",
    "gclid"
]

const useAcquisitionType = (callback) => {
    try{
        if(typeof window !== 'undefined'){
        
            let acquisitionType
            let referer = document.referrer;
            let urlParams = new URL(window.location.href).searchParams

            let hasPaidParams
            let hasGooglePaidParams
            if(urlParams && urlParams.keys()){
                for(let x of urlParams.keys()){
                    if(PaidUrlParams.includes(x)){
                        hasPaidParams = true
                    }

                    if(GoogleSpecificPaidUrlParams.includes(x)){
                        hasGooglePaidParams = true
                    }
                }
            }
    
            if(!referer && !hasPaidParams && !hasGooglePaidParams) {
                acquisitionType = AcquisitionTypes.direct
            }
            else if(hasGooglePaidParams){
                acquisitionType = AcquisitionTypes.paid
            }
            else if(OrganicDomains.some(domain => referer.includes(domain))) {
    
                if(hasPaidParams) {
                    acquisitionType = AcquisitionTypes.paid
                }
                else {
                    acquisitionType = AcquisitionTypes.organic
                }
            }            

            callback && callback(acquisitionType)
        }
    }
    catch(err){
        console.error("Error getting acquisition type", err)
    }
    
};

export default useAcquisitionType