const Slide = (e, startOfPattern, endOfPattern, numberOfLoopsToTheRight, setNumberOfLoopsToTheRight, infiniteScroll, direction) => {
    const carouselWindow = e.target.parentElement.childNodes[1].childNodes[1]
    const carouselInner = carouselWindow.childNodes[0]
    const carouselChildren = Array.from(carouselInner.childNodes)
    const items = carouselChildren.filter(x => !x.getAttribute("data-nonitem"))
    const leftGradient = e.target.parentElement.childNodes[1].childNodes[0]
    const rightGradient = e.target.parentElement.childNodes[1].childNodes[2]
    const gradientTransparentOffset = 20

    if (!carouselWindow || !items || !items.length || !leftGradient || !rightGradient) {
        return
    }
    const itemToScrollToIndex = GetFirstOutOfBoundsItem(direction, items, leftGradient, rightGradient, gradientTransparentOffset)
    const itemToScrollTo = items[itemToScrollToIndex]
    let slideLength = 0

    if (direction === 'right') {
        slideLength = itemToScrollTo.getBoundingClientRect().left - leftGradient.getBoundingClientRect().right
    } else if (direction === 'left') {
        slideLength = rightGradient.getBoundingClientRect().left - itemToScrollTo.getBoundingClientRect().right
    }

    ScrollCarousel(direction, slideLength, 250, carouselWindow, startOfPattern, endOfPattern, numberOfLoopsToTheRight, setNumberOfLoopsToTheRight, infiniteScroll, gradientTransparentOffset)
}

const ScrollCarousel = (direction, distance, duration, carouselWindow, startOfPattern, endOfPattern, numberOfLoopsToTheRight, setNumberOfLoopsToTheRight, infiniteScroll, gradientTransparentOffset) => {
    distance += gradientTransparentOffset
    const directionNumber = direction === 'right' ? 1 : -1
    const intervalCount = 30
    const scrollTime = duration / intervalCount
    let scrollCount = 0
    const startCarouselWindowScroll = carouselWindow.scrollLeft
    const exponent = 1 / 2
    const carouselInner = carouselWindow.childNodes[0]
    const endOfPatternScroll = endOfPattern.getBoundingClientRect().left - carouselInner.getBoundingClientRect().left
    const startOfPatternScroll = Math.abs(startOfPattern.getBoundingClientRect().left - carouselInner.getBoundingClientRect().left)
    const carouselWindowWidth = carouselWindow.getBoundingClientRect().width
    const scrollInterval = setInterval(function () {
        if (scrollCount <= intervalCount) {
            scrollCount += 1
            let newScrollLeft = startCarouselWindowScroll + (((scrollCount / intervalCount) ** exponent) * distance) * directionNumber;
            if (infiniteScroll && newScrollLeft > endOfPatternScroll) {
                setNumberOfLoopsToTheRight(numberOfLoopsToTheRight + 1)
                newScrollLeft = startOfPatternScroll + newScrollLeft - endOfPatternScroll
            } else if (infiniteScroll && newScrollLeft + carouselWindowWidth < startOfPatternScroll && numberOfLoopsToTheRight > 0) {
                setNumberOfLoopsToTheRight(numberOfLoopsToTheRight - 1)
                newScrollLeft = endOfPatternScroll + newScrollLeft - startOfPatternScroll
            }
            carouselWindow.scrollLeft = newScrollLeft
        } else {
            clearInterval(scrollInterval)
        }
    }, scrollTime)
}

const GetFirstOutOfBoundsItem = (direction, items, leftGradient, rightGradient, gradientTransparentOffset) => {
    const leftGradientBounds = leftGradient.getBoundingClientRect()
    const rightGradientBounds = rightGradient.getBoundingClientRect()
    let itemBounds = null
    let prevItemVisible = false
    let i
    if (direction === "right") {
        i = 0
        while (i < items.length) {
            itemBounds = items[i].getBoundingClientRect()
            if (itemBounds.right <= rightGradientBounds.left + gradientTransparentOffset) {
                prevItemVisible = true
            } else if (prevItemVisible) {
                break;
            }
            i++
        }
    } else if (direction === "left") {
        i = items.length - 1
        while (i > 0) {
            itemBounds = items[i].getBoundingClientRect()
            if (itemBounds.left >= leftGradientBounds.right - gradientTransparentOffset) {
                prevItemVisible = true
            } else if (prevItemVisible) {
                break;
            }
            i--
        }
    }

    if (i < 0) {
        return 0
    } else if (i >= items.length) {
        return items.length - 1
    } else {
        return i
    }
}

export {Slide, GetFirstOutOfBoundsItem}