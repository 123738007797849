import React from "react";
import Ribbon from "../../Atoms/Ribbon/Ribbon";

const getRibbonTextFromPromo = (promo) => {
  if (!promo?.anythingToDisplay) return null

  const { dollars, percent } = promo?.priceAfterPromo?.listPriceSavings || {};
  const hasSavings = dollars?.decimal > 0 || percent?.decimal > 0;

  if (hasSavings) {
    if (dollars?.decimal < 100 && percent?.string) {
      return `-${percent.string}`;
    } else if (dollars?.decimal) {
      return `$${Math.floor(dollars.decimal)} Off`;
    }
  }
  return null
}

export const PromotionRibbon = ({ 
    displayText = null, 
    textToOveride = null, 
    promotionPreview, 
    ...props 
  }) => {
    if (!promotionPreview && !displayText) return false;
  
    const brand = displayText === "New Product" ? "secondarypalette_black" : "secondary";
  
    if (textToOveride) {
      return <Ribbon text={textToOveride} brand={brand} {...props} />;
    }

    var ribbonText = null
    if (/\$\d+ Flat Rate Shipping/.test(displayText)) {
      ribbonText = displayText.replace("Flat Rate", "").trim(); 
    }

    if (displayText && (displayText.includes("%") || displayText.includes("$"))){
      ribbonText = getRibbonTextFromPromo(promotionPreview) || displayText
    } else {
      ribbonText = displayText || getRibbonTextFromPromo(promotionPreview)
    }

    if (!ribbonText) return false;

    return <Ribbon text={ribbonText} brand={brand} {...props} />;
  };
  