import React, { useContext, useEffect } from "react";
import { NotificationContext } from "Contexts/NotificationContext/NotificationContext";

export default function OfflineStatus() {
  const { PushNotification, DismissNotificationByMessage } =
    useContext(NotificationContext);

  useEffect(() => {
    const message = "You are currently offline";

    const offlineHandler = () =>
    {
      PushNotification(message, "negative", 0, null, {
        icon: "warning",
        intent: "negative",
      })
    }

    const onlineHandler = () => {
      DismissNotificationByMessage(message);
      PushNotification("You're back online!", "positive", 2000, null, {
        icon: "check",
        intent: "positive",
      });
    }
      

    window.addEventListener("offline", offlineHandler);
    window.addEventListener("online", onlineHandler);

    return () => {
      window.removeEventListener("offline", offlineHandler)
      window.removeEventListener("online", onlineHandler)
    }
  }, []);

  return false;
}
