import {useVehicleStore} from "../../Stores/VehicleStore";
import styles from "./YmmButton.module.scss";
import Button from "../../Molecules/Button";
import React from "react";
import Typography from "../../Atoms/Typography/Typography";

const YmmButton = ({ isMobile }) => {

    const selectedCustomerProjectTitle = useVehicleStore((state) => state.getSelectedCustomerProjectTitle());
    const clearSelectedCustomerProject = useVehicleStore((state) => state.clearSelectedCustomerProject);

    if (!selectedCustomerProjectTitle) return null;

    let displayClass = isMobile ? styles.isMobile : styles.isDesktop;

    return (
        <div className={displayClass}>
            {isMobile && (
                <Typography
                    className={styles.filteringBy}
                    size={1}
                    style={{ color: 'white' }}
                >
                    Filtering By:
                </Typography>
            )}
            <Button
                brand={'secondary'}
                className={styles.ymmPill}
                size={'small'}
                intent={'normal'}
                type={'button'}
                segmentEvent={{ event: 'Clicked', properties: { element: 'Header - Closed YMM Pill' } }}
                onClick={() => {
                    clearSelectedCustomerProject();
                }}
                tabIndex={isMobile ? -1 : null}
            >
                <Typography
                    tone={'subtle'}
                    size={0.875}
                    className={styles.ymmPillText}
                    font={'heading'}
                    inline
                >
                    {selectedCustomerProjectTitle}
                </Typography>
                <Typography
                    tone={'subtle'}
                    size={1}
                    className={styles.ymmPillX}
                    font={'bold'}
                    inline
                >
                    X
                </Typography>
            </Button>
        </div>
    );
};

export default YmmButton;