import React from 'react'
import ArticlePreview from '../ArticlePreview'
import Button from 'Molecules/Button'
import Typography from 'Atoms/Typography'

import styles from './FeaturedArticle.module.scss'
import Skeleton from 'Atoms/Skeleton'
import SkeletonContent from '../../../Containers/SkeletonContent'
import { createClickedSegmentEvent } from '../../../Utilities/Instrumentation/Impressions/impressionSegmentEvents'

const FeaturedArticle = ({ featuredArticle, showTitle }) => {
    if (!featuredArticle) {
        return <SkeletonFeaturedArticle />
    }

    const { url, articleThumbnailUrl, title, description } = featuredArticle

    return (
        <div className={styles.featuredArticleContainer}>
            {showTitle && (
                <div className={styles.featuredArticleTitle}>
                    <Typography size={1.25} tone={'contrast'}>
                        Featured Article
                    </Typography>
                </div>
            )}
            <ArticlePreview
                imageUrl={articleThumbnailUrl}
                articleUrl={url}
                title={title}
                summary={description}
                shouldLimitDescriptionToOneLine={showTitle}
            />
            <Button
                className={styles.featuredArticleButton}
                intent={'action'}
                segmentEvent={createClickedSegmentEvent('ToolboxBladeFeatured')}
                href={url}
                size={'small'}
            >
                <Typography>Read More</Typography>
            </Button>
        </div>
    )
}

const SkeletonFeaturedArticle = () => {
    return (
        <div className={styles.featuredArticleContainer}>
            <Skeleton fullWidth height={180} />
            <SkeletonContent
                rows={3}
                skeletonProps={{
                    className: styles.toolboxOption,
                    height: 25,
                    fullWidth: true
                }}
            />
            <Button
                className={styles.featuredArticleSkeletonButton}
                intent={'action'}
                size={'small'}
                segmentEvent={createClickedSegmentEvent('FeaturedArticleSkeletonReadMore')}
            >
                <Typography>Read More</Typography>
            </Button>
        </div>
    )
}

export default FeaturedArticle
