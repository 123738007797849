const GraphQLRequest = async (url, query, variables) => {
    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            query: query,
            variables: variables
        }),
        credentials: 'include'
    }).then((response) => {
        return response.json()
    }).then((response) => {
        if (response.errors) {
            response.errors.forEach((x) => console.error(x))
        }
        return response.data
    }).catch((error) => {
        throw error;
    })
}

export default GraphQLRequest;

