import React from "react";

import Svg from "Atoms/Svg";
import Typography from "Atoms/Typography";
import Button from "Molecules/Button";

import styles from "./DownloadAppButton.module.scss";
import useAppInstall from "../../Hooks/useAppInstall/useAppInstall";
import { INSTALLPAGEURL, getDeviceInfo, isInApp } from "../../Hooks/useAppInstall/AppUtilities";
import Impression from "../../UtilityComponents/ImpressionComponent/Impression";
import { createClickedSegmentEvent } from "../../Utilities/Instrumentation/Impressions/impressionSegmentEvents";
import Skeleton from "../../Atoms/Skeleton/Skeleton";

const DownloadAppButton = ({ location = "not set", stillShowIfNoPrompt = true, showSkeleton = true }) => {

  const { pwaEnabledAtAll, canPromptForAppInstall, displayAppInstallPrompt } = useAppInstall();

  const inApp = isInApp()
  if (pwaEnabledAtAll == null && showSkeleton && !inApp) {
    return <Skeleton width={221} height={69} />
  }

  if (!pwaEnabledAtAll || inApp) {
    return false;
  }
  if (!canPromptForAppInstall && !stillShowIfNoPrompt) {
    return false;
  }

  const deviceDetails = getDeviceInfo();

  const prefix = `Native app install prompt`

  let text = ""
  if (canPromptForAppInstall) {
    if (deviceDetails.isIos) {
      text = "iOS (iPhone)"
    } else if (deviceDetails.isAndroid) {
      text = "Android"
    }
  }
  const popPrompt = () => {
    displayAppInstallPrompt();
  }


  return (
    <>
      <Impression sectionName={`${prefix} - ${location}`} />
      <Button
        brand={"secondarypalette_black"}
        size={"small"}
        href={canPromptForAppInstall ? null : INSTALLPAGEURL}
        onClick={popPrompt}
        className={styles.button}
        testingName={`pwa_install_button ${location}`}
        segmentEvent={createClickedSegmentEvent('PwaInstallButton')}
      >
        <div className={styles.iconContainer}>
          <Svg
            size={text.length ? 3 : 1}
            icon={"icon_cloud_download"}
            className={styles.notificationIcon}
          ></Svg>
        </div>
        <div className={styles.textContainer}>
          <Typography size={0.875}>INSTALL OUR APP</Typography>
          <Typography size={1.25}>{text}</Typography>
        </div>
      </Button>
    </>

  );
};

export default DownloadAppButton;
