import { useServicesContext } from "Contexts/ServicesContext/ServicesContext";
import useSWR from "swr";

import { useUserStore } from "../../Stores/UserStore/UserStore";
import {
  GetUserGuid,
  IsUserBot,
} from "../../Utilities/CookieUtilties/UserCookieUtilities";
import GetShoppingCart, { getUserShoppingCartQuery } from "./cartRequests";


const initialCart = {
  shoppingCartId: 0,
  itemCount: 0,
  skuBasesInCart: [],
};

const useCart = () => {
  const services = useServicesContext();
  const checkoutUrl = services?.checkout?.url;
  const isHydrated = useUserStore((x) => x.context.hydrated);

  const cacheKey =
    isHydrated && !IsUserBot()
      ? [getUserShoppingCartQuery, GetUserGuid()]
      : null;

  const {
    data: cart,
    mutate,
    error,
  } = useSWR(
    cacheKey,
    (_, webUserGuid) => GetShoppingCart(checkoutUrl, webUserGuid),
    { fallbackData: initialCart }
  );

  const updateCart = () => mutate();

  const isSkuBaseInCart = (skuBaseNumber) => {
    try {
      return cart.skuBasesInCart?.some((x) => x === skuBaseNumber);
    } catch (err) {
      console.error(err);
      return false;
    }
  };
  return {
    updateCart,
    cart,
    isSkuBaseInCart,
  };
};

export default useCart;
