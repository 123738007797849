import { addSessionProperties } from "../../EngagedSessionStorageUtility/EngagedSessionStorageUtility";

const impressionSegmentEventNames = {
  VIEWED: "Viewed",
  CLICKED: "Clicked",
};

const createViewedSegmentEvent = (element, audience, additionalProps) => {
  const segmentEvent = {
    event: impressionSegmentEventNames.VIEWED,
    properties: {
      element: element,
    },
  };

  if (additionalProps) {
    segmentEvent.properties = { ...segmentEvent.properties, ...additionalProps }
  }

  segmentEvent.properties = addSessionProperties(segmentEvent.properties);

  if (audience) segmentEvent.properties.audience = audience;

  return segmentEvent;
};

const createClickedSegmentEvent = (element, audience, additionalProps) => {
  let properties = { element };

  if (audience) {
    properties.audience = audience;
  }

  if (additionalProps) {
    properties = { ...properties, ...additionalProps }
  }

  properties = addSessionProperties(properties);

  return {
    event: impressionSegmentEventNames.CLICKED,
    properties,
  };
};

const mockClickedEvent = {
  event: "Test",
  properties: {
    element: "TestElement",
  },
};

export {
  createViewedSegmentEvent,
  createClickedSegmentEvent,
  mockClickedEvent,
};
