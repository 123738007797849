import React, { useContext, useEffect, useState } from "react";

import AppName from "AppName";
import { TrackingContext } from "Contexts/TrackingContext/TrackingContext";
import useGlobalPrivacyControl from "Hooks/useGlobalPrivacyControl";
import Script from "next/script";

import { useUserStore } from "../../../Stores/UserStore/UserStore";
import {
  GetUserGuid,
  IsUserBot,
} from "../../../Utilities/CookieUtilties/UserCookieUtilities";


export default function GoogleAnalytics4() {
  const trackingContext = useContext(TrackingContext);

  const [renderGA4, setShouldRenderGA4] = useState(false);
  const [gaTrackingId, setGaTrackingId] = useState();
  const { isGpcEnabled } = useGlobalPrivacyControl();
  const hydrated = useUserStore((x) => x.context.hydrated);
  const isRegistered = useUserStore((x) => x.context.isRegistered);

  useEffect(() => {
    const host = window.location.hostname.toLowerCase();

    switch (host) {
      case "www.speedwaymotors.com":
        setGaTrackingId("G-9NTXM7BJK3");
        break;
      case "staging.speedway2.com":
        setGaTrackingId("G-8L191PZ3ZF");
        break;
      case "qa.speedway2.com":
      default:
        setGaTrackingId("G-MK32X9Y3FD");
        break;
    }
  }, []);

  useEffect(() => {
    if (isGpcEnabled || !gaTrackingId || typeof gtag === "undefined") {
      return;
    }

    // update the tracking context so that we're ready to use session ids
    const sessionIdPromise = new Promise((resolve) => {
      gtag("get", gaTrackingId, "session_id", resolve);
    });
    const sessionNumPromise = new Promise((resolve) => {
      gtag("get", gaTrackingId, "session_number", resolve);
    });
    const clientIdPromise = new Promise((resolve) => {
      gtag("get", gaTrackingId, "client_id", resolve);
    });

    Promise.all([sessionIdPromise, sessionNumPromise, clientIdPromise]).then(
      function ([sessionId, sessionNumber, clientId]) {
        const ga4Session = {
          sessionId,
          sessionNumber,
          clientId,
        };

        trackingContext.setGa4SessionData(ga4Session);
        window.ga4SessionData = ga4Session;
        window.dispatchEvent(new CustomEvent("ga4Loaded", null));
      }
    );
  }, [gaTrackingId, typeof gtag !== "undefined", isGpcEnabled]);

  useEffect(() => {
    // we need to wait until the user context has been updated with data (hydrated)
    // before allowing the script to render
    const shouldRender = !isGpcEnabled && hydrated && !IsUserBot();
    if (shouldRender) {
      setShouldRenderGA4(true);
    }
  }, [hydrated, isGpcEnabled]);


  if (isGpcEnabled || !renderGA4 || !gaTrackingId) {
    return false;
  }

  const webUserGuid = GetUserGuid();
  const ga4UserIdKeyValueOrBlank =
    webUserGuid && isRegistered ? `'user_id': '${webUserGuid}',` : "";
  return (
    <>
      <Script
        id="ga4-script"
        data-testid="ga4-script"
        src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`}
        strategy="afterInteractive"
      />
      <Script id="ga4-script-config" strategy="afterInteractive">
        {`
            // this is somewhat redundant as we have it in SsrScripts.js (first scripts to fire)
            // but it helps with storybook
            window.dataLayer = window.dataLayer || [];
                window.gtag = function() {
                dataLayer.push(arguments);
            }
            /////////////////////////////////////

            window.gtag("js", new Date());
            window.gtag("config", "${gaTrackingId}", {
              ${ga4UserIdKeyValueOrBlank}
              'content_group': '${
                trackingContext?.ga4ContentGroup?.toString() ?? AppName
              }'
            });
        `}
      </Script>
    </>
  );
}
