import React, { useContext, useEffect, useState } from 'react'
import Modal from 'Components/Modal/ModalWithPortal'
import { NavigationContext } from 'Contexts/Navigation/NavigationContext'
import styles from './PushNoteUserPermissionPrompt.module.scss'
import Divider from 'Atoms/Divider'
import Typography from 'Atoms/Typography'
import Svg from 'Atoms/Svg'
import Image from 'next/image'

import { getCurrentUserPushPermissions, handleUserPermissionPromptDismiss, handleUserPermissionPromptSubmission } from '../../Hooks/useAppInstall/NotificationUtilities'
import Gutters from '../../Atoms/Gutters/Gutters'
import Switch from '../../Atoms/Form/Elements/Switch'
import Button from '../../Molecules/Button/Button'
import { createClickedSegmentEvent } from '../../Utilities/Instrumentation/Impressions/impressionSegmentEvents'

const PushNoteUserPermissionPrompt = () => {
    const { state: navigationState, dispatch: navigationDispatch } = useContext(NavigationContext)
    const [permissions, setPermissions] = useState([])
    const [submitting, setSubmitting] = useState(false)
    useEffect(() => {

        const hydrate = async () => {
            if (navigationState?.pushNotePermissionPromptState?.isOpen && !permissions.length) {
                const perm = await getCurrentUserPushPermissions();
                setPermissions(perm);
            }
        }

        hydrate();
    }, [navigationState?.pushNotePermissionPromptState?.isOpen])

    const onClose = async () => {
        setSubmitting(false)
        navigationDispatch({
            type: 'setPushNotePermissionPromptState',
            payload: {
                pushNotePermissionPromptState: {
                    isOpen: false
                }
            }
        })
    }

    if (!navigationState?.pushNotePermissionPromptState?.isOpen || !permissions?.length) {
        return;
    }


    const change = (e, type) => {
        let newPermissions = permissions.map(x => x)
        let toUpdate = newPermissions.find(y => y.type === type)
        toUpdate.granted = e
        setPermissions(newPermissions)
    }

    const onCloseWrapper = () => { handleUserPermissionPromptDismiss(onClose) }


    return <Modal
        id={"PushNoteUserPermissionPrompt"}
        defaultOpened
        customOnClose={onCloseWrapper}
        allowOutsideClickClose={false}
        closeButton={false}
    >
        <div className={styles.modal}>
            <Typography size={1.25}>Enable Push Notifications?</Typography>
            {permissions.map(x =>
                <div className={styles.row} key={`${x.type}`}>

                    <Switch
                        id={`PushNoteUserPermissionPrompt_switch_${x.type}`}
                        brand={'primary'}
                        checked={x.granted}
                        disabled={submitting}
                        onChange={(e) => change(e, x.type)} />
                    <Typography>{x.displayName}</Typography>
                </div>

            )}
            <div className={styles.buttonRow}>
                <Button intent={'subdued'} size={'small'} disabled={submitting} event={{}} onClick={onCloseWrapper} text={'No, Thanks'} segmentEvent={createClickedSegmentEvent('PushNotificationDeny')} />
                <Button brand={'secondary'} size={'small'} isWaiting={submitting} event={{}} onClick={() => { setSubmitting(true), handleUserPermissionPromptSubmission(permissions, onClose) }} text={'Enable'} segmentEvent={createClickedSegmentEvent('PushNotificationAccept')} />
            </div>

        </div>



    </Modal>
}

export default PushNoteUserPermissionPrompt