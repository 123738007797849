import React from 'react';
import Typography from 'Atoms/Typography';
import ErrorBoundary from 'UtilityComponents/ErrorBoundary';
import RecommendationsSkeleton from "Molecules/RecommendationsSkeleton";
import styles from './RecommendationsCard.module.scss';
import RecommendationCard from 'Molecules/RecommendationCard';
import Card from 'Atoms/Card';
import Divider from "Atoms/Divider";
import classNames from "classnames";
import AppName from 'AppName';

const RecommendationsCard = ({
  recBlock,
  dataTestId,
  blockIndex,
  isLoading,
  cardDisplayType,
  numberOfSkeletons,
  numberOfSkeletonSections,
  skeletonType,
  useDivider,
  useSubtitle,
  useItemCount,
  horizontalCard,
}) => {
  cardDisplayType = (cardDisplayType || recBlock?.cardDisplayType)?.toLowerCase();

  return (
    <ErrorBoundary>
      {isLoading ?
        <RecommendationsSkeleton
          dataTestId={dataTestId}
          displayType={"Card"}
          isCard={false}
          cardDisplayType={cardDisplayType}
          numberOfSkeletons={numberOfSkeletons}
          numberOfSkeletonSections={numberOfSkeletonSections}
          skeletonType={skeletonType}
          blockIndex={blockIndex}
          useDivider={useDivider}
          useSubtitle={useSubtitle}
          useItemCount={useItemCount}
          horizontalCard={horizontalCard}
        />
        :
        <Card>
          <Typography as={'h2'} size={1.25} font={'bold'} className={styles.title}>
            {recBlock.title}
          </Typography>
          {useSubtitle ? <Typography size={0.875} font={'regular'} className={styles.title}>{recBlock.subtitle}</Typography> : false}
          {useItemCount ?
            <Typography size={0.875} font={'regular'} className={styles.title}>
              {recBlock.recommendations?.length != 1 ?
                `${recBlock.recommendations?.length} items` : '1 item'}
            </Typography>
            : false}
          {useDivider ? <Divider className={styles.divider} /> : <div className={styles.noDivider} />}
          {getCardType(cardDisplayType)}
        </Card>
      }
    </ErrorBoundary>
  )

  function getCardType(cardDisplayType) {
    if (cardDisplayType == "grid" && recBlock.recommendations.length > 2) {
      return (
        <div className={styles.grid_rec_wrapper}>
          {recBlock.recommendations.splice(0, 4).map((rec, index) => {
            return (<div data-testid={`${dataTestId}_grid_card_${blockIndex}_rec_${index}`}>
              <RecommendationCard
                key={index}
                recBlock={recBlock}
                rec={rec}
                index={index}
                dataTestId={dataTestId}
                horizontal={horizontalCard}
              />
            </div>)
          })}
        </div>);
    } else if (cardDisplayType == "double" && recBlock.recommendations.length > 1) {
      const contentType = (recBlock.recommendations?.[0]?.__typename == "LandingPage" &&
        recBlock.recommendations?.[0]?.landingPageDetails.displayValue != null);

      return (
        <div className={contentType || horizontalCard ? styles.double_landing_page_rec_wrapper : styles.grid_rec_wrapper}>
          {recBlock.recommendations.splice(0, 2).map((rec, index) => {
            return (<div className={styles.double_rec_base} data-testid={`${dataTestId}_double_card_${blockIndex}_rec_${index}`}>
              <RecommendationCard
                key={index}
                recBlock={recBlock}
                rec={rec}
                index={index}
                dataTestId={dataTestId}
                horizontal={horizontalCard}
              />
            </div>)
          })}
        </div>);
    } else {
      return (
        <div className={styles.single_rec_wrapper} data-testid={`${dataTestId}_single_card_${blockIndex}_rec`}>
          <RecommendationCard
            recBlock={recBlock}
            rec={recBlock.recommendations[0]}
            index={0}
            dataTestId={dataTestId}
            horizontal={horizontalCard}
          />
        </div>);
    }
  }
};

export default RecommendationsCard