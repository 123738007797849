import React from "react";
import styles from "./RecommendationsCompare.module.scss";
import RecommendationsSkeleton from "Molecules/RecommendationsSkeleton";
import ErrorBoundary from "UtilityComponents/ErrorBoundary";
import Typography from "Atoms/Typography";
import ProductComparisonTable from "Organisms/ProductComparisonTable/ProductComparisonTable";

const RecommendationsCompare = ({
  recBlock,
  dataTestId,
  blockIndex,
  isLoading,
  numberOfSkeletons,
  numberOfSkeletonSections,
  skeletonType,
  treatment,
}) => {
  return (
    <ErrorBoundary>
      <RecommendationsCompareBase
        recBlock={recBlock}
        isLoading={isLoading}
        numberOfSkeletons={numberOfSkeletons}
        numberOfSkeletonSections={numberOfSkeletonSections}
        skeletonType={skeletonType}
        dataTestId={dataTestId}
        blockIndex={blockIndex}
        treatment={treatment}
      />
    </ErrorBoundary>
  );
};

const RecommendationsCompareBase = ({
  recBlock,
  dataTestId,
  blockIndex,
  isLoading,
  numberOfSkeletons,
  numberOfSkeletonSections,
  skeletonType,
  treatment
}) => {
  if (!recBlock) {
    console.error("The recBlock prop cannot be null");
    return false;
  }

  return (
    <div>
      {isLoading ? (
        <RecommendationsSkeleton
          dataTestId={dataTestId}
          displayType={"Grid"}
          numberOfSkeletons={numberOfSkeletons}
          numberOfSkeletonSections={numberOfSkeletonSections}
          skeletonType={skeletonType}
          blockIndex={blockIndex}
        />
      ) : (
        <>{recBlock.title ?
          <Typography
            as={"h2"}
            size={1.25}
            font={"bold"}
            className={styles.title}
            id={"compare_title"}
          >
            {recBlock.title}
          </Typography> : null}

          <ProductComparisonTable recBlock={recBlock} treatment={treatment} dataTestId={dataTestId} />

        </>
      )}
    </div>
  );
};



export default RecommendationsCompare;
