import React, { useContext, useRef, useState, useEffect } from 'react'
import ClassNames from 'classnames'
import { StorageAccessor } from 'Utilities/LocalStorageUtility'
import Button from "Molecules/Button";
import Svg from 'Atoms/Svg'
import Modal from 'Components/Modal/ModalWithPortal'
import Panel from 'Atoms/Panel'
import Typography from 'Atoms/Typography'
import ColorFinder from 'Utilities/ColorFinder'
import { NotificationContext } from 'Contexts/NotificationContext/NotificationContext'
import Skeleton from 'Atoms/Skeleton'
import styles from './Banners.module.scss'
import styled from 'styled-components'
import { createClickedSegmentEvent } from '../../../Utilities/Instrumentation/Impressions/impressionSegmentEvents';



const BannerHeaderBand = ({
  id,
  title,
  mobileTitle,
  description,
  promoCode,
  promoCodeDescription,
  endDate,
  bannerIntent,
  icon,
  url,
  linkText,
  bannerType,
  index,
  dismissCallback
}) => {
  const { PushNotification } = useContext(NotificationContext);
  const modal = useRef(null)
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    if (modalOpen) modal?.current?.open();
    if (!modalOpen) modal?.current?.close();
  }, [modalOpen])

  useEffect(() => {
    sendBannerEvent('Viewed')
  }, [])


  const sendBannerEvent = (eventType) => {
    try {
      if (eventType == `Viewed`) {
        return;
      }
      analytics.track(`Promotion ${eventType}`, {
        promotion_id: promoCode ?? url ?? 'not_set',
        creative: `header_${bannerType.toLowerCase()}_banner`,
        name: title,
        position: `header_banner_${index}`
      })
    } catch (e) {
      console.log('unable to send segment event for banner')
    }
  }


  if (!title && !description) {
    return null;
  }

  if (bannerType == 'promotion') {
    const onPromoClick = (e, promoCode, title, index) => {
      e.stopPropagation();
      if (navigator && navigator.clipboard) {
        navigator.clipboard.writeText(promoCode);
        PushNotification(`Promo code copied to clipboard`, null, 3000, null, {
          icon: 'check',
          intent: 'positive',
        });
      }
      sendBannerEvent('Clicked');
    };

    const promoCodeContent = promoCode ? (
      <div
        className={styles.promoCodeWrapper}
        onClick={(e) => onPromoClick(e, promoCode, title, index)}
        data-testid={'promoCode'}
      >
        <Typography
          className={ClassNames(
            styles.promoCodeText,
            styles.promoCode
          )}
          font={'regular'}
          tone={'subtle'}
          size={0.875}
          inline
        >
          {'PROMO CODE'}
        </Typography>
        <Typography
          className={ClassNames(styles.promoCodeText)}
          font={'regular'}
          tone={'subtle'}
          size={0.875}
          inline
        >
          {` ${promoCode}`}
        </Typography>
      </div>
    ) : false;

    const linkStyling = ColorFinder('action', null, null)

    const modalOpen = () => {
      sendBannerEvent('Clicked');
      if (!promoCodeDescription && url) {
        window.location.href = url;
        return;
      }
      setModalOpen(true)
    }

    return (
      <>

        <Panel intent={bannerIntent} className={styles.banner}>
          <div
            className={ClassNames(
              styles.bannerContentWrapper,
              styles.promotion
            )}
            data-testid={'promotionBanner-' + id}
            onClick={modalOpen}
          >
            <div className={styles.desktop}>
              <div className={styles.bannerDescTitleWrapper}>
                <Typography
                  className={ClassNames(
                    styles.bannerText,
                    styles.bannerTitle
                  )}
                  font={'bold'}
                  tone={'contrast'}
                  size={1}
                  inline
                >
                  {`${title}`}
                </Typography>
                <Typography
                  className={ClassNames(
                    styles.bannerText,
                    styles.bannerDescription
                  )}
                  font={'regular'}
                  tone={'contrast'}
                  size={1}
                  inline
                >
                  {`${description}`}
                </Typography>
              </div>
            </div>
            <div className={styles.desktop}>{promoCodeContent}</div>
            <div className={styles.mobile}>
              <Typography
                className={ClassNames(
                  styles.bannerText,
                  styles.bannerTitle,
                  styles.bannerMobileTitle
                )}
                tone={'contrast'}
                size={1}
              >
                {mobileTitle}
              </Typography>
            </div>
            <Svg className={styles.svg} icon={icon} size={1} tone={'normal'} />
          </div>
          {dismissCallback ? <Svg testId={'siteWideBannerDismiss'} className={ClassNames(styles.svg, styles.close)} icon={'close'} size={1} tone={'normal'} onClick={dismissCallback} hoverable /> : false}
        </Panel>
        <Modal
          id={'bannerModal'}
          outsideClick
          closeButton
          customOnClose={() => setModalOpen(false)}
          ref={modal}
        >
          <Panel layer={0} className={ClassNames(styles.promotionModal)}>
            <Typography
              className={ClassNames(styles.modalText, styles.modalTitle)}
              font={'heading'}
              tone={'normal'}
              size={2}
            >
              {`${title}`}
            </Typography>
            <Typography
              className={styles.modalText}
              font={'regular'}
              tone={'normal'}
              size={1.0}
            >
              {`${description}`}
            </Typography>
            {promoCodeDescription ?
              <Typography
                as={'div'}
                className={styles.modalText}
                font={'regular'}
                tone={'normal'}
                size={0.875}>
                <StyledDangerousHtml
                  dangerouslySetInnerHTML={{ __html: promoCodeDescription }}
                  linkStyling={linkStyling} />
              </Typography> : false}
            {url && linkText ?
              <Button intent={'action'} segmentEvent={createClickedSegmentEvent('BannerLink')} size={'small'} href={url} text={linkText} />
              : null
            }
            {endDate ?
              <Typography
                className={styles.modalText}
                font={'regular'}
                tone={'normal'}
                size={0.875}
              >
                {`Offer valid until: ${new Date(endDate).toLocaleDateString()}`}
              </Typography> : false}
            {promoCode ?
              <div onClick={e => onPromoClick(e, promoCode, title, index)} className={ClassNames(styles.promoCodeWrapper, styles.modalPromoCodeWrapper)}>
                <Typography
                  className={styles.modalText}
                  font={'bold'}
                  tone={'normal'}
                  size={0.875}
                >
                  {`Using Promotion Code: ${promoCode}`}
                </Typography>
              </div> : false
            }

          </Panel>
        </Modal>
      </>
    );
  } else {
    return (
      <Panel
        className={styles.banner}
        style={{ backgroundColor: '#FEF5E5' }}
      >
        <div
          className={styles.bannerContentWrapper}
          data-testid={'maintenanceBanner-' + id}
        >
          <Svg
            className={styles.svg}
            icon={icon}
            size={1}
            intent={'warning'}
            tone={'normal'}
            style={{ marginRight: '10px' }}
          />
          <div className={styles.bannerDescTitleWrapper}>
            <div className={ClassNames(styles.desktop, 'bg-light')}>
              <Typography
                className={ClassNames(
                  styles.bannerText,
                  styles.bannerTitle
                )}
                font={'bold'}
                tone={'contrast'}
                size={1}
                inline
              >
                {`${title}: `}
              </Typography>
              <Typography
                className={styles.bannerText}
                font={'regular'}
                tone={'normal'}
                size={1}
                inline
              >
                {description}
              </Typography>
            </div>
            <div className={ClassNames(styles.mobile, 'bg-light')}>
              <Typography
                className={ClassNames(
                  styles.bannerText,
                  styles.bannerTitle
                )}
                tone={'contrast'}
                size={1}
              >
                {mobileTitle}
              </Typography>
            </div>
          </div>
        </div>
      </Panel>
    );
  }
};

const PromotionBanner = (props) => {
  return (
    <BannerHeaderBand
      {...props}
      bannerType={'promotion'}
      bannerIntent={'action'}
      icon={'moreInformation'}
    />
  );
};

const MaintenanceBanner = (props) => {
  return (
    <BannerHeaderBand {...props} bannerType={'maintenance'} bannerIntent={'warning'} icon={'warning'} />
  );
};

const PlaceholderBanner = () => {
  return <Skeleton className={styles.skeleton} height={34} fullWidth={true} />
}

BannerHeaderBand.PromotionBanner = PromotionBanner;
BannerHeaderBand.MaintenanceBanner = MaintenanceBanner;
BannerHeaderBand.PlaceholderBanner = PlaceholderBanner;

const StyledDangerousHtml = styled.p`
  a {
    color: ${(props) => props.linkStyling.color};
    &:hover {
      color: ${(props) => props.linkStyling.hoverColor};
    }
  }
`

export default BannerHeaderBand;
