import { useEffect } from "react";
import AppName from "AppName";

import { useRecommendationStore } from "../RecommendationStore";
import { useUserStore } from "Stores/UserStore/UserStore";
import { getSuperMarketAffinity } from "Utilities/Instrumentation/SegmentUtilities/segmentFunctions";
import useSegment from "Hooks/useSegment";


const SendRecommendationImpressions = ({set}) => {

    //REC STORE
    const isLoading = useRecommendationStore((x) => x.isLoading);
    const recommendationSectionsInView = useRecommendationStore((x) => x.recommendationSectionsInView);
    const hasHydratedClientProductData = useRecommendationStore((x) => x.hasHydratedClientProductData);
    const recommendationData = useRecommendationStore((x) => x.recommendationData);
    const recommendationImpressionsSent = useRecommendationStore((x) => x.recommendationImpressionsSent);

    //USER STORE
    const userVinGet = useUserStore((x) => x.context.userVin);
    const getGuid = useUserStore((x) => x.context.getGuid);

    //SEGMENT
    const { sendSegmentTrackEventWithName } = useSegment();

    useEffect(() => {
        // there's a timing issue between sections being in view and all the data being loaded
        // this use effect will ensure that recommendation impressions are sent
        // for in view sections (ONLY ONCE) and that it happens after the sections are loaded
        if (!isLoading && hasHydratedClientProductData) {
            // this means that the recs are loaded (we start with isLoading true by default)
            const inViewSections = Object.entries(recommendationSectionsInView)
                .filter(([_, sectionInView]) => sectionInView)
                .map(([section]) => section);
            inViewSections.forEach((section) =>
                sendSegmentImpressionsForSection(section)
            );
        }
    }, [isLoading, recommendationSectionsInView, hasHydratedClientProductData]);

    const sendSegmentImpressionsForSection = (section) => {
        // don't send the same impressions twice
        if (recommendationImpressionsSent?.[section]) {
            return;
        }

        const recs = recommendationData?.recommendationSections?.find(
            (section) => (x) => x.sectionType === section
        );

        const allRecommendations =
            recs?.recommendationGroups?.flatMap((rec) => rec?.recommendations) ?? [];
        if (!allRecommendations || !allRecommendations.length) {
            return;
        }

        // todo
        // do we want to send impressions for articles?
        // what about landing pages?
        // not yet... at least for parity
        sendSegmentTrackEventWithName("Product List Viewed", {
            user_vin: `${userVinGet()}`,
            web_user_guid: `${getGuid()}`,
            list_id: "Recommendations " + section,
            category: AppName,
            products: allRecommendations
                .filter(
                    (rec) =>
                        rec.publicSkuBaseDetails != null ||
                        rec.publicSkuBaseDetails !== undefined ||
                        rec.comparisonValues != null
                )
                .map((rec, i) => {
                    let price =
                        rec.publicSkuBaseDetails?.salePrice ||
                        parseFloat(rec.pricing?.priceString?.replace("$", "")) ||
                        rec.publicSkuBaseDetails?.pricing?.salePrice ||
                        parseFloat(
                            rec.publicSkuBaseDetails?.pricing?.salePriceString?.replace(
                                "$",
                                ""
                            )
                        );

                    if (!price) {
                        console.error("price is null for segment event");
                        console.error(JSON.stringify(rec));
                    }

                    let availability =
                        rec.publicSkuBaseDetails?.availabilityEnum ?? rec.availabilityEnum;
                    if (!availability) {
                        availability =
                            rec.publicSkuBaseDetails?.availability ?? rec.availability;
                    }

                    let reviews =
                        rec.publicSkuBaseDetails?.reviewCount ?? rec.reviewCount;

                    return {
                        product_id:
                            rec.publicSkuBaseDetails?.skuBaseNumber ?? rec.skuBaseNumber,
                        sku: rec.publicSkuBaseDetails?.skuBaseNumber ?? rec.skuBaseNumber,
                        name: rec.publicSkuBaseDetails?.displayTitle ?? rec.displayTitle,
                        price: price,
                        category:
                            rec.publicSkuBaseDetails?.segmentCategorization ??
                            rec.segmentCategorization,
                        url: rec.publicSkuBaseDetails?.productPageUrl ?? rec.productPageUrl,
                        image_url:
                            rec.publicSkuBaseDetails?.thumbnailImageLink ?? rec.imageLink,
                        quantity: 1,
                        brand: rec.publicSkuBaseDetails?.brand ?? rec.brand,
                        position: i,
                        availability: availability,
                        rating: reviews
                            ? rec.publicSkuBaseDetails?.rating ?? rec.rating
                            : 0,
                        reviews: reviews,
                        audience_affinity: getSuperMarketAffinity(
                            rec.publicSkuBaseDetails ?? rec
                        ),
                    };
                }),
        });
        set((state) => {
            return {
                ...state,
                recommendationImpressionsSent: {
                    ...state.recommendationImpressionsSent,
                    [section]: true,
                }
            }
        });
    }
}

export default SendRecommendationImpressions;