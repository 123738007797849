import React, { useState } from "react";

import Typography from "Atoms/Typography";
import Panel from "Atoms/Panel";
import List from "Molecules/List";

import ExistingProjectHeading from "./ExistingProjectHeading";
import ExistingProjectList from "./ExistingProjectList";
import styles from "./ExistingProjectSection.module.scss";
import ShowAll from "./ShowAll";

const ExistingProjectSection = ({ type, total, projects, haveClearSelection }) => {
  // If section has no content then don't display (CTA is special case)
  if (type !== "CTA" && total < 1) return false;
  
  const [showAll, setShowAll] = useState(false);

  return (
    <Panel className={styles.container} layer={0}>
      <ExistingProjectHeading type={type} total={total} haveClearSelection={haveClearSelection} />
      {type === "CTA" ? (
        <div className={styles.garageFlavorText}>
          <Typography tone={"normal"} size={0.9} font={"regular"}>
            Add vehicles to your garage for easy access to parts, accessories,
            and recommendations.
          </Typography>
        </div>
      ) :
        <>
        <ExistingProjectList showAllState={showAll} customerProjects={projects} />
        <List itemLayout="horizontal" className={styles.dropdownLinks}>
          <ShowAll
            showAllState={showAll}
            setShowAll={setShowAll}
            customerProjects={projects}
            type={type}
          />
        </List>
        </>
      }
    </Panel>
  );
};

export default ExistingProjectSection;
