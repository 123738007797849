import React, { Fragment } from 'react'

import Image from 'next/image'

import Typography from 'Atoms/Typography'
import Card from 'Atoms/Card'
import Area from 'Molecules/Area'
import ErrorBoundry from 'UtilityComponents/ErrorBoundary'
import IndexOptions from 'Components/IndexOptions'

import styles from './kitItems.module.scss'

const KitItems = ({ kitItems, selectKitComponentIndexOption }) => {

  if (!kitItems || kitItems.length == 0)
    return false
  if (!kitItems.every(item => item.indexOptions && item.indexOptions.length > 0))
    return false

  return (
    <ErrorBoundry>
      {kitItems
        .sort((a, b) => {
          return (parseInt(a.ordinality) || a.skuBaseName) - (parseInt(b.ordinality) || b.skuBaseName);
        })
        .map((item, i) =>
          <Fragment key={`item_${item.skuBaseName}_${i}`}>
            <Typography size={0.875} className={styles.kit_item_count}>
              {`Item ${i + 1} of ${kitItems.length}`}
            </Typography>
            <Card fill>
              <div className={styles.kit_item_wrapper}>
                {/* Left Side */}
                <span className={styles.kit_item_image_wrapper}>
                  <Image
                    src={`https:${item.imageUrl}`}
                    width={72}
                    height={72} />
                </span>
                {/* Right Side */}
                <span data-testid={`kit_drawer_component_${i + 1}`}>
                  <Typography tone={'contrast'} font={'bold'}>
                    {item.skuBaseName}
                  </Typography>
                  {(item.indexOptions[0].availability && item.indexOptions[0].availability != 'InStock') &&
                    <Area
                      className={styles.kit_avail}
                      intent={item.indexOptions[0].isPurchaseable ? 'warning' : 'negative'}
                      data-testid={item.indexOptions[0].isPurchaseable ? 'kit_avail_warn' : 'kit_avail_neg'}>
                      <Typography size={0.875}>
                        {item.indexOptions[0].availabilityDisplayCopy || item.indexOptions[0].availability}
                      </Typography>
                    </Area>
                  }
                  <Typography size={0.875} className={styles.kit_item_text_spacer}>
                    {`Qty: ${item.purchaseQuantity}`}
                  </Typography>
                  <IndexOptions
                    indexOptions={item.indexOptions}
                    selectIndexOption={(skuBase, skuVariant, selectedIndexOptions, mostRecentlySelectedIndexOption) => selectKitComponentIndexOption(skuBase, skuVariant, selectedIndexOptions, mostRecentlySelectedIndexOption, i, item.purchaseQuantity)}
                    productPageId={item.skuBaseNumber}
                    buttonCuttoffValue={4} />
                </span>
              </div>
            </Card>
          </Fragment>
        )}
    </ErrorBoundry>
  )
}

export default KitItems