import React from 'react'
import ListItemText from 'Molecules/List/ListItemText'
import ListItemIcon from 'Molecules/List/ListItemIcon'
import Badge from 'Atoms/Badge'

import styles from '../HeaderDrawer.module.scss'

const BadgeDrawerMenuItem = ({
    primaryText,
    secondaryText,
    icon,
    badgeBrand,
    badgeIntent,
    badgeNumber,
    badgeHover,
    removeNumber
}) => {
    let ListItemIconComponent = (
        <ListItemIcon
            icon={icon}
            className={styles.listItemIcon}
            tone={'subtle'}
            size={1.5}
        />
    )

    if (badgeNumber && badgeNumber > 0) {
        ListItemIconComponent = (
            <Badge
                brand={badgeBrand}
                intent={badgeIntent}
                number={removeNumber ? 0 : badgeNumber}
                hover={badgeHover}
                size={'small'}
            >
                <ListItemIcon
                    icon={icon}
                    className={styles.listItemIcon}
                    tone={'subtle'}
                    size={1.5}
                />
            </Badge>
        )
    }
    return (
        <>
            <ListItemText

                primary={primaryText}
                secondary={secondaryText}
                className={styles.listItemTextContainer}
                primaryTypographyProps={{
                    size: 1.25,
                    tone: 'subtle'
                }}
                secondaryTypographyProps={{
                    size: 1.5,
                    tone: 'contrast'
                }}
            />
            {ListItemIconComponent}
        </>
    )
}

export default BadgeDrawerMenuItem
