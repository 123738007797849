import { useState } from "react";

import useFeatureFlag from "Hooks/useFeatureFlag";
import useLockedAsyncEffect from "Hooks/useLockedAsyncEffect";
import Retry from "Utilities/Retry";

import { useUserStore } from "../../../Stores/UserStore/UserStore";
import { useUserSpecificProductDataStore } from "../UserSpecificProductDataStore";


export default function LazyLoadShippingData({ disable }) {
  const [previousZipCode, setPreviousZipCode] = useState(null);

  const trackedSkuBases = useUserSpecificProductDataStore(
    (x) => x.trackedSkuBases
  );
  const shippingDataBySkuBase = useUserSpecificProductDataStore(
    (x) => x.shippingDataBySkuBase
  );
  const addShippingDataForSkuBase = useUserSpecificProductDataStore(
    (x) => x.addShippingDataForSkuBase
  );
  const updateUserZipCode = useUserStore(
    (x) => x.updateUserZipCode
  )

  const shippingEstimateFlag = useFeatureFlag("PRP_FrontEnd_ShippingEstimates");
  const zipCode = useUserStore((x) => x.context.zipCode);

  async function loadProductShippingData() {
    if (!shippingEstimateFlag) {
      return;
    }
    if (disable) {
      return;
    }
    var zip = zipCode;

    if (!zip) {
      return;
    }

    if(zip.includes("-")){
      zip = zip.split("-")[0]
    }

    //checks if the zip string contains non-numeric characters
    if (!/^\d+$/.test(zip)) {
      return;
    }
    if (zip.length !== 5) {
      return;
    }

    if(zipCode.includes("-")){
      updateUserZipCode(zip)
    }

    let skuBasesToRequest = [];

    // If the zip has changed, request shipping data for all tracked skuBases
    if (zip !== previousZipCode) {
      skuBasesToRequest = trackedSkuBases;
    } else {
      skuBasesToRequest = trackedSkuBases.filter(
        (skuBase) => !shippingDataBySkuBase[skuBase]
      );
    }

    if (skuBasesToRequest.length) {
      const module = await Retry(() =>
        import("Utilities/shippingEstimates/skuBaseShippingEstimates")
      );
      const getSkuBaseShippingEstimates = module.default;
      const shippingData = await getSkuBaseShippingEstimates(
        skuBasesToRequest,
        zip
      );
      shippingData.forEach((shipping) => {
        addShippingDataForSkuBase(shipping.skuBase, shipping);
      });
    }
    setPreviousZipCode(zip); // to detect when to full reload all data
  }

  useLockedAsyncEffect(async () => {
    await loadProductShippingData();
  }, [shippingEstimateFlag, disable, trackedSkuBases, zipCode]);

  return false;
}
