import React, {useEffect, useState} from "react";
import classNames from "classnames";
import Image from "next/image";
import PropTypes from "prop-types";
import Skeleton from "../../Atoms/Skeleton";
import styles from "./RecommendationsCategoryGrid.module.scss";
import {imageUrlsByCategory} from "./ImageUrlsByCategory";
import useWindow from "../../Hooks/useWindow";

const SkeletonItem = () => (
    <div className={styles.gridItem}>
        <Skeleton fullHeight fullWidth />
    </div>
);

const fallbackCategories = {
    street: [
        { categoryName: "Engine", url: "/shop/engine~2-10" },
        { categoryName: "Brakes", url: "/shop/brakes~2-3" },
        { categoryName: "Chassis and Suspension", url: "/shop/chassis-and-suspension~2-16" },
        { categoryName: "Cooling and Heating", url: "/shop/cooling-and-heating~2-4" },
        { categoryName: "Audio and Electronics", url: "/shop/audio-and-electronics~2-8" },
        { categoryName: "Steering", url: "/shop/steering~2-15" },
    ],
    race: [
        { categoryName: "Engine", url: "/shop/engine~2-10" },
        { categoryName: "Air and Fuel Delivery", url: "/shop/air-and-fuel-delivery~2-1" },
        { categoryName: "Chassis and Suspension", url: "/shop/chassis-and-suspension~2-16" },
        { categoryName: "Safety Equipment", url: "/shop/safety-equipment~2-14" },
        { categoryName: "Driveline and Axles", url: "/shop/driveline-and-axles~2-5" },
        { categoryName: "Tools and Equipment", url: "/shop/tools-and-equipment~2-17" },
        { categoryName: "Trailer, Towing and Cargo", url: "/shop/trailer-towing-and-cargo~2-133" },
        { categoryName: "Exterior, Accessories and Trim", url: "/shop/exterior-accessories-and-trim~2-2" },
        { categoryName: "Tools and Equipment", url: "/shop/speedway-motors-tools-and-equipment~3-10191-18" },
    ]
};

const RecommendationsCategoryGrid = ({ recBlock, isLoading, audience = "street" }) => {
    const [effectiveAudience, setEffectiveAudience] = useState(audience);
    const [categories, setCategories] = useState([]);

    useWindow((window) => {
        const url = new URL(window.location.href);
        const hasRaceParam = url.searchParams.get('o') === 'r';
        setEffectiveAudience(hasRaceParam ? 'race' : audience);
    });

    useEffect(() => {
        if (isLoading || !recBlock?.recommendations || recBlock.displayType?.toLowerCase() !== "categorygrid") {
            return;
        }

        const recommendedCategories = recBlock.recommendations
            .filter(category =>
                category.url !== null &&
                imageUrlsByCategory[effectiveAudience] &&
                imageUrlsByCategory[effectiveAudience][category.categoryName])
            .map(category => {
                const audienceImages = imageUrlsByCategory[effectiveAudience] || {};
                const imageUrl = audienceImages[category.categoryName];

                return {
                    ...category,
                    imageUrl: `${!imageUrl.includes("https:") ? "https:" : ""}${imageUrl}`,
                    displayName: category.categoryName
                };
            })
            .slice(0, 6);

        if (recommendedCategories.length < 6) {
            const currentFallbacks = fallbackCategories[effectiveAudience] || fallbackCategories.street;
            const existingNames = new Set(recommendedCategories.map(cat => cat.categoryName));

            const additionalCategories = currentFallbacks
                .filter(fallback => !existingNames.has(fallback.categoryName))
                .slice(0, 6 - recommendedCategories.length)
                .map(fallback => ({
                    ...fallback,
                    imageUrl: imageUrlsByCategory[effectiveAudience]?.[fallback.categoryName] ||
                        "https://content.speedwaymotors.com/OtherImages/missingimage2.jpg",
                    displayName: fallback.categoryName
                }));

            recommendedCategories.push(...additionalCategories);
        }

        setCategories(recommendedCategories);
    }, [recBlock?.recommendations, effectiveAudience, isLoading]);

    if (!isLoading && recBlock?.displayType?.toLowerCase() !== "categorygrid") {
        return null;
    }

    return (
        <div className={styles.container}>
            <h2
                className={classNames("p p-bold", styles.title)}
                data-testid="dummyDataTestId"
            >
                {isLoading ? <Skeleton height={48} width={250} /> : "Top Categories For You"}
            </h2>
            <div className={styles.gridWrapper}>
                <div className={styles.grid}>
                    {isLoading
                        ? Array(6).fill().map((_, index) => <SkeletonItem key={index} />)
                        : categories.map((category) => (
                            <a
                                href={category.url}
                                key={category.categoryName}
                                className={styles.gridItem}
                            >
                                <div className={styles.imagePlaceholder}>
                                    <Image
                                        className={styles.image}
                                        src={category.imageUrl}
                                        alt={`${category.displayName} image`}
                                        layout="fill"
                                    />
                                    <div className={styles.categoryInfo}>
                                        <div className={classNames("p p-bold", styles.categoryTitle)}>
                                            {category.displayName}
                                        </div>
                                    </div>
                                </div>
                            </a>
                        ))}
                </div>
            </div>
        </div>
    );
};

RecommendationsCategoryGrid.propTypes = {
    recBlock: PropTypes.shape({
        recommendations: PropTypes.arrayOf(
            PropTypes.shape({
                categoryName: PropTypes.string.isRequired,
                url: PropTypes.string,
            })
        ),
        displayType: PropTypes.string,
    }),
    isLoading: PropTypes.bool,
    audience: PropTypes.string,
};

RecommendationsCategoryGrid.defaultProps = {
    isLoading: false,
    audience: "street"
};

export default RecommendationsCategoryGrid;