import createZustandContext from "Utilities/Zustand/createZustandContext";
import {
  NavigationContext as OldNavigationContext,
  NavigationProvider as OldNavigationProvider,
} from "Contexts/Navigation/NavigationContext";
import { useEffect } from "react";
import useWindow from 'Hooks/useWindow';

const navigationContext = createZustandContext(
  ({ set, get }) => {
    return ({
      vehicleSelectDrawerIsOpen: false,
      closeVehicleSelectDrawer: () => set({ vehicleSelectDrawerIsOpen: false }),
      openVehicleSelectDrawer: (closeVehicleDrawerCallback) => {
        set({ closeVehicleDrawerCallback: closeVehicleDrawerCallback })
        set({ vehicleSelectDrawerIsOpen: true })
      },
    })


    return ({

    // dispatch: (action, payload) => get()[action](payload),
      garage: {
        isGarageModalOpen: false,
        isYmmModalOpen: false,
        isGarageDropdownOpen: false,
        market: "street", // this gets used on the ymm modal to say what tab is opened, TNG cant do this out right. Micro frontends can remove this
      },
      controlBar: {
        isMenuActive: false,
        isShopActive: false,
        isGarageActive: false,
        isAccountActive: false,
        isCartActive: false,
      },

      setGarageState: (garageState) => {
        set((state) => {
        
          if (state.garage.isGarageModalOpen) {
            return {
              garage: {
                ...state.garage,
                ...garageState,
              },
              controlBar: {
                ...state.controlBar,
                isGarageActive: true,
              },
              // pageType: calculatePageType(),
            };
          }
        
          return {
            garage: { ...state.garage, ...garageState },
            pageType: calculatePageType,
          };
        });
      },

      setYmmModal: (isOpen) => {
        get().setGarageState({
          isYmmModalOpen: isOpen,
          market: get().garage.market,
        })
      },

    // setPdpSkeleton: (isVisible, product) => {
    //   set((state) => ({
    //     loadingStates: {
    //       ...state.loadingStates,
    //       pdpSkeleton: { isVisible, product },
    //     },
    //   }));
    // },


    //ask about this because this is confusing in NavigationContext
    setPageType: (pageType) => {
      set({ pageType });
    },

    setControlBarState: (controlBarState) => {
      set((state) => ({
        controlBar: {
          ...state.controlBar,
          ...controlBarState,
        },
        // pageType: calculatePageType,
      }));
    },

    //needs implementation w/ drawer reducer...
    setDrawerState: (drawerState) => {
      set((state) => ({
        drawer: {
          ...state.drawer,
          ...drawerState,
        },
      }));
    },

    setAccountState: (accountState) => {set({ account: accountState})},

    setEmailState: (emailState) => {
      const drawerIsOpen = get().drawer.isDrawerOpen;
      let isSaytModalOpen = false;
      try{
        isSaytModalOpen = !!document.getElementById("sayt_container")
      }catch(e){
        console.error(e)
      }

      // dont open if drawer is open, return prev state
      if (drawerIsOpen || isSaytModalOpen) {
        set((state) => ({
          email: initialState.email,
        }));
      } else {
        set((state) => ({
          email: { ...initialState.email, ...emailState },
        }));
        console.log(get().email)
      }
    },

    setLoyaltyState: (loyaltyState) => {
      const drawerIsOpen = get().drawer.isDrawerOpen;
      let isSaytModalOpen = false;
      try{
        isSaytModalOpen = !!document.getElementById("sayt_container")
      }catch(e){
        console.error(e)
      }

      // dont open if drawer is open, return prev state
      if (drawerIsOpen || isSaytModalOpen) {
        set((state) => ({
          loyalty: { ...initialState.loyalty },
        }));
      } else {
        set((state) => ({
          loyalty: { ...initialState.loyalty, ...loyaltyState },
        }));
      }
    },

    setNonBetaState: (nonBetaState) => {
      const drawerIsOpen = get().drawer.isDrawerOpen;

      set({
        nonBeta: drawerIsOpen ? initialState.nonBeta : { ...initialState.nonBeta, ...nonBetaState },
      });
    },
  })},
  {
    name: "NavigationStore",
    OldContext: OldNavigationContext,
    OldProvider: OldNavigationProvider,
  }
);

export const useNavigationStore = navigationContext.useStore;
export const NavigationProvider = navigationContext.Provider;
