import { useState } from "react";
import { useUserSpecificProductDataStore } from "../UserSpecificProductDataStore";
import { useVehicleStore } from "Stores/VehicleStore";
import { useFeatureFlag } from "Hooks/useFeatureFlag/useFeatureFlag";

import useLockedAsyncEffect from "Hooks/useLockedAsyncEffect";
import Retry from "Utilities/Retry";

export default function LazyLoadFitmentData({ disable }) {
  const [previousFitmentDataFromFitmentFacet, setPreviousFitmentDetailsFromFitmentFacet] = useState({firstRender: true}); //must != null for first fetch
  const [previousTrackedSkuBases, setPreviousTrackedSkuBases] = useState([])

  const trackedSkuBases = useUserSpecificProductDataStore((x) => x.trackedSkuBases);
  const fitmentDataBySkuBase = useUserSpecificProductDataStore((x) => x.fitmentDataBySkuBase);
  const addFitmentDataForSkuBase = useUserSpecificProductDataStore((x) => x.addFitmentDataForSkuBase);
  const fitmentDetailsFromFitmentFacet = useUserSpecificProductDataStore((x) => x.fitmentDetailsFromFitmentFacet);
  const fitmentDetailsFromSelectedFacets = useUserSpecificProductDataStore((x) => x.fitmentDetailsFromSelectedFacets)
  const selectedCustomerProject = useVehicleStore((x) => x.context?.selectedCustomerProject);
  const impressionDontDelete = useFeatureFlag("Engine_Fitment_Master_Flag")
  const engineFitmentChecksEnabled = useFeatureFlag("PRP_New_Engine_Fitment_Checks")

  async function loadProductFitmentData() {
    if (disable){
      return;
    }

    const hasSelectedCustomerProjectFromFacets = engineFitmentChecksEnabled 
      ? fitmentDetailsFromSelectedFacets?.vehicleBaseId 
        || fitmentDetailsFromSelectedFacets?.raceTypeId 
        || fitmentDetailsFromSelectedFacets?.engineManufacturerId 
      : fitmentDetailsFromFitmentFacet?.vehicleBaseId || fitmentDetailsFromFitmentFacet?.raceTypeId
    const hasSelectedCustomerProject = selectedCustomerProject?.vehicleBaseId || selectedCustomerProject?.raceTypeId || selectedCustomerProject?.engineDisplayName

    if (
      !hasSelectedCustomerProjectFromFacets && !hasSelectedCustomerProject
    ) {
      for(let skuBase of Object.keys(fitmentDataBySkuBase)){
        addFitmentDataForSkuBase(skuBase, null);
      }
      setPreviousFitmentDetailsFromFitmentFacet({firstRender: true});
      setPreviousTrackedSkuBases([])
      return;
    }

    //use the vehicle fitment from facets first for PRPs, fallback to customer project
    let fitmentToUse 
    
    if (hasSelectedCustomerProjectFromFacets) 
      fitmentToUse = engineFitmentChecksEnabled ? fitmentDetailsFromSelectedFacets : fitmentDetailsFromFitmentFacet
    else 
      fitmentToUse = selectedCustomerProject

    let skuBasesToRequest = [];

    // If the selected fitment has changed, request fitment data for all tracked skuBases
    if (fitmentToUse != previousFitmentDataFromFitmentFacet) {
      skuBasesToRequest = trackedSkuBases;
    }
    else if (trackedSkuBases.length > 0 && trackedSkuBases.length > previousTrackedSkuBases.length) {
      skuBasesToRequest = trackedSkuBases.filter(
        (skuBase) => !fitmentDataBySkuBase[skuBase]
      );
    }

    if (skuBasesToRequest.length) {
      const module = await Retry(() => import("Utilities/checkFitment/checkFitment"));
      const CheckFitmentForSkuBases = module.default;
      const fitmentData = await CheckFitmentForSkuBases(skuBasesToRequest, fitmentToUse);
      fitmentData?.forEach((fitment) => {
        addFitmentDataForSkuBase(fitment.key, fitment?.value?.fitment?.[0]?.value);
      });
      setPreviousFitmentDetailsFromFitmentFacet(fitmentToUse);
      setPreviousTrackedSkuBases(trackedSkuBases)
    }

  }

  useLockedAsyncEffect(async () => {
    await loadProductFitmentData();
  }, [
    disable,
    trackedSkuBases,
    fitmentDetailsFromFitmentFacet?.vehicleBaseId,
    fitmentDetailsFromFitmentFacet?.raceTypeId,
    fitmentDetailsFromFitmentFacet?.engineDisplayName,
    fitmentDetailsFromSelectedFacets?.engineManufacturerId,
    fitmentDetailsFromSelectedFacets?.engineMakeId,
    fitmentDetailsFromSelectedFacets?.engineSeriesId,
    fitmentDetailsFromSelectedFacets?.engineConfigurationId,
    fitmentDetailsFromSelectedFacets?.engineVersionId,
    fitmentDetailsFromSelectedFacets?.engineQualifierId,
    fitmentDetailsFromSelectedFacets?.engineDisplacementId,
    fitmentDetailsFromSelectedFacets?.engineCodeId,
    selectedCustomerProject?.vehicleBaseId,
    selectedCustomerProject?.raceTypeId,
    selectedCustomerProject?.engineDisplayName,
    engineFitmentChecksEnabled
  ]);

  return false
}
