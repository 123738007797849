import React, { useEffect } from "react";

import AutoComplete from "Atoms/Form/Elements/AutoComplete";
import Panel from "Atoms/Panel";
import Typography from "Atoms/Typography";
import {
  AddUserVehicle,
  FetchRaceTypes,
  getProperUrl,
} from "Contexts/VehicleContext/CoreVehicleRequests";
import Button from "Molecules/Button";
import { useThemeStore } from "Stores/ThemeStore";
import { useVehicleStore } from "Stores/VehicleStore";
import ClassNames from "classnames";
import Image from "next/image";

import useSegment from "../../../../Hooks/useSegment/useSegment";
import { useUserStore } from "../../../../Stores/UserStore/UserStore";
import { GetUserGuid } from "../../../../Utilities/CookieUtilties/UserCookieUtilities";
import { createFitmentAddedSegmentEventFromCustomerProject } from "../../../../Utilities/Instrumentation/Fitment/fitmentSegmentEvents";
import { createClickedSegmentEvent } from "../../../../Utilities/Instrumentation/Impressions/impressionSegmentEvents";
import styles from "../YmmModal.module.scss";


const RaceTypeTab = ({ isEnabled, services, isProductPage }) => {
  if (!isEnabled) {
    return null;
  }

  const updateRaceTypes = useVehicleStore((x) => x.updateRaceTypes);
  const raceTypes = useVehicleStore((x) => x.context.raceTypes);
  const selectedFacetData = useVehicleStore((x) => x.context.selectedFacetData);
  const selectedCustomerProject = useVehicleStore((x) => x.context.selectedCustomerProject);

  const updateUserGuid = useUserStore((x) => x.context.updateUserGuid);

  const setIsLoading = useThemeStore((x) => x.setIsLoading);
  const { sendCustomSegmentTrackEvent, sendSegmentTrackEventWithName } =
    useSegment();

  const options = [];

  raceTypes?.forEach((raceType) => {
    options.push({
      label: raceType?.displayName,
      value: raceType?.raceTypeId,
    });
  });

  useEffect(() => {
    async function fetchAllRaceTypes() {
      let response = await FetchRaceTypes(
        raceTypes,
        services?.traits?.url,
        selectedFacetData
      );
      if (response?.raceTypeLinks) {
        response.raceTypeLinks.sort((raceType1, raceType2) => {
          if (raceType1.priority > raceType2.priority) {
            return 1;
          }
          if (raceType2.priority > raceType1.priority) {
            return -1;
          }
          return 0;
        });
        updateRaceTypes(response, selectedCustomerProject?.raceTypeId);
      }
    }

    fetchAllRaceTypes();
  }, [isEnabled]);

  return (
    <Panel className={styles.modalPanel}>
      <div
        className={`${ClassNames(
          styles.raceTab,
          styles.ymmContainer
        )} lr-RaceSelector`}
      >
        <div className={ClassNames(styles.ymmModalItem, styles.raceTypes)}>
          <AutoComplete
            options={options}
            onChange={async (event) => {
              AddNewRaceTypeToGarage(
                event.value,
                GetUserGuid(),
                services?.customerProjects?.url,
                raceTypes,
                updateUserGuid,
                updateRaceTypes,
                setIsLoading,
                isProductPage,
                selectedFacetData,
                sendSegmentTrackEventWithName,
                true
              );
            }}
            label={"Select Your Race Car"}
            selectedValue={null}
            isDisabled={false}
            placeholder={"Race Car"}
            openMenuOnFocus={false}
          />
        </div>
        <Button
          className={styles.addRaceTypeButton}
          size={"small"}
          intent={"action"}
          fill
          onClick={() => {
            if (selectedCustomerProject?.raceTypeId !== null)
              window.location.href = getProperUrl(selectedCustomerProject);
          }}
          segmentEvent={createClickedSegmentEvent("AddRaceCar")}
          type={"button"}
          disabled={selectedCustomerProject?.raceTypeId === null}
          text={"Add Race Car"}
        />
      </div>
      <div
        className={ClassNames(styles.hideMobile, styles.raceTypeImageContainer)}
      >
        {raceTypes?.map((raceType) => {
          return (
            <div
              key={raceType.displayName}
              className={styles.raceTypeListItem}
              onClick={async (event) => {
                AddNewRaceTypeToGarage(
                  raceType.raceTypeId,
                  GetUserGuid(),
                  services?.customerProjects?.url,
                  raceTypes,
                  updateUserGuid,
                  updateRaceTypes,
                  setIsLoading,
                  isProductPage,
                  selectedFacetData,
                  sendCustomSegmentTrackEvent,
                  false
                );
              }}
            >
              <Typography
                size={1}
                font={"heading"}
                inline
                className={styles.raceTypeText}
              >
                {raceType.displayName}
              </Typography>
              <Image
                src={raceType.imageUrl}
                alt={raceType.displayName}
                layout={"fill"}
                className={styles.raceTypeImage}
              ></Image>
            </div>
          );
        })}
      </div>
    </Panel>
  );
};

async function AddNewRaceTypeToGarage(
  selectedRaceTypeId,
  userGuid,
  url,
  raceTypes,
  updateUserGuid,
  updateRaceTypes,
  setIsLoading,
  isProductPage,
  selectedFacetData,
  sendCustomSegmentTrackEvent,
  isDropdown
) {
  try {
    setIsLoading(true);
    const racetTypeId = parseInt(selectedRaceTypeId, 10);
    const raceType = raceTypes.find(
      (raceType) => raceType.raceTypeId === racetTypeId
    );

    const response = await AddUserVehicle(
      userGuid,
      url,
      null,
      null,
      null,
      racetTypeId,
      null,
      selectedFacetData
    );

    if (!response) {
      setIsLoading(false);
    }

    const newCustomerProjects = response?.addNewProjectToGarage?.projects;
    if (
      response?.addNewProjectToGarage?.success &&
      !response?.addNewProjectToGarage?.alreadyExisted
    ) {
      const projectId = response?.addNewProjectToGarage?.addedCustomerProjectId;
      const customerProject = newCustomerProjects?.find(x => x.projectId === projectId);
      const segmentEvent = createFitmentAddedSegmentEventFromCustomerProject(customerProject);
      sendCustomSegmentTrackEvent(segmentEvent);
      const trackEvent = createClickedSegmentEvent(
        isDropdown ? "race type dropdown" : "race type tile"
      );
      sendCustomSegmentTrackEvent(trackEvent);
    }

    const user = response?.addNewProjectToGarage?.webUser;
    await updateRaceTypes(
      raceTypes,
      racetTypeId,
      newCustomerProjects
    );
    if (user?.userGuid) {
      updateUserGuid(user?.userGuid);
    }

    let raceCar = raceTypes
      ? raceTypes.find((x) => x.raceTypeId === racetTypeId)
      : null;

    if (raceCar) {
      if (isProductPage) {
        window.location.reload();
        return false;
      } else {
        window.location.href = getProperUrl(raceCar);
      }
    } else {
      throw "Unable to find racecar in vehicle state";
    }
  } catch (err) {
    console.error("Unable to add race vehicle to garage", err);
    setIsLoading(false);
  }
}

export default RaceTypeTab;
