const drawerStates = {
  existingProjects: "EXISTING_PROJECTS",
  addYmm: "ADD_YMM",
  addRaceType: "ADD_RACETYPE",
  addEngine: "ADD_ENGINE",
  updateProjectAddEngine: "UPDATE_PROJECT_ADD_ENGINE",
  updateProjectAddYMM: "UPDATE_PROJECT_ADD_YMM",
  updateProjectAddRaceType: "UPDATE_PROJECT_ADD_RACETYPE"
};

export default drawerStates;
