async function getProductRecommendationData(recommendationData, webUserGuid) {
  try {
    let recommendations = [];
    let skuBaseNumbers = [];
    for (let i = 0; i < recommendationData.recommendationSections.length; i++) {
      for (let j = 0; j < recommendationData.recommendationSections[i].recommendationGroups.length; j++) {
        if (recommendationData.recommendationSections[i].recommendationGroups[j].clientDataType == "PdpRecentHistory") {
          if (recommendationData.recommendationSections[i].recommendationGroups[j].recommendations.some(x => x.clientHydrated)) {
            return recommendationData;
          }
          recommendations = [...recommendations, ...recommendationData.recommendationSections[i].recommendationGroups[j].recommendations];
          skuBaseNumbers = [...skuBaseNumbers, ...recommendationData.recommendationSections[i].recommendationGroups[j].recommendations.map(x => x.skuBase)];
        }
      }
    }

    if (!skuBaseNumbers.length) {
      return recommendationData;
    }

    let productData = await getProductData(skuBaseNumbers, webUserGuid);

    setProductRecommendationData(recommendations, productData);

    for (let i = 0; i < recommendationData.recommendationSections.length; i++) {
      const recs = recommendationData.recommendationSections[i].recommendationGroups.find(x => x.clientDataType === "PdpRecentHistory")
      if (recs) {
        recs.recommendations = recommendations.filter(x => x.clientHydrated);
      }
    }

    return recommendationData;
  }
  catch (err) {
    console.error(err);
    return recommendationData;
  }
}

async function getProductRecommendationDataForSection(section, webUserGuid) {
  try {
    let recommendations = [];
    let skuBaseNumbers = [];
    for (let j = 0; j < section.recommendationGroups?.length; j++) {
      if (section.recommendationGroups[j].clientDataType == "PdpRecentHistory") {
        if (section.recommendationGroups[j].recommendations.some(x => x.clientHydrated)) {
          return section;
        }
        recommendations = [...recommendations, ...section.recommendationGroups[j].recommendations];
        skuBaseNumbers = [...skuBaseNumbers, ...section.recommendationGroups[j].recommendations.map(x => x.skuBase)];
      }
    }

    if (!skuBaseNumbers.length) {
      return section;
    }

    let productData = await getProductData(skuBaseNumbers, webUserGuid);

    setProductRecommendationData(recommendations, productData);

    const recs = section.recommendationGroups.find(x => x.clientDataType === "PdpRecentHistory")
    if (recs) {
      recs.recommendations = recommendations.filter(x => x.clientHydrated);
    }

    return section;
  }
  catch (err) {
    console.error(err);
  }
}


async function getProductData(skuBaseNumbers, webUserGuid) {

  const variables = {
    skuBaseNumbers: skuBaseNumbers?.filter(x => x),//filter out any empties
    webUserGuid,
  }

  const body = JSON.stringify({
    query,
    variables
  })

  let url = '/graphql/products';

  const res = await fetch(url,
    {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body
    })
  const data = await res.json()

  let productData = data.data.skuBases;

  return productData
}

function setProductRecommendationData(recommendations, productData) {
  for (let i = 0; i < recommendations.length; i++) {
    let matchingProduct = productData.find(x => x.skuBaseNumber == recommendations[i].skuBase);
    if (!matchingProduct) {
      continue;
    }
    recommendations[i].clientHydrated = true;
    recommendations[i].publicSkuBaseDetails.productPageUrl = matchingProduct.productPageUrl;
    recommendations[i].publicSkuBaseDetails.pricing = matchingProduct.pricing;
    recommendations[i].publicSkuBaseDetails.rating = matchingProduct.rating;
    recommendations[i].publicSkuBaseDetails.reviewCount = matchingProduct.reviewCount;
    recommendations[i].publicSkuBaseDetails.availability = matchingProduct.availability;
    recommendations[i].publicSkuBaseDetails.displayTitle = matchingProduct.displayTitle;
    recommendations[i].publicSkuBaseDetails.brand = matchingProduct.brand;
    recommendations[i].publicSkuBaseDetails.segmentCategorization = matchingProduct.segmentCategorization;
    recommendations[i].publicSkuBaseDetails.superMarketAffinity = matchingProduct.superMarketAffinity;
  }
}


const query = `
  query GetSkuBases($skuBaseNumbers: [String], $webUserGuid: String){
    skuBases (skuBaseNumbers: $skuBaseNumbers, webUserGuid: $webUserGuid){
      skuBaseNumber
      displayTitle
      pricing {
        salePriceString
        strikeThroughPrice
        salePrice
      }
      rating
      reviewCount
      availability
      productPageUrl
      brand
      segmentCategorization
      superMarketAffinity
    }
  }
  `;

export { getProductRecommendationData, getProductRecommendationDataForSection }