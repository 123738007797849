const LoyaltyUserStatus = {
    unknown: `unknown`,
    enrolled: `enrolled`,
    eligible: `eligible`,
    not_eligible: `not_eligible`,
    not_loggedin: `not_loggedin`
}

export default LoyaltyUserStatus

const ShouldShow = (userStatus) => {
    return userStatus != LoyaltyUserStatus.not_eligible && userStatus != LoyaltyUserStatus.unknown
}

export { ShouldShow }