import React, { useContext } from "react";
import { withMemo } from "../withMemo/withMemo";

export const withContext = (Component, Context, mapper, options) => {

  const getProps = () => {
    const context = useContext(Context);
    const mappedProps = mapper(context);
    return mappedProps
  }

  return withMemo(Component, getProps, options)
};

export const withContexts = (Component, Contexts, mapper, options) => {

  if (!Contexts?.length) {
    console.error("withContexts: Contexts must be defined and an array");
  }
  if (Contexts.length > 5) {
    console.error("withContexts: only supports up to 5 contexts");
  }

  const getProps = () => {
    const first = useContext(Contexts[0] ?? {});
    const second = useContext(Contexts[1] ?? {});
    const third = useContext(Contexts[2] ?? {});
    const fourth = useContext(Contexts[3] ?? {});
    const fifth = useContext(Contexts[4] ?? {});
    const mappedProps = mapper(first, second, third, fourth, fifth);
    return mappedProps
  }

  return withMemo(Component, getProps, options)
};
