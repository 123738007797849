import useTikTokSaveClickParameter from "./useTikTokSaveClickParameter"
import useCjAffiliateSaveClickParameter from "./useCjAffiliateSaveClickParameter"


function ClickParameters(){
    useCjAffiliateSaveClickParameter()
    useTikTokSaveClickParameter()

    return false
}

export default ClickParameters