import React, { useEffect, useRef, useState } from "react";

import AutoComplete from "Atoms/Form/Elements/AutoComplete/AutoComplete";
import Panel from "Atoms/Panel";
import Typography from "Atoms/Typography";
import {
  AddUserVehicle,
  FetchYearMakeModel,
  getProperUrl,
} from "Contexts/VehicleContext/CoreVehicleRequests";
import Button from "Molecules/Button";
import { useThemeStore } from "Stores/ThemeStore";
import ClassNames from "classnames";

import useSegment from "../../../../Hooks/useSegment/useSegment";
import { useUserStore } from "../../../../Stores/UserStore/UserStore";
import { useVehicleStore } from "../../../../Stores/VehicleStore";
import { GetUserGuid } from "../../../../Utilities/CookieUtilties/UserCookieUtilities";
import { createFitmentAddedSegmentEventFromCustomerProject } from "../../../../Utilities/Instrumentation/Fitment/fitmentSegmentEvents";
import { createClickedSegmentEvent } from "../../../../Utilities/Instrumentation/Impressions/impressionSegmentEvents";
import styles from "../YmmModal.module.scss";


const initialStreetVehicleState = {
  year: {
    value: null,
    label: null,
  },
  make: {
    value: null,
    label: null,
  },
  model: {
    value: null,
    label: null,
  },
};

const StreetTab = ({ isEnabled, services }) => {
  if (!isEnabled) {
    return null;
  }

  const vehicleState = useVehicleStore((x) => x.context);
  const selectedFacetData = useVehicleStore((x) => x.context.selectedFacetData);
  const models = useVehicleStore((x) => x.context.models);
  const years = useVehicleStore((x) => x.context.years);
  const makes = useVehicleStore((x) => x.context.makes);
  const allYears = useVehicleStore((x) => x.context.allYears);
  const prioritizedMakes = useVehicleStore((x) => x.context.prioritizedMakes);
  const reloadCurrentLocation = useVehicleStore(
    (x) => x.context.reloadCurrentLocation
  );
  const selectYearMakeModel = useVehicleStore((x) => x.selectYearMakeModel);
  const updateYearMakeModelData = useVehicleStore(
    (x) => x.updateYearMakeModelData
  );

  const updateUserGuid = useUserStore((x) => x.context.updateUserGuid);

  const setIsLoading = useThemeStore((x) => x.setIsLoading);
  const [streetVehicleState, setStreetVehicleState] = useState(
    initialStreetVehicleState
  );
  const [submitHref, setSubmitHref] = useState(null);
  const { sendCustomSegmentTrackEvent } = useSegment();

  useEffect(() => {
    const { year, make, model } = streetVehicleState;

    async function fetchAllYMM() {
      var response = await FetchYearMakeModel(
        vehicleState,
        year.value,
        make.value,
        model.value,
        services?.traits?.url,
        selectedFacetData
      );

      if (response) {
        if (!model.value) {
          updateYearMakeModelData(response.ymmData);
        }
        let selectedModel = models
          ? models.find((x) => x.optionId === model.value)
          : [];

        try {
          if (selectedModel) {
            setSubmitHref(getProperUrl(selectedModel));
          }
        } catch (e) {
          console.error(e);
        }

        UpdateUsersGarage(
          year.value,
          make.value,
          make.label,
          model.value,
          model.label,
          GetUserGuid(),
          services?.customerProjects?.url,
          reloadCurrentLocation,
          selectedModel,
          selectYearMakeModel,
          updateUserGuid,
          setIsLoading,
          selectedFacetData,
          sendCustomSegmentTrackEvent
        );
      }
    }

    fetchAllYMM();
  }, [isEnabled, streetVehicleState]);

  const makeRef = useRef();
  const modelRef = useRef();
  const yearRef = useRef();

  useEffect(() => {
    if (yearRef?.current) {
      yearRef.current.focus();
    }
  }, [years]);

  useEffect(() => {
    if (makeRef?.current) {
      makeRef.current.focus();
    }
  }, [makes]);

  useEffect(() => {
    if (modelRef?.current) {
      modelRef.current.focus();
    }
  }, [models]);

  return (
    <Panel className={styles.modalPanel}>
      <div className={ClassNames(styles.streetTab, styles.ymmContainer)}>
        <YmmDropdown
          placeholder={"Select a Year"}
          label={"Year"}
          className={styles.dropdownTitle}
          selectedValue={streetVehicleState.year}
          dropdownData={allYears}
          onChange={(option) => {
            setStreetVehicleState((prevState) => ({
              ...initialStreetVehicleState,
              year: {
                value: parseInt(option.value, 10),
                label: option.label,
              },
            }));
          }}
          selectRef={yearRef}
        />
        <YmmDropdown
          placeholder={"Select a Make"}
          label={"Make"}
          selectedValue={streetVehicleState.make}
          dropdownData={makes}
          className={styles.dropdownTitle}
          onChange={(option) => {
            setStreetVehicleState((prevState) => ({
              ...initialStreetVehicleState,
              year: prevState.year,
              make: {
                value: parseInt(option.value, 10),
                label: option.label,
              },
            }));
          }}
          selectRef={makeRef}
          prioritizedMakes={prioritizedMakes}
        />
        <YmmDropdown
          placeholder={"Select a Model"}
          label={"Model"}
          selectedValue={streetVehicleState.model}
          className={styles.dropdownTitle}
          dropdownData={models}
          onChange={(option) => {
            setStreetVehicleState((prevState) => ({
              ...prevState,
              model: {
                value: parseInt(option.value, 10),
                label: option.label,
              },
            }));
          }}
          selectRef={modelRef}
        />
        <div className={styles.ymmModalItem}>
          <Button
            className={styles.addVehicleButton}
            intent={"action"}
            fill
            size={"small"}
            segmentEvent={createClickedSegmentEvent("VehicleAdded")}
            type={"button"}
            disabled={streetVehicleState?.model?.value === null || !submitHref}
            href={submitHref}
          >
            <Typography
              className={styles.buttonText}
              tone={"contrast"}
              size={0.75}
              font={"regular"}
              inline
            >
              Add Vehicle
            </Typography>
          </Button>
        </div>
      </div>
    </Panel>
  );
};

const YmmDropdown = ({
  label,
  placeholder,
  selectedValue,
  dropdownData,
  onChange,
  prioritizedMakes,
  selectRef,
}) => {
  let options = [];

  if (prioritizedMakes?.length > 0) {
    prioritizedMakes.forEach((data) => {
      options.push({ label: data.displayName, value: data.optionId });
    });
  }

  let emptyStyling = "";

  if (dropdownData?.length > 0) {
    dropdownData.forEach((data) => {
      options.push({ label: data.displayName, value: data.optionId });
    });
  } else {
    emptyStyling = styles.streetEmptyDropdown;
  }

  let isDisabled = !dropdownData || dropdownData.length === 0;

  return (
    <div
      className={`${ClassNames(styles.ymmModalItem, emptyStyling)} ${
        isDisabled ? "" : `lr-${label}Selector`
      }`}
    >
      <AutoComplete
        options={options}
        onChange={(value) => onChange(value)}
        label={label}
        selectedValue={selectedValue}
        isDisabled={isDisabled}
        placeholder={placeholder}
        ref={selectRef}
      />
    </div>
  );
};

async function UpdateUsersGarage(
  year,
  makeId,
  make,
  modelId,
  model,
  currentUserGuid,
  serviceUrl,
  reloadCurrentLocation,
  selectedModel,
  selectYearMakeModel,
  updateUserGuid,
  setIsLoading,
  selectedFacetData,
  sendCustomSegmentTrackEvent
) {
  if (year === null || makeId === null || modelId === null) {
    return;
  }
  try {
    setIsLoading(true);

    const response = await AddUserVehicle(
      currentUserGuid,
      serviceUrl,
      year,
      makeId,
      modelId,
      null,
      null,
      selectedFacetData
    );

    if (!response) {
      setIsLoading(false);
    }

    const newCustomerProjects = response?.addNewProjectToGarage?.projects;
    if (
      response?.addNewProjectToGarage?.success &&
      !response?.addNewProjectToGarage?.alreadyExisted
    ) {
      const projectId = response?.addNewProjectToGarage?.addedCustomerProjectId;
      const customerProject = newCustomerProjects?.find(x => x.projectId === projectId);
      const segmentEvent = createFitmentAddedSegmentEventFromCustomerProject(customerProject);
      sendCustomSegmentTrackEvent(segmentEvent);
    }

    await selectYearMakeModel(
      year,
      makeId,
      modelId,
      newCustomerProjects
    );
    // if no user don't continue
    if (response?.addNewProjectToGarage?.webUser?.userGuid) {
      updateUserGuid(response?.addNewProjectToGarage?.webUser?.userGuid);
    } else {
      console.error("no web user guid " + JSON.stringify(response));
    }

    if (reloadCurrentLocation) {
      // return false or it skips to line below
      window.location.reload();
      return false;
    }

    window.location.href = getProperUrl(selectedModel);
  } catch (err) {
    console.error("Unable to Update Users Garage", err, year, makeId, modelId);
    setIsLoading(false);
  }
}

export default StreetTab;
