import CookieReader from 'Utilities/CookieUtilties/CookieReader'
import BotFilter from '../BotFilter/BotFilter';

export function GetUserGuid() {
    return CookieReader("WebUserGuid");
}

export function GetDeviceGuid() {
    return CookieReader("DeviceId");
}

export function GetUserVin() {
    return CookieReader("VIN");
}

export function IsUserBot() {
    const vin = GetUserVin();
    if (vin.toLowerCase().startsWith("bot-")) {
        return true;
    }
    if (typeof (navigator) != 'undefined') {
        if (BotFilter(navigator.userAgent)) {
            return true
        }
        return navigator.userAgent.toLowerCase().includes('bot')
    }

    return false;

}