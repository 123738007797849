import React, {Fragment} from 'react'
import Typography from 'Atoms/Typography'
import Divider from 'Atoms/Divider'
import List from 'Molecules/List'
import ListItemLink from 'Molecules/List/ListItemLink'
import {buildNewSearchUrl} from '../SaytUtilities/setNewSearchUrl'
import SaytItemText from '../SaytItemText/SaytItemText'
import Svg from 'Atoms/Svg'

import styles from '../SaytDropdown.module.scss'
import {updateRecentSearches} from 'Layout/Header/RecentSearches/RecentSearches'
import {createClickedSegmentEvent} from "Utilities/Instrumentation/Impressions/impressionSegmentEvents";

const SuggestedSearches = ({
    searchTerm,
    saytResults,
    searchUrl,
    ...props
}) => {

    let numberOfSuggestions = 12

    const setNewSearchUrl = (suggestion) => {
        updateRecentSearches(suggestion);
    }

    return (
        <Fragment>
            <div className={styles.hideMobile}>
                <Typography tone={'contrast'} size={0.875} font={'heading'}>
                    SUGGESTED SEARCHES
                </Typography>
                <Divider className={styles.saytHr} />
            </div>
            <div className={saytResults?.suggestedQueries ? styles.saytSearchSuggestions : styles.saytSearchBackground}>
                <List itemLayout={'vertical'} component={'div'}>
                    {saytResults.suggestedQueries ? saytResults.suggestedQueries.slice(0, numberOfSuggestions).map((item, i) => {
                        let url = buildNewSearchUrl(item.query, searchUrl)
                        return (
                            <>
                                <ListItemLink
                                    key={'suggested' + item.ordinality}
                                    className={styles.saytLink}
                                    onClick={() => {
                                        setNewSearchUrl(item.query)
                                        updateRecentSearches(searchTerm)
                                    }}
                                    dataTesting={'suggested' + item.ordinality}
                                    href={url}
                                    hover
                                    segmentEvent={createClickedSegmentEvent("SuggestedQuery")}
                                >
                                    <SaytItemText
                                        searchTerm={searchTerm}
                                        title={item.query}
                                        position={item.ordinality}
                                        titleWithMarkups={item.queryWithMarkups}
                                    />
                                </ListItemLink>
                                {i === 0 && saytResults.categoryLinks?.length ?
                                    <div>
                                        {saytResults.categoryLinks.map(cat => {
                                            return (
                                                <ListItemLink
                                                    key={`partType_${cat.name}`}
                                                    href={cat.url}
                                                    className={styles.suggestedSearchPartTypes}
                                                    hover
                                                    segmentEvent={createClickedSegmentEvent("SuggestedQueryPartType")}
                                                >
                                                    <Svg size={0.75} tone={"superfluous"} icon={'child-arrow'}/>
                                                    <Typography size={0.875} tone={'subtle'}>in {cat.name}</Typography>
                                                </ListItemLink>
                                            )
                                        })}
                                    </div> :
                                    false}
                            </>
                        )
                    })
                        : <Typography tone={'normal'} size={1} font={'bold'} className={styles.suggestedPlaceHolderText}>
                            No suggested searches
                        </Typography>}
                </List>
            </div>
        </Fragment>
    )
}

export default SuggestedSearches
