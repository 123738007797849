import Event from 'Utilities/Event'
import { FeatureFlags } from "AppSettings"
import { StorageAccessor } from 'Utilities/LocalStorageUtility'
import { GetUserGuid, GetUserVin, IsUserBot } from 'Utilities/CookieUtilties/UserCookieUtilities'
import React, { useState, useEffect } from 'react'
import AppName from 'AppName'
import defer from 'Utilities/defer'




const sendFeatureFlagEventsToGa = async (ffObj) => {
    const sentFeatureFlageKey = "SentFF"
    let sentFlags = StorageAccessor.sessionStorage.getObj(sentFeatureFlageKey);
    if (sentFlags?.length && sentFlags.filter(x => x.name === ffObj.name && x.value === ffObj.value).length > 0) {
        return;
    }
    sentFlags = sentFlags ?? [];
    Event({ category: "FrontEndFeatureFlag", action: `${ffObj.name}_${ffObj.value}` }, true, true)();
    console.log(`Feature flag used: ${ffObj.name} ${ffObj.value}`) //for logrocket
    sentFlags = [...sentFlags, ffObj]
    StorageAccessor.sessionStorage.setObj(sentFeatureFlageKey, sentFlags)

    try {
        defer(() => {
            try {
                gtag('event', 'feature_flag_assigned', {
                    'name': `${ffObj.name}`,
                    'treatment': `${ffObj.value}`
                })
            }
            catch (e) {
                console.error('unable to send gtag event', e)
            }

        })
    } catch (e) {
        console.error(e)
    }
}

const GetFeatureFlags = () => {
    const foundFeatureFlags = window.featureFlags ?? StorageAccessor.sessionStorage.getObj(BuildCacheKey(GetUserVin(), GetUserGuid()));
    if (foundFeatureFlags == PENDING) {
        return null;
    }

    if (foundFeatureFlags) {
        return foundFeatureFlags;
    }
    FetchFeatureFlags();
    return null;
}

const BuildCacheKey = (vin, guid) => {
    return `FF_${AppName}_${vin}_${guid}`
}

const PENDING = `pending`
const FEATUREFLAGSUPDATEDEVENT = "feature_flags_fetched"

const FetchFeatureFlags = (extraFeatureFlags = [], existingFlags = {}) => {
    if (typeof (window) === 'undefined' || window.featureFlags === PENDING) {
        return;
    }
    window.featureFlags = PENDING;
    FetchFeatureFlagsActual(extraFeatureFlags, existingFlags)
}

const FetchFeatureFlagsActual = async (extraFeatureFlags = [], existingFlags = {}) => {
    try {
        let variables = {
            vin: GetUserVin(),
            webUserGuid: GetUserGuid(),
            appSpecificFeatureFlags: extraFeatureFlags.concat(FeatureFlags)
        }

        const body = JSON.stringify({
            id: 'GetFeatureFlags',
            variables
        })

        const res = await fetch("/graphql/customer",
            {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body
            })

        const response = await res.json()


        let result = {};
        response.data.featureFlags.forEach(x => {
            result[x.name] = x.value
        })
        result = { ...existingFlags, ...result }
        const event = new CustomEvent(FEATUREFLAGSUPDATEDEVENT);
        window.featureFlags = result;
        StorageAccessor.sessionStorage.setObj(BuildCacheKey(variables.vin, variables.webUserGuid), result, 3);
        window.dispatchEvent(event);
    } catch (err) {
        console.error("failed to fetch feature flags", err)
    }

}

const useFeatureFlagInternal = (flagName, getAsLazy, actuallyFetch = true) => {
    const [flagValue, setFlagValue] = useState(null);
    const GetFeatureFlag = () => {

        if (IsUserBot()) {
            return;
        }
        if (!actuallyFetch) {
            return
        }

        const allFlags = GetFeatureFlags();
        if (!allFlags) {
            return;
        }
        const thisFlag = allFlags[flagName];

        if (thisFlag != undefined) {
            setFlagValue(thisFlag)
            sendFeatureFlagEventsToGa({ name: flagName, value: thisFlag })
            return;
        }

        if (getAsLazy) {
            FetchFeatureFlags([flagName], allFlags);
            return;
        }

        console.error(`A feature flag was asked for that was not provided to the front end: ${flagName}. Check the master FF or the appsettings file.`)

    }
    const GetFeatureFlagFromEvent = () => {
        GetFeatureFlag();
    }

    useEffect(() => {

        window.addEventListener(FEATUREFLAGSUPDATEDEVENT, GetFeatureFlagFromEvent);
        return () => window.removeEventListener(FEATUREFLAGSUPDATEDEVENT, GetFeatureFlagFromEvent);
    }, []);

    useEffect(() => {
        GetFeatureFlag();
    }, [actuallyFetch]);

    return flagValue;
}



const useFeatureFlag = (flagName) => {
    return useFeatureFlagInternal(flagName, false);
}



const useLazyFeatureFlag = (flagName, actuallyFetch = true) => {

    return useFeatureFlagInternal(flagName, true, actuallyFetch);
}


export default useFeatureFlag

export { useFeatureFlag, useLazyFeatureFlag, sendFeatureFlagEventsToGa }