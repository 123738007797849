import { useEffect } from "react";

import { useRecommendationStore } from "../RecommendationStore";
import { useUserStore } from "Stores/UserStore/UserStore";
import { getProductRecommendationDataForSection } from "../../../Contexts/RecommendationContext/fetches/productRecommendationData";
import useRecentHistory from "Hooks/useRecentHistory";

const ClientRecommendationLoader = ({ set }) => {

  //REC STORE
  const isLoading = useRecommendationStore((x) => x.isLoading);
  const hasHydratedClientProductData = useRecommendationStore((x) => x.hasHydratedClientProductData);
  const recommendationData = useRecommendationStore((x) => x.recommendationData);
  const setSectionData = useRecommendationStore((x) => x.setSectionData);

  //USER STORE
  const getGuid = useUserStore((x) => x.context.getGuid);

  useEffect(() => {
    if (!isLoading && !hasHydratedClientProductData) {
      setClientRecommendations(recommendationData);
    }
  }, [isLoading]);

  async function setClientRecommendations(recommendationsResponse) {
    try {
      if (
        recommendationsResponse.recommendationSections &&
        recommendationsResponse.recommendationSections.length
      ) {
        for (
          let i = 0;
          i < recommendationsResponse.recommendationSections.length;
          i++
        ) {

          let updated = false;
          for (
            let j = 0;
            j <
            recommendationsResponse.recommendationSections[i]
              .recommendationGroups.length;
            j++
          ) {
            if (
              recommendationsResponse.recommendationSections[i]
                .recommendationGroups[j].clientDataType === "PdpRecentHistory"
            ) {
              let history = useRecentHistory.GetRecentHistory(
                "Pdp",
                "skuBaseNumber"
              );
              recommendationsResponse.recommendationSections[i].recommendationGroups[j].recommendations = history.map((x) => {
                return {
                  __typename: "Product",
                  skuBase: x.skuBaseNumber,
                  publicSkuBaseDetails: {
                    displayTitle: x.title,
                    productPageUrl: x.url,
                    thumbnailImageLink: x.imageUrl,
                    skuBaseNumber: x.skuBaseNumber,
                    brand: x.brand,
                    segmentCategorization: x.segmentCategorization,
                  },
                };
              });

              updated = true;
              recommendationsResponse.recommendationSections[i].recommendationGroups[j].clientHydrated = true;
            }
          }

          if(updated){
            let hydrated = await getProductRecommendationDataForSection(recommendationsResponse.recommendationSections[i], getGuid())
            let updatedSection = {...hydrated}

            setSectionData(updatedSection);
          }
        }
      }

      set((state) => {
        return {
          ...state,
          hasHydratedClientProductData: true,
        };
      });

      return recommendationsResponse;
    } catch (err) {
      set((state) => {
        return {
          ...state,
          hasHydratedClientProductData: true,
        };
      });

      console.error(err);
      return recommendationsResponse;
    }
  }
}

export default ClientRecommendationLoader;
