// infinite scrolling when clicking pagination controls is handled in slide.js

// Quick image showing how infinite scroll works: https://speedwaymotors.atlassian.net/wiki/spaces/~60993e5a9fd9f30067024970/pages/2399633420/Scroll+Loop
const LoopToOtherSide = (direction, startOfPatternRef, endOfPatternRef, lazyBoundaryRef, numberOfLoopsToTheRight, setNumberOfLoopsToTheRight) => {
    if (lazyBoundaryRef == null) {
        return
    }
    const startOfPattern = startOfPatternRef.current
    const endOfPattern = endOfPatternRef.current
    const carouselWindow = lazyBoundaryRef.current
    const carouselInner = lazyBoundaryRef.current.childNodes[0]

    if (direction === 'right') {
        LoopRight(startOfPattern, endOfPattern, carouselWindow, carouselInner, numberOfLoopsToTheRight, setNumberOfLoopsToTheRight);
    } else {
        LoopLeft(startOfPattern, endOfPattern, carouselWindow, carouselInner, numberOfLoopsToTheRight, setNumberOfLoopsToTheRight);
    }
}

const LoopRight = (startOfPattern, endOfPattern, carouselWindow, carouselInner, numberOfLoopsToTheRight, setNumberOfLoopsToTheRight) => {
    if (endOfPattern.getBoundingClientRect().left < carouselWindow.getBoundingClientRect().left) {
        console.info('loop right')
        setNumberOfLoopsToTheRight(numberOfLoopsToTheRight + 1)
        const endOfPatternScroll = endOfPattern.getBoundingClientRect().left - carouselInner.getBoundingClientRect().left
        let distancePastEndOfPattern = carouselWindow.scrollLeft - endOfPatternScroll;
        distancePastEndOfPattern = distancePastEndOfPattern > 0 ? distancePastEndOfPattern : 0;
        carouselWindow.scrollLeft = startOfPattern.getBoundingClientRect().left - carouselInner.getBoundingClientRect().left + distancePastEndOfPattern
    }
}

const LoopLeft = (startOfPattern, endOfPattern, carouselWindow, carouselInner, numberOfLoopsToTheRight, setNumberOfLoopsToTheRight) => {
    if (numberOfLoopsToTheRight > 0 && startOfPattern.getBoundingClientRect().left > carouselWindow.getBoundingClientRect().right) {
        console.info('loop left')
        setNumberOfLoopsToTheRight(numberOfLoopsToTheRight - 1)
        const startOfPatternScroll = Math.abs(startOfPattern.getBoundingClientRect().left - carouselInner.getBoundingClientRect().left)
        let distancePastEndOfPattern = startOfPatternScroll - (carouselWindow.scrollLeft + carouselWindow.getBoundingClientRect().width)
        distancePastEndOfPattern = distancePastEndOfPattern > 0 ? distancePastEndOfPattern : 0
        carouselWindow.scrollLeft = endOfPattern.getBoundingClientRect().left - carouselInner.getBoundingClientRect().left - carouselWindow.getBoundingClientRect().width - distancePastEndOfPattern
    }
}

export default LoopToOtherSide