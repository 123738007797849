import React, { useRef, useState, useEffect, Fragment } from 'react'
import ClassNames from 'classnames'
import {useFloating, offset, shift, autoPlacement, FloatingArrow, arrow} from '@floating-ui/react';
import useOutsideClick from 'Hooks/useOutsideClick'
import useKeyPress, { KEYCODES } from 'Hooks/useKepress'
import popoverStyles from './Popover.module.scss'
import FadeInFadeOut from 'Components/Transitions/FadeInFadeOut/FadeInFadeOut'

const Popover = ({
    children,
    content,
    className,
    isOpen,
    onToggleMenu,
    setCustomParentTriggerRef,
    hoverable
}) => {

    const arrowRef = useRef(null)

    const {refs, floatingStyles, context} = useFloating({
        middleware: [
            shift(),
            arrow({
                element: arrowRef
            })
        ]
    });

    const { current } = refs.reference
    useEffect(() => {
        if (current && setCustomParentTriggerRef) {
            setCustomParentTriggerRef(current)
        }
    }, [current, setCustomParentTriggerRef])

    useOutsideClick(refs.floating, [refs.reference], () => onToggleMenu(false))
    useKeyPress(KEYCODES.escape, () => onToggleMenu(false))

    return (
        <Fragment>
            <div
                ref={refs.setReference}
                className={ClassNames(className, popoverStyles.popoverTrigger)}
                onClick={() => {
                    onToggleMenu(!isOpen)
                }}

                onMouseEnter={() => {
                    if (!hoverable) {
                        return
                    }
                    onToggleMenu(true)
                }}
            >
                {children}
            </div>
            {isOpen ? 
                <div
                    ref={refs.setFloating}
                    style={floatingStyles}
                    className={popoverStyles.popover}
                >
                    <FloatingArrow
                        ref={arrowRef}
                        context={context}
                        fill={'white'}
                    />
                    {content}
                </div>
            : false}
        </Fragment>
    )
}
export default Popover
