import React, { useContext, useEffect, useState } from 'react'
import ErrorBoundary from 'UtilityComponents/ErrorBoundary';
import useFeatureFlag from '../../Hooks/useFeatureFlag/useFeatureFlag'; // <== add this!!

export const FeatureFlagWrapper = ({ ff, children, childrenIfFalse = null, childrenWhileLoading = null }) => {

    let flag = useFeatureFlag(ff);

    if (flag === null && childrenWhileLoading) {
        return (<>{childrenWhileLoading}</>);
    }

    if (!flag) {
        return (<>{childrenIfFalse}</>);
    }

    return (<>{children}</>)
}

const FeatureFlagWrapperWithErrorHandling = (props) => {
    return (
        <ErrorBoundary>
            <FeatureFlagWrapper {...props} />
        </ErrorBoundary>
    )
}

export default FeatureFlagWrapperWithErrorHandling