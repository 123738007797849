import React, { useContext, useEffect } from "react";
import useCart from "Hooks/useCart/useCart";
import { useUserSpecificProductDataStore } from "../UserSpecificProductDataStore";

export default function SynchronizeCartData() {
  const setCartDataForSkuBases = useUserSpecificProductDataStore(
    (x) => x.setCartDataForSkuBases
  );
  const { cart } = useCart();

  useEffect(() => {
    const skuBasesInCart = {};
    if (cart?.skuBasesInCart) {
      cart.skuBasesInCart.forEach((skuBase) => {
        skuBasesInCart[skuBase] = true;
      });
    }

    setCartDataForSkuBases(skuBasesInCart);
  }, [cart?.skuBasesInCart]);

  return false

}
