import React, { useState } from "react";

import Divider from "Atoms/Divider";
import Typography from "Atoms/Typography";
import RecommendationCard from "Molecules/RecommendationCard";
import RecommendationsSkeleton from "Molecules/RecommendationsSkeleton";
import Carousel from "Organisms/Carousel";
import ErrorBoundary from "UtilityComponents/ErrorBoundary";

import styles from "./RecommendationsCarousel.module.scss";


const RecommendationsCarousel = ({
  recBlock,
  id,
  dataTestId,
  blockIndex,
  isLoading,
  numberOfSkeletons,
  numberOfSkeletonSections,
  skeletonType,
  useDivider,
  useSubtitle,
  useItemCount,
  horizontalCard,
  leftAlignCarousel,
  showCarouselGradient,
  extendGuttersForCarousel,
  treatment,
  ...props
}) => {
  if (props.eventAction) {
    console.error(
      "eventAction is deprecated, please remove it from the calling component"
    );
  }

  let recommendations;
  let isClientProductDataLoading =
    recBlock.clientDataType == "PdpRecentHistory" &&
    !recBlock.clientHydrated;

  if (recBlock && recBlock.recommendations) {
    recommendations = recBlock.recommendations.filter(
      (rec) =>
        (rec.publicSkuBaseDetails &&
          rec.publicSkuBaseDetails.availability != "Discontinued" &&
          rec.publicSkuBaseDetails.availability != "NotAvailable") ||
        rec.articleDetails ||
        rec.facetOptionLink ||
        rec.landingPageDetails ||
        rec.eventId ||
        rec.term
    );
  }

  if (!(recommendations || []).length && !isClientProductDataLoading) {
    return false;
  }

  let numSkeletonSections = numberOfSkeletonSections;
  if (isClientProductDataLoading && !isLoading) {
    numSkeletonSections = 1;
  }

  let openByDefault = false;
  const [isOpen, setIsOpen] = useState(openByDefault);

  return (
    <ErrorBoundary>
      {isLoading || isClientProductDataLoading ? (
        <RecommendationsSkeleton
          dataTestId={dataTestId}
          displayType={"Carousel"}
          numberOfSkeletons={numberOfSkeletons}
          numberOfSkeletonSections={numSkeletonSections}
          skeletonType={skeletonType}
          blockIndex={blockIndex}
          useDivider={useDivider}
          useSubtitle={useSubtitle}
          useItemCount={useItemCount}
          horizontalCard={horizontalCard}
          leftAlignCarousel={leftAlignCarousel}
        />
      ) : (
        <>
          <div className={styles.svg}>
            <Typography
              as={"h2"}
              size={1.25}
              font={"bold"}
              tone={"contrast"}
              className={styles.title}
            >
              {recBlock.title}
            </Typography>
            {/* TODO: Finish this component. Z-Index and right side spacing + finalize Miso call*/}
            {/*<Popover*/}
            {/*  isOpen={isOpen}*/}
            {/*  content={*/}
            {/*    <div>*/}
            {/*      <Feedback*/}
            {/*        prompt={""}*/}
            {/*        size={1.25}*/}
            {/*        hideCopy*/}
            {/*        hideTextFeedback*/}
            {/*        callback={() => {*/}
            {/*          setTimeout(() => setIsOpen(!isOpen), 500);*/}
            {/*        }}*/}
            {/*        misoId={recBlock.misoId}*/}
            {/*      />*/}
            {/*    </div>*/}
            {/*  }*/}
            {/*  onToggleMenu={() => setIsOpen(!isOpen)}*/}
            {/*>*/}
            {/*  <Button*/}
            {/*    intent={"facade"}*/}
            {/*    icon={"kebab"}*/}
            {/*    segmentEvent={{*/}
            {/*      event: "Clicked",*/}
            {/*      properties: { element: id },*/}
            {/*    }}*/}
            {/*    size={"small"}*/}
            {/*    inline*/}
            {/*  >*/}
            {/*    /!*<Svg brand={""} icon={"kebab"} size={1.25} tone={"contrast"} />*!/*/}
            {/*  </Button>*/}
            {/*</Popover>*/}
          </div>
          {useSubtitle ? (
            <Typography size={0.875} font={"regular"} className={styles.title}>
              {recBlock.subtitle}
            </Typography>
          ) : (
            false
          )}
          {useItemCount ? (
            <Typography size={0.875} font={"regular"} className={styles.title}>
              {recBlock.recommendations?.length != 1
                ? `${recBlock.recommendations?.length} items`
                : "1 item"}
            </Typography>
          ) : (
            false
          )}
          {useDivider ? (
            <Divider className={styles.divider} />
          ) : (
            <div className={styles.noDivider} />
          )}
          <Carousel
            data-testid={`${dataTestId}_carousel_recs_${blockIndex}`}
            id={id}
            leftAlignCarousel={leftAlignCarousel}
            showGradient={showCarouselGradient}
            extendGutters={extendGuttersForCarousel}
            className={styles.carousel}
          >
            {recommendations.map((rec, index) => (
              <ErrorBoundary key={`${dataTestId}-${index}`}>
                <Carousel.Item
                  className={
                    rec.term ? styles.carousel_term_item : styles.carousel_item
                  }
                  data-testid={`${dataTestId}_carousel_${blockIndex}_rec_${index}`}
                >
                  <RecommendationCard
                    rec={rec}
                    index={index}
                    recBlock={recBlock}
                    dataTestId={dataTestId}
                    horizontal={horizontalCard}
                    treatment={treatment}
                  />
                </Carousel.Item>
              </ErrorBoundary>
            ))}
          </Carousel>
        </>
      )}
    </ErrorBoundary>
  );
};

export default RecommendationsCarousel;
