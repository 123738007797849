import React, { Fragment, useRef } from "react";
import { CSSTransition } from "react-transition-group";

import styled from "styled-components";

const FadeInFadeOut = ({ children, fadeInTimer = 200, isOpen }) => {
  if (isOpen == null) {
    //throw 'isOpen needs to be defined.'
  }

  // https://github.com/reactjs/react-transition-group/issues/668
  const nodeRef = useRef(null)
  return (
    // technically we shouldn't need css transition, but I ran into some issues while removing it
    // it gets to stay for now...
    <CSSTransition nodeRef={nodeRef} in={isOpen} timeout={fadeInTimer} unmountOnExit>
      <StyledPopoverWrapper ref={nodeRef} isOpen={isOpen}>{children}</StyledPopoverWrapper>
    </CSSTransition>
  );
};

const StyledPopoverWrapper = styled.div`
  position: absolute;
  transition: opacity 200ms;

  // allow clicks when the drawer is open, but have everything pass through when it's closed (since we're hide/showing with opacity)
  pointer-events: ${(props) => (props.isOpen ? "auto" : "none")};
  // show the drawer when it's open, but hide it when it's closed
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
`;

export default FadeInFadeOut;
