import React, { useEffect } from "react";
import Head from 'next/head'
import Script from "next/script";

export default function GoogleAds() {
  // google automatically handles GPC-- see here
  // https://support.google.com/google-ads/answer/9614122?hl=en
  return (
    <>
      <Head>
        <Script
          id="gads-script"
          data-testid="gads-script"
          src={`https://www.googletagmanager.com/gtag/js?id=AW-1061040585`}
          async
        />
        <Script id="googleAds-script-config" strategy="beforeInteractive">
          {`
            window.dataLayer = window.dataLayer || []; function gtag() { dataLayer.push(arguments); }
            window.gtag('js', new Date());
            window.gtag('config', 'AW-1061040585', {
                'linker': {
                    'domains': ['app.maker.co']
                },
                'groups': ['ads']
            });
          `}
        </Script>
      </Head>
    </>
  );
}
