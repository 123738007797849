import React from 'react'
import ClassNames from 'classnames'
import Typography from 'Atoms/Typography'

import styles from './SaytItemText.module.scss'

const SaytItemText = ({
    title,
    className,
    titleWithMarkups
}) => {
    const classnames = ClassNames(className)


    return (
        <div className={classnames}>
            <Typography
                key={title}
                size={1}
                tone={'normal'}
                inline
                condensed
                font={'regular'}
                className={ClassNames(styles.SaytItemText)}
            >
                {titleWithMarkups ?
                    <span
                        className={styles.saytMisoAutoSuggest}
                        dangerouslySetInnerHTML={{ __html: titleWithMarkups }}
                    />
                    :
                    <span className={styles.saytMisoAutoSuggest}>{title}</span>

                }

            </Typography>
        </div>
    )


}


export default SaytItemText
