import { useState } from "react";
import { useUserSpecificProductDataStore } from "../UserSpecificProductDataStore";
import { useFeatureFlag } from "Hooks/useFeatureFlag/useFeatureFlag";
import { StorageAccessor } from 'Utilities/LocalStorageUtility'
import RestRequest from "Utilities/RestClient/RestClient";

import useLockedAsyncEffect from "Hooks/useLockedAsyncEffect";

export default function LazyLoadPromoData({ disable }) {
    const trackedSkuBases = useUserSpecificProductDataStore(
        (x) => x.trackedSkuBases
    );
    
    const addPromoDataForSkuBase = useUserSpecificProductDataStore(
        (x) => x.addPromoDataForSkuBase
    );

    const enabled = useFeatureFlag("PRP_FrontEnd_Promo_Preview");

    async function loadProductPromoData() {
        if (!enabled) {
            return;
        }

        if (disable) {
            return;
        }

        let { found, toFetch } = TryGetPromosFromCache(trackedSkuBases);

        let promoResponse

        if (toFetch.length) {
            promoResponse = await RestRequest(`/api/checkout/promotionpreview/batch`, null, { skuNumbers: toFetch.map(x => { return { skuBaseNumber: x } }) });
        }

        const allPromos = found.concat(promoResponse?.skuPromotionPreviews ?? []);

        UpdatePromosCache(allPromos ?? []);

        allPromos.forEach((promo) => {
            addPromoDataForSkuBase(promo.skuBaseNumber, promo);
        });
    }

    useLockedAsyncEffect(async () => {
        await loadProductPromoData();
    }, [enabled, disable, trackedSkuBases]);

    return false;
}

const getKey = (skuBase) => `Promo_${skuBase}`
const MINUTES = 5 //setting this to 5 so the cache doesn't grow to a massive size

const TryGetPromosFromCache = (skuBases) => {
    let found = [];
    let toFetch = [];
    for (let i = 0, ilen = skuBases.length; i < ilen; i++) {
        let thisOne = StorageAccessor.sessionStorage.getObj(getKey(skuBases[i]));

        if (thisOne) {
            found.push(thisOne);
        } else {
            toFetch.push(skuBases[i])
        }
    }

    return { found, toFetch }
}


const UpdatePromosCache = (promos) => {
    for (let i = 0, ilen = promos.length; i < ilen; i++) {
        StorageAccessor.sessionStorage.setObj(getKey(promos[i].skuBaseNumber), promos[i], MINUTES);
    }
}
