import GraphQLRequest from "Utilities/GraphQLClient/GraphQLClient";

export const getUserShoppingCartQuery = `query GetUserShoppingCartShallow($webUserGuid: String = null) {
    shoppingCart: shoppingCartShallow(webUserGuid: $webUserGuid) {
        shoppingCartId
        numProducts
        skuBasesInCart
    }
}`;

const GetShoppingCart = async (checkoutUrl, webUserGuid) => {

  if (!webUserGuid) {
    return null;
  }

  try {
    const variables = {
      webUserGuid,
    };

    const data = await GraphQLRequest(
      checkoutUrl,
      getUserShoppingCartQuery,
      variables
    );
    const shoppingCartId = data?.shoppingCart?.shoppingCartId ?? 0;
    const itemCount = data?.shoppingCart?.numProducts ?? 0;
    const skuBasesInCart = data?.shoppingCart?.skuBasesInCart ?? [];

    return {
      hydrated: true,
      shoppingCartId,
      itemCount,
      skuBasesInCart
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

export default GetShoppingCart;
