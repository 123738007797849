'use client'
import React, {useState} from 'react'

const config = {
    threshold: 100, //changes the "sensitivity"
    up: {thresholdModifier: -1, axis: 'clientY'},
    down: {thresholdModifier: 1, axis: 'clientY'},
    right: {thresholdModifier: 1, axis: 'clientX'},
    left: {thresholdModifier: -1, axis: 'clientX'},
}

const blackListedTagNames = ['input', 'textarea' ]

const Swiper = ({children, direction, onSwipe}) => {

    const[startPosition, setStartPosition] = useState(0)
    const[endPosition, setEndPosition] = useState(0)

    let configObj = config[direction.toLowerCase()]

    if(!configObj){
        return false
    }

    return(
        <div onTouchStart={(touchEvent) => {

            let start = touchEvent.targetTouches[0][configObj.axis]

            if(!blackListedTagNames.some(x => x.toLowerCase() == touchEvent.target?.tagName?.toLowerCase())) {
                setStartPosition(start)
            } else {
                setStartPosition(null)
            }
        }}
        onTouchMove={(touchEvent) => {
            let end = touchEvent.targetTouches[0][configObj.axis]
            setEndPosition(end)
        }}
        onTouchEnd={() => {
            const threshold = config.threshold * configObj.thresholdModifier
            const distanceSwiped = endPosition - startPosition

            if(configObj.thresholdModifier > 0 && distanceSwiped > threshold && startPosition !== null){
                onSwipe()
            }
            else if(configObj.thresholdModifier < 0 && distanceSwiped < threshold && startPosition !== null){
                onSwipe()
            }
        }}>
            {children}
        </div>
    )
}

export default Swiper