import React, { useState } from "react";

import Form from "Atoms/Form";
import Button from "Molecules/Button";

import Switch from "../../Atoms/Form/Elements/Switch";
import TextLink from "../../Atoms/TextLink/TextLink";
import { useUserStore } from "../../Stores/UserStore/UserStore";
import styles from "./Feedback.module.scss";


const EMAILINPUTID = "feedback_email_input";
const FeedbackTextArea = ({
  name,
  feedbackPrompt,
  isPositive,
  callback,
  searchProvider,
}) => {
  const [text, setText] = useState("");
  const [contactOkay, setContactOkay] = useState(false);
  const emailAddress = useUserStore((x) => x.context.emailAddress);
  const [email, setEmail] = useState(emailAddress ?? "");

  const onChange = (e) => {
    setText(e.target.value);
  };

  const [hasBeenClicked, setHasBeenClicked] = useState(false);

  const onClick = (e) => {
    e.preventDefault();
    setHasBeenClicked(true);
    if (callback) {
      callback();
    }
  };

  const qualifier = isPositive ? "Positive" : "Negative";

  const segmentEvent = {
    event: "Feedback Submitted",
    properties: {
      emotion: qualifier.toLowerCase(),
      feedback: text,
      element: name,
      okay_to_contact: contactOkay,
      entered_email: email,
    },
  };

  segmentEvent.properties.feedbackURL =
  `customer=${encodeURIComponent(segmentEvent.properties.entered_email)}` +
  `&message=${encodeURIComponent(segmentEvent.properties.feedback)}` +
  `&type=${encodeURIComponent(segmentEvent.properties.element)}` +
  `&emotion=${encodeURIComponent(segmentEvent.properties.emotion)}`;

  if (searchProvider) segmentEvent.properties.search_provider = searchProvider;

  const onEmailChange = () => {
    setEmail(document.getElementById(EMAILINPUTID)?.value);
  };

  return hasBeenClicked ? null : (
    <Form className={styles.container} onSubmit={onClick}>
      <p className={"p p-bold"}>Thank you for your feedback!</p>
      <Form.TextArea
        autoFocus
        className={styles.textArea}
        rows={6}
        label={feedbackPrompt}
        onChange={onChange}
      />
      <div className={styles.switch}>
        <Switch
          id={"contactOkaySwitch"}
          brand={"primary"}
          checked={contactOkay}
          onChange={setContactOkay}
        />
        <p className={"p p-0_875 p-color-subtle"}>
          It's okay to contact me about this
        </p>
      </div>
      {contactOkay ? (
        <>
          <Form.Textbox
            fill
            onChange={onEmailChange}
            brand={"primary"}
            id={EMAILINPUTID}
            type={"email"}
            value={email}
            placeholder={"Your Email"}
          />
        </>
      ) : (
        false
      )}
      <Button
        className={styles.submitButton}
        segmentEvent={segmentEvent}
        intent={"action"}
        size={"small"}
        text={"Send Feedback"}
        data-testid={`${name}DescriptiveFeedback-${qualifier}`}
        type={"submit"}
        disabled={!!!text}
      />

      <p className={"p p-color-subtle p-0_875"}>
        If you need assistance, please{" "}
        <TextLink size={0.875} intent={"action"} href={`/info/helpcenter`}>
          contact us
        </TextLink>
      </p>
    </Form>
  );
};

export default FeedbackTextArea;
