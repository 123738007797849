import React from 'react'
import { useEffect } from 'react'


// This exists so that DeepSpace9 can wait to run code until after Clutch has been loaded
const IsClutchLoaded = () => {
    useEffect(() => {
        window.clutchLoadedCallbacks?.forEach(cb => cb());
        window.clutchLoaded = true
    }, []);

    return <></>;
}

export default IsClutchLoaded