import ClassNames from 'classnames';
import React, { Fragment, useEffect, useRef } from 'react';
import styled from 'styled-components';

import Typography from 'Atoms/Typography';
import LayerColorFinder from 'Utilities/LayerColorFinder';

import InlineSpinner from '../../InlineSpinner/InlineSpinner';
import styles from './Textbox.module.scss';

const Textbox = (
    {
        list,
        id,
        label,
        type,
        intent,
        brand,
        fill,
        isWaiting,
        hideSpinner,
        placeholder,
        validationErrorMessages,
        prefixContent,
        containerClassName,
        disabled,
        autoFocus,
        children,
        ...props
    }
) => {
    const inputRef = useRef(null)
    useEffect(() => {
        if (autoFocus) {
            inputRef.current?.focus()
        }
    }, [disabled, autoFocus])

    type = type || "text"

    const classNames = ClassNames(
        styles.textbox,
        fill && styles.fill,
        { [styles.prefixedTextbox]: !!prefixContent }
    )

    
    const borderColor = intent || brand ? LayerColorFinder(undefined, undefined, brand, intent) : LayerColorFinder(2)
    
    const disabledColor = LayerColorFinder(1);
    
    if (disabled) {
        autoFocus = false;
    }

    return (
        <Fragment>
            {isWaiting ?
                <div className={styles.spinnerWrapper}>
                    <InlineSpinner brand={brand} intent={intent} />
                </div>
                :
                <>
                    <Label id={id} text={label} />
                    <Typography intent={'negative'} >
                        {validationErrorMessages?.map((m) => (<div>{m}</div>))}
                    </Typography>
                    <StyledDiv className={ClassNames(classNames, containerClassName)} borderColor={borderColor}>
                        {prefixContent}
                        <StyledInput ref={inputRef} disabled={disabled} autoFocus={autoFocus} disabledColor={disabledColor} className={hideSpinner ? styles.hideSpinner : false} list={list} type={type} id={id} name={id} placeholder={placeholder} borderColor={borderColor} {...props} />
                        {children}
                    </StyledDiv>
                </>}
        </Fragment>
    )
}

const Label = ({ text, id }) => {
    if (!text) {
        return false
    }

    return (
        <Typography as={'label'} htmlFor={id}>{text}</Typography>
    )
}

const StyledDiv = styled.div`
    position: relative;
    display: flex;
    border-color: ${props => props.borderColor};
`

const StyledInput = styled.input`
    border-color: ${props => props.borderColor};
    flex: 1;
    --disabled: ${props => props.disabledColor};
`

export default Textbox;