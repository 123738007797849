export const isInApp = () => {
  try {
    if (typeof window === "undefined") {
      return false;
    }

    return (
      window.navigator?.standalone ||
      (window.matchMedia &&
        window.matchMedia("(display-mode: standalone)").matches) ||
      document.querySelector("body.enablePwaStyling")
    );
  } catch (err) {
    console.error("Error determining if currently running in-app", err);
    return false;
  }
};

export const isQa = () => {
  try {
    if (typeof window === "undefined") {
      return false;
    }

    return (
      window.location.hostname.includes(`qa`) ||
      window.location.hostname.includes(`localhost`)
    );
  } catch (err) {
    console.error("Error determining if currently on QA", err);
    return false;
  }
};

export const INSTALLPAGEURL = "/pages/appInstall";

export const getDeviceInfo = () => {
  const result = {
    isDesktop: false,
    isAppleDevice: false,
    isIos: false,
    isOsx: false,
    isSafari: false,
    isAndroid: false,
    preDatesPWASupport: false,
  };

  try {
    if (typeof window === "undefined") {
      return result;
    }

    result.isIos = /iPad|iPhone|iPod/.test(navigator.userAgent);
    result.isOsx = !result.isIos && /Mac OS X/.test(navigator.userAgent);
    result.isAndroid = /Android/.test(navigator.userAgent);
    result.isAppleDevice = result.isIos || result.isOsx;
    result.isDesktop = !result.isIos && !result.isAndroid;
    result.isSafari =
      result.isAppleDevice &&
      !/CriOS|Brave|FxiOS|Chrome/.test(navigator.userAgent);

    if (result.isIos) {
      result.preDatesPWASupport = getIfIosVersionBefore16point4();
    }
    if (result.isOsx) {
      result.preDatesPWASupport = getIfOsxVersionBefore14(result.isSafari);
    }

    return result;
  } catch (err) {
    console.error("Error getting device info", err);
    return result;
  }
};

const getIfOsxVersionBefore14 = (isSafari) => {
  try {
    if (isSafari) {
      let string = navigator.userAgent.split("Version/")[1].split(" ")[0];
      let integers = string.split(".").map((x) => parseInt(x));
      return integers[0] < 17;
    }

    let string = navigator.userAgent.split(" Mac OS X ")[1].split(")")[0];
    let integers = string.split("_").map((x) => parseInt(x));
    return integers[0] < 14;
  } catch (err) {
    console.error(`Error determining oSX version`, err);
    return true;
  }
};

const getIfIosVersionBefore16point4 = () => {
  try {
    let string = navigator.userAgent.split(" OS ")[1].split(" like ")[0];
    let integers = string.split("_").map((x) => parseInt(x));
    return integers[0] <= 16 && integers[1] < 4;
  } catch (err) {
    console.error(`Error determining iOS version`, err);
    return true;
  }
};
