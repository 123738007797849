import React, { useEffect, useState, useRef } from "react";

import AutoComplete from "Atoms/Form/Elements/AutoComplete/AutoComplete";
import Button from "Molecules/Button";

import { getProperUrl } from "Contexts/VehicleContext/CoreVehicleRequests";
import { useThemeStore } from "Stores/ThemeStore";
import { useVehicleStore } from "Stores/VehicleStore";
import useSegment from "Hooks/useSegment"
import { createClickedSegmentEvent, createViewedSegmentEvent } from "Utilities/Instrumentation/Impressions/impressionSegmentEvents";
import getEngineIds from "Utilities/CustomerProjectUtilities/getEngineIds";
import styles from "Organisms/CustomerProjectDrawer/CustomerProjectDrawer.module.scss";

import EngineDropdown from "../AddEngineView/EngineDropdown/EngineDropdown";

const initialDropdownState = {
  raceType: {
    value: null,
    label: null,
  },
  stockEngine: {
    value: null,
    label: null,
  },
};

const AddRaceTypeView = ({ existingState, hasEngineSelected }) => {
  const [dropdownState, setDropdownState] = useState(initialDropdownState);
  const [submitHref, setSubmitHref] = useState(null);
  const [showEngineDropdown, setShowEngineDropdown] = useState(false);
  const [segmentEventText, setSegmentEventText] = useState("Add Race Car");
  const [sentSegmentViewedEvent, setSentSegmentViewedEvent] = useState(false);
  const [existingRtState, setExistingRtState] = useState(false)

  const raceTypes = useVehicleStore((x) => x.context.raceTypes);
  const stockEngines = useVehicleStore((x) => x.context.stockEngines);
  const existingRtStateFromStore = useVehicleStore(x => x.context.existingState)

  const addCustomerProject = useVehicleStore((x) => x.addCustomerProject);
  const fetchRaceTypeData = useVehicleStore((x) => x.fetchRaceTypeData);
  const isFetching = useVehicleStore(
    (x) => x.context.isFetching
  );
  const reloadCurrentLocation = useVehicleStore((x) => x.context.reloadCurrentLocation);
  const selectCustomerProject = useVehicleStore((x) => x.selectCustomerProject);
  const updateCustomerProjectData = useVehicleStore(
    (x) => x.updateCustomerProjectData
  );
  const { sendSegmentTrackEvent } = useSegment()

  const raceTypeDropdownRef = useRef();
  const stockEngineDropdownRef = useRef();

  const setIsLoading = useThemeStore((x) => x.setIsLoading);

  const isRaceTypeDropdownDisabled = isFetching || !raceTypes || raceTypes?.length === 0;
  const isEngineDropdownDisabled = isRaceTypeDropdownDisabled || isFetching || !stockEngines || stockEngines?.length === 0 || !dropdownState.raceType.value;

  useEffect(() => {
    if (existingState) {
      setExistingRtState(existingState)
    } else {
      setExistingRtState(existingRtStateFromStore)
    }
  }, [])

  useEffect(() => {
    if (existingRtState && !hasEngineSelected) {
      setDropdownState(existingRtState);
      fetchRaceTypeData(existingRtState.raceType.value);
    }
  }, [existingRtState]);

  useEffect(() => {
    const { raceType, stockEngine } = dropdownState;

    if (
      raceType.value &&
      ((stockEngine.value && stockEngine.value !== -1) || hasEngineSelected)
    ) {
      addOrUpdateRaceType();
    } else {
      fetchRaceTypeData(raceType.value);
      if (raceType.value) {
        const raceTypeInfo = raceTypes?.find(
          (x) => x.optionId == raceType.value
        );
        if (raceTypeInfo) {
          const url = getProperUrl(raceTypeInfo);
          if (url) setSubmitHref(url);
        }
      }
    }
  }, [dropdownState]);

  useEffect(() => {
    determineDropdownFocus(
      dropdownState,
      raceTypes,
      stockEngines,
      raceTypeDropdownRef,
      stockEngineDropdownRef,
      existingRtState,
      isRaceTypeDropdownDisabled,
      isEngineDropdownDisabled
    );
  }, [dropdownState, raceTypes, stockEngines, isRaceTypeDropdownDisabled, isEngineDropdownDisabled]);

  useEffect(() => {
    setShowEngineDropdown(dropdownState.stockEngine.value === -1);
  }, [dropdownState.stockEngine.value === -1]);

  const addOrUpdateRaceType = async () => {
    setIsLoading(true);
    const raceTypeId = dropdownState.raceType.value;
    try {
      const parsedRaceTypeId = parseInt(raceTypeId, 10);
      const raceType = raceTypes.find(
        (raceType) => raceType.optionId === parsedRaceTypeId
      );
      const engineDefinitionId = dropdownState.stockEngine.value;
      const selectedStockEngine = stockEngines?.find(
        (x) => x.optionId === engineDefinitionId
      );

      const engineIds = getEngineIds(hasEngineSelected, existingRtState, selectedStockEngine)

      let response;
      let addedOrUpdatedProjectId;
      if (existingRtState) {
        response = await updateCustomerProjectData({
          raceTypeId: existingRtState.raceType?.value ?? raceTypeId,
          raceTypeDisplayName: existingRtState.raceType?.label,
          engineIds,
          customerProjectId: existingRtState?.customerProjectId,
        });
        addedOrUpdatedProjectId = response.updatedCustomerProjectId
      } else {
        response = await addCustomerProject({
          raceTypeId: parsedRaceTypeId,
          raceTypeDisplayName: raceType.displayName,
          engineIds,
        });
        addedOrUpdatedProjectId = response.addedCustomerProjectId
      }

      if (!response) {
        setIsLoading(false);
        return;
      }

      const selectedProject = response.projects.find(project => project.projectId === addedOrUpdatedProjectId)
      const url = getProperUrl(selectedProject);      

      await selectCustomerProject(selectedProject.projectId, url);

      if (reloadCurrentLocation) {
        // return false or it skips to line below
        window.location.reload();
        return false;
      }
      window.location.href = url;
    } catch (ex) {
      console.error(ex);
    }
    setIsLoading(false);
  };

  const raceTypeOptions = raceTypes?.map((raceType) => {
    return {
      label: raceType.displayName,
      value: raceType.optionId,
    };
  });
  const stockEngineOptions = stockEngines?.map((stockEngine) => {
    return {
      label: stockEngine.displayName,
      value: stockEngine.optionId,
    };
  });

  // WIFR-33088 DRY, could be broken out to helper function?
  useEffect(() => {
    let eventText
    if (existingRtState?.customerProjectId) {
      eventText = "Update Race Car"
    } else {
      eventText = "Add Race Car"
    }
    const stockEngine = dropdownState.stockEngine.value
    // If checks for any engine at all since -1 also counts
    if (stockEngine) {
      const selectedStockEngine = stockEngines?.find(
        (x) => x.optionId === stockEngine
      )
      const selectedStockEngineNotSwap =  selectedStockEngine && stockEngine !== -1;
      if (selectedStockEngineNotSwap) {
        eventText += " With Stock Engine"
        const engineIds = selectedStockEngine.engineIds
        if (!(engineIds.engineDisplacementId > 1 || engineIds.engineVersionId > 1)) {
          eventText += " - Family"
        } else {
          eventText += " - Displacement"
        }
        sendSegmentTrackEvent(createClickedSegmentEvent(eventText))
      } else {
        eventText += " With Engine Swap"
      }
    }
    setSegmentEventText(eventText)
  }, [existingRtState?.customerProjectId, dropdownState?.stockEngine])

  useEffect(() => {
    if (!sentSegmentViewedEvent) {
      const viewEvent = createViewedSegmentEvent("Add RT View");
      sendSegmentTrackEvent(viewEvent)
      setSentSegmentViewedEvent(true)
   }
  })

  return (
    <>
      <AutoComplete
        options={raceTypeOptions}
        onChange={(option) =>
          setDropdownState(() => ({
            ...initialDropdownState,
            raceType: {
              value: parseInt(option.value, 10),
              label: option.label,
            },
          }))
        }
        selectedValue={dropdownState.raceType}
        isDisabled={isRaceTypeDropdownDisabled}
        label={"Race Car"}
        boldLabel={true}
        placeholder={"Select a Race Car"}
        ref={raceTypeDropdownRef}
        useNativeSelect={false}
      />
      {!hasEngineSelected && (
        <AutoComplete
          options={stockEngineOptions}
          onChange={(option) => {
            console.log("New Engine Interaction")
            setDropdownState((prevState) => ({
              ...prevState,
              stockEngine: {
                value: parseInt(option.value, 10),
                label: option.label,
              },
            }))
          }
          }
          label={'Engine'}
          addonLabel={'(Optional)'}
          boldLabel={true}
          placeholder={"Select an Engine"}
          selectedValue={dropdownState.stockEngine}
          isDisabled={isEngineDropdownDisabled}
          ref={stockEngineDropdownRef}
          useNativeSelect={false}
        />
      )}
      {!showEngineDropdown ? (
        <Button
          className={styles.dropdownAddButton}
          icon={"race"}
          size={"small"}
          intent={"action"}
          type={"button"}
          text={
            existingRtState?.customerProjectId
              ? "Update Race Car"
              : "Add Race Car"
          }
          testingName={
            existingRtState?.customerProjectId
              ? "Update Race Car"
              : "Add Race Car"
          }
          fill
          onClick={() => {
            addOrUpdateRaceType();
          }}
          segmentEvent={createClickedSegmentEvent(segmentEventText)}
          disabled={dropdownState.raceType.value === null || !submitHref}
        />
      ) : (
        <EngineDropdown
          buttonIcon={"race"}
          buttonText={
            existingRtState?.customerProjectId
              ? "Update Race Car"
              : "Add Race Car"
          }
          segmentEventText={segmentEventText}
          raceTypeId={dropdownState.raceType?.value}
          existingRtState={existingRtState}
        />
      )}
    </>
  );
};

const determineDropdownFocus = (
  dropdownState,
  raceTypes,
  stockEngines,
  raceTypeDropdownRef,
  stockEngineDropdownRef,
  existingRtState,
  isRaceTypeDropdownDisabled,
  isEngineDropdownDisabled
) => {
  const { raceType, stockEngine } = dropdownState;

  const isRaceTypeSelected = raceType.value;
  const isStockEngineSelected = stockEngine.value;

  if (
    raceTypes &&
    !isRaceTypeSelected &&
    !isStockEngineSelected &&
    raceTypeDropdownRef?.current?.focus &&
    !isRaceTypeDropdownDisabled
  )
    raceTypeDropdownRef.current.focus();
  else if (
    stockEngines &&
    !isStockEngineSelected &&
    existingRtState?.raceType?.value &&
    stockEngineDropdownRef?.current?.focus &&
    !isEngineDropdownDisabled
  )
    stockEngineDropdownRef.current.focus();
};

export default AddRaceTypeView;
