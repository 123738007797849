import React, { useEffect } from "react";
import TextLink from "Atoms/TextLink";
import styles from "./LandingPageLink.module.scss";
import Image from "next/image";
import classNames from "classnames";
import Skeleton from "Atoms/Skeleton";

const LandingPageLink = ({
  link,
  segmentEvent,
  hasImages,
  dataTestId,
  isBrandImage,
  isRaceTypeImage,
  className,
  areImagesLoading,
  imageSize = { height: 150, width: 150 },
  svg,
  svgSize
}) => {



  let width = imageSize.width
  let height = imageSize.height

  if (isBrandImage) {
    width = 149
    height = 55
  }

  if (isRaceTypeImage) {
    width = 231
    height = 79
  }


  return (
    <TextLink
      className={classNames(
        styles.link,
        hasImages ? styles.linkWithImage : null,
        className
      )}
      chevron={hasImages ? "none" : "right"}
      svg={svg}
      svgSize={svgSize}
      size={1}
      fill
      onClick={link.callback}
      nofollow={link.nofollow}
      segmentEvent={link.segmentEvent ?? segmentEvent}
      href={link.url}
      data-testid={
        dataTestId
          ? `landing_page_link_${dataTestId}_${link.displayValue}`
          : `landing_page_link_${link.displayValue}`
      }
      title={link.displayValue}
      aria-label={link.altText ?? link.displayValue}
    >
      {hasImages &&
        (areImagesLoading ? (
          <Skeleton
            className={styles.imageSkeleton}
            width={width}
            height={height}
          />
        ) : (
          <div className={styles.imageWrapper}>
            <Image
              src={
                link.imageUrl ??
                "https://content.speedwaymotors.com/OtherImages/missingimage2.jpg"
              }
              width={width}
              height={height}
              alt={link.displayValue && !isBrandImage ? '' : link.altText ?? `${link.displayValue} Image`}
            />
          </div>
        ))}

      {!isBrandImage && (
        <span className={classNames(styles.linkInnerText, !hasImages ? styles.linkInnerTextSpacing : null)}>{link.displayValue}</span>
      )}
    </TextLink>
  );
};

export default LandingPageLink;
