import React, { useContext } from 'react'
import ClassNames from 'classnames'

import Svg from 'Atoms/Svg'
import Typography from 'Atoms/Typography'

import { NavigationContext } from 'Contexts/Navigation/NavigationContext'
import { useThemeStore } from 'Stores/ThemeStore'
import styles from '../../HeaderDrawer.module.scss'

// alternateCloseFunction can be used on the secondary text if needed
// main case is to login user
const ReturnItem = ({ className, primary, secondary, closeFunction, alternateCloseFunction }) => {
    const { dispatch: navigationDispatch } = useContext(NavigationContext)
    const setIsLoading = useThemeStore((x) => x.setIsLoading)
    return (
        <div
            className={ClassNames(className, styles.returnNav)}
        >
            <div className={styles.returnIcon}
                onClick={() =>
                    closeFunction
                        ? closeFunction()
                        : navigationDispatch({
                            type: 'setDrawerState',
                            payload: {
                                drawerState: {
                                    isDrawerOpen: true
                                },
                                drawerAction: {
                                    type: 'dropNavigation'
                                }
                            }
                        })
                }>
                <Svg icon={'backarrow'} tone={'normal'} size={1.5} />
            </div>
            <div className={styles.returnText}
                onClick={() => {
                    if (closeFunction) {
                        closeFunction()
                    }
                    else {
                        navigationDispatch({
                            type: 'setDrawerState',
                            payload: {
                                drawerState: {
                                    isDrawerOpen: true
                                },
                                drawerAction: {
                                    type: 'dropNavigation'
                                }
                            }
                        })
                    }

                    if (alternateCloseFunction && secondary) {
                        try {
                            setIsLoading(true)
                        }
                        catch (err) {
                            console.error("Failed to show loader")
                        }
                        alternateCloseFunction()
                    }
                }}
            >
                <div>
                    <ReturnItemContent primary={primary} secondary={secondary} />
                </div>
            </div>
        </div>
    )
}

const ReturnItemContent = ({ secondary, primary }) => {
    if (!secondary) {
        return <Svg icon={'wheel'} brand={'secondary'} size={3} />
    }
    else {
        return (
            <>
                <Typography tone={'normal'} size={1.25}>
                    {primary}
                </Typography>
                <Typography tone={'normal'} brand={'secondary'} size={1.5}>
                    {secondary}
                </Typography>
            </>
        )
    }
}

export default ReturnItem
