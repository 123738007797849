import React, { useContext } from "react";

import Panel from "Atoms/Panel";
import ListItemLink from "Molecules/List/ListItemLink";
import ListItem from "Molecules/List/ListItem";
import List from "Molecules/List/List";
import ListItemText from "Molecules/List/ListItemText";
import ReturnItem from "../Common/ReturnItem";
import { NavigationContext } from "Contexts/Navigation/NavigationContext";
import useContent from "Hooks/useContent/useContent";

import styles from "../../HeaderDrawer.module.scss";

const ToolboxMenuItemNavigation = ({ menu, closeFunction }) => {
  const { dispatch: navigationDispatch } = useContext(NavigationContext);
  const { tech, builds, news } = useContent(true);
  const { primary, secondary } = menu;

  let contentData;

  switch (secondary) {
    case "Tech":
      contentData = tech;
      break;
    case "Builds":
      contentData = builds;
      break;
    case "News":
      contentData = news;
      break;
    default:
      console.error("Unrecognized menu: " + secondary);
  }

  if (!contentData) {
    return null;
  }

  return (
    <>
      <ReturnItem
        primary={primary}
        secondary={secondary}
        closeFunction={closeFunction}
      />
      <Panel className={styles.subNavigation}>
        <List component={"div"} className={styles.subNavigationList}>
          {contentData.map((item) => {
            const RootComponent = item.href ? ListItemLink : ListItem;

            return (
              <RootComponent
                key={item.id}
                className={styles.listItem}
                onClick={
                  item.href
                    ? null
                    : () => {
                        navigationDispatch(item.onClick);
                      }
                }
                event={{
                  category: "Header",
                  action: `Header Drawer ${item.title} clicked`,
                }}
                href={item.href}
                hover
                data-testid={item.secondary}
              >
                <ListItemText
                  primary={item.title}
                  primaryTypographyProps={{
                    size: 1,
                    tone: "contrast",
                  }}
                  className={styles.listItemText}
                />
              </RootComponent>
            );
          })}
        </List>
      </Panel>
    </>
  );
};

export default ToolboxMenuItemNavigation;
