import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import useKeypress from '../../Hooks/useKepress'
import ClassNames from 'classnames'
import FocusTrap from 'UtilityComponents/FocusTrap'


// avoid width prop and set your width in the child
const Modal = ({
    toggleOpen,
    open,
    allowOutsideClickClose = true,
    children,
    contentBelowModal,
    contentAboveModal,
    id,
    width,
    alignItems = 'center',
    focusTrap,
    className
}) => {
    useKeypress('Escape', () => {
        open = false;
    })
    // Disable scrolling when the modal is open.
    // https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/
    const classNames = ClassNames(className)

    useEffect(() => {
        if (open) {
            const scrollY = window.scrollY
            document.body.style.position = 'fixed'
            document.body.style.width = '100%'
            document.body.style.top = `-${scrollY}px`
            if (document.getElementsByClassName('crisp-client').length > 0) {
                document.getElementsByClassName(
                    'crisp-client'
                )[0].style.display = 'none'
            }
        } else {
            const scrollY = document.body.style.top;
            document.body.style.position = 'static'
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
            if (document.getElementsByClassName('crisp-client').length > 0) {
                document.getElementsByClassName(
                    'crisp-client'
                )[0].style.display = ''
            }
        }

        return () => {
            const scrollY = document.body.style.top;
            document.body.style.position = 'static'
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
            if (document.getElementsByClassName('crisp-client').length > 0) {
                document.getElementsByClassName(
                    'crisp-client'
                )[0].style.display = ''
            }
        }
    }, [open])

    let belowModalDiv = ''
    let aboveModalDiv = ''
    if (contentBelowModal) {
        belowModalDiv = (
            <BelowModalDiv className="contentBelowModal">
                {contentBelowModal}
            </BelowModalDiv>
        )
    }
    if (contentAboveModal) {
        aboveModalDiv = (
            <AboveModalDiv className="contentBelowModal">
                {contentAboveModal}
            </AboveModalDiv>
        )
    }

    const modal = (
        <ModalWrapper className={'fadeIn'}
            alignItems={alignItems}
            onMouseDown={(e) => {
                e.target.id === 'modalClickWrapper' && allowOutsideClickClose
                    ? toggleOpen(!open)
                    : false
            }}
            id={'modalClickWrapper'}
        >
            <div id={`${id ? id : ''}`} className={classNames}>
                {aboveModalDiv}
                <ModalContent width={width}>{children}</ModalContent>
                {belowModalDiv}
            </div>
        </ModalWrapper>
    )

    if (open) {
        return focusTrap === false ? (
            modal
        ) : (
            <FocusTrap isActive={open}>{modal}</FocusTrap>
        )
    }
    return false
}

const ModalContent = styled.div`
    height: calc(100% - 40px);
    width: ${(props) => props.width};
`

const BelowModalDiv = styled.div`
    text-align: center;
    position: relative;
    background-color: transparent;
    padding: 5px;
`
const AboveModalDiv = styled.div`
    text-align: center;
    position: relative;
    background-color: transparent;
    padding: 5px;
`

const ModalWrapper = styled.div`
    position: fixed;
    margin: 0 auto;
    display: flex;
    width: 100vw;
    height: 100vh;
    right: 0;
    top: 0;
    justify-content: center;
    align-items: ${(props) => props.alignItems};
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 3;
    overflow: auto;
`

Modal.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    header: PropTypes.object,
    content: PropTypes.object,
    toggleOpen: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    allowOutsideClickClose: PropTypes.bool,
    contentBelowModal: PropTypes.object,
    height: PropTypes.string,
    width: PropTypes.string,
    alignItems: PropTypes.string
}

export default Modal
