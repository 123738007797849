import React, { useState, useEffect, useContext } from 'react'
import ClassNames from 'classnames'

import List from 'Molecules/List'
import Panel from 'Atoms/Panel'
import Typography from 'Atoms/Typography'
import Svg from 'Atoms/Svg'
import ListItemText from 'Molecules/List/ListItemText'
import ListItemLink from 'Molecules/List/ListItemLink'
import ListItem from 'Molecules/List/ListItem'
import SearchBar from 'Molecules/SearchBar'
import ReturnItem from '../Common/ReturnItem'

import { Services } from '../Services'
import { NavigationContext } from 'Contexts/Navigation/NavigationContext'
import Skeleton from 'Atoms/Skeleton'
import styles from '../../HeaderDrawer.module.scss'

const FilterableNavigation = ({ fetch, service, menu, id, url, closeFunction }) => {
    const [menuItems, setMenuItems] = useState(null)
    const [searchQuery, setSearchQuery] = useState('')

    useEffect(() => {
        if (fetch && Services[service]) {
            const serviceFunction = Services[service]
            const fetchData = async () => {
                const response = await serviceFunction(id, url)
                setMenuItems(response)
            }
            fetchData()
        } else {
            const { navigation } = menu
            setMenuItems(navigation)
        }
    }, [])

    const handleSearch = (e) => {
        e.preventDefault()
    }

    let filteredMenuItems = []
    if (menuItems) {
        filteredMenuItems = menuItems.filter((mi) => {
            if (mi.primary) {
                return mi.primary.replace(/[\W_]+/g, " ").toLowerCase().includes(searchQuery.replace(/[\W_]+/g, " ").toLowerCase())
            }

        })
    }


    const { primary, secondary } = menu


    return (
        <div className={styles.drawerNavContent}>
            <ReturnItem primary={primary} secondary={secondary} closeFunction={closeFunction} />
            <Panel className={styles.subNavigation}>
                <div className={styles.subNavigationItemSearch}>
                    {(menuItems?.length ?? 0) > 0 ?
                        <SearchBar
                            iconSettings={{
                                position: 'start'
                            }}
                            handleSearch={handleSearch}
                            onChange={(e) => {
                                setSearchQuery(e)
                            }}
                            value={searchQuery}
                            placeholder={`Filter By ${secondary}`}
                        />
                        :
                        <Skeleton height={47} fullWidth />

                    }

                </div>
                <List component={'div'} className={styles.subNavigationList}>
                    {(menuItems?.length ?? 0) > 0 ?

                        filteredMenuItems.map((item) => {
                            return <NavListItem key={item.primary} item={item} />
                        })

                        :
                        <NavSkeleton />

                    }




                </List>
            </Panel>
        </div>
    )
}



const NavListItem = ({ item, isSubItem = false }) => {
    const { dispatch: navigationDispatch } = useContext(NavigationContext)
    const RootComponent = item.href
        ? ListItemLink
        : ListItem;

    if (isSubItem) {
        if (item.primary == 'GMT325') {
            item.primary = '81-03 S-10'
        } else if (item.primary == 'GMT330') {
            item.primary = '82-00 S-10 Blazer'
        } else if (item.primary == 'GMT400') {
            item.primary = '88-00'
        } else if (item.primary == 'GMT800') {
            item.primary = '98-07'
        } else if (item.primary == 'GMT900') {
            item.primary = '07-13'
        }
    } else {
        if (item.primary == 'GMT Trucks') {
            item.primary = 'GM Fullsize Trucks'
        }
    }

    return (
        <>
            <RootComponent
                key={item.primary}
                className={styles.listItem}
                onClick={
                    item.href
                        ? null
                        : () => {
                            navigationDispatch(item.onClick)
                        }
                }
                href={item.href}
                hover
                data-testid={item.primary}
                as={item.href ? null : 'div'}
                >
                <ListItemText
                    primary={
                        !item.simplified
                            ? item.primary
                            : item.secondary
                    }
                    primaryTypographyProps={{
                        className: isSubItem ? styles.subItem : null,
                        size: isSubItem ? 0.875 : 1,
                        tone: 'contrast'
                    }}
                />

            </RootComponent>
            {
                (item.subItems?.length ?? 0) > 0 ?
                    item.subItems.map((subItem) => { return <NavListItem isSubItem key={subItem.href} item={subItem} /> })
                    :
                    null

            }
        </>
    )
}


const NavSkeleton = () => {
    let results = [];
    for (let i = 0; i < 20; i++) {
        let width = (Math.random() * 50) + 50;
        results.push(<><ListItem key={i}><Skeleton key={i} height={24} width={width} percentWidth /></ListItem></>)
    }

    return results;
}

export default FilterableNavigation
