'use client'
import React, { useEffect, useState } from "react";
import ClassNames from "classnames";
import PropTypes from "prop-types";
import styled from "styled-components";
import DrawerContext from "Contexts/DrawerContext";
import Panel from "Atoms/Panel";
import Mask from "Atoms/Mask";
import Svg from "Atoms/Svg";
import Swiper from "Atoms/Swiper/Swiper";
import styles from "./Drawer.module.scss";
import { createPortal } from "react-dom";
import usePortal from "../../Hooks/usePortal";

const propTypes = {
  location: PropTypes.oneOf(["left", "right", "bottom"]),
  width: PropTypes.oneOfType([PropTypes.number]),
};

const Drawer = ({
  portalId,
  children,
  location = "left",
  width = "quarter",
  fill,
  open,
  className,
  closeFunction,
  inverseTheme,
  renderWhenClosed = false,
  testId,
  enableSwipeToClose = false,
  containerClassName,
  ariaLabel,
  drawerZIndex = 200
}) => {
  const target = usePortal(portalId, drawerZIndex);

  //   renderWhenClosed will only skip the first render of the drawer (i.e. not adding it to the DOM)
  //   if the drawer is initally rendered closed. Once open is passed in, the drawer will always be in the DOM
  //   just styled as the open prop indicates
  const [shouldRender, setShouldRender] = useState(!!renderWhenClosed);

  useEffect(() => {
    if (open) {
      setShouldRender(true);
    }
  }, [open]);

  if ((!shouldRender && !!!open) || !target) return false;

  const drawerContextValue = {
    opened: open,
    location: location,
  };

  const isDrawerLeft = location === "left" ? true : false;
  const isDrawerRight = location === "right" ? true : false;
  const isDrawerBottom = location === "bottom" ? true : false;

  inverseTheme = inverseTheme || false;

  if (isDrawerBottom) {
    if (open) {
      return createPortal(
        <DrawerContext.Provider value={drawerContextValue}>
          <div className={styles.drawerZIndex} role={'navigation'} aria-label={ariaLabel}>
            <div className={styles.bottom_drawer_wrapper}>
              <DrawerIcon
                isEnabled
                onClose={closeFunction}
                inverseTheme={inverseTheme}
                location={location}
                testId={testId}
              />
              <Panel
                inverseTheme={inverseTheme}
                className={ClassNames(styles.bottom_drawer_panel)}
              >
                {children}
              </Panel>
            </div>
            {!fill ? <Mask visible={open} onClick={() => closeFunction()} /> : false}
          </div>
        </DrawerContext.Provider>,
        target
      );
    }
    return false;
  }

  const entrails = (
    <DrawerContext.Provider value={drawerContextValue}>
      <div className={ClassNames(styles.drawerZIndex, containerClassName)} role={'navigation'} aria-label={ariaLabel}>
        <StyledDrawerWrapper
          className={className}
          location={location}
          width={width}
          fill={fill}
          open={open}
        >
          {!fill ?
            <DrawerIcon
              isEnabled={isDrawerRight}
              onClose={closeFunction}
              inverseTheme={inverseTheme}
              location={location}
              testId={testId}
            /> : false}
          <Panel
            inverseTheme={inverseTheme}
            className={ClassNames(styles.drawerPanel)}
          >
            <>{children}</>
          </Panel>
          {!fill ?
            <DrawerIcon
              isEnabled={isDrawerLeft}
              onClose={closeFunction}
              location={location}
              inverseTheme={inverseTheme}
              testId={testId}
            /> : false}
        </StyledDrawerWrapper>
        {!fill ? <Mask visible={open} onClick={() => closeFunction()} /> : false}
      </div>
    </DrawerContext.Provider>
  );

  if (enableSwipeToClose) {
    return createPortal(
      <Swiper direction={location} onSwipe={closeFunction}>
        {entrails}
      </Swiper>,
      target
    );
  }

  return createPortal(entrails, target);
};

const DrawerIcon = ({ isEnabled, onClose, location, inverseTheme, testId }) => {
  return isEnabled ? (
    //  this extra div is needed to allow closing the drawer
    // when clicking below the icon
    <div onClick={() => onClose()}>
      <StyledCloseButtonPanel
        className={styles.drawerClose}
        onClick={() => onClose()}
        location={location}
        inverseTheme={inverseTheme}
      >
        <Svg
          icon={"close"}
          data-testid={
            testId ? `drawerCloseButton_${testId}` : "drawerCloseButton"
          }
        />
      </StyledCloseButtonPanel>
    </div>
  ) : (
    false
  );
};

const StyledDrawerWrapper = styled.div`
  ${({ location, open }) => {
    if (location === "left") {
      if (open) return `display: flex; left: 0px; right 0px; max-width: 400px;`;
      else return "display: none;";
    } else if (location === "right")
      if (open) return `display: flex; left: 0px; right: 0px; width: 100%;`;
      else return "display: none;";
  }}
  top: 0;
  bottom: 0;
  margin-top: 0;
  position: fixed;
  z-index: 99;
  -webkit-overflow-scrolling: touch;
  @media (min-width: 1024px) {
    width: ${(props) => props.fill ? "100vw" : `${props.width}px`};
    display: flex;
    max-width: none;
    ${({ location, open, width, fill }) => {
      let css;
      if (location === "left") css = "right: auto; left: ";
      else if (location === "right") css = "left auto; right: ";
      if (open) css += "0px;";
      else css += fill ? `-100vw;` : `-${width}px;`;

      return css;
    }}
  }
  ${({ location }) => {
    if (location === "left") return "transition: left .2s linear;";
    else if (location === "right") return "transition: right .2s linear;";
  }}
`;

const StyledCloseButtonPanel = styled(Panel)`
  ${(props) =>
    props.location === "bottom"
      ? "margin-bottom: 8px; margin-left: calc(100vw - 40px);"
      : ""}

  cursor: pointer;
  font-size: 1.25em;
  padding: 8px;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-top: 8px;
  display: flex;
  align-self: flex-start;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  text-align: center;
  margin-left: 3px;
  margin-right: 3px;
`;

Drawer.propTypes = propTypes;

export default Drawer;
