import React from 'react'
import Script from 'next/script'
import useGlobalPrivacyControl from "Hooks/useGlobalPrivacyControl";

export default function Tracking() {
    
    const { isGpcEnabled } = useGlobalPrivacyControl();

    if (isGpcEnabled) {
        return false
    }

    return (
        <>
            <Script
                enableOnlyOnNextFrontEnd={true}
                id="googleads-init"
                data-testid="googleads-init"
                strategy="afterInteractive"
                src="https://www.googleadservices.com/pagead/conversion_async.js"
            />

            <Script    
                enableOnlyOnNextFrontEnd={true}
                id="facebookTracking-load"
                data-testid="facebookTracking-load"
                strategy="afterInteractive" 
                src="https://connect.facebook.net/en_US/fbevents.js"
            />
        </>
    )
}
