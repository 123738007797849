// VERY IMPORTANT for lazy loadable components. Allows a user
// who has a network failure in downloading a component to try
// and download it a few more times. This should solve a lot
// of loadable-component erros.
const Retry = (fn, retriesLeft = 3, interval = 1000, debug = false) => {
    return new Promise((resolve, reject) => {
        if(debug) {
          console.log("The Retry Function was called.")
        }
        fn()
        .then((module) => {
            resolve(module)
        })
        .catch((error) => {
          //console.log("HELO")
          setTimeout(() => {
            if (retriesLeft === 1) {
              console.error('maximum retries exceeded');
              reject(error);
              return;
            }
  
            // Passing on "reject" is the important part
            if(debug) {
              Retry(fn, retriesLeft - 1, interval, true).then(resolve, reject);
            }
            else {
              Retry(fn, retriesLeft - 1, interval).then(resolve, reject);
            }
          }, interval);
        });
    });
  }
  export default Retry