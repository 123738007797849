import useSegment from "../../../Hooks/useSegment";

function buildSegmentWishListProductOnlyEventData(wishListProduct, index) {
  return {
    product_id: wishListProduct?.skuBaseNumber,
    sku: wishListProduct?.skuBaseNumber,
    variant: wishListProduct?.sku,
    category: wishListProduct?.segmentCategory ?? "",
    name: wishListProduct?.title,
    price: parseFloat(wishListProduct?.price?.price) ?? 0,
    quantity: parseInt(wishListProduct?.quantity) ?? 0,
    position: index ?? 0,
    brand: wishListProduct?.brand ?? "",
    url: wishListProduct?.productUrl,
    image_url: wishListProduct?.imageUrl,
  };
}

function buildSegmentWishListArticleOnlyEventData(wishListArticle, index) {
  return {
    article_id: wishListArticle?.id,
    author: wishListArticle?.author ?? "",
    title: wishListArticle?.title,
    position: index ?? 0,
    url: wishListArticle?.url ?? "",
    image_url: wishListArticle?.articleThumbnailUrl ?? "",
  };
}

export const createWishListEvent = async (wishListId, wishListName) => {
  const { sendSegmentTrackEventWithName } = useSegment();

  if (typeof analytics !== "undefined") {
    sendSegmentTrackEventWithName("Wishlist Created", {
      wishlist_id: wishListId?.toString(),
      wishlist_name: wishListName ?? "",
    });
  }
};

export const deleteWishListEvent = async (
  wishListId,
  wishListName,
  wishListProducts,
  wishListArticles
) => {
  if (typeof analytics !== "undefined") {
    analytics.track("Wishlist Deleted", {
      wishlist_id: wishListId?.toString(),
      wishlist_name: wishListName ?? "",
      products: wishListProducts.map((x, i) =>
        buildSegmentWishListProductOnlyEventData(x, i)
      ),
      articles: wishListArticles.map((x, i) =>
        buildSegmentWishListArticleOnlyEventData(x, i)
      ),
    });
  }
};
