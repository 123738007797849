'use client'
import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'


import { useThemeStore } from 'Stores/ThemeStore'

import styles from './Skeleton.module.scss'
import styled from 'styled-components'

const propTypes = {
    className: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
    fullWidth: PropTypes.bool,
    data: PropTypes.any,
    percentWidth: PropTypes.bool,
    inverse: PropTypes.bool,
    fullHeight: PropTypes.bool
}

const Skeleton = ({ className, width, height, fullWidth, data, percentWidth, inverse, fullHeight }) => {

    if ((!height && !fullHeight) || (!fullWidth && !width && !percentWidth)) {
        return null
    }


    const themeInverse = useThemeStore((x) => x.inverse)

    const opacity = themeInverse == "dark" && !inverse ? 0.3 : 1;

    if (data) {
        return data
    }

    return (
        <StyledSkeleton
            data-testid="skeleton"
            className={inverse ? classNames(styles.darkSkeleton, className) : classNames(styles.skeleton, className)}
            height={height}
            width={width}
            fullWidth={fullWidth}
            percentWidth={percentWidth}
            fullHeight={fullHeight}
            opacity={opacity}
        ></StyledSkeleton>
    )
}

const StyledSkeleton = styled('div')`
    height: ${(props) => (props.fullHeight ? '100%' : `${props.height}px`)};
    width: ${(props) => (props.fullWidth ? '100%' : `${props.width}${props.percentWidth ? '%' : 'px'}`)};
    opacity: ${(props) => props.opacity};
    border-radius: ${(props) => (props.fullHeight ? '0px' : `12px`)};
`

Skeleton.propTypes = propTypes

export default Skeleton
