import React from 'react'
import ClassNames from 'classnames'
import Typography from 'Atoms/Typography'


import styles from './ListItemText.module.scss'

const ListItemText = ({
    primary,
    secondary,
    className,
    underlineHover,
    primaryTypographyProps,
    secondaryTypographyProps,
    ...props
}) => {
    const classnames = ClassNames(className)
    primaryTypographyProps = primaryTypographyProps
        ? primaryTypographyProps
        : {}
    secondaryTypographyProps = secondaryTypographyProps
        ? secondaryTypographyProps
        : {}

    const primarySettings = {
        size: primaryTypographyProps.size ? primaryTypographyProps.size : 1,
        tone: primaryTypographyProps.tone
            ? primaryTypographyProps.tone
            : 'normal',
        intent: primaryTypographyProps.intent
            ? primaryTypographyProps.intent
            : null,
        font: primaryTypographyProps.font
            ? primaryTypographyProps.font
            : 'regular',
        className: primaryTypographyProps.className
            ? primaryTypographyProps.className
            : null,
        underlineHover: primaryTypographyProps.underlineHover
            ? primaryTypographyProps.underlineHover
            : null
    }

    const secondarySettings = {
        size: secondaryTypographyProps.size ? secondaryTypographyProps.size : 1,
        tone: secondaryTypographyProps.tone
            ? secondaryTypographyProps.tone
            : 'normal',
        intent: secondaryTypographyProps.intent
            ? secondaryTypographyProps.intent
            : null,
        font: secondaryTypographyProps.font
            ? secondaryTypographyProps.font
            : 'regular',
        className: secondaryTypographyProps.className
            ? secondaryTypographyProps.className
            : null
    }

    return (
        <>
            <div className={classnames}>
                <Typography
                    {...primarySettings}
                    className={ClassNames(
                        primarySettings.className,
                        styles.listItemText
                    )}
                >
                    {primary}
                </Typography>
                <SecondaryText
                    {...secondarySettings}
                    className={ClassNames(
                        secondarySettings.className,
                        styles.listItemText
                    )}
                >
                    {secondary}
                </SecondaryText>
            </div>
        </>
    )
}

const SecondaryText = ({ children, ...props }) => {
    if (children) {
        return <Typography {...props}>{children}</Typography>
    }

    return false
}

export default ListItemText
