import React, { createContext, useContext, useEffect, useState } from "react";

import { ListContext } from "Contexts/ListContext/ListContext";
import { NotificationContext } from "Contexts/NotificationContext/NotificationContext";
import { TrackingContext } from "Contexts/TrackingContext/TrackingContext";
import useCart from "Hooks/useCart/useCart";
import useRecentHistory from "Hooks/useRecentHistory.js";
import useWindow from "Hooks/useWindow";
import defer from "Utilities/defer";
import { v4 as uuidv4 } from "uuid";

import { useUserStore } from "../../Stores/UserStore/UserStore";
import { GetUserGuid } from "../../Utilities/CookieUtilties/UserCookieUtilities";
import { sendSegmentTrackEvent } from "../../Utilities/Instrumentation/SegmentUtilities/segmentFunctions";


const AddToCartExperienceContext = createContext({});

const AddToCartExperienceProvider = ({ children, value, services }) => {
  const [context, setContext] = useState({
    ...value,
    isSelectIndexOptionsDrawerOpen: (value && value.isSelectIndexOptionsDrawerOpen) || false,
    isKitDetailsDrawerOpen: (value && value.isKitDetailsDrawerOpen) || false,
    cartTotalPrice: (value && value.cartTotalPrice) || 0.0,
    itemCount: (value && value.itemCount) || 0,
    isVariantDetailsDrawerOpen: false,
    productPageId: null,
    itemsForSelectIndexOptionsDrawer: [],
    product: null,
    indexOptions: null,
    isIndexOptionErrorState: false,
    addToListSkuLoading: null
  });
  const [fullCart, setFullCart] = useState(null);
  const { cart, updateCart } = useCart();
  const listContext = useContext(ListContext);
  const { segmentReady, misoId, pageAttribution } = useContext(TrackingContext);
  const { PushNotification } = useContext(NotificationContext);
  const updateUserGuid = useUserStore((x) => x.context.updateUserGuid);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.maker = {
        ...window.maker,
        addToCart: (skuVariantNumber, quantityRequested, skuBaseNumber) => {
          addToCart([
            {
              skuVariantNumber,
              quantityRequested,
              skuBaseNumber,
            },
          ]);
        },
        test: () => { },
      };
    }
  }, [typeof window, services]);

  useWindow(async () => {
    const url = new URL(window.location.href);
    const action = url.searchParams.get("action")
    if (action) {
      const skuorskubasetoadd = url.searchParams.get("skuorskubasetoadd");
      if (skuorskubasetoadd) {
        if (action == "addtocart")
          await handleAddToCartPRP(null, skuorskubasetoadd, null);
        else if (action == "addtolist")
          await handleAddToListPRP(null, skuorskubasetoadd);
      }
      else console.error("Missing skuorskubasetoadd url param");
    }
  });

  const showKitDetailsDrawer = (config) => {
    setContext({
      ...context,
      isKitDetailsDrawerOpen: true,
      isKitDetailsDrawerForAtc: config ? config.atc : true,
      isKitDetailsDrawerForList: config ? config.list : false,
      isVariantDetailsDrawerOpen: false,
    });
  };

  const hideKitDetailsDrawer = () => {
    setContext({
      ...context,
      isKitDetailsDrawerOpen: false,
    });
  };

  const showAtcDrawer = () => {
    setContext({
      ...context,
      isAtcDrawerOpen: true,
    });
  };

  const hideAtcDrawer = () => {
    setContext({
      ...context,
      isAtcDrawerOpen: false,
    });
  };

  const showListDrawer = (items) => {
    listContext.showListDrawer(items);
    setContext({
      ...context,
      isVariantDetailsDrawerOpen: false,
      isKitDetailsDrawerOpen: false,
      isSelectIndexOptionsDrawerOpen: false
    });
  };

  const hideListDrawer = () => {
    listContext.hideListDrawer();
  };

  const showSelectIndexOptionsDrawer = (items) => {
    setContext({
      ...context,
      isVariantDetailsDrawerOpen: false,
      isKitDetailsDrawerOpen: false,
      isSelectIndexOptionsDrawerOpen: true,
      itemsForSelectIndexOptionsDrawer: items
    });
  };

  const hideSelectIndexOptionsDrawer = () => {
    setContext({
      ...context,
      isSelectIndexOptionsDrawerOpen: false,
    });
  };

  const populateKitDetailsDrawer = (
    kitComponents,
    skuVariantNumber,
    quantityRequested,
    title,
    images,
    skuBaseNumber,
    brand,
    price,
    segmentCategory,
    url,
    availability,
    rating,
    reviewCount,
    superMarketAffinity
  ) => {
    setContext({
      ...context,
      kitComponents: kitComponents,
      skuVariantNumber: skuVariantNumber,
      skuBaseNumber: skuBaseNumber,
      quantityRequested: quantityRequested,
      title: title,
      images: images,
      isKitDetailsDrawerOpen: context.isKitDetailsDrawerOpen,
      brand: brand,
      price: price,
      segmentCategory: segmentCategory,
      url: url,
      availability,
      rating,
      reviewCount,
      superMarketAffinity,
    });
  };

  const selectKitComponentIndexOption = (
    skuBase,
    skuVariant,
    selectedIndexOptions,
    mostRecentlySelectedIndexOption,
    componentOrdinality,
    componentPurchaseQuantity
  ) => {
    import("./fetches/product.js").then((f) => {
      f.onKitComponentIndexOptionSelection(
        skuBase,
        skuVariant,
        selectedIndexOptions,
        mostRecentlySelectedIndexOption,
        componentPurchaseQuantity
      ).then((data) => {
        const newContext = { ...context };
        newContext.kitComponents.sort((a, b) => {
          return (
            (parseInt(a.ordinality) || a.skuBaseName) -
            (parseInt(b.ordinality) || b.skuBaseName)
          );
        })[componentOrdinality].indexOptions =
          data.selectKitComponentIndexOption;
        setContext({
          ...newContext,
        });
      });
    });
  };

  const getLists = () => {
    let webUserGuid = GetUserGuid();
    if (!webUserGuid || webUserGuid.length < 1) return;

    listContext.getUserLists(true);
  };

  const createList = async (items, listName) => {
    let webUserGuid = GetUserGuid();

    //todo: DRY violation
    if (!webUserGuid || webUserGuid.length < 1) {
      //xxxxxxxx-xxxx-Mxxx-Nxxx-xxxxxxxxxxxx
      webUserGuid = uuidv4();
      updateUserGuid(webUserGuid);
    }

    let success = await listContext.createNewList(
      listName,
      items,
      null,
      "/graphql/checkout",
      webUserGuid
    );

    if (success) {
      if (items && items.length) {
        PushNotification(
          `List '${listName}' successfully created with ${items.length} ${items.length === 1 ? "item" : "items"
          }.`,
          null,
          5000,
          null,
          { icon: "check", intent: "positive" }
        );
      } else {
        PushNotification(
          `List '${listName}' successfully created.`,
          null,
          5000,
          null,
          { icon: "check", intent: "positive" }
        );
      }
    } else {
      PushNotification(
        `Unable to add item to list at this time.`,
        null,
        5000,
        null,
        { icon: "cross", intent: "negative" }
      );
    }

    hideListDrawer();
  };

  const addToList = async (items, listId) => {
    let webUserGuid = GetUserGuid();

    //todo: DRY violation
    if (!webUserGuid || webUserGuid.length < 1) {
      //xxxxxxxx-xxxx-Mxxx-Nxxx-xxxxxxxxxxxx
      webUserGuid = uuidv4();
      updateUserGuid(webUserGuid);
    }

    let success = await listContext.addToList(
      items,
      listId,
      "/graphql/checkout"
    );

    if (success) {
      PushNotification(
        `${items.length} ${items.length === 1 ? "item" : "items"
        } added to list.`,
        null,
        5000,
        null,
        { icon: "check", intent: "positive" }
      );
    } else {
      PushNotification(
        `Unable to add item to list at this time.`,
        null,
        5000,
        null,
        { icon: "cross", intent: "negative" }
      );
    }
    hideListDrawer();
  };

  const hydrateCreditedProductList = (items) => {
    try {
      const mostRecentListName =
        useRecentHistory.GetMostRecentProductListName();
      const mostRecentListDeets =
        useRecentHistory.GetMostRecentProductListObject();

      for (let i = 0, ilen = items.length; i < ilen; i++) {
        items[i].creditedSource =
          typeof mostRecentListName == "string"
            ? mostRecentListName
            : undefined;

        if (mostRecentListDeets?.attribution) {
          items[i].attribution = mostRecentListDeets.attribution;
        }
      }
    } catch (err) {
      console.error("Failed to get credited product list for atc", err);
    }
  };

  const addToCart = async (
    items,
    hideDrawer = false,
    fallbackPdpUrl = null,
    isAddRecs = false
  ) => {
    let webUserGuid = GetUserGuid();

    hydrateCreditedProductList(items);

    //todo: DRY violation
    if (!webUserGuid || webUserGuid.length < 1) {
      //xxxxxxxx-xxxx-Mxxx-Nxxx-xxxxxxxxxxxx
      webUserGuid = uuidv4();
      updateUserGuid(webUserGuid);
    }

    // this running context allows for us to kick off requests to the
    // checkout service and the discovery service at the same time
    let runningContext = {
      ...context,
    };
    //console.info('items', items)

    const module = await import("./fetches/checkout.js");
    const data = await module.addToCart(
      items,
      webUserGuid,
      services?.checkout?.url
    );

    if (data.addToCart && data.addToCart.success) {
      // In the case that items passed in don't have images (Maker ATC), we need to map the recently added product image from the response to show in the atc drawer
      try {
        items.forEach((item) => {
          if (!item?.images?.length) {
            let matchingSku = data.addToCart.items.find(
              (x) => x.skuVariantNumber === item.skuVariantNumber
            );
            item.images = [matchingSku?.regularImageUrl];
          }
        });
      } catch (err) {
        console.error(err);
      }

      runningContext = {
        ...runningContext,
        cartTotalPrice: data.addToCart.cartTotalPrice,
        itemCount: data.addToCart.itemCount,
        items: items,
        isKitDetailsDrawerOpen: false,
        isVariantDetailsDrawerOpen: false,
        isSelectIndexOptionsDrawerOpen: false,
        isAtcDrawerOpen: !hideDrawer,
      };

      if (isAddRecs && context.items) {
        runningContext.items = context.items.concat(items)
      }

      const getSuperMarketAffinity = (item) => {
        item.superMarketAffinity = item.superMarketAffinity ?? [];
        if (item.superMarketAffinity.length === 0) {
          return "none";
        }
        if (item.superMarketAffinity.length === 1) {
          return item.superMarketAffinity[0].toLowerCase();
        }
        return "multiple";
      };

      //segment add to cart
      if (segmentReady) {
        //console.info(items)
        items.forEach((item) => {
          if (!item.skuBaseNumber || !item.skuVariantNumber) {
            console.error("sending ATC event to segment without sku numbers");
          }

          let atcEventObj = {
            cart_id: webUserGuid,
            product_id: item.skuBaseNumber,
            sku: item.skuBaseNumber,
            name: item.title,
            variant: item.skuVariantNumber,
            quantity: item.quantityRequested,
            brand: item.brand,
            category: item.segmentCategory,
            audience_affinity: getSuperMarketAffinity(item),
            price:
              typeof item.price === "string"
                ? parseFloat(item.price.replace("$", ""))
                : item.price,
            url: item.url,
            image_url: (item.images || []).length
              ? `https:${item.images[0]}`
              : "",
            availability: item.availabilityEnum?.length
              ? item.availabilityEnum
              : item.availability,
            rating: item.reviewCount ? item.rating : 0,
            reviews: item.reviewCount,
          };

          if (misoId) {
            atcEventObj.miso = { miso_id: misoId };
          }

          const sourceObject = useRecentHistory.GetMostRecentProductListObject(
            item.creditedSource
          );

          let attribution
          if (item.attribution && item.attribution.is_engine)
            attribution = item.attribution
          else if (item.attribution && pageAttribution)
            attribution = {
              ...item.attribution,
              ...pageAttribution
            }
          else
            attribution = item.attribution || pageAttribution || {};

          sendSegmentTrackEvent({
            event: "Product Added",
            properties: {
              ...atcEventObj,
              ...sourceObject,
              ...attribution,
            },
          });
        });
      }

      if (window.gtag) {
        items.forEach((item) => {
          defer(() => {
            window.gtag("event", "add_to_cart", {
              value: item.priceDecimal ?? item.price,
              items: [
                {
                  id: item.skuVariantNumber,
                  google_business_vertical: "retail",
                },
              ],
              send_to: "ads",
            });
          });
        });
      }

      updateCart();
      if (fullCart) {
        getFullCart(true);
      }

      setContext(runningContext);
    } else {
      if (fallbackPdpUrl) {
        if (typeof window !== "undefined") {
          window.location.href = fallbackPdpUrl;
        }
      }
      PushNotification(
        "Error occured while adding to cart.",
        null,
        5000,
        null,
        { icon: "unavailable", intent: "negative" }
      );
    }

    if (hideDrawer) {
      return data.addToCart.success;
    }

    return data.addToCart.success;
  };

  const showVariantDetailsDrawer = async (
    productPageId,
    product,
    indexOptions,
    isAtc = false
  ) => {
    setContext({
      ...context,
      isVariantDetailsDrawerOpen: true,
      isVariantDetailsDrawerAtc: isAtc,
      isIndexOptionErrorState: false,
      productPageId,
      product,
      indexOptions,
    });
  };

  const hideVariantDetailsDrawer = async () => {
    setContext({
      ...context,
      isVariantDetailsDrawerOpen: false,
      isVariantDetailsDrawerAtc: false,
    });
  };

  const errorIndexOptions = () => {
    setContext({
      ...context,
      isIndexOptionErrorState: true,
    });
  };

  const showAndPopulateKitDetailsDrawer = (config, product) => {
    const {
      kitComponents,
      skuVariantNumber,
      quantityRequested,
      title,
      images,
      skuBaseNumber,
      brand,
      url,
      priceDecimal,
      segmentCategory,
      rating,
      reviewCount,
      availability,
      superMarketAffinity,
    } = product;

    setContext({
      ...context,
      brand: brand,
      segmentCategory: segmentCategory,
      url: url,
      price: priceDecimal,
      isKitDetailsDrawerOpen: true,
      isKitDetailsDrawerForAtc: config ? config.atc : true,
      isKitDetailsDrawerForList: config ? config.list : false,
      isVariantDetailsDrawerOpen: false,
      kitComponents,
      skuVariantNumber,
      skuBaseNumber,
      quantityRequested,
      title,
      images,
      rating,
      reviewCount,
      availability,
      superMarketAffinity,
    });
  };

  const handleAddToListPRP = async (productPageId, skuVariant) => {
    setAddToListDrawerLoadingSku(skuVariant);
    const product = await handleIndexOptionSelection(productPageId, skuVariant);
    const { indexOptions, isIndexOptionKit } = product;
    if (indexOptions.length > 0) {
      if (indexOptions.some((option) => option.indexOptionValues.length > 1))
        showVariantDetailsDrawer(productPageId, product, indexOptions);
    } else if (indexOptions.length === 0 && isIndexOptionKit)
      showAndPopulateKitDetailsDrawer({ list: true }, product);
    else showListDrawer([product]);

    setAddToListDrawerLoadingSku(null);
  };

  const handleAddToCartPRP = async (
    productPageId,
    skuVariant,
    fallbackPdpUrl
  ) => {
    const product = await handleIndexOptionSelection(
      productPageId,
      skuVariant,
      null,
      null,
      fallbackPdpUrl
    );
    if (!product && fallbackPdpUrl) {
      window.location.href = fallbackPdpUrl;
    }
    const { indexOptions, isIndexOptionKit } = product;
    try {
      if (indexOptions.length > 0) {
        if (indexOptions.some((option) => option.indexOptionValues.length > 1))
          showVariantDetailsDrawer(productPageId, product, indexOptions, true);
        else window.location.href = fallbackPdpUrl;
      } else if (indexOptions.length === 0 && isIndexOptionKit)
        showAndPopulateKitDetailsDrawer({ atc: true }, product);
      else addToCart([product], false, fallbackPdpUrl);
    } catch (error) {
      console.error(error);
      if (typeof window !== "undefined" && fallbackPdpUrl) {
        window.location.href = fallbackPdpUrl;
      }
    }
  };

  const handleAddRecommendationsToCart = async (
    recommendations
  ) => {
    if (recommendations?.length === 0
      || recommendations.some((x) => !x.publicSkuBaseDetails?.hasNoKitBaseComponents)) {
      console.error('Product list is not valid for add to cart method. Fix the conditions for the ATC button showing up.')
      return;
    }

    console.info('Adding recommendations to cart')

    let products = recommendations.map((x) => ({
      skuBaseNumber: x.publicSkuBaseDetails.skuBaseNumber,
      skuVariantNumber: x.publicSkuBaseDetails.indexOptions?.length ? null : x.publicSkuBaseDetails.skuBaseNumber,
      title: x.publicSkuBaseDetails.displayTitle,
      productPageId: x.publicSkuBaseDetails.productPageId,
      quantityRequested: 1,
      indexOptions: x.publicSkuBaseDetails.indexOptions,
      imageUrl: x.publicSkuBaseDetails.thumbnailImageLink,
      brand: x.publicSkuBaseDetails.brand,
      segmentCategory: x.publicSkuBaseDetails.segmentCategorization,
      superMarketAffinity: x.publicSkuBaseDetails.superMarketAffinity,
      price: x.publicSkuBaseDetails.pricing.salePrice,
      url: x.publicSkuBaseDetails.productPageUrl,
      availability: x.publicSkuBaseDetails.availability,
      rating: x.publicSkuBaseDetails.rating,
      reviews: x.publicSkuBaseDetails.reviewCount,
    }))

    if (products.some((x) => x.indexOptions?.length)) {
      console.info('Showed SelectIndexOptionsDrawer')
      showSelectIndexOptionsDrawer(products)
      return
    }

    try {
      addToCart(products, false, null, true);
    } catch (error) {
      console.error(error);
    }
  };

  const selectIndexOption = async (
    item, 
    productPageId, 
    selectedIndexOptions, 
    lastSelectedOption
  ) => {
    console.info('productPageId', productPageId)
    const product = await handleIndexOptionSelection(
      productPageId,
      null,
      selectedIndexOptions,
      lastSelectedOption
    );

    item.indexOptions = product.indexOptions
    item.skuVariantNumber = product.skuVariantNumber
    item.price = product.priceDecimal

    setContext({
      ...context,
      isIndexOptionErrorState: false,
    });
  };

  const handleIndexOptionSelectionVDD = async (
    productPageId,
    skuVariant,
    selectedIndexOptions,
    lastSelectedOption
  ) => {
    const product = await handleIndexOptionSelection(
      productPageId,
      skuVariant,
      selectedIndexOptions,
      lastSelectedOption
    );
    const { isIndexOptionKit } = product;
    let kitDrawerItems = {};

    if (isIndexOptionKit) {
      const {
        kitComponents,
        skuVariantNumber,
        quantityRequested,
        title,
        images,
        skuBaseNumber,
      } = product;
      kitDrawerItems = {
        kitComponents,
        skuVariantNumber,
        skuBaseNumber,
        quantityRequested,
        title,
        images,
      };
    }

    setContext({
      ...context,
      product,
      isIndexOptionErrorState: false,
      ...kitDrawerItems,
    });
  };

  const handleAddToListVDD = () => {
    const { skuVariantNumber, isIndexOptionKit } = context.product;
    if (!skuVariantNumber) errorIndexOptions();
    else if (isIndexOptionKit) showKitDetailsDrawer({ list: true });
    else showListDrawer([context.product]);
  };

  const handleAddToCartVDD = () => {
    const { skuVariantNumber, isIndexOptionKit, productPageUrl } =
      context.product;
    try {
      if (!skuVariantNumber) errorIndexOptions();
      else if (isIndexOptionKit) showKitDetailsDrawer({ atc: true });
      else addToCart([context.product], false, productPageUrl);
    } catch (error) {
      console.error(error);
      if (typeof window !== "undefined" && productPageUrl) {
        window.location.href = productPageUrl;
      }
    }
  };

  const addToCartSelectIndexOptionsDrawer = (items) => {
    if (items.some((x) => !x.skuVariantNumber)) {
      errorIndexOptions()
      return
    }
    console.info('Added recs to cart from SelectIndexOptionsDrawer')
    addToCart(items, null, null, true)
  }

  const handleIndexOptionSelection = async (
    productPageId,
    skuVariant,
    selectedIndexOptions,
    lastSelectedOption,
    fallbackPdpUrl
  ) => {
    const productModule = await import("./fetches/product.js");
    const {
      title,
      skuVariantNumber,
      indexOptions,
      mainImage,
      superMarketAffinity,
      isIndexOptionKit,
      kitComponents,
      images,
      skuBaseNumber,
      productPageId: productPageIdFromResponse,
      isPurchaseable,
      brand,
      segmentCategorization,
      productPageUrl,
      price,
      priceDecimal,
      rating,
      reviewCount,
      availability,
      availabilityEnum,
    } = await productModule.handleIndexOptionSelection(
      productPageId,
      skuVariant,
      selectedIndexOptions,
      lastSelectedOption
    );

    try {
      const product = {
        productPageId: productPageId ?? productPageIdFromResponse,
        title,
        skuVariantNumber,
        imageUrl: mainImage,
        isIndexOptionKit,
        kitComponents,
        images,
        skuBaseNumber,
        brand: brand.brandName,
        segmentCategory: segmentCategorization,
        url: productPageUrl,
        price,
        priceDecimal,
        indexOptions,
        quantityRequested: 1,
        superMarketAffinity,
        isPurchaseable,
        reviewCount,
        rating,
        availability,
        availabilityEnum,
      };
      return product;
    } catch (error) {
      console.error(error);
      if (typeof window !== "undefined" && fallbackPdpUrl) {
        window.location.href = fallbackPdpUrl;
      }
    }
  };

  const setAddToListDrawerLoadingSku = (skuNumber) => {
    setContext((context) => {
      return {
        ...context,
        addToListSkuLoading: skuNumber,
      };
    });
  };

  const getFullCart = async (forceFresh = false) => {
    if (fullCart && !forceFresh) {
      return fullCart;
    }

    const module = await import("./fetches/checkout.js");
    const data = await module.fetchFullCart(GetUserGuid());
    setFullCart(data);
    return data;
  };

  return (
    <AddToCartExperienceContext.Provider
      value={{
        showKitDetailsDrawer,
        hideKitDetailsDrawer,
        showAtcDrawer,
        hideAtcDrawer,
        showListDrawer,
        hideListDrawer,
        showSelectIndexOptionsDrawer,
        hideSelectIndexOptionsDrawer,
        populateKitDetailsDrawer,
        selectIndexOption,
        addToCartSelectIndexOptionsDrawer,
        selectKitComponentIndexOption,
        addToCart,
        getLists,
        addToList,
        createList,
        showVariantDetailsDrawer,
        hideVariantDetailsDrawer,
        errorIndexOptions,
        showAndPopulateKitDetailsDrawer,
        handleAddToListPRP,
        handleAddToCartPRP,
        handleAddRecommendationsToCart,
        handleIndexOptionSelectionVDD,
        handleAddToListVDD,
        handleAddToCartVDD,
        getFullCart,
        fullCart,
        ...context, // this might have a hidden cost to it
      }}
    >
      {children}
    </AddToCartExperienceContext.Provider>
  );
};

export { AddToCartExperienceContext, AddToCartExperienceProvider };
