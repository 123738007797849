import { useEffect, useContext } from "react";
import { useVehicleStore } from "Stores/VehicleStore";
import { setItem } from "Utilities/LocalStorageUtility";
import { NOT_FOUND_COOKIE } from "../Constants/Cookies";
import { GetUserGuid } from 'Utilities/CookieUtilties/UserCookieUtilities'
import { NavigationContext } from 'Contexts/Navigation/NavigationContext'

const customerProjectsLocalStorageKey = "customerVehicles";

export default function VehicleStoreWiring({ }) {
    const userGuid = GetUserGuid();

    const customerProjects = useVehicleStore(x => x.context.customerProjects);
    const fetchCustomerProjects = useVehicleStore(x => x.fetchCustomerProjects);
    const findMatchingProjects = useVehicleStore(x => x.findMatchingProjects);
    const selectCustomerProject = useVehicleStore(x => x.selectCustomerProject);
    const clearSelectedCustomerProject = useVehicleStore(x => x.clearSelectedCustomerProject);
    const fitmentCookie = useVehicleStore(x => x.fitmentCookie);
    const hydrated = useVehicleStore(x => x.context.hydrated);
    const setSelectedCustomerProjectHydratedStatus = useVehicleStore(x => x.setSelectedCustomerProjectHydratedStatus);
    const getCustomerProjectsLocalStorage = useVehicleStore(x => x.getCustomerProjectsLocalStorage);
    const removeCustomerProjectsLocalStorage = useVehicleStore(x => x.removeCustomerProjectsLocalStorage);
    const selectedFacetData = useVehicleStore((x) => x.context.selectedFacetData);
    const {  state: navigationState } = useContext(NavigationContext);

    useEffect(() => {
        if (selectedFacetData && navigationState?.pageType === 'PRP') 
            fetchCustomerProjects(true);
    }, [selectedFacetData, navigationState.pageType])

    useEffect(() => {
        if (userGuid) {
            const locallyStoredCustomerProjects = getCustomerProjectsLocalStorage();

            if (
                locallyStoredCustomerProjects?.customerProjects?.length &&
                !locallyStoredCustomerProjects.userGuid
            ) {
                setItem(
                    customerProjectsLocalStorageKey,
                    JSON.stringify({
                        ...locallyStoredCustomerProjects,
                        userGuid,
                    })
                );
            }

            if (getCustomerProjectsLocalStorage()?.userGuid !== userGuid) {
                removeCustomerProjectsLocalStorage();
            }

            fetchCustomerProjects();
        }
    }, [userGuid]);

    useEffect(() => {
        async function handleSelectedCustomerProject() {
            if (fitmentCookie && fitmentCookie !== NOT_FOUND_COOKIE && !userGuid) {
                await clearSelectedCustomerProject();
            }

            //found cookie. parse it
            let matchingProjects = findMatchingProjects(
                fitmentCookie,
                customerProjects
            );
            if (matchingProjects?.length > 0) {
                await selectCustomerProject(matchingProjects[0].projectId);
            } else if (hydrated) {
                setSelectedCustomerProjectHydratedStatus(fitmentCookie !== null);
            }
        }

        handleSelectedCustomerProject();
    }, [customerProjects, fitmentCookie, userGuid, hydrated]);


    // WIFR-32486 I believe this is no longer needed because we don't put faceted data into garage - Blaine Traudt
    // useEffect(() => {
    //   if (selectedFacetData) {
    //     removeCustomerProjectsLocalStorage();
    //     fetchcustomerProjects();
    //   }
    // }, [selectedFacetData]);

    // WIFR-32486 Dead code. partType is not decalred and this is handled in VehicleStore - Blaine Traudt
    // useEffect(() => {
    //   if (typeof window !== "undefined") {
    //     window.maker = {
    //       ...window.maker,
    //       filterByVehicleAndPartType: (partType) => {
    //         filterByVehicleAndPartType([
    //           {
    //             partType,
    //           },
    //         ]);
    //       },
    //       test: () => {},
    //     };
    //   }
    // }, [typeof window]);

    return false
}
