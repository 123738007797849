import React, {useContext} from 'react'
import ClassNames from 'classnames'
import SearchBar from 'Molecules/SearchBar'
import {NavbarItem} from '../NavbarItem/NavbarItem'
import {NotificationContext} from 'Contexts/NotificationContext/NotificationContext'
import searchStyles from 'Molecules/SearchBar/SearchBar.module.scss' //this is bad
import headerStyles from '../Header.module.scss' //this is bad
import styles from './SearchBarHeader.module.scss'

const SearchBarHeader = ({ className, placeholder, dataTesting, isMobile, isSaytModalOpen }) => {
    const { DismissAllNotifications } = useContext(NotificationContext)

    try {
        return (
            <NavbarItem
                className={ClassNames(
                    headerStyles.headerItem,
                    headerStyles.searchHeaderItem,
                    searchStyles.searchBarWrapper
                )}
                onClick={() =>
                    {
                        DismissAllNotifications()

                        const event = new CustomEvent('sayt_modal_opened', {
                            detail: {
                                isEditing: false
                            }
                        });
                        window.dispatchEvent(event)
                    }
                }
                component={'div'}
                data-testid={dataTesting}
            >
                <SearchBar
                    iconSettings={{ size: 1.25, position: "end", filled: true }}
                    className={ClassNames(className, styles.headerSearch)}
                    onChange={(e) => false}
                    handleSearch={(e) => false}
                    placeholder={placeholder}
                    value={''}
                    isMobile={isMobile}
                    setModal={isSaytModalOpen}
                    readOnly
                />
            </NavbarItem>
        )
    } catch (e) {
        console.error(e)
        return false
    }
}

export default SearchBarHeader
