
import Typography from 'Atoms/Typography'
import Area from 'Molecules/Area'
import LoyaltyUserStatus, { ShouldShow } from './LoyaltyUserStatus';



const LoyaltyBadge = ({ loyaltyLink }) => {

    const state = loyaltyLink;
    if (!state || !ShouldShow(state.userStatus)) {

        return null;
    }

    if (state.userStatus == LoyaltyUserStatus.eligible || state.userStatus == LoyaltyUserStatus.not_loggedin) {
        return <Area
            brand={`primary`}
            noBorder
            noMargin
            noPadding
        >
            <Typography
                font='bold'
                size={0.75}
            >
                Sign up now!
            </Typography>
        </Area>
    }

    if (state.currentPoints.totalDollarValue <= 0) {
        return <Area
            brand={`primary`}
            noBorder
            noMargin
            noPadding
        >
            <Typography
                font='bold'
                size={0.75}
            >
                Start earning!
            </Typography>
        </Area>
    }
    if (state.expiringPoints.totalDollarValue.decimal > 1) {
        return <Area
            intent='negative'
            noBorder
            noMargin
            noPadding
        >
            <Typography
                font='bold'
                size={0.75}
            >
                Expiring soon!
            </Typography>
        </Area>
    }



    return <Area
        brand={`primary`}
        noBorder
        noMargin
        noPadding
    >
        <Typography
            font='bold'
            size={0.75}
        >
            {state.currentPoints.totalDollarValue.decimal > 1 ? state.currentPoints.totalDollarValue.string : `${state.currentPoints.totalPoints} points`}
        </Typography>
    </Area>


}

export default LoyaltyBadge