import GraphQLRequest from "Utilities/GraphQLClient/GraphQLClient";

const RecommendationsFragment = `
  sectionType
  treatment
  recommendationGroups {
    title
    subtitle
    isExpandable
    displayType
    recommendations {
      __typename
      ... on LandingPage {
        landingPageDetails{
            displayValue
            thumbnailImageUrl
            field
            facetOptionLink{
                url
                noFollow
            }
          }
      }
      ... on Category {
        categoryName
        url
      }
      ... on Product {
        skuVariant
        skuBase
        publicSkuBaseDetails(webUserGuid: $webUserGuid){
          displayTitle
          skuBaseNumber
          productPageUrl
          thumbnailImageLink
          rating
          pricing {
            salePrice
            salePriceString
          }
          skuVariantSummaries {
            skuNumber
          }
          segmentCategorization
          brand
        }
        publicSkuVariantDetails(webUserGuid: $webUserGuid){
          displayTitle
          skuVariantNumber
          kit {
            skuBaseComponents {
              kitSkuNumber
            }
            skuVariantComponents {
              kitSkuNumber
            }
          }
        }
      }
      ... on Article {
        articleId
        articleDetails{
            id
            misoId
            title
            authors
            description
            createdAt
            lastDeployedAt
            deployDate
            articleThumbnailUrl
            url
            versionId
            
        } 
    }
    }
  }
`;

const fetchFeaturedArticle = async (contentServiceUrl) => {
  try {
    let variables = {};

    const query = `query GetFeaturedArticle {
              featuredArticle {
                  id,
                  title,
                  authors,
                  description,
                  url,
                  articleThumbnailUrl
                  }
              }`;
    const response = await GraphQLRequest(contentServiceUrl, query, variables);

    return response?.featuredArticle;
  } catch (err) {
    return null;
  }
};

const fetchRecommendedArticles = async (
  recommendationsUrl,
  recommendedArticleRequest
) => {
  try {
    const variables = {
      ...recommendedArticleRequest,
      sections: ["Toolbox_Blade"],
    };
    const query =
      `
    query GetRecommendationSections($sections: [String]
      $skuBaseNumbers: [String]
      $skuVariantNumbers: [String]
      $articleIds: [String]
      $ymmData: YmmDataInput
      $keywords: String
      $userVin: String
      $webUserGuid: String){
      recommendationSections(sections: $sections
        skuBaseNumbers:$skuBaseNumbers
        skuVariantNumbers:$skuVariantNumbers
        articleIds:$articleIds
        ymmData:$ymmData
        keywords:$keywords
        userVin:$userVin
        webUserGuid:$webUserGuid){
        ` +
      RecommendationsFragment +
      `
      }
    }
    `;
    const response = await GraphQLRequest(recommendationsUrl, query, variables);
    const recs =
      response?.recommendationSections?.[0]?.recommendationGroups?.[0]
        ?.recommendations;
    if (!recs) {
      console.error(
        "no featured article recs were found in contentRequests.js... they were null!"
      );
    } else {
      return recs;
    }
  } catch (err) {
    return [];
  }
};

const fetchTech = () => {
  return [
    {
      id: 1,
      title: "Body",
      href: "/the-toolbox/body-tech~34-1-1",
    },
    {
      id: 2,
      title: "Brakes",
      href: "/the-toolbox/brakes-tech~34-1-2",
    },
    {
      id: 3,
      title: "Buyer's Guides",
      href: "/the-toolbox/buyer-s-guides~512-160",
    },
    {
      id: 4,
      title: "Electrical & Ignition",
      href: "/the-toolbox/electrical-and-ignition-tech~34-1-3",
    },
    {
      id: 5,
      title: "Engine & Exhaust",
      href: "/the-toolbox/engine-and-exhaust-tech~34-1-4",
    },
    {
      id: 6,
      title: "Safety",
      href: "/the-toolbox/safety-tech~34-1-6",
    },
    {
      id: 7,
      title: `Suspension & Steering`,
      href: "/the-toolbox/suspension-and-steering-tech~34-1-7",
    },
    {
      id: 8,
      title: "Tools",
      href: "/the-toolbox/tools-tech~34-1-8",
    },
    {
      id: 9,
      title: "Transmission & Driveline",
      href: "/the-toolbox/transmission-and-driveline-tech~34-1-9",
    },
  ];
};
const fetchBuilds = () => {
  return [
    {
      id: 1,
      title: "1952 Chevy Truck",
      href: "/the-toolbox/1952-chevy-truck~512-25",
    },
    {
      id: 2,
      title: "1967 Chevelle",
      href: "/the-toolbox/1967-chevelle~512-151",
    },
    {
      id: 3,
      title: "1968 C10 Truck",
      href: "/the-toolbox/1968-c10-truck~512-165",
    },
    {
      id: 4,
      title: `'32 Roadster`,
      href: "/the-toolbox/32-roadster~512-110",
    },
    {
      id: 5,
      title: "410 Sprint Car Build",
      href: "/the-toolbox/410-sprint-car-build~512-109",
    },
    {
      id: 6,
      title: "Bucket Beauties",
      href: "/the-toolbox/bucket-beauties~512-19",
    },
    {
      id: 7,
      title: "Chevy II Nova Gasser",
      href: "/the-toolbox/chevy-ii-nova-gasser~512-111",
    },
    {
      id: 8,
      title: "Model A",
      href: "/the-toolbox/model-a~512-152",
    },
    {
      id: 9,
      title: "Project Chevelle",
      href: "/the-toolbox/project-chevelle~512-81",
    },
  ];
};

const fetchNews = () => {
  return [
    {
      id: 1,
      title: "Classic Truck",
      href: "/the-toolbox/classic-truck-news~3-857-3",
    },
    {
      id: 2,
      title: "Hot Rod",
      href: "/the-toolbox/hot-rod~1-225",
    },
    {
      id: 3,
      title: "Muscle Car",
      href: "/the-toolbox/muscle-car-news~3-949-3",
    },
    {
      id: 4,
      title: "Open Wheel",
      href: "/the-toolbox/open-wheel-news~3-741-3",
    },
    {
      id: 5,
      title: "Oval Track",
      href: "/the-toolbox/oval-track-news~3-2-3",
    },
    {
      id: 6,
      title: `T-Bucket`,
      href: "/the-toolbox/t-bucket-news~3-1877-3",
    },
  ];
};

const fetchVideos = () => {
  return [
    {
      id: 1,
      title: "How to Select and Order Custom Length Driveshaft Kits",
      href: "/the-toolbox",
    },
    { id: 2, title: "Racer Appreciation Week 2021", href: "/the-toolbox" },
    {
      id: 3,
      title: "Engine Install and Firing - 410 Sprint Car Build",
      href: "/the-toolbox",
    },
    {
      id: 4,
      title: "Engine Build and Dyno - 410 Sprint Car Build",
      href: "/the-toolbox",
    },
    {
      id: 5,
      title: "Employee Rides: 1956 Chevy Nomad - Kurt Bonner",
      href: "/the-toolbox",
    },
    {
      id: 6,
      title: "1954 Chevy Sedan - Employee Rides: Kevin Webel",
      href: "/the-toolbox",
    },
  ];
};

const fetchPodcasts = () => {
  return [
    {
      id: 1,
      episode: 10,
      title: "Show Rods, Car Kings & the Orbitron: Dave Shuten",
      href: "/the-toolbox",
    },
    {
      id: 2,
      episode: 9,
      title: "Surviving a 140mph Crash in a 1969 Camro: Dave Wallace",
      href: "/the-toolbox",
    },
    {
      id: 3,
      episode: 8,
      title: "The Past & Future of Hot Rodding: Brian Brennan",
      href: "/the-toolbox",
    },
    {
      id: 4,
      episode: 7,
      title: "Unheard Stories from the Golden Age of Racing: Bob Mays",
      href: "/the-toolbox",
    },
    {
      id: 5,
      episode: 6,
      title: `Indy, PIkes PEak, & Team Speedway: Robby Unser's Life of Racing`,
      href: "/the-toolbox",
    },
    {
      id: 6,
      episode: 5,
      title: "The Aggressively Elegant Cars of Andy Leach",
      href: "/the-toolbox",
    },
  ];
};

export {
  fetchFeaturedArticle,
  fetchRecommendedArticles,
  fetchTech,
  fetchBuilds,
  fetchNews,
  fetchVideos,
  fetchPodcasts,
};
