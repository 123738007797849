import React, {useContext, useState} from 'react';
import Image from 'next/image';
import Typography from 'Atoms/Typography';
import TextLink from 'Atoms/TextLink';
import Card from 'Atoms/Card';
import Svg from 'Atoms/Svg';
import Skeleton from 'Atoms/Skeleton';
import ColorFinder from 'Utilities/ColorFinder';
import styled from 'styled-components';
import styles from './listCard.module.scss';
import CoveringSpinner from '../../../Atoms/CoveringSpinner/CoveringSpinner';
import {ListContext} from '../../../Contexts/ListContext/ListContext';
import {useUserStore} from '../../../Stores/UserStore/UserStore';
import {NotificationContext} from '../../../Contexts/NotificationContext/NotificationContext';
import Event from "Utilities/Event";

const ListCard = ({ list, listName, productCount, total, item, image, addToList, listContainsItem, isBuildListCard, listId, currentListId }) => {
    if (isBuildListCard && listId === currentListId) {
        return null;
    }

    const { color: secondaryPalettePurpleColor } = ColorFinder(null, null, 'secondarypalette_purple');
    const { color: subtleColor } = ColorFinder(null, 'subtle', null);
    const [isLoading, setIsLoading] = useState(false);
    const [isMovedItemLoading, setIsMovedItemLoading] = useState(false);
    const { PushNotification } = useContext(NotificationContext);
    const [moveToListId, setMoveTolistId] = useState(null);
    const {
        listContext,
        getUserLists,
        hideListDrawer,
        moveItemBetweenLists,
        moveArticleBetweenLists,
        removeItemFromList,
        removeArticleFromList,
        addArticlesToList,
    } = useContext(ListContext);
    const emailAddress = useUserStore((x) => x.context.emailAddress);

    const isProducts = !!item?.skuBaseNumber;

    const displayNotification = (isSuccess, successMessage, errorMessage) => {
        if (isSuccess)
            PushNotification(successMessage, null, 5000, null, {
                icon: 'check',
                intent: 'positive',
            });
        else
            PushNotification(errorMessage, null, 5000, null, {
                icon: 'unavailable',
                intent: 'negative',
            });
    };

    const moveItem = async (toListId, fromListId, item) => {

        if (!item) {
            console.error('Item is null or undefined, skipping moveItem execution.');
            return;
        }

        setIsMovedItemLoading(true);
        setMoveTolistId(toListId);

        let isSuccess;
        try {
            if (isProducts) {
                isSuccess = await moveItemBetweenLists(toListId, fromListId, item.listProductId);
            } else {
                isSuccess = await moveArticleBetweenLists(toListId, fromListId, item.id);
            }

            displayNotification(isSuccess, 'Item Moved', 'Item not moved, please try again');
            Event({
                category: 'BuildList',
                action: 'Moved item to another list',
                label: isProducts ? 'Product' : 'Article',
            })();
        } catch (error) {
            console.error('Error moving item:', error);
            displayNotification(false, 'Move Item Failed', 'An error occurred while moving the item. Please try again.');
        } finally {
            setIsMovedItemLoading(false);
            setMoveTolistId(null);
        }
    };

    return (
        <Card fill hover onClick={async (e) => {

            if (isBuildListCard) {
                moveItem(list.id, currentListId, item)

                await getUserLists(true);
                hideListDrawer()

            } else {
                addToList(setIsLoading)
            }
        }}>
            <div className={styles.card_container}>
                <CoveringSpinner isLoading={isLoading} fillWidth>
        <span className={styles.image_container}>
          {listContainsItem ?
              <Image src={`https:${image || '//content.speedwaymotors.com/OtherImages/missingimage2.jpg'}`}
                     width={65}
                     height={65} /> :
              <TextLink inline>
                  {productCount === 0 ? (
                      <Svg icon={'add'} tone={'superfluous'} className={styles.list_image_svg} />
                  ) : (
                      <Image src={`https:${image || '//content.speedwaymotors.com/OtherImages/missingimage2.jpg'}`}
                             width={65}
                             height={65} />
                  )}
              </TextLink>
          }
        </span>
                    <div className={styles.list_card_right}>
                        {listContainsItem ?
                            <Typography size={1} font={'bold'} data-testid={`list_drawer_${(listName?.toLowerCase())?.split(' ')?.join('_')}`}>
                                {`${listName}`}
                            </Typography> :
                            <TextLink size={1} font={'bold'} data-testid={`list_drawer_${(listName?.toLowerCase())?.split(' ')?.join('_')}`}>
                                {`${listName}`}
                            </TextLink>
                        }
                        <Typography size={0.875} tone={'subtle'}>
                            {`${productCount} ${productCount === 1 ? 'item' : 'items'}`}
                        </Typography>
                        <Typography size={0.875} tone={'contrast'}>
                            {total ? `${total}` : '$0.00'}
                        </Typography>
                    </div>

                    <div className={styles.list_card_control}>
                        <StyledListCardSvg className={styles.list_card_svg} listContainsItem={listContainsItem} secondaryPalettePurpleColor={secondaryPalettePurpleColor} subtleColor={subtleColor}>
                            {listContainsItem ?
                                <Svg icon={'check'} size={1} brand={'secondarypalette_purple'} /> :
                                <Svg icon={'plus'} size={1} tone={'subtle'} />}
                        </StyledListCardSvg>
                    </div>
                </CoveringSpinner>
            </div>
        </Card>
    )
}

const StyledListCardSvg = styled.div`
    border-color: ${props => props.listContainsItem ? `${props.secondaryPalettePurpleColor}` : `${props.subtleColor}`};
`

export default ListCard

export const ListCardSkeleton = () => {
    return <Card fill>
        <div className={styles.list_card_skeleton}>
            <Skeleton height={65} width={65} />
            <div className={styles.deets}>
                <Skeleton height={14} width={100}/>
                <Skeleton height={14} width={120}/>
            </div>
        </div>
    </Card>
}