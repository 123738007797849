import useSWR from "swr"; 
import { getUserAudiences } from "../Contexts/UserContext/UserRequests";

export const useUserAudiences = (customerUrl, webUserGuid = null) => {
    const fetchData = async () => {
      const { data, cacheBusted } = await getUserAudiences(customerUrl, webUserGuid);
      return { data, cacheBusted };
    };
  
    const { data } = useSWR(
      webUserGuid ? ["userAudiences", customerUrl, webUserGuid] : null,
      fetchData
    );
  
    return {
      userAudiences: data?.data ?? { audiences: [], googleAudiences: [] },
      audiencesCacheBusted: data?.cacheBusted ?? false,
    };
  };