import React from 'react'
import styled from 'styled-components'
import styles from './TabGroup.module.scss'

const TabGroup = ({ children, ...props }) => {
    try {
        return (
            <StyledTabGroup className={styles.tab}>
                <div className={styles.tabsWrapper}>
                    {children}
                </div>           
            </StyledTabGroup>
        )
    } catch (e) {
        console.error(e)
        return false
    }
}
const StyledTabGroup = styled.div`
    height: 100%;
    background-color: white;
    border-radius: 0.5rem 0.5rem 0 0;
    border: 1px solid #d3d3d3;
    cursor: pointer;
`
export default TabGroup
