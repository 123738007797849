import React from 'react'
import ClassNames from 'classnames'

import ListItem from 'Molecules/List/ListItem'
import ListItemIcon from 'Molecules/List/ListItemIcon'
import ListItemText from 'Molecules/List/ListItemText'
import ListItemLink from 'Molecules/List/ListItemLink'

import styles from './NavbarItem.module.scss'

const NavbarItem = React.forwardRef(
    (
        {
            children,
            component,
            icon,
            primaryText,
            secondaryText,
            primaryTextOptions,
            secondaryTextOptions,
            className,
            onClick,
            hover,
            badge,
            badgeProps,
            ariaLabel,
            pwaOnly,
            ...props
        },
        ref
    ) => {
        const clickEvent = onClick ? () => onClick() : null
        component = typeof component !== 'undefined' ? component : 'button'

        return (
            <ListItem
                component={component}
                className={ClassNames(className, styles.navbarItem, pwaOnly ? styles.pwaOnly : false)}
                onClick={clickEvent}
                hover={hover}
                ref={ref}
                ariaLabel={ariaLabel}
                {...props}
            >
                {children ? (
                    children
                ) : (
                    <>
                        <ListItemIcon
                            icon={icon}
                            tone={'subtle'}
                            size={1.75}
                            badge={badge}
                            badgeProps={badgeProps}
                        />
                        <NavbarItemText
                            primary={primaryText}
                            secondary={secondaryText}
                            primaryTextOptions={primaryTextOptions}
                            secondaryTextOptions={secondaryTextOptions}
                        />
                    </>
                )}
            </ListItem>
        )
    }
)

const NavbarItemText = ({
    primary,
    secondary,
    primaryTextOptions,
    secondaryTextOptions
}) => {
    if (!primary || !secondary) {
        return false
    }

    primaryTextOptions = primaryTextOptions ?? {}
    secondaryTextOptions = secondaryTextOptions ?? {}

    return (
        <ListItemText
            primary={primary}
            primaryTypographyProps={{
                tone: 'subtle',
                size: 0.875,
                font: 'heading',
                intent: null,
                ...primaryTextOptions
            }}
            secondary={secondary}
            secondaryTypographyProps={{
                tone: 'normal',
                size: 1,
                font: 'bold',
                intent: null,
                ...secondaryTextOptions
            }}
            secon
            className={styles.navbarItemText}
        />
    )
}

const NavbarItemLink = ({
    children,
    href,
    icon,
    primaryText,
    secondaryText,
    className,
    title,
    hover,
    ref,
    onClick,
    onHover,
    badgeProps,
    badge,
    ...props
}) => {
    return (
        <ListItemLink
            className={ClassNames(className, styles.navbarItemLink)}
            href={href}
            hover={hover}
            title={title}
            ref={ref}
            onClick={onClick}
            onHover={onHover}
            {...props}
        >
            {children ? (
                children
            ) : (
                <>
                    <ListItemIcon icon={icon} tone={'subtle'} size={1.75} badgeProps={badgeProps} badge={badge} />
                    <NavbarItemText
                        primary={primaryText}
                        secondary={secondaryText}
                    />
                </>
            )}
        </ListItemLink>
    )
}

export { NavbarItem, NavbarItemLink }
