import React, { Fragment, useMemo } from 'react'

import Typography from 'Atoms/Typography'

import IndexOptionButtons from './buttons/indexoptionButtons'
import IndexOptionSelect from './select/indexoptionSelect'


import styles from './indexoptions.module.scss'

const IndexOptions = ({ indexOptions, selectIndexOption, productPageId, isIndexOptionErrorState, buttonCuttoffValue }) => {
  if (!indexOptions || indexOptions.length === 0)
    return false

  //get the currently selected index option values
  //shape: [[123], [456], [789]]
  const selectedIndexOptionValuesLists = useMemo(() => indexOptions
    .filter(option => option.indexOptionValues && option.indexOptionValues.some(value => value.isSelected))
    .map(option =>
      option.indexOptionValues
        .filter(value => value.isSelected)
        .map(value => value.id)
    ))

  // condense list of lists down to just a list
  //shape: [123, 456, 789]
  const selectedIndexOptionValues = useMemo(() => selectedIndexOptionValuesLists && selectedIndexOptionValuesLists.length > 0 ?
    [].concat.apply([], selectedIndexOptionValuesLists)
    : undefined)
  return (
    <Fragment>
      {indexOptions
        .sort((a, b) => {
          return (parseInt(a.ordinality) || a.displayName) - (parseInt(b.ordinality) || b.displayName);
        })
        .map((option, i) => {
          return (
            <Fragment key={'ix-option-select-' + option.id}>
              <div className={styles.pdp_offer_option}>
                <Typography font={'bold'} tone={'contrast'} size={1} inline>
                  {`${option.displayName}:`}
                </Typography>
                {option.indexOptionValues.some(value => value.isSelected) ?
                  <Typography tone={'contrast'} size={1} inline data-testid={`selected_option_${option.id}`}>
                    {`\t${option.indexOptionValues.filter(value => value.isSelected)[0].displayName}`}
                  </Typography> :
                  isIndexOptionErrorState ?
                    <Typography intent={'negative'} size={1} inline data-testid={`needed_selection_${option.id}`}>
                      {`\tSelect a ${option.displayName.toLowerCase()}`}
                    </Typography>
                    :
                    <Typography tone={'subtle'} size={1} inline data-testid={`option_${option.id}`}>
                      {`\tSelect a ${option.displayName.toLowerCase()}`}
                    </Typography>
                }
              </div>
              {/* SWATCHES WILL GO HERE */}
              { option.indexOptionValues.length <= buttonCuttoffValue ?
                <IndexOptionButtons
                  indexOptionValues={option.indexOptionValues}
                  selectIndexOption={selectIndexOption}
                  productPageId={productPageId}
                  selectedIndexOptions={selectedIndexOptionValues}
                  optionIndexForTesting={i}
                  isIndexOptionErrorState={isIndexOptionErrorState} />
                : <IndexOptionSelect
                  indexOptionValues={option.indexOptionValues}
                  selectIndexOption={selectIndexOption}
                  label={option.displayName}
                  productPageId={productPageId}
                  selectedIndexOptions={selectedIndexOptionValues}
                  isIndexOptionErrorState={isIndexOptionErrorState} />}
            </Fragment>
          )
        })}
    </Fragment>
  )
}

export default IndexOptions