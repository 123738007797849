import React, { useContext, Fragment, useEffect } from "react";
import ClassNames from "classnames";

import Panel from "Atoms/Panel";
import ListItemLink from "Molecules/List/ListItemLink";
import ListItem from "Molecules/List/ListItem";
import List from "Molecules/List/List";
import ListItemText from "Molecules/List/ListItemText";
import ListItemIcon from "Molecules/List/ListItemIcon";
import ReturnItem from "../Common/ReturnItem";
import ArticleCard from 'Containers/ArticleCard'
import { NavigationContext } from "Contexts/Navigation/NavigationContext";
import styles from "../../HeaderDrawer.module.scss";
import useContent from "Hooks/useContent/useContent";

const RecommendedArticleNavigation = ({ menu, closeFunction }) => {
  const { dispatch: navigationDispatch } = useContext(NavigationContext);
  const { recommendedArticles } = useContent(true);

  const { navigation, primary, secondary } = menu;

  if (!recommendedArticles) {
    return null;
  }

  return (
    <>
      <ReturnItem
        primary={primary}
        secondary={secondary}
        closeFunction={closeFunction}
      />{" "}
      <Panel className={styles.subNavigation}>
        <List component={"div"} className={styles.subNavigationList}>
          {navigation.map((item) => {
            const RootComponent = item.href ? ListItemLink : ListItem;

            return (
              <RootComponent
                key={item.id}
                className={styles.listItem}
                onClick={
                  item.href
                    ? null
                    : () => {
                        navigationDispatch(item.onClick);
                      }
                }
                event={item.event}
                href={item.href}
                hover
                data-testid={item.secondary}
              >
                <ListItemText
                  primary={!item.simplified ? item.primary : item.secondary}
                  primaryTypographyProps={{
                    size: 1,
                    tone: "contrast",
                  }}
                  className={styles.listItemText}
                />
                <ListItemIcon
                  icon={item.icon ? item.icon : "chevron"}
                  className={styles.listItemIcon}
                  size={1}
                />
              </RootComponent>
            );
          })}
        </List>
      </Panel>
      {recommendedArticles.map((article) => {
        return article.articleDetails ? (
          <Panel
            key={article.articleId}
            className={ClassNames(
              styles.subNavigation,
              styles.recommendedContent
            )}
          >
            <ArticleCard
              article={article.articleDetails ? article.articleDetails : null}
              border={false}
            />
          </Panel>
        ) : null;
      })}
    </>
  );
};

export default RecommendedArticleNavigation;
