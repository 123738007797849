import React, { useContext, useEffect, useState } from "react";

import Popover from "Components/Popover";
import { NavigationContext } from "Contexts/Navigation/NavigationContext";
import useFeatureFlag from "Hooks/useFeatureFlag/useFeatureFlag";
import { useVehicleStore } from "Stores/VehicleStore";
import ClassNames from "classnames";

import { GetUserGuid } from "../../../Utilities/CookieUtilties/UserCookieUtilities";
import headerStyles from "../Header.module.scss";
import { NavbarItem } from "../NavbarItem/NavbarItem";
import GarageDropdown from "./GarageDropdown";

const GarageSection = ({ services }) => {
  try {
    const customerProjects = useVehicleStore((x) => x.context.customerProjects);
    const getSelectedCustomerProjectTitle = useVehicleStore(
      (x) => x.getSelectedCustomerProjectTitle
    );
    const executeGarageReload = useVehicleStore((x) => x.executeGarageReload);
    const selectedCustomerProject = useVehicleStore(
      (x) => x.context.selectedCustomerProject
    );
    const {
      state: navigationState,
      dispatch: navigationDispatch,
      setYmmModal: setModal,
    } = useContext(NavigationContext);
    const { garage } = navigationState;
    const { isGarageDropdownOpen } = garage;
    const [customRef, setCustomParentTriggerRef] = useState(null);
    const impressionDontDelete = useFeatureFlag("Engine_Fitment_Master_Flag")
    const frontendEngineFitmentUX = useFeatureFlag(
      "FrontEnd_Engine_Fitment_UX"
    );
    const customerProjectDrawer = useFeatureFlag(
      "FrontEnd_CustomerProject_Drawer_Enabled"
    );
    useEffect(() => {
      if (typeof window !== "undefined") {
        if (window.SMI) {
          window.SMI.showDs9YMMDropdown = function (
            market = "street",
            reloadCurrentLocation = false
          ) {
            const totalCustomerProjects = customerProjects
              ? customerProjects.length
              : 0;

            if (totalCustomerProjects === 0) {
              setModal(true);
              executeGarageReload(reloadCurrentLocation);
              return;
            }

            if (totalCustomerProjects > 0 && customRef) {
              customRef.click();
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
              });
              navigationDispatch({
                type: "setGarageState",
                payload: {
                  garageState: {
                    isGarageDropdownOpen: true,
                    market: market,
                  },
                },
              });
              executeGarageReload(reloadCurrentLocation);
            }
          };
        }
      }
    }, [typeof window, customRef, customerProjects]);

    const handleToggleGarageDropdown = (isOpen) => {
      navigationDispatch({
        type: "setGarageState",
        payload: { garageState: { isGarageDropdownOpen: isOpen } },
      });
    };
    const totalCustomerProjects = customerProjects
      ? customerProjects.length
      : 0;
    const displayText =
      getSelectedCustomerProjectTitle() ??
      (totalCustomerProjects > 0 ? "Select A Vehicle" : "Add a Vehicle");

    if (frontendEngineFitmentUX && customerProjectDrawer) {
      return (
        <NavbarItem
          key={selectedCustomerProject}
          icon={"garage"}
          badge
          badgeProps={{
            intent: "action",
            number: totalCustomerProjects,
            size: "small",
            removeNumber: true,
          }}
          primaryText={"My Garage"}
          secondaryTextOptions={{
            className: ClassNames(
              headerStyles.headerGarageTextWrapper,
              headerStyles.headerOverflowText
            ),
          }}
          secondaryText={displayText}
          hover
          data-testid={"Garage"}
          onClick={() =>
            navigationDispatch({
              type: "setCustomerProjectDrawerState",
              payload: { garageState: { isCustomerProjectDrawerOpen: true } },
            })
          }
        />
      );
    }

    return (
      <Popover
        content={
          <GarageDropdown userGuid={GetUserGuid()} services={services} />
        }
        setCustomParentTriggerRef={setCustomParentTriggerRef}
        isOpen={isGarageDropdownOpen}
        onToggleMenu={handleToggleGarageDropdown}
        className={ClassNames(
          headerStyles.headerItem,
          headerStyles.mobileHide,
          headerStyles.mobileLimitedHide,
          headerStyles.headerGarage
        )}
      >
        <NavbarItem
          key={selectedCustomerProject}
          icon={"garage"}
          badge
          badgeProps={{
            intent: "action",
            number: totalCustomerProjects,
            size: "small",
            removeNumber: true,
          }}
          primaryText={"My Garage"}
          secondaryTextOptions={{
            className: ClassNames(
              headerStyles.headerGarageTextWrapper,
              headerStyles.headerOverflowText
            ),
          }}
          secondaryText={displayText}
          hover
          data-testid={"Garage"}
        />
      </Popover>
    );
  } catch (e) {
    console.error(e);
    return false;
  }
};

export default GarageSection;
