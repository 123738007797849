import React, {
  useEffect,
  useImperativeHandle,
  useState,
  forwardRef,
  useCallback,
} from "react";
import { createPortal } from "react-dom";
import usePortal from "Hooks/usePortal";
import Panel from "Atoms/Panel";
import Svg from "Atoms/Svg";
import Mask from "Atoms/Mask";
import FocusTrap from "UtilityComponents/FocusTrap";
import classNames from "classnames";
import styles from "./ModalWithPortal.module.scss";
import PaginationControl from "../../Atoms/PaginationControl/PaginationControl";

export function Modal(
  {
    id,
    children,
    defaultOpened = false,
    focusTrap = false,
    outsideClick = false,
    closeButton = true,
    customOnClose = () => { },
    pagination = false,
    onPaginationLeft,
    onPaginationRight,
    containerClassName,
  },
  ref
) {
  if (typeof window === "undefined") {
    return null;
  }

  const [isOpen, setIsOpen] = useState(defaultOpened);

  const close = useCallback(() => {
    setIsOpen(false);
    customOnClose();
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      open: () => setIsOpen(true),
      close,
      isOpen,
    }),
    [close]
  );

  const handleEscape = useCallback(
    (event) => {
      if (event.keyCode === 27) close();
    },
    [close]
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("keydown", handleEscape, false);
    }

    AdjustCrispChatLayout(isOpen);

    return () => {
      document.removeEventListener("keydown", handleEscape, false);
      AdjustCrispChatLayout(false);
    };
  }, [handleEscape, isOpen]);

  const target = usePortal(id, 300);

  let modalComponent = (
    <div className={classNames(containerClassName, 'fadeIn')}>
      <div
        className={styles.modal}
        onClick={(e) => {
          e.stopPropagation();
          if (e.target === e.currentTarget && outsideClick) {
            close();
          }
        }}
        data-testid={"modalContainer"}
      >
        <div className={styles.modalContainer}>
          {closeButton ? (
            <Panel
              className={classNames(
                styles.modalClose,
                pagination ? styles.modalClosePaginated : null
              )}
              onClick={() => close()}
            >
              <Svg
                size={0.75}
                icon={"close"}
                data-testid={"modalCloseButton"}
              />
            </Panel>
          ) : null}
          {pagination ? (
            <div className={styles.modalBodyContainer}>
              <div className={styles.paginationLeft}>
                <PaginationControl
                  direction={"left"}
                  intent={"action"}
                  onClick={() => {
                    if (!pagination) return;
                    onPaginationLeft();
                  }}
                />
              </div>
              <Panel
                className={classNames(
                  styles.modalBody,
                  styles.modalBodyPaginated
                )}
                layer={0}
              >
                {children}
              </Panel>
              <div className={styles.paginationRight}>
                <PaginationControl
                  direction={"right"}
                  intent={"action"}
                  onClick={() => {
                    if (!pagination) return;
                    onPaginationRight();
                  }}
                />
              </div>
            </div>
          ) : (
            children &&
            <Panel layer={0} className={styles.modalBody}>{children}</Panel>
          )}
        </div>
      </div>
      <Mask onClick={() => close()} visible={isOpen} />
    </div>
  );

  if (isOpen) {
    return target
      ? createPortal(
        focusTrap === false ? (
          modalComponent
        ) : (
          <FocusTrap isActive={isOpen}>{modalComponent}</FocusTrap>
        ),
        target
      )
      : null;
  }

  return target ? createPortal(null, target) : null;
}

function AdjustCrispChatLayout(open) {
  let crispELems = document.getElementsByClassName("crisp-client");
  if (open) {
    document.body.style.overflow = "hidden";
    document.body.style.marginRight = "0.875rem"
    if (crispELems.length > 0) {
      crispELems[0].style.display = "none";
    }
  } else {
    document.body.style.overflow = "auto";
    document.body.style.marginRight = ""
    if (crispELems.length > 0) {
      crispELems[0].style.display = "";
    }
  }
}

export default forwardRef(Modal);
