import {useEffect, useState} from "react";
import useGlobalState from "../useGlobalState/useGlobalState";

const useGlobalStateWithFetch = (key, fetchMethod, triggers) => {

    const { globalState, setGlobalState } = useGlobalState(key, {});
    const [isPending, setIsPending] = useState(false);

    const update = async () => {
        if(window.globalStateLocks[key]){
            return;
        }

        setIsPending(true)
        window.globalStateLocks[key] = true

        try{
            const newState = await fetchMethod();
            setGlobalState(newState);
        }catch(e){
            console.error(`Error fetching global state for key ${key}`, e)
        }
        
        setIsPending(false)
        window.globalStateLocks[key] = false
    }

    useEffect(() => {
        // initialize the locks that we use here
        window.globalStateLocks = window.globalStateLocks ?? {};

        // initialize the state and change the state when the triggers change
        update();
    }, triggers ? triggers : [])

    return { globalState, update, isPending }
}

export default useGlobalStateWithFetch;