import React, { Fragment, useState } from "react";

import Typography from "Atoms/Typography";
import LayerColorFinder from "Utilities/LayerColorFinder";
import ClassNames from "classnames";
import styled from "styled-components";

import styles from "./TextArea.module.scss";

const TextArea = ({
  onChange,
  className,
  defaultValue = "",
  placeholder = "",
  id,
  dataTestId,
  label,
  characterLimit,
  rows,
  autoFocus,
  brand,
  intent,
  ...props
}) => {
  const [charactersRemaining, setCharactersRemaining] = useState(
    defaultValue.length > 0
      ? characterLimit - defaultValue.length
      : characterLimit
  );
  const borderColor =
    intent || brand
      ? LayerColorFinder(undefined, undefined, brand, intent)
      : LayerColorFinder(2);

  const classes = ClassNames(styles.textarea, className);
  return (
    <Fragment>
      <Typography as={"label"} htmlFor={id} size={0.875}>
        {label}
      </Typography>
      <StyledTextArea
        borderColor={borderColor}
        defaultValue={defaultValue.substring(0, characterLimit)}
        placeholder={placeholder}
        name={id}
        rows={rows}
        className={classes}
        data-testid={dataTestId}
        autoFocus={autoFocus}
        maxLength={characterLimit != 0 ? characterLimit : undefined}
        onChange={(e) => {
          onChange(e);
          setCharactersRemaining(characterLimit - e.target.value.length);
        }}
        {...props}
      />
      {characterLimit != null && characterLimit > 0 ? (
        <Typography
          size={0.75}
          intent="subdued"
          className={styles.charsRemaining}
        >
          {charactersRemaining > 0 ? charactersRemaining : "No"} characters
          remaining
        </Typography>
      ) : (
        false
      )}
    </Fragment>
  );
};

const StyledTextArea = styled.textarea`
  border-color: ${(props) => props.borderColor};
`;

export default TextArea;
