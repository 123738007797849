import SetCookie from "Utilities/CookieUtilties/SetCookie"
import { v4 as uuidv4 } from "uuid";
import CookieReader from "Utilities/CookieUtilties/CookieReader";

export default function setLocalHostCookies() {
    
    const existingWebUserGuid = CookieReader("WebUserGuid")
    const existingVIN = CookieReader("VIN")


    if (!existingWebUserGuid ) {
        const webUserGuid = uuidv4();
        SetCookie("WebUserGuid", webUserGuid)
    }    

    if (!existingVIN){
        const VIN = uuidv4();
        SetCookie("VIN", VIN)
    }

}

