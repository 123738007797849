// Make sure that any new size values you add are sorted
const Sizes = [
    0.5,
    0.75,
    0.875, 
    1, 
    1.25, 
    1.5, 
    1.75,
    2,
    2.5,
    3,
    4,
    6,
    7,
    8,
    9,
    10,
    11,
    12
]

export default Sizes



