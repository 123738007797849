import React from 'react'
import ClassNames from 'classnames'
import Svg from 'Atoms/Svg'
import Typography from 'Atoms/Typography'

import styles from './TopbarLink.module.scss'

const TopbarLink = ({ children, className, icon, href, tone, size, testingName }) => {
    tone = tone ? tone : 'subtle'
    
    return (
        <a className={ClassNames(className, styles.topbarLink)} href={href} data-testid={testingName}>
            <TopBarIcon icon={icon}/>
            <Typography
                className={ClassNames(styles.topbarText)}
                tone={tone}
                size={size ? size : 0.75}
                font={'regular'}
            >
                {children}
            </Typography>
        </a>
    )
}

const TopBarIcon = ({icon}) => {
    if(!icon){
        return false
    }

    if (icon == 'creditcard') {
        return (
            <svg version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                className={styles.topbarIcon}
                style={{ 'width': '0.75rem', 'height': '0.75rem' }} >
                <defs>
                    <symbol id="icon-creditcard" viewBox="0 0 33 32">
                        <path fill="#ffffff" style={{ "fill": "var(--color1, #ffffff)" }} d="M0 24.090c-0 0.013-0 0.028-0 0.043 0 1.081 0.866 1.959 1.941 1.98l0.002 0h29.114c1.078-0.021 1.943-0.899 1.943-1.98 0-0.015-0-0.030-0.001-0.045l0 0.002v-10.81h-32.999z" />
                        <path fill="#ffffff" style={{ "fill": "var(--color1, #ffffff)" }} d="M31.057 5.887h-29.114c-1.078 0.021-1.943 0.899-1.943 1.98 0 0.015 0 0.030 0.001 0.045l-0-0.002v2.177h32.999v-2.177c0-0.013 0-0.028 0-0.043 0-1.081-0.866-1.959-1.941-1.98l-0.002-0z" />
                    </symbol>
                </defs>
                <use href="#icon-creditcard" />
            </svg>
        )
    }

    if (icon == 'deals') {
        return (
            <svg version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                className={styles.topbarIcon}
                style={{ 'width': '0.75rem', 'height': '0.75rem' }} >
                <defs>
                    <symbol id="icon-deals" viewBox="0 0 32 32">
                        <path fill="#ffffff" style={{ "fill": "var(--color1, #ffffff)" }} d="M30.95 16.8l-16.4-16.45c-0.2-0.2-0.5-0.35-0.8-0.35h-12.6c-0.65 0-1.15 0.5-1.15 1.15v12.55c0 0.3 0.1 0.6 0.35 0.8l16.45 16.45c0.65 0.65 1.55 1 2.4 1s1.75-0.35 2.4-1l9.3-9.3c0.65-0.65 1-1.5 1-2.4 0.050-0.95-0.3-1.8-0.95-2.45zM6.9 9.4c-1.4 0-2.55-1.15-2.55-2.55s1.15-2.55 2.55-2.55 2.55 1.15 2.55 2.55-1.2 2.55-2.55 2.55z" />
                    </symbol>
                </defs>
                <use href="#icon-deals" />
            </svg>
        )
    }

    if (icon == 'trackorder') {
        return (
            <svg version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                className={styles.topbarIcon}
                style={{ 'width': '0.75rem', 'height': '0.75rem' }} >
                <defs>
                    <symbol id="icon-trackorder" viewBox="0 0 32 32">
                        <path fill="#ffffff" style={{ "fill": "var(--color1, #ffffff)" }} d="M16.35 18.452c-3.731 0-6.755-3.024-6.755-6.755s3.024-6.755 6.755-6.755v0c3.753 0 6.705 3.102 6.705 6.755 0.050 3.753-2.952 6.755-6.705 6.755zM16.35-0.013c-0 0-0 0-0 0-6.439 0-11.659 5.22-11.659 11.659 0 0.018 0 0.035 0 0.053v-0.003c0 5.855 4.904 13.261 10.108 19.616 0.36 0.415 0.888 0.675 1.476 0.675s1.116-0.261 1.474-0.673l0.002-0.002c5.354-6.355 10.258-13.761 10.258-19.616 0-6.505-5.204-11.709-11.659-11.709z" />
                    </symbol>
                </defs>
                <use href="#icon-trackorder" />
            </svg>
        )
    }

    if (icon == 'help') {
        return (
            <svg version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                className={styles.topbarIcon}
                style={{ 'width': '0.75rem', 'height': '0.75rem' }} >
                <defs>
                    <symbol id="icon-help" viewBox="0 0 32 32">
                        <path fill="#ffffff" style={{ "fill": "var(--color1, #ffffff)" }} d="M16 0c-8.837 0-16 7.163-16 16s7.163 16 16 16v0c8.837 0 16-7.163 16-16s-7.163-16-16-16v0zM17.45 27.050c-0.505 0.496-1.197 0.801-1.961 0.801-0.031 0-0.062-0-0.094-0.002l0.005 0c-0.85 0-1.55-0.25-2.050-0.8-0.496-0.49-0.803-1.17-0.803-1.921 0-0.045 0.001-0.090 0.003-0.135l-0 0.006c0-0.8 0.25-1.5 0.8-2.050 0.49-0.496 1.17-0.803 1.921-0.803 0.045 0 0.090 0.001 0.135 0.003l-0.006-0c0.85 0 1.55 0.25 2.050 0.8 0.496 0.49 0.803 1.17 0.803 1.921 0 0.045-0.001 0.090-0.003 0.135l0-0.006c0.006 0.063 0.009 0.135 0.009 0.209 0 0.728-0.311 1.383-0.807 1.84l-0.002 0.002zM21.95 13.15c-0.55 1.15-1.85 3.35-3.85 6.75l-0.1 0.1h-5.25l0.25-0.4c1.1-1.696 2.426-3.935 3.682-6.218l0.318-0.632c0.457-0.776 0.762-1.695 0.848-2.675l0.002-0.025c0-1.35-0.7-2-2.2-2-0.9 0-1.55 0.2-1.85 0.65s-0.5 1.15-0.5 2v0.3h-4.6v-0.25c0-4.3 2.4-6.45 7.15-6.45 2.2 0 3.9 0.5 5.15 1.5 1.137 0.924 1.857 2.322 1.857 3.889 0 0.092-0.002 0.183-0.007 0.274l0.001-0.013c-0.050 0.95-0.35 2-0.9 3.2z" />
                    </symbol>
                </defs>
                <use href="#icon-help" />
            </svg>
        )
    }

    return (
        <Svg
            className={styles.topbarIcon}
            icon={icon}
            size={0.75}
            tone={'subtle'}
        />
    )
}
export default TopbarLink
