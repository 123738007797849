import { sanitize } from "Utilities/ObjectUtilities";

import {
  addSessionProperties,
  incrementSessionStorageKeyEventCount,
  setIsEngagedSessionFired,
  trackEngagedSession,
} from "../../EngagedSessionStorageUtility/EngagedSessionStorageUtility";
import { productViewedSchema } from "../Product/productSegmentSchemas";

const segmentSchemaLookup = {
  "Product Viewed": productViewedSchema,
};

const validateSegmentEvent = (name, properties) => {
  const schema = segmentSchemaLookup[name];
  if (schema) {
    const validationErrors = Object.keys(schema)
      .filter((key) => !schema[key](properties[key]))
      .map((key) => new Error(`event: ${name}, property: ${key} is invalid.`));
    if (validationErrors.length > 0)
      for (const { message } of validationErrors) console.error(message);
  }
};

const sendSegmentTrackEvent = (event) => {
  if (!window.analytics) return;

  // Remove properties that are null or undefined
  const sanitizedEvent = sanitize(event);
  const name = sanitizedEvent.name || sanitizedEvent.event;

  let properties = sanitizedEvent.properties;

  validateSegmentEvent(name, properties);

  properties = addSessionProperties(properties);

  incrementSessionStorageKeyEventCount(name);
  trackEngagedSession();

  if (name) {
    analytics.track(name, properties);
  } else {
    analytics.track(properties);
  }

  setIsEngagedSessionFired();
};

const getSuperMarketAffinity = (item) => {
  if (!item) return "none";

  item.superMarketAffinity = item.superMarketAffinity ?? [];
  if (item.superMarketAffinity.length == 0) {
    return "none";
  }
  if (item.superMarketAffinity.length == 1) {
    return item.superMarketAffinity[0].toLowerCase();
  }
  return "multiple";
};

export { sendSegmentTrackEvent, validateSegmentEvent, getSuperMarketAffinity };
