import { useSyncExternalStore } from "react";

const buildStateEventName = (key) => `globalState_updated_${key}`

// this is split because you can set local state without rerendering the setting component through this
export const useGlobalStateSetter = (key) => {

    const STATE_UPDATED_EVENTNAME = buildStateEventName(key)

    function triggerStateChangedEvent(){
        const event = new CustomEvent(STATE_UPDATED_EVENTNAME);
        window.dispatchEvent(event);
    }

    const setGlobalState = (newState) => {
        window.globalState = window.globalState ?? {};
        window.globalState[key] = newState;
        triggerStateChangedEvent()
    }

    return { setGlobalState }
}

const useGlobalState = (key, initialState) => {
    const { setGlobalState } = useGlobalStateSetter(key)
    const STATE_UPDATED_EVENTNAME = buildStateEventName(key)

    function subscribeToGlobalStateChangeEvents(callback){
        window.globalState = window.globalState ?? {};
        window.addEventListener(STATE_UPDATED_EVENTNAME, callback)
        return () => window.removeEventListener(STATE_UPDATED_EVENTNAME, callback)
    }
    
    function getStateFromWindow(){
        return window.globalState?.[key] ?? initialState;
    }
    function getServerState(){
        return initialState
    }

    // https://react.dev/reference/react/useSyncExternalStore#subscribing-to-a-browser-api
    const globalState = useSyncExternalStore(subscribeToGlobalStateChangeEvents, getStateFromWindow, getServerState)



    return { globalState, setGlobalState }
}

export default useGlobalState;