import React, { Fragment } from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'

import SaytItemText from '../SaytItemText/SaytItemText'

import Typography from 'Atoms/Typography'
import Divider from 'Atoms/Divider'
import List from 'Molecules/List'
import ListItem from 'Molecules/List/ListItem'
import Image from 'next/image'
import styles from '../SaytDropdown.module.scss'
import { getSuperMarketAffinity } from "Utilities/Instrumentation/SegmentUtilities/segmentFunctions";

const propTypes = {
    maxProdsToDisplay: PropTypes.number,
    saytResults: PropTypes.object,
}


const SaytItems = ({ saytItems, type, maxItemsToDisplay = 6 }) => {
    if (!saytItems) {
        return null
    }

    // Can do this everytime as the op on small lists is negligble performance
    saytItems = saytItems.slice(0, maxItemsToDisplay)

    // si = saytItem
    let siHeadingText = ""
    let siDevKey = ""
    let siFallbackMessage = ""
    let siClassNames = ""
    try {
        switch (type) {
            case "PRODUCT":
                siHeadingText = "PRODUCTS"
                siDevKey = "product"
                siFallbackMessage = "No suggested products"
                siClassNames = ClassNames(styles.saytItemImage)
                break;
            case "ARTICLE":
                siHeadingText = "ARTICLES"
                siDevKey = "article"
                siFallbackMessage = "No suggested articles"
                siClassNames = ClassNames(styles.saytItemImage, styles.saytItemArticleImage)
                break;
            default:
                throw "Must have approved type of SayItem to display content"
        }
    }
    catch (err) {
        console.error(err)
        return null
    }

    return (
        <Fragment>
            <Typography tone={'contrast'} size={0.875} font={'heading'}>
                {siHeadingText}
            </Typography>
            <Divider className={styles.saytHr} />
            <List itemLayout={'horizontal'} className={styles.saytItems}>
                <SaytItemList classNames={siClassNames} type={type} saytItems={saytItems} siDevKey={siDevKey} siFallbackMessage={siFallbackMessage} />
            </List>
        </Fragment>
    )
}

const SaytItemList = ({ classNames, saytItems, siDevKey, siFallbackMessage, type }) => {
    if (saytItems.length === 0) {
        return (
            <Typography tone={'normal'} size={1} font={'bold'} className={styles.placeHolderText}>
                {siFallbackMessage}
            </Typography>
        )
    }

    return (
        saytItems.map((item) => {
            let listItemDevKey = siDevKey + item.position
            item.thumbImageUrl = item.thumbImageUrl ?? '//content.speedwaymotors.com/OtherImages/missingimage2.jpg';
            return (
                <ListItem
                    key={listItemDevKey}
                    href={item.url}
                    component={'a'}
                    className={styles.listItem}
                    dataTesting={listItemDevKey}
                    segmentEvent={type == 'PRODUCT' ? {
                        event: 'Product Clicked',
                        properties: {
                            list_id: 'sayt-products',
                            product_id: item.skuBaseNumber,
                            sku: item.skuBaseNumber,
                            category: item.publicSkuBaseDetails?.segmentCategorization,
                            name: item.title,
                            brand: item.publicSkuBaseDetails?.brand,
                            variant: item.skuVariantNumber,
                            price: item.price,
                            position: item.position,
                            url: item.url,
                            image_url: item.thumbImageUrl,
                            availability: item.publicSkuBaseDetails?.availability,
                            rating: item.publicSkuBaseDetails?.reviewCount ? item.publicSkuBaseDetails?.rating : 0,
                            reviews: item.publicSkuBaseDetails?.reviewCount,
                            audience_affinity: getSuperMarketAffinity(item)
                        }
                    } : type == 'ARTICLE' ? {
                        event: 'Article Clicked',
                        properties: {
                            list_id: 'sayt-articles',
                            //TODO: article_id: null,
                            title: item.title,
                            //TODO: author: null,
                            position: item.position,
                            url: item.url,
                            image_url: item.thumbImageUrl,
                        }
                    } : null}
                >
                    <div className={styles.saytItemImage}>
                        <Image
                            src={!item.thumbImageUrl.includes("https") ? `https:${item.thumbImageUrl}` : item.thumbImageUrl}
                            alt={item.title}
                            layout={'fill'}
                            objectFit={"contain"}
                        />
                    </div>

                    <SaytItemText title={item.title} position={item.position} />
                </ListItem>
            )
        })
    )
}
SaytItems.propTypes = propTypes

export default SaytItems
