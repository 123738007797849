import React, { useContext } from 'react'

import Panel from 'Atoms/Panel'
import ListItemLink from 'Molecules/List/ListItemLink'
import ListItem from 'Molecules/List/ListItem'
import List from 'Molecules/List'
import ListItemText from 'Molecules/List/ListItemText'
import ListItemIcon from 'Molecules/List/ListItemIcon'
import ReturnItem from "../Common/ReturnItem"

import { NavigationContext } from 'Contexts/Navigation/NavigationContext'

import styles from '../../HeaderDrawer.module.scss'

const SecondaryNavigation = ({ menu, closeFunction }) => {
    const { dispatch: navigationDispatch } = useContext(NavigationContext)
    const { navigation, primary, secondary } = menu

    return (
        <>
            <ReturnItem primary={primary} secondary={secondary} closeFunction={closeFunction} />
            <Panel className={styles.subNavigation}>
                <List component={'div'} className={styles.subNavigationList}>
                    {navigation.map((item) => {
                        if (item.disabled) {
                            return null;
                        }
                        const RootComponent = item.href
                            ? ListItemLink
                            : ListItem

                        return (
                            <RootComponent
                                key={item.id}
                                className={styles.listItem}
                                onClick={
                                    item.href
                                        ? null
                                        : () => {
                                            navigationDispatch(item.onClick)
                                        }
                                }
                                event={item.event}
                                href={item.href}
                                hover
                                data-testid={item.secondary}
                                as={item.href ? null : 'div'}
                            >
                                <ListItemText
                                    primary={
                                        !item.simplified
                                            ? item.primary
                                            : item.secondary
                                    }
                                    primaryTypographyProps={{
                                        size: 1,
                                        tone: 'contrast'
                                    }}
                                    className={styles.listItemText}
                                />
                                <ListItemIcon
                                    icon={item.icon ? item.icon : 'chevron'}
                                    className={styles.listItemIcon}
                                    size={1}
                                />
                            </RootComponent>
                        )
                    })}
                </List>
            </Panel>
        </>
    )
}

export default SecondaryNavigation
