const getShopPartsMainMenu = (flags) => {
    return {
        primary: 'Shop',
        secondary: 'Parts',
        showCallNow: true,
        navigation: [
            {
                id: 'Street',
                primary: 'Shop By',
                secondary: 'Street',
                simplified: true,
                href: '/street',
                icon: 'street'
            },
            {
                id: 'Race',
                primary: 'Shop By',
                secondary: 'Race',
                simplified: true,
                href: '/race',
                icon: 'race'
            },
            {
                id: 'Deals',
                primary: 'Shop By',
                secondary: 'Deals',
                simplified: true,
                href: '/deals',
                icon: 'deals'
            },
            {
                id: 'Category',
                primary: 'Shop By',
                secondary: 'Category',
                simplified: true,
                href: flags.linkToSitemapPages ? '/categories' : undefined,
                onClick: {
                    type: 'setDrawerState',
                    payload: {
                        drawerState: {
                            isDrawerOpen: true
                        },
                        drawerAction: {
                            type: 'addNavigation',
                            payload: {
                                component: 'FilterableNavigation',
                                menu: 'shopByCategory',
                                props: {
                                    fetch: true,
                                    service: 'traits',
                                    id: 'Category'
                                }
                            }
                        }
                    }
                }
            },
            {
                id: 'Platform',
                primary: 'Shop By',
                secondary: 'Platform',
                simplified: true,
                href: flags.linkToSitemapPages ? '/platforms' : undefined,
                disabled: flags.isPlatformDisabled,
                onClick: {
                    type: 'setDrawerState',
                    payload: {
                        drawerState: {
                            isDrawerOpen: true
                        },
                        drawerAction: {
                            type: 'addNavigation',
                            payload: {
                                component: 'FilterableNavigation',
                                menu: 'shopByPlatform',
                                props: {
                                    fetch: true,
                                    service: 'traits',
                                    id: 'Platform'
                                }
                            }
                        }
                    }
                }
            },
            {
                id: 'Engine',
                primary: 'Shop By',
                secondary: 'Engine Family',
                simplified: true,
                href: flags.linkToSitemapPages ? '/engines' : undefined,
                onClick: {
                    type: 'setDrawerState',
                    payload: {
                        drawerState: {
                            isDrawerOpen: true
                        },
                        drawerAction: {
                            type: 'addNavigation',
                            payload: {
                                component: 'FilterableNavigation',
                                menu: 'shopByEngine',
                                props: {
                                    fetch: true,
                                    service: 'traits',
                                    id: flags.isEngineFitmentSeoEnabled ? 'NewEngine' : 'Engine'
                                }
                            }
                        }
                    }
                }
            },
            {
                id: 'Transmission',
                primary: 'Shop By',
                secondary: 'Transmission',
                simplified: true,
                href: flags.linkToSitemapPages ? '/transmissions' : undefined,
                onClick: {
                    type: 'setDrawerState',
                    payload: {
                        drawerState: {
                            isDrawerOpen: true
                        },
                        drawerAction: {
                            type: 'addNavigation',
                            payload: {
                                component: 'FilterableNavigation',
                                menu: 'shopByTransmission',
                                props: {
                                    fetch: true,
                                    service: 'traits',
                                    id: 'Transmission'
                                }
                            }
                        }
                    }
                }
            },
            {
                id: 'Makes',
                primary: 'Shop By',
                secondary: 'Makes',
                simplified: true,
                href: flags.linkToSitemapPages ? '/makes' : undefined,
                onClick: {
                    type: 'setDrawerState',
                    payload: {
                        drawerState: {
                            isDrawerOpen: true
                        },
                        drawerAction: {
                            type: 'addNavigation',
                            payload: {
                                component: 'FilterableNavigation',
                                menu: 'shopByMake',
                                props: {
                                    fetch: true,
                                    service: 'traits',
                                    id: 'Make'
                                }
                            }
                        }
                    }
                }
            },
            {
                id: 'Brand',
                primary: 'Shop By',
                secondary: 'Brand',
                simplified: true,
                href: flags.linkToSitemapPages ? '/brands' : undefined,
                onClick: {
                    type: 'setDrawerState',
                    payload: {
                        drawerState: {
                            isDrawerOpen: true
                        },
                        drawerAction: {
                            type: 'addNavigation',
                            payload: {
                                component: 'FilterableNavigation',
                                menu: 'shopByBrand',
                                props: {
                                    fetch: true,
                                    service: 'traits',
                                    id: 'Brand'
                                }
                            }
                        }
                    }
                }
            },

            {
                id: 'Rearaxle',
                primary: 'Shop By',
                secondary: 'Rear Axle',
                simplified: true,
                href: flags.linkToSitemapPages ? '/rearaxle' : undefined,
                onClick: {
                    type: 'setDrawerState',
                    payload: {
                        drawerState: {
                            isDrawerOpen: true
                        },
                        drawerAction: {
                            type: 'addNavigation',
                            payload: {
                                component: 'FilterableNavigation',
                                menu: 'shopByRearAxle',
                                props: {
                                    fetch: true,
                                    service: 'traits',
                                    id: 'Rearaxle'
                                }
                            }
                        }
                    }
                }
            },
            {
                id: 'Services',
                primary: 'Shop By',
                secondary: 'Services',
                simplified: true,
                onClick: {
                    type: 'addNavigation',
                    payload: {
                        component: 'SecondaryNavigation',
                        menu: 'shopByServices',
                        props: { fetch: false }
                    }
                },
                onClick: {
                    type: 'setDrawerState',
                    payload: {
                        drawerState: {
                            isDrawerOpen: true
                        },
                        drawerAction: {
                            type: 'addNavigation',
                            payload: {
                                component: 'SecondaryNavigation',
                                menu: 'shopByServices',
                                props: {
                                    fetch: false
                                }
                            }
                        }
                    }
                }
            }
        ]
    }
}

const getShopByServicesSubMenu = () => {
    return {
        primary: 'Shop By',
        secondary: 'Services',
        showCallNow: true,
        navigation: [
            {
                id: 'RacingEngines',
                primary: 'Shop By',
                secondary: 'Racing Engines',
                simplified: true,
                href: '/Featured/Racing-Engines'
            },
            {
                id: 'Shocks',
                primary: 'Shop By',
                secondary: 'Shocks',
                simplified: true,
                href: '/Info/Shock'
            }
        ]
    }
}

const getShopByCategoriesSubMenu = () => {
    return {
        primary: 'Shop By',
        secondary: 'Category',
        showCallNow: true,
    }
}

const getShopByMakeSubMenu = () => {
    return {
        primary: 'Shop By',
        secondary: 'Make',
        showCallNow: true,
    }
}

const getShopByBrandSubMenu = () => {
    return {
        primary: 'Shop By',
        secondary: 'Brand',
        showCallNow: true,
    }
}

const getShopByEngineSubMenu = () => {
    return {
        primary: 'Shop By',
        secondary: 'Engine',
        showCallNow: true,
    }
}

const getShopByTransmissionSubMenu = () => {
    return {
        primary: 'Shop By',
        secondary: 'Transmission',
        showCallNow: true,
    }
}

const getShopByRearAxleSubMenu = () => {
    return {
        primary: 'Shop By',
        secondary: 'Rear Axle',
        showCallNow: true,
    }
}

const getShopByPlatformSubMenu = () => {
    return {
        primary: 'Shop By',
        secondary: 'Platform',
        showCallNow: true,
    }
}

export {
    getShopPartsMainMenu,
    getShopByServicesSubMenu,
    getShopByCategoriesSubMenu,
    getShopByMakeSubMenu,
    getShopByBrandSubMenu,
    getShopByEngineSubMenu,
    getShopByTransmissionSubMenu,
    getShopByRearAxleSubMenu,
    getShopByPlatformSubMenu
}
