
import { useEffect } from 'react'

const useOutsideClick = (ref, ignoreElements, callback) => {
    const listener = (e) => {
        let isIgnoreElementClicked = false
        ignoreElements.forEach((ignoreElement) => {
            if (
                ignoreElement &&
                (ignoreElement === e.target ||
                    (ignoreElement.current && ignoreElement.current.contains(e.target)))
            ) {
                isIgnoreElementClicked = true
            }
        })
        const isOutsideClicked = ref.current && !ref.current.contains(e.target)

        if (!isIgnoreElementClicked) {
            if (isOutsideClicked) {
                callback()
            }
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', listener)
        document.addEventListener('touchstart', listener)
        return () => {
            document.removeEventListener('mousedown', listener)
            document.removeEventListener('touchstart', listener)
        }
    })
}

export default useOutsideClick
