import React from 'react'
import ClassNames from 'classnames'
import styled from 'styled-components'
import styles from './Tab.module.scss'

const Tab = ({ children, onClick, className, testingName, ...props }) => {
    try {
        const classNames = ClassNames(className, styles.tabWrapper)

        return (
            <StyledTab className={classNames} onClick={onClick} {...props} data-testid={testingName}>
                {children}
            </StyledTab>
        )
    } catch (e) {
        console.error(e)
        return false
    }
}
const StyledTab = styled.div`
    display: inline;
    border-radius: 0.4rem 0.4rem 0 0;
`
export default Tab
