import React, { Fragment, useContext, useEffect, useState } from "react";

import { ListContext } from "Contexts/ListContext/ListContext";
import { NavigationContext } from "Contexts/Navigation/NavigationContext";
import useCart from "Hooks/useCart/useCart";
import List from "Molecules/List";
import ListItem from "Molecules/List/ListItem";
import ListItemIcon from "Molecules/List/ListItemIcon";
import ListItemLink from "Molecules/List/ListItemLink";
import ListItemText from "Molecules/List/ListItemText";
import { useVehicleStore } from "Stores/VehicleStore";

import DownloadAppButton from "../../../../../Components/DownloadAppButton/DownloadAppButton";
import { useUserStore } from "../../../../../Stores/UserStore/UserStore";
import BadgeDrawerMenuItem from "../../BadgeDrawerMenuItem/BadgeDrawerMenuItem";
import CallNow from "../../CallNow";
import styles from "../../HeaderDrawer.module.scss";
import ReturnItem from "../Common/ReturnItem";


const PrimaryNavigation = ({ menu, ...props }) => {
  const { dispatch: navigationDispatch } = useContext(NavigationContext);
  const { cart } = useCart();
  const customerProjects = useVehicleStore((x) => x.context.customerProjects);
  const { listContext } = useContext(ListContext);
  const [primary, setPrimary] = useState(null);
  const [secondary, setSecondary] = useState(null);
  const { navigation } = menu;
  const totalCustomerProjects = customerProjects ? customerProjects.length : 0;
  const firstName = useUserStore((x) => x.context.firstName);

  useEffect(() => {
    if (firstName) {
      setPrimary("Hello,");
      setSecondary(firstName);
    }
  }, [firstName]);

  let primaryBadgeBrand;
  let primaryBadgeIntent;

  if (typeof window !== "undefined") {
    if (window.outerWidth < 1023 && props.darkControlBarEnabled) {
      primaryBadgeBrand = "secondarypalette_purple";
    } else {
      primaryBadgeIntent = "action";
    }
  }

  return (
    <Fragment>
      <ReturnItem
        className={styles.primaryReturnItem}
        primary={primary}
        secondary={secondary}
        closeFunction={() => {
          navigationDispatch({
            type: "setDrawerState",
            payload: {
              drawer: {
                isDrawerOpen: false,
              },
            },
          });
        }}
        alternateCloseFunction={() => {
          if (window) window.location = "/Account/Login?ReturnUrl=%2faccount";
        }}
      />
      <List component={"div"} className={styles.primaryNavItems}>
        {navigation.map((item) => {
          let MenuItemComponent;
          const RootComponent = item.href ? ListItemLink : ListItem;
          const componentElement = !item.href ? "button" : null;

          switch (item.id) {
            case "myCart":
              MenuItemComponent = (
                <BadgeDrawerMenuItem
                  primaryText={item.primary}
                  secondaryText={item.secondary}
                  icon={item.icon}
                  badgeBrand={"secondary"}
                  badgeNumber={cart?.itemCount ?? 0}
                  hover={true}
                />
              );
              break;
            case "myGarage":
            case "myList":
              const badgeInt =
                item.id === "myList"
                  ? listContext.listCount
                  : totalCustomerProjects;
              MenuItemComponent = (
                <BadgeDrawerMenuItem
                  primaryText={item.primary}
                  secondaryText={item.secondary}
                  icon={item.icon}
                  badgeBrand={primaryBadgeBrand}
                  badgeIntent={primaryBadgeIntent}
                  badgeNumber={badgeInt}
                  removeNumber={true}
                  hover={true}
                />
              );
              break;
            default:
              MenuItemComponent = (
                <>
                  <ListItemText
                    primary={item.primary}
                    secondary={item.secondary}
                    className={styles.listItemTextContainer}
                    primaryTypographyProps={{
                      size: 1.25,
                      tone: "subtle",
                    }}
                    secondaryTypographyProps={{
                      size: 1.5,
                      tone: "contrast",
                    }}
                  />
                  <ListItemIcon
                    icon={item.icon}
                    className={styles.listItemIcon}
                    tone={"subtle"}
                    size={1.5}
                  />
                </>
              );
              break;
          }

          return (
            <RootComponent
              key={item.id}
              className={styles.listItem}
              onClick={
                item.href
                  ? null
                  : () => {
                      if (item.customOnClick) {
                        // Check for just mobile here for the garage function
                        // this is a last resort to get a desired behavior until we look at this
                        // state management again for performance issues
                        var pageWidth = Math.max(
                          document.body.scrollWidth,
                          document.documentElement.scrollWidth,
                          document.body.offsetWidth,
                          document.documentElement.offsetWidth,
                          document.documentElement.clientWidth
                        );

                        // This value is from the breakpoint scss file
                        item.customOnClick(
                          navigationDispatch,
                          pageWidth <= 1023
                        );
                      } else {
                        navigationDispatch(item.onClick);
                      }
                    }
              }
              href={item.href}
              component={componentElement}
              hover
              data-testid={item.primary + item.secondary}
            >
              {MenuItemComponent}
            </RootComponent>
          );
        })}
      </List>
      <div className={styles.appInstallFrame}>
        <DownloadAppButton location={`navigation drawer`} />
      </div>

      <CallNow />
    </Fragment>
  );
};

export default PrimaryNavigation;
