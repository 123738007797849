import {StorageAccessor} from "../LocalStorageUtility";

const KEY_EVENTS = [
    "Product Added",
    "Product Added to Wishlist",
    "Fitment Added",
    "Signed In",
    "Product Reviewed",
    "Product Shared",
    "Order Completed",
    "Products Searched",
    "Signed Up",
    "Wishlist Created",
];

const ENGAGEMENT_DURATION_THRESHOLD = 10;
const ENGAGEMENT_KEY_EVENT_THRESHOLD = 1;
const ENGAGEMENT_PAGE_VIEW_THRESHOLD = 2;

export function incrementSessionStorageKeyEventCount(eventName) {
    try {
        if (KEY_EVENTS.includes(eventName)) {
            const keyEventCount =
                StorageAccessor.sessionStorage.getObj("keyEventCount");
            const newKeyEventCount =
                (typeof keyEventCount === "number" ? keyEventCount : 0) + 1;
            StorageAccessor.sessionStorage.setObj("keyEventCount", newKeyEventCount);
        }
    } catch (error) {
        console.error("Error incrementing key event count:", error);
    }
}

export function incrementSessionStoragePageViewCount() {
    try {
        const pageViewCount =
            StorageAccessor.sessionStorage.getObj("pageViewCount");
        const newPageViewCount =
            (typeof pageViewCount === "number" ? pageViewCount : 0) + 1;

        StorageAccessor.sessionStorage.setObj("pageViewCount", newPageViewCount);
    } catch (error) {
        console.error("Error incrementing page view count:", error);
    }
}

export function initializeSession() {
    try {
        if (!StorageAccessor.sessionStorage.getObj("sessionStartTime")) {
            StorageAccessor.sessionStorage.setObj(
                "sessionStartTime",
                new Date().toISOString()
            );
        }
        if (StorageAccessor.sessionStorage.getObj("pageViewCount") === null) {
            StorageAccessor.sessionStorage.setObj("pageViewCount", 0);
        }
        if (StorageAccessor.sessionStorage.getObj("keyEventCount") === null) {
            StorageAccessor.sessionStorage.setObj("keyEventCount", 0);
        }
        if (
            StorageAccessor.sessionStorage.getObj("isEngagedSessionFired") === null
        ) {
            StorageAccessor.sessionStorage.setObj("isEngagedSessionFired", false);
        }
    } catch (error) {
        console.error("Error initializing session storage:", error);
    }
}

export function getSessionDuration() {
    const sessionStartTimeStr =
        StorageAccessor.sessionStorage.getObj("sessionStartTime");

    if (!sessionStartTimeStr) {
        console.warn(
            "Session start time not found in session storage. Defaulting to 0."
        );
        return 0;
    }

    const sessionStartTime = new Date(sessionStartTimeStr);

    if (isNaN(sessionStartTime.getTime())) {
        console.warn("Invalid session start time format. Defaulting to 0.");
        return 0;
    }

    const currentTime = new Date();
    const durationInSeconds = Math.floor((currentTime - sessionStartTime) / 1000);

    return Math.max(durationInSeconds, 0);
}

export function calculateIsEngagedSession(
    sessionDuration,
    keyEventCount,
    pageViewCount
) {
    try {
        return (
            sessionDuration >= ENGAGEMENT_DURATION_THRESHOLD ||
            keyEventCount >= ENGAGEMENT_KEY_EVENT_THRESHOLD ||
            pageViewCount >= ENGAGEMENT_PAGE_VIEW_THRESHOLD
        );
    } catch (error) {
        console.warn("Error calculating isEngagedSession:", error);
        return false;
    }
}

export function getIsEngagedSessionFired() {
    try {
        const isEngagedSessionFired = StorageAccessor.sessionStorage.getObj(
            "isEngagedSessionFired"
        );
        return isEngagedSessionFired === true;
    } catch (error) {
        console.error("Error getting isEngagedSessionFired:", error);
        return false;
    }
}

export function trackEngagedSession() {
    if (getIsEngagedSessionFired()) {
        return;
    }

    const sessionDuration = getSessionDuration();
    const keyEventCount = getSessionStorageKeyEventCount();
    const pageViewCount = getSessionStoragePageViewCount();
    const isEngagedSession = calculateIsEngagedSession(
        sessionDuration,
        keyEventCount,
        pageViewCount
    );

    StorageAccessor.sessionStorage.setObj("isEngagedSession", isEngagedSession);
}

export function setIsEngagedSessionFired() {
    if (!getIsEngagedSession()) {
        return;
    }
    StorageAccessor.sessionStorage.setObj("isEngagedSessionFired", true);
}

function getSessionStorageCount(key) {
    try {
        let count = parseInt(StorageAccessor.sessionStorage.getObj(key), 10);

        if (isNaN(count)) {
            count = 0;
        }

        return count;
    } catch (error) {
        console.error(`Error getting ${key}:`, error);
        return 0;
    }
}

function getSessionStorageValue(key) {
    try {
        const value = StorageAccessor.sessionStorage.getObj(key);

        return value === null ? false : value;
    } catch (error) {
        console.error(`Error getting ${key}:`, error);
        return false;
    }
}

export const getSessionStorageKeyEventCount = () =>
    getSessionStorageCount("keyEventCount");
export const getSessionStoragePageViewCount = () =>
    getSessionStorageCount("pageViewCount");
export const getIsEngagedSession = () =>
    getSessionStorageValue("isEngagedSession");

export function addSessionProperties(properties = {}) {
    const path =
        typeof window !== "undefined" ? window.location.pathname : undefined;
    const sessionDuration = getSessionDuration();
    const keyEventCount = getSessionStorageKeyEventCount();
    const pageViewCount = getSessionStoragePageViewCount();
    const isEngagedSessionFired = getIsEngagedSessionFired();
    const isEngagedSession = calculateIsEngagedSession(
        sessionDuration,
        keyEventCount,
        pageViewCount
    );

    return {
        ...properties,
        path,
        sessionDuration,
        keyEventCount,
        pageViewCount,
        isEngagedSession,
        isEngagedSessionFired,
    };
}
