'use client'
//import styles from './NewTypography.module.scss' // you're looking for "global.scss"
import React, { useContext } from 'react'
import PanelContext from 'Contexts/PanelContext'
import { useThemeStore } from 'Stores/ThemeStore'

const Typography = ({
    tone,
    size = 1,
    font = "regular",
    brand,
    intent,
    inline,
    fill,
    children,
    className = "",
    underline,
    underlineHover,
    strikethrough,
    as,
    disabled,
    hoverable,
    dataTesting,
    condensed,
    style,
    ...props
}) => {
    const Component = as ?? "p"
    let inlineStyles = style ? { ...style } : {};



    let colorString = brand ?? intent ?? tone ?? "none";

    let sizeClass = `${size}`.replace(".", "_")
    let classes = `p p-color-${colorString} p-font-${font} p-${sizeClass} ${className} `

    const getBoolClass = (name, value) => {
        if (!value) {
            return;
        }
        classes += `p-${name} `
    }


    const theme = useThemeStore((x) => x?.theme)

    const panelContext = useContext(PanelContext)

    getBoolClass("strikethrough", strikethrough);
    getBoolClass("underline", underline);
    getBoolClass("hover", hoverable);
    getBoolClass("hover-underline", underlineHover);
    getBoolClass("condensed", condensed);
    getBoolClass("fill", fill);

    getBoolClass("inline", inline);
    getBoolClass("invert", theme == "dark" || (typeof (panelContext) !== 'undefined' && panelContext.inverseTheme));


    return <Component className={classes} data-testid={dataTesting} style={inlineStyles} {...props} >{children}</Component>
}




export default Typography
