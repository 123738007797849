const Brands = [
    'primary',
    'secondary',
    'secondarypalette_orange',
    'secondarypalette_purple',
    'secondarypalette_blue',
    'secondarypalette_black',
    undefined
]

export default Brands