import React from 'react'
import ClassNames from 'classnames'
import Svg from 'Atoms/Svg'
import Badge from 'Atoms/Badge'

import styles from './ListItemIcon.module.scss'

const ListItemIcon = ({
    icon,
    tone,
    size,
    brand,
    children,
    className,
    badge,
    badgeProps,
    ...props
}) => {
    if (!icon && !children) {
        return null
    }

    const classnames = ClassNames(className, styles.listItemIcon)
    const iconTone = tone ? tone : 'normal'
    const iconSize = size ? size : '1'
    const iconBrand = brand ? brand : null

    if (badge && badgeProps && badgeProps.number && badgeProps.number > 0) {
        const badgeSettings = {
            ...badgeProps,
            number: badgeProps.removeNumber ? 0 : badgeProps.number,
        }

        return (
            <Badge {...badgeSettings}>
                <div className={classnames}>
                    <Svg
                        icon={icon}
                        tone={iconTone}
                        size={iconSize}
                        brand={iconBrand}
                        {...props}
                    />
                </div>
            </Badge>
        )
    }

    return (
        <>
            <div className={classnames}>
                <Svg
                    icon={icon}
                    tone={iconTone}
                    size={iconSize}
                    brand={iconBrand}
                    {...props}
                />
            </div>
        </>
    )
}

export default ListItemIcon
