import React, {Fragment, useCallback, useContext, useEffect, useRef} from 'react';
import Typography from 'Atoms/Typography';
import ListCreationModal from 'Containers/ListCreationModal/ListCreationModal';
import {ListContext} from 'Contexts/ListContext/ListContext';
import Button from 'Molecules/Button';
import Drawer from 'Molecules/NewDrawer/Drawer';
import {createClickedSegmentEvent} from '../../Utilities/Instrumentation/Impressions/impressionSegmentEvents';
import ListCard, {ListCardSkeleton} from './listCard/listCard';

import styles from './listDrawer.module.scss'

const ListDrawer = () => {
    const { listContext, getUserLists, hideListDrawer, createNewList, addToList, addArticlesToList } = useContext(ListContext);
    const { itemsToAddToList, itemsToMoveToList, isListDrawerOpen, listDrawerType, selectedListId, isBuildListDrawer } = listContext;

    const isForProduct = listDrawerType === 'product';
    let lists = listContext.lists;
    const createListModal = useRef(null);
    const listCount = (lists?.filter((x) => !x.isSavedForLater || isForProduct) || []).length;

    useEffect(() => {
        if (isListDrawerOpen) {
            getUserLists(true);
        }
    }, [isListDrawerOpen]);

    const AddToListWrapper = useCallback(
        async (listId, isSavedForLater, setLoading) => {
            if (setLoading) setLoading(true);
            await handleMisoInteraction(itemsToAddToList, isForProduct);
            const addFunc = isForProduct ? addToList : addArticlesToList;
            const success = await addFunc(
                isForProduct || typeof itemsToAddToList[0] === 'number'
                    ? itemsToAddToList
                    : itemsToAddToList.map((x) => x.articleId),
                listId
            );
            if (success) {
                getUserLists(true);
                hideListDrawer();
            }
            if (setLoading) setLoading(false);
        },
        [itemsToAddToList, addToList, listContext.listDrawerType]
    );

    const footer = (
        <Button
            intent={'action'}
            invert
            onClick={() => {
                createListModal.current.open();
                hideListDrawer();
            }}
            text={'Create New List'}
            size={'small'}
            fill
            segmentEvent={createClickedSegmentEvent('ListDrawerCreateNew')}
            data-testid={'list_drawer_create_new_list'}
        />
    );

    const item = (itemsToAddToList?.length ? itemsToAddToList[0] : (itemsToMoveToList?.length ? itemsToMoveToList[0] : null));

    return (
        <>
        <Drawer
            portalId={'listDrawer'}
            location={'right'}
            isOpen={isListDrawerOpen}
            onClose={() => hideListDrawer()}
            title={isBuildListDrawer ? 'Move This Item to a List' : 'Save This Item to a List'}
            image={`https:${item?.images?.[0] || item?.imageLink || '//content.speedwaymotors.com/OtherImages/missingimage2.jpg'}`}
            footer={footer}
            testId={isBuildListDrawer ? `buildList_drawer_${item?.skuBaseNumber}` : `drawer_${item?.skuBaseNumber}`}
        >
            <div data-testid={`list_drawer_${isListDrawerOpen ? 'open' : 'closed'}`}></div>
            <Typography size={1.25}>Your Lists</Typography>
            <Typography
                testId={isBuildListDrawer ? `buildList_drawer_subtitle_${item?.skuBaseNumber}` : `drawer_subtitle_${item?.skuBaseNumber}`}
                size={0.875}>{isBuildListDrawer ? 'Move this item to a list' : 'Save this item to a list'}</Typography>
            <Typography size={0.875}>
                {`${isBuildListDrawer ? listCount - 1 : listCount} ${listCount === 1 ? 'List' : 'Lists'}`}
            </Typography>

            {lists ? (
                lists
                    .filter((x) => !x.isSavedForLater || isForProduct)
                    .map((list, i) => (
                        <>
                            <div className={styles.spacer}></div>
                            <ListCard
                                key={`list_${i}`}
                                list={list}
                                listName={list.listName}
                                productCount={list.productCount || 0}
                                total={list.listPrice}
                                item={item}
                                image={
                                    (list.products || []).length
                                        ? list.products[0].imageUrl
                                        : '//content.speedwaymotors.com/OtherImages/missingimage2.jpg'
                                }
                                listId={list.id}
                                addToList={(setLoading) => AddToListWrapper(list.id, list.isSavedForLater, setLoading)}
                                listContainsItem={DoesListContainItem(list.products, list.articles, itemsToAddToList, listDrawerType)}
                                isBuildListCard={isBuildListDrawer}
                                currentListId={selectedListId}
                            />
                        </>
                    ))
            ) : (
                <>
                    <ListCardSkeleton/>
                    <ListCardSkeleton/>
                    <ListCardSkeleton/>
                </>
            )}
        </Drawer>
    <ListCreationModal
        onCreateList={(listName) => createNewList(listName, isForProduct ? itemsToAddToList : null, !isForProduct ? itemsToAddToList.map((item) => item.articleId) : null)}
                ref={createListModal}
            />
        </>
    );
};

function DoesListContainItem(products, articles, itemsToAddToList, type) {
    if (type === 'product' && (products || []).length) {
        return products.some(product =>
            itemsToAddToList?.some(item => product.sku === item.skuVariantNumber)
        )
    }

    if (type === 'article' && (articles || []).length) {
        return articles.some(article =>
            itemsToAddToList?.some(item => article.id === (typeof (item) == 'number' ? item : item.articleId))
        )
    }

    return false
}

async function handleMisoInteraction(itemsToAddToList, isForProduct) {
    if (window?.fireMisoInteraction) {
        if (isForProduct) {
            window.fireMisoInteraction({
                prod_id: itemsToAddToList[0].skuBaseNumber,
                sku: itemsToAddToList[0].skuVariantNumber,
                eventType: 'addToList'
            })
        }
        else {
            let articleId = typeof (itemsToAddToList[0]) == 'number' ? itemsToAddToList[0] : itemsToAddToList[0].articleId

            window.fireMisoInteraction({
                prod_id: `Article-${articleId}`,
                sku: `Article-${articleId}`,
                eventType: 'addToList'
            })
        }
    }
}

export default ListDrawer