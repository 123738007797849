import { useEffect, useState } from "react";

import LogRocket from "logrocket";


const useLogRocket = (logRocketInitialized) => {
  const [queue, setQueue] = useState([]);

  useEffect(() => {
    if (logRocketInitialized && queue.length) {
      queue.map((e) => {
        try {
          LogRocket.track(e.eventName, e.eventData);
        } catch (err) {
          console.error(err);
        }
      });
      setQueue([]);
    }
  }, [logRocketInitialized, JSON.stringify(queue)]);

  function logRocketTrack(eventName, eventData) {
    if (logRocketInitialized) {
      try {
        LogRocket.track(eventName, eventData);
      } catch (err) {
        console.error(err);
      }
    } else {
      setQueue((current) => [...current, { eventName, eventData }]);
    }
  }

  return { logRocketTrack };
};

export default useLogRocket;
