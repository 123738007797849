import React, { useContext } from 'react'
import ClassNames from 'classnames'

import { RecommendationProvider } from 'Stores/RecommendationStore/RecommendationStore'
import { AddToCartExperienceContext } from 'Contexts/AddToCartExperienceContext/AddToCartExperienceContext'
import useFeatureFlag from "Hooks/useFeatureFlag/useFeatureFlag";

import RecommendationSection from 'Organisms/RecommendationSection'

import Button from 'Molecules/Button'
import Drawer from 'Molecules/NewDrawer/Drawer'

import Typography from 'Atoms/Typography'
import TextLink from 'Atoms/TextLink'
import Panel from 'Atoms/Panel'
import Svg from 'Atoms/Svg'
import Divider from 'Atoms/Divider'

import AddToCartDrawerCartProducts from './cartProducts/cartProducts'
import AddToCartRecommendations from './recommendations/addToCartRecommendations'
import styles from './addToCartDrawer.module.scss'

const AddToCartDrawer = ({ mockRecommendations }) => {

  const addToCartExperienceContext = useContext(AddToCartExperienceContext);


  const numberOfProductAddedToCart = ((addToCartExperienceContext || {}).items || []).reduce((prevQuantity, currItem) => prevQuantity + currItem.quantityRequested, 0);


  const footer = <>
    <span className={styles.atc_drawer_panel_footer_header}>
      <span>
        <Typography size={0.75}>
          {`${addToCartExperienceContext && addToCartExperienceContext.itemCount ? addToCartExperienceContext.itemCount : 0} ${addToCartExperienceContext && addToCartExperienceContext.itemCount === 1 ? 'item' : 'items'} in cart`}
        </Typography>
        <Typography size={1} tone={'contrast'}>
          Subtotal
        </Typography>
      </span>
      <Typography size={1.5} font={'bold'} tone={'contrast'} inline className={ClassNames(styles.atc_drawer_subtotal)}>
        {addToCartExperienceContext && addToCartExperienceContext.cartTotalPrice ? addToCartExperienceContext.cartTotalPrice : '$0.00'}
      </Typography>
    </span>

    <Button
      text={'View Cart'}
      intent={'positive'}
      size={'small'}
      fill
      segmentEvent={{ event: 'Clicked', properties: { element: 'View Cart Button - ATC Drawer' } }}
      href={`/shoppingcart`}
      data-testid={'atc-drawer-view-cart'} />
    <TextLink size={1} className={ClassNames(styles.center, styles.atc_drawer_continue_shopping)} onClick={addToCartExperienceContext.hideAtcDrawer} data-testid={'atc-drawer-continue-shopping'} segmentEvent={{ event: 'Clicked', properties: { element: 'Continue Shopping Button - ATC Drawer' } }}>
      Continue Shopping
    </TextLink>
  </>

  const buildImagesForDrawer = () => {
    if (!addToCartExperienceContext?.items) {
      return null
    }

    let images = addToCartExperienceContext?.items.map((x) => x?.images?.length ? `https://${x?.images[0]}` : null)
    images = images.filter((x) => x != null)
    images = images.filter((value, index, array) => array.indexOf(value) === index);
    return images
  }

  return (
    <Drawer
      portalId={'atcDrawer'}
      location={'right'}
      width={640}
      isOpen={addToCartExperienceContext.isAtcDrawerOpen}
      onClose={() => addToCartExperienceContext.hideAtcDrawer()}
      title={`${(numberOfProductAddedToCart || 0)} ${(numberOfProductAddedToCart || 0) === 1 ? 'Item' : 'Items'} Added to Cart`}
      footer={footer}
      svgProps={{icon: 'check', intent: 'positive'}}
      images={buildImagesForDrawer()}
      isStacked={addToCartExperienceContext.isSelectIndexOptionsDrawerOpen}
    >

      <>
        <div data-testId={`atc_drawer_${addToCartExperienceContext.isAtcDrawerOpen ? 'open' : 'closed'}`}></div>
        {addToCartExperienceContext.isAtcDrawerOpen ?
            <RecommendationProvider
              sections={["ATC_Drawer"]}
              skuBaseNumbers={(addToCartExperienceContext.items ?? []).map(product => product.skuBaseNumber)}>
              <RecommendationSection section={"ATC_Drawer"} numberOfSkeletons={3} numberOfSkeletonSections={1} skeletonDisplayType={"Vertical"} displayType={"Vertical"} spacingSize={'None'} lazyMargin={"800px"} testData={mockRecommendations} isCard showAddToCart={true} />
            </RecommendationProvider>
          :
          false
        }
      </>
    </Drawer>
  )
}

export default AddToCartDrawer