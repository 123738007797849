'use client'
import React from 'react'
import PropTypes from 'prop-types'
import ClassNames from 'classnames'
import useSegment from 'Hooks/useSegment'

import Typography from 'Atoms/Typography'
import Svg from 'Atoms/Svg'


import { Svgs } from "Constants/Svg";

import styles from './TextLink.module.scss'
import useNavigationState from '../../Hooks/useAppInstall/useNavigationState'



const propTypes = {
    href: PropTypes.string,
    onClick: PropTypes.func,
    scrollTo: PropTypes.string,
    targetBlank: PropTypes.bool,
    icon: PropTypes.oneOf(Svgs),
    chevron: PropTypes.oneOf(['left', 'right', 'none']),
    event: PropTypes.shape({
        category: PropTypes.string,
        action: PropTypes.string,
        label: PropTypes.string,
    }),
    segmentEvent: PropTypes.object,
    nofollow: PropTypes.bool,
}

const TextLink = ({
    children,
    href,
    onClick,
    scrollTo,
    targetBlank,
    icon,
    chevron,
    svg,
    svgSize,
    className,
    event,
    segmentEvent,
    nofollow,
    fill,
    title,
    as,
    ...props
}) => {

    const { sendSegmentTrackEvent, sendSegmentTrackEventWithName } = useSegment()
    const { showNavigation, tryDisableGtmAutoLinkTrackingForInternalNavigation } = useNavigationState();

    let classNames = ClassNames(
        className,
        styles.text_link,
        svg ? styles.svgLink : undefined
    )


    let chevronSvg = chevron === 'right' ?
        <Svg icon={svg ? svg : 'chevron'}
            size={svgSize ?? props.size}
            style={svg ? {} : fill ? { position: 'absolute', right: '0', marginTop: `${props.size / 4}rem` } : { marginLeft: `${props.size / 3}rem`, paddingLeft: `${props.size / 3}rem` }}
            className={svg ? styles.svg : fill ? styles.chevronRight : styles.chevron} />
        :
        false


    as = as || "p"
    if (href) {
        props.href = href;
        as = "a"
    }
    if (targetBlank) {
        props.target = "_blank"

    }
    if (targetBlank && nofollow) {
        props.rel = "nofollow noopener noreferrer"

    } else if (targetBlank) {
        props.rel = "noopener noreferrer"
    } else if (nofollow) {
        props.rel = "nofollow"
    }


    if (as != "a")
        title = null


    function onClickWrapper(e) {
        if (onClick) {
            onClick(e)
        }

        const isNavigatingFromBrowserInsteadOfJs = href && !e?.defaultPrevented 
        const isSpaLikeInteraction = !isNavigatingFromBrowserInsteadOfJs
        if(isNavigatingFromBrowserInsteadOfJs){
            tryDisableGtmAutoLinkTrackingForInternalNavigation(href)
        }
        if (href) {
            showNavigation();
        }

        // only defer if it's a SPA-like interaction
        const deferSegmentEvent = isSpaLikeInteraction
        if (segmentEvent) {
            if (segmentEvent.name && segmentEvent.properties) {
                sendSegmentTrackEventWithName(segmentEvent.name, segmentEvent.properties, deferSegmentEvent)
            }
            else {
                sendSegmentTrackEvent(segmentEvent, deferSegmentEvent)
            }
        }

        if (scrollTo) {
            document.getElementById(`${scrollTo}`).scrollIntoView({ behavior: 'smooth' })
        }
    }



    return (

        <Typography as={as} className={classNames} onClick={e => onClickWrapper(e)} {...props} hoverable title={title}>
            {children}
            {chevronSvg}
        </Typography>
    )
}


TextLink.propTypes = propTypes

export default TextLink
