const Palette = {
    dark: {
        hue: 0,
        saturation: 0,
        lightness: 0,
        alpha: 1
    },
    light: {
        hue: 0,
        saturation: 0,
        lightness: 100,
        alpha: 1
    },
    positive: {
        hue: 150,
        saturation: 100,
        lightness: 31,
        alpha: 1
    },
    positive_dark: {
        hue: 132,
        saturation: 77,
        lightness: 39,
        alpha: 1
    },
    negative: {
        hue: 5,
        saturation: 100,
        lightness: 50,
        alpha: 1
    },
    action: {
        hue: 210,
        saturation: 77,
        lightness: 46,
        alpha: 1
    },
    warning: {
        hue: 26,
        saturation: 81,
        lightness: 52,
        alpha: 1
    },
    purple_reign: {
        hue: 279,
        saturation: 82,
        lightness: 22,
        alpha: 1
    },
    kick_asphalt: {
        hue: 0,
        saturation: 0,
        lightness: 0,
        alpha: 1
    },
    speedway_yellow: {
        hue: 44,
        saturation: 100,
        lightness: 59,
        alpha: 1
    },
    speedway_orange: {
        hue: 26,
        saturation: 81,
        lightness: 52,
        alpha: 1
    },
    speedway_purple: {
        hue: 289,
        saturation: 60,
        lightness: 32,
        alpha: 1
    },
    speedway_blue: {
        hue: 195,
        saturation: 100,
        lightness: 44,
        alpha: 1
    },
    subdued: {
        hue: 0,
        saturation: 0,
        lightness: 55,
        alpha: 1
    },
    lingerie: {
        hue: 0,
        saturation: 0,
        lightness: 100,
        alpha: 0.25
    },
    facade: {
        hue: 0,
        saturation: 0,
        lightness: 0,
        alpha: 0
    },
    disabled: {
        hue: 0,
        saturation: 0,
        lightness: 83,
        alpha: 1
    }
}
export default Palette;