import React, { useContext, useEffect, useState } from "react";

import Badge from "Atoms/Badge";
import Typography from "Atoms/Typography";
import Popover from "Components/Popover";
import { NavigationContext } from "Contexts/Navigation/NavigationContext";
import PanelContext from "Contexts/PanelContext";
import useFeatureFlag from "Hooks/useFeatureFlag/useFeatureFlag";
import Button from "Molecules/Button";
import List from "Molecules/List/List";
import ListItem from "Molecules/List/ListItem";
import ListItemLink from "Molecules/List/ListItemLink";
import ListItemText from "Molecules/List/ListItemText";
import { useVehicleStore } from "Stores/VehicleStore";
import LayerColorFinder from "Utilities/LayerColorFinder";
import ClassNames from "classnames";
import styled from "styled-components";

import Skeleton from "../../../Atoms/Skeleton/Skeleton";
import { isInApp } from "../../../Hooks/useAppInstall/AppUtilities";
import useNotificationCount from "../../../Hooks/useNotificationCount/useNotificationCount";
import LoyaltyBadge from "../../../Molecules/Loyalty/LoyaltyBadge";
import { ShouldShow } from "../../../Molecules/Loyalty/LoyaltyUserStatus";
import useLoyaltyLinkLazy from "../../../Stores/LoyaltyStore/useLazyLoyaltyLink";
import { useUserStore } from "../../../Stores/UserStore/UserStore";
import { createClickedSegmentEvent } from "../../../Utilities/Instrumentation/Impressions/impressionSegmentEvents";
import headerStyles from "../Header.module.scss";
import { NavbarItem } from "../NavbarItem/NavbarItem";
import accountStyles from "./AccountDropdown.module.scss";

const AccountDropdown = ({ ...props }) => {
  const { state: navigationState, dispatch: navigationDispatch } =
    useContext(NavigationContext);

  const { count } = useNotificationCount();

  const { account } = navigationState;
  const { isAccountDropdownOpen } = account;
  const [accountHeading, setAccountHeading] = useState({
    primary: "Account",
    secondary: "Sign In",
  });

  const isRegistered = useUserStore((x) => x.context.isRegistered);
  const firstName = useUserStore((x) => x.context.firstName);
  const isLoyaltyBetaUser = useUserStore((x) => x.context.isLoyaltyBetaUser);
  const isLoyaltyRegisteredUser = useUserStore(
    (x) => x.context.isLoyaltyRegisteredUser
  );

  try {
    useEffect(() => {
      if (isRegistered && firstName && firstName !== accountHeading.secondary) {
        setAccountHeading({
          primary: "Account",
          secondary: firstName,
        });
      } else {
        setAccountHeading({
          primary: "Account",
          secondary: "Sign In",
        });
      }
    }, [isRegistered]);

    const handleToggleAccountDropdown = (isOpen) => {
      navigationDispatch({
        type: "setAccountState",
        payload: { accountState: { isAccountDropdownOpen: isOpen } },
      });
    };

    let badgeProps = null;

    if (count) {
      badgeProps = {
        intent: `negative`,
        number: count,
        size: "small",
        testid: "noteCount-desktopNav",
      };
    } else if (isLoyaltyBetaUser && isLoyaltyRegisteredUser) {
      badgeProps = {
        brand: "primary",
        number: "1",
        removeNumber: true,
        size: "small",
        testid: "loyaltyEligibility-desktopNav",
      };
    }

    return (
      <Popover
        className={ClassNames(
          headerStyles.headerItem,
          headerStyles.mobileHide,
          headerStyles.mobileLimitedHide,
          headerStyles.headerAccount
        )}
        isOpen={isAccountDropdownOpen}
        onToggleMenu={handleToggleAccountDropdown}
        content={<AccountNavigation />}
      >
        <NavbarItem
          hover
          icon={"user"}
          component={"button"}
          primaryText={accountHeading.primary}
          secondaryText={accountHeading.secondary}
          secondaryTextOptions={{
            className: ClassNames(
              headerStyles.headerAccountTextWrapper,
              headerStyles.headerOverflowText
            ),
          }}
          data-testid={"account"}
          badge={!!badgeProps}
          badgeProps={badgeProps}
        />
      </Popover>
    );
  } catch (e) {
    console.error(e);
    return false;
  }
};

const AccountNavigation = () => {
  const logoutUser = useUserStore((x) => x.logoutUser);
  const isRegistered = useUserStore((x) => x.context.isRegistered);
  const loyaltyLink = useLoyaltyLinkLazy();
  const shouldShowLoyalty = loyaltyLink && ShouldShow(loyaltyLink.userStatus);
  const firstName = useUserStore((x) => x.context.firstName);
  const updateCustomerProjects = useVehicleStore(
    (x) => x.updateCustomerProjects
  );

  const pwaEnabledFlag = useFeatureFlag("Frontend_PWA");
  const inApp = isInApp();

  const { count, isEnabled } = useNotificationCount();

  const accountNavItems = [
    {
      title: "Notifications",
      href: "/account/notificationhistory",
      disabled: !isEnabled,
      badgeProps: {
        intent: `negative`,
        number: count,
        size: "small",
        testid: "noteCount-desktopPopover",
        hideIfNumberIsFalsy: true,
      },
    },
    {
      title: "Order History",
      href: "/account/orderhistory",
    },
    {
      title: "Track My Order",
      href: "/Track/OrderLocator",
    },
    {
      title: "Start a Return",
      href: "/Track/ReturnLocator",
    },
    {
      title: "My Lists",
      href: "/buildlist",
    },
    {
      title: "Rewards",
    },
    {
      title: "My Garage",
      href: "/CustomerVehicle",
    },
    {
      title: "Install App",
      href: "/pages/appinstall",
    },
    {
      title: "Account Settings",
      href: "/account",
    },
  ];

  const panelContext = useContext(PanelContext);
  const inverseTheme =
    panelContext?.layer !== undefined ? panelContext.inverseTheme : false;
  const hoverLayer =
    panelContext?.layer !== undefined ? panelContext.layer + 1 : 0;
  const dividerColor = LayerColorFinder(hoverLayer, inverseTheme);

  return (
    <List
      className={accountStyles.accountNavigation}
      component={"nav"}
      itemLayout={"vertical"}
    >
      {!isRegistered ? (
        <div className={accountStyles.accountNavigationHeader}>
          <Button
            onClick={() => {
              const returnUrl =
                encodeURIComponent(
                  `${window.location.pathname}${window.location.search}`
                ) + "&showSessionMessage=true";
              window.location.href = `/Account/Login?returnUrl=${returnUrl}`;
            }}
            intent={"positive"}
            size={"small"}
            testingName={"Sign In /Register"}
            fill
            segmentEvent={createClickedSegmentEvent("AccountDropdownLogin")}
          >
            <Typography size={0.875} font={"regular"} tone={"normal"}>
              Sign In/Register
            </Typography>
          </Button>
          <StyledDivider dividerColor={dividerColor} />
        </div>
      ) : (
        false
      )}
      <div className={accountStyles.accountNavigationBody}>
        {accountNavItems.map((item) => {
          let listItem = null;
          switch (item?.title) {
            case "Rewards":
              listItem = (
                <>
                  {!loyaltyLink && <Skeleton fullWidth height={24} />}
                  {shouldShowLoyalty ? (
                    <ListItemLink
                      key={item.title}
                      className={accountStyles.listItem}
                      href={loyaltyLink?.onClickUrl}
                      hover
                    >
                      <ListItemText primary={item.title} />
                      <LoyaltyBadge loyaltyLink={loyaltyLink} />
                    </ListItemLink>
                  ) : null}
                </>
              );
              break;
            case "Install App":
              listItem = (
                <>
                  {pwaEnabledFlag && !inApp ? (
                    <ListItemLink
                      key={item.title}
                      className={accountStyles.listItem}
                      href={item?.href}
                      segmentEvent={{
                        event: "Clicked",
                        properties: {
                          element: `PWA Install - Account Dropdown`,
                        },
                      }}
                      hover
                    >
                      <ListItemText primary={item.title} />
                    </ListItemLink>
                  ) : null}
                </>
              );
              break;
            default:
              listItem = item.disabled ? (
                false
              ) : (
                <>
                  <ListItemLink
                    key={item.title}
                    className={accountStyles.listItem}
                    href={item?.href}
                    hover
                  >
                    {item.badgeProps ? (
                      <Badge {...item.badgeProps}>
                        <ListItemText primary={item.title} />
                      </Badge>
                    ) : (
                      <ListItemText primary={item.title} />
                    )}
                  </ListItemLink>
                </>
              );
              break;
          }
          return listItem;
        })}
        {isRegistered === true ? (
          <ListItem
            className={accountStyles.listItem}
            onClick={() => {
              logoutUser();
              updateCustomerProjects([])
            }}
            dataTesting={"logout"}
            hover
          >
            <ListItemText primary={"Sign Out"} />
          </ListItem>
        ) : (
          false
        )}
      </div>
    </List>
  );
};

const StyledDivider = styled.hr`
  margin-top: 10px;
  margin-bottom: 5px;
  color: ${(props) => props.dividerColor && props.dividerColor};
`;
export default AccountDropdown;
