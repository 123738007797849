'use client'
import React, {useRef, useState} from 'react'
import ClassNames from 'classnames'
import styled from 'styled-components'
import Panel from 'Atoms/Panel'
import Svg from 'Atoms/Svg'

import PanelContext from 'Contexts/PanelContext'
import ColorFinder from 'Utilities/ColorFinder'


import styles from './SearchBar.module.scss'
import YmmButton from "../../Components/YmmButton/YmmButton";
import {updateRecentSearches} from "../../Layout/Header/RecentSearches/RecentSearches";

const SearchBar = ({
    className,
    placeholder,
    handleSearch,
    onChange,
    disableIcon,
    iconSettings,
    autofocus,
    disabledInput,
    readOnly,
    ymmOrRaceTypeValue,
    dataTesting,
    dataTestingSearchSubmit,
    isMobile,
    value,
    clearSearchTerm,
    isSaytModalOpen,
    textAhead,
    setModal,
    ...props
}) => {
    const [inputFocused, setInputFocused] = useState(false)


    const inputRef = useRef(null)

    iconSettings =
        iconSettings !== undefined
            ? iconSettings
            : {
                position: 'end',
                size: 1.25,
                icon: 'magnifyingglass',
                filled: false
            }

    const classnames = ClassNames(className, styles.searchWrapper)
    const { color } = ColorFinder(null, 'normal')
    const { placeholderColor } = ColorFinder(null, 'superfluous')

    const setTextAheadAsSearch = (event) => {
        const isTab = event.nativeEvent.type == "keydown" && event.nativeEvent.keyCode == 9;
        const isClick = event.nativeEvent.type == "click";
        if ((isTab || isClick) && textAhead) {
            event.preventDefault();

            onChange(textAhead);

            analytics.track("Clicked", { element: `Sayt typeahead - ${event.nativeEvent.type}` })
        }
    }


    const clearSearchTermLocal = () => {
        inputRef.current.focus();
        clearSearchTerm();
    }

    return (
        <PanelContext.Provider value={{}}>

            <Panel className={classnames}>
                <form
                    role={'search'}
                    action={'https://www.speedwaymotors.com/search'}
                    onSubmit={(event) => {
                        event.preventDefault()
                        updateRecentSearches(value)
                        handleSearch(event)
                    }}
                >
                    <Panel border={!isSaytModalOpen && !isMobile} className={styles.searchWrap} layer={0}>
                        {isSaytModalOpen &&
                            <YmmButton
                                ymmOrRaceTypeValue={ymmOrRaceTypeValue}
                                isMobile={false}
                            />
                        }

                        <div className={styles.searchLayer}>
                            {textAhead && value ? <input type={'text'} className={styles.typeAhead} tabIndex={-1} name={"textahead"} placeholder={textAhead} readOnly={true} /> : null}

                            <StyledSearchBar
                                ref={inputRef}
                                type={'search'}
                                autoComplete={"off"}
                                aria-label={'search'}
                                placeholder={placeholder}
                                name={'query'}
                                onFocus={() => setInputFocused(true)}
                                onBlur={() => setInputFocused(false)}
                                onChange={(event) => onChange(event.target.value)}
                                onKeyDown={setTextAheadAsSearch}
                                onClick={setTextAheadAsSearch}
                                color={color}
                                placeholderColor={placeholderColor}
                                autoFocus={autofocus}
                                disabled={disabledInput}
                                readOnly={readOnly}
                                value={value}
                                data-testid={dataTesting}
                                tabIndex={isMobile ? -1 : null}
                            />
                        </div>
                    </Panel>
                    <SearchIcon
                        settings={iconSettings}
                        disabled={iconSettings.position !== 'end'}
                        tone={inputFocused}
                        dataTestingSearchSubmit={dataTestingSearchSubmit}
                        isMobile={isMobile}
                        shouldShowX={(value && isSaytModalOpen)}
                        clearSearchTerm={clearSearchTermLocal}
                    />
                </form>
            </Panel>
        </PanelContext.Provider>
    )
}

const SearchIcon = ({
    settings,
    disabled,
    dataTestingSearchSubmit,
    isMobile,
    shouldShowX,
    clearSearchTerm
}) => {
    if (disabled) {
        return false
    }


    let { size, tone, icon, filled, position } = settings
    size = size ? size : 1
    tone = tone ? 'normal' : 'superfluous'
    icon = icon ? icon : 'magnifyingglass'
    filled = filled ? filled : false
    position = position ? position : 'end'




    return (
        <Panel
            layer={0}
            brand={filled && !isMobile ? 'primary' : null}
            className={ClassNames(
                styles.searchIcon,
                position === 'start' ? styles.fillStart : styles.fillEnd
            )}
        >
            {shouldShowX ?

                <button
                    onClick={() => clearSearchTerm()}
                    type={'button'}
                    data-testid={'clearCurrentSearch'}
                    tabIndex={!isMobile ? -1 : null}
                    aria-label={'clearCurrentSearch'}
                >
                    <Svg icon={'close'} size={size} tone={tone} />
                </button>
                : null
            }

            <button
                type={'submit'}
                data-testid={dataTestingSearchSubmit}
                tabIndex={isMobile ? -1 : null}
                aria-label={'searchButton'}
            >
                <Svg icon={icon} size={size} tone={tone} />
            </button>
        </Panel>
    )
}

const StyledSearchBar = styled.input`
    color: hsla(0, 0%, 0%, 1);
    ::placeholder {
        color: ${(props) => props.placeholderColor};
    }
    ::-webkit-input-placeholder {
        color: ${(props) => props.placeholderColor};
    }
    :-ms-input-placeholder {
        color: ${(props) => props.placeholderColor};
    }
    ::-moz-placeholder {
        color: ${(props) => props.placeholderColor};
    }
    :-moz-placeholder {
        color: ${(props) => props.placeholderColor};
    }
`

export default SearchBar
