const isRequiredString = (input) => {
  return input && input.trim();
};

const isInEnum = (input, enumValues) => {
  if (!input && !Array.isArray(enumValues) && !enumValues.length > 0)
    return false;

  let enumRegexString = "^(";
  enumValues.forEach((enumValue, index) => {
    enumRegexString += `${enumValue}`;
    if (index !== enumValues.length - 1) enumRegexString += "|";
  });
  enumRegexString += ")$";

  const regex = new RegExp(enumRegexString);
  return regex.test(input);
};

const isRequiredNumber = (input) => {
  return typeof input === "number";
};

const isRequiredInteger = (input) => {
  return Number.isInteger(input);
};

export { isRequiredString, isInEnum, isRequiredNumber, isRequiredInteger };
