
import { useThemeStore } from 'Stores/ThemeStore'
import useMemoryCache from 'Hooks/useMemoryCache';

const LayerColorFinder = (
    layer,
    inverseTheme,
    brand,
    intent
) => {

    const themeContext = useThemeStore(x => x)

    const color = useMemoryCache('LayerColorFinder', () => {

        let hue, saturation, lightness, alpha;

        if (brand) {
            ({ hue, saturation, lightness, alpha } = themeContext.brand[brand].color);
            return `hsla(${hue}, ${saturation}%, ${lightness}%, ${alpha})`;
        }

        if (intent) {
            ({ hue, saturation, lightness, alpha } = themeContext.intent[intent].color);
            return `hsla(${hue}, ${saturation}%, ${lightness}%, ${alpha})`;
        }

        ({ hue, saturation, lightness, alpha } = inverseTheme ? themeContext.inverse_background_color : themeContext.background_color);

        // Gets a layer modifier based on the layer passed in
        const layerModifierValues = {
            0: 0,
            1: 10,
            2: 15,
            3: 20,
        }

        // Multiplies the intent modifier based on wether we want to add or subtract the intent modifier to the intent
        const shadeOrTintModifierValues = {
            'shade': -1,
            'tint': 1,
        }

        const layerModifierValue = layerModifierValues[layer] !== undefined ? layerModifierValues[layer] : 0
        const shadeOrTintMultiplier = inverseTheme ? shadeOrTintModifierValues[themeContext.inverse_background_modifier] : shadeOrTintModifierValues[themeContext.background_modifier]

        // Assign values based on wether the intent exists or not
        const intentModifier = layerModifierValue && shadeOrTintMultiplier ? layerModifierValue * shadeOrTintMultiplier : 0

        const modifiedLightness = parseInt(lightness) + parseInt(intentModifier);
        return `hsla(${hue}, ${saturation}%, ${modifiedLightness}%, ${alpha})`;

    }, [themeContext.name, layer, inverseTheme, brand, intent])

    return color
}

export default LayerColorFinder