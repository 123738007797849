import React, { useContext } from "react";

import Skeleton from "Atoms/Skeleton";
import TextLink from "Atoms/TextLink";
import Typography from "Atoms/Typography";
import PanelContext from "Contexts/PanelContext";
import useSegment from "Hooks/useSegment/useSegment";
import Button from "Molecules/Button";
import LayerColorFinder from "Utilities/LayerColorFinder";
import ClassNames from "classnames";
import Image from "next/image";

import { createArticleClickedSegmentEvent } from "../../Utilities/Instrumentation/Articles/articleSegmentEvents";
import { createClickedSegmentEvent } from "../../Utilities/Instrumentation/Impressions/impressionSegmentEvents";
import styles from "./ArticleCard.module.scss";

const ArticleCard = ({
  className,
  article,
  actionComponent,
  horizontal,
  useButton,
  dataTestId,
  border = true,
  useReducedArticleCard = false,
  index,
  isEvent = false,
}) => {
  const panelContext = useContext(PanelContext);

  const panelColor = LayerColorFinder(
    panelContext.layer,
    panelContext.inverseTheme
  );

  const { sendCustomSegmentTrackEvent } = useSegment();
  const segmentEvent = isEvent ? createClickedSegmentEvent(`ticket: ${article.eventId}`) : createArticleClickedSegmentEvent(article, index);

  let image = article?.articleThumbnailUrl ? (
    <div
      className={ClassNames(
        styles.imageContainer,
        horizontal
          ? styles.horizontalImageContainer
          : styles.verticalImageContainer
      )}
    >
      <Image
        src={article.articleThumbnailUrl}
        fill
        layout="fill"
        className={horizontal ? styles.horizontalImage : styles.verticalImage}
        alt={article?.title ?? "Article Image"}
        sizes="(max-width: 640px) 100vw,
                (max-width: 1024px) 33vw,
                25vw"
      />
    </div>
  ) : null;

  let descriptionClassNames = useReducedArticleCard
    ? styles.reducedDescription
    : ClassNames(
      className,
      styles.description,
      horizontal ? styles.horizontalDescription : styles.verticalDescription
    );
  let description = article?.description ? (
    <div className={descriptionClassNames}>
      <div className={styles.descriptionWrapper}>
        {!useButton || horizontal ? (
          <div className={styles.readMoreWrapper}>
            <div
              className={styles.gradientTransition}
              style={{ "--c": panelColor }}
            ></div>
            <TextLink
              as={"div"}
              onClick={() => {
                sendCustomSegmentTrackEvent(segmentEvent);
              }}
            >
              <Typography
                size={horizontal ? 0.625 : 0.75}
                intent={"action"}
                className={styles.readMore}
                data-testid={"readMoreLink"}
                style={{ "--c": panelColor }}
              >
                {isEvent ? "Buy Tickets" : "Read More"}
              </Typography>
            </TextLink>
          </div>
        ) : (
          false
        )}
        <Typography size={!horizontal ? 0.75 : 0.625}>
          {article.description}
        </Typography>
      </div>
    </div>
  ) : null;

  let titleClassNames = ClassNames(
    styles.title,
    horizontal ? styles.horizontalTitle : styles.verticalTitle
  );

  let title = article?.title ? (
    <Typography size={0.875} tone={"contrast"} className={titleClassNames}>
      {article.title}
    </Typography>
  ) : null;

  return (
    <div
      data-testid={isEvent ? `${dataTestId}_myracepassevent_${article?.title}` : `${dataTestId}_article_${article?.id}`}
      className={ClassNames(
        styles.articleWrapper,
        horizontal ? styles.horizontalWrapper : styles.verticalWrapper,
        border ? styles.border : null
      )}
    >
      <TextLink
        as={"div"}
        onClick={() => {
          sendCustomSegmentTrackEvent(segmentEvent);
        }}
        className={styles.clickableArea}
      >
        <a href={article?.url} style={{ textDecoration: "none" }}>
          <div className={horizontal ? styles.horizontalContentWrapper : ""}>
            <Skeleton
              className={ClassNames(
                styles.imageContainer,
                horizontal ? styles.horizontalImage : styles.verticalImage,
                horizontal
                  ? styles.horizontalImageContainer
                  : styles.verticalImageContainer
              )}
              data={image}
              fullWidth
              height={1}
            />
            <div className={styles.textContent}>
              <Skeleton
                className={titleClassNames}
                data={title}
                fullWidth
                height={horizontal ? 21 : 24}
              />
              {!horizontal ? (
                false
              ) : article && !isEvent ? (
                <Typography tone={'subtle'} size={0.625}>{"by " + article?.authors}</Typography>
              ) : (
                <div style={{ height: "11px" }}></div>
              )}
              <Skeleton
                className={descriptionClassNames}
                data={description}
                fullWidth
                height={1}
              />
              {useButton && !horizontal ? (
                <Button
                  text={isEvent ? "Buy Tickets" : "Read More"}
                  intent={"action"}
                  size={"small"}
                  fill
                  className={styles.readMoreButton}
                  segmentEvent={createClickedSegmentEvent('ArticleCardAction')}
                />
              ) : (
                false
              )}
            </div>
          </div>
        </a>
      </TextLink>
      {horizontal || useButton || !actionComponent ? (
        false
      ) : (
        <div className={styles.actionComponent}>{actionComponent}</div>
      )}
    </div>
  );
};

export default ArticleCard;
