import { useEffect } from "react"
import { StorageAccessor } from "../../Utilities/LocalStorageUtility"


const FunnelTimer = ({ urls, funnelName }) => {

    const isInFunnel = () => {
        return urls.map(x => x.toLowerCase()).some(x => window.location.href.toLowerCase().includes(x))
    }

    const CACHEKEY = `funnelTimer_${funnelName}`

    const handleInFunnel = (currentEvent) => {
        if (currentEvent) {
            return;
        }
        const newEvent = { ...timerEvent }
        newEvent.startTime = new Date();
        newEvent.element = funnelName;
        StorageAccessor.sessionStorage.setObj(CACHEKEY, newEvent);
    }

    const handleOutOfFunnel = (currentEvent) => {
        if (!currentEvent) {
            return;
        }
        currentEvent.endTime = new Date();
        currentEvent.startTime = new Date(currentEvent.startTime);
        currentEvent.ellapsedMilli = currentEvent.endTime - currentEvent.startTime
        currentEvent.ellapsedSeconds = Math.round(currentEvent.ellapsedMilli / 1000)

        analytics.track("Timer", currentEvent);

        StorageAccessor.sessionStorage.removeItem(CACHEKEY);
    }

    const handleFunnel = async () => {
        try {
            const currentEvent = StorageAccessor.sessionStorage.getObj(CACHEKEY)
            if (isInFunnel(currentEvent)) {
                handleInFunnel(currentEvent);
                return;
            }
            handleOutOfFunnel(currentEvent);
        } catch (e) {
            console.error("unexpected error handling funnel", e)
        }

    }



    useEffect(() => {
        handleFunnel();
    }, [])

    return null;
}

export default FunnelTimer

const timerEvent = {
    element: null,
    startTime: null,
    endTime: null,
    ellapsedMilli: null,
    ellapsedSeconds: null
}