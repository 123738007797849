import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './CarouselItem.module.scss'

const propTypes = {
    className: PropTypes.string,
    active: PropTypes.bool,
    children: PropTypes.node,
};

const CarouselItem = ({
    className = '', 
    active = false, 
    children = null, 
    ...props
}) => {
    const prefix = styles.carouselItem;

    const classes = ClassNames(
        prefix,
        active ? (prefix + '-active') : undefined,
        className,
    );
    return (
        <div className={classes} {...props}>
            {children}
        </div>
    );
}


CarouselItem.displayName = 'CarouselItem';
CarouselItem.propTypes = propTypes;

export default CarouselItem;
