import React from "react";

import Divider from "Atoms/Divider";
import Panel from "Atoms/Panel";
import Svg from "Atoms/Svg";
import Typography from "Atoms/Typography";
import LayerColorFinder from "Utilities/LayerColorFinder";

import styles from "./DrawerHeader.module.scss";

const DrawerHeader = ({
  title,
  onClose,
  testId,
  svgProps,
  showBackButton,
  onBackButtonPressed,
}) => {
  return (
    <Panel
      style={{
        "--borderColor": LayerColorFinder(3),
      }}
      className={styles.container}
      layer={0}
    >
      {showBackButton && (
        <div
          className={styles.backButton}
          data-testid={testId ? `drawerBackButton_${testId}` : "drawerBackButton"}
        >
          <Svg
            size={1.25}
            icon={"backarrow"}
            font={"bold"}
            onClick={onBackButtonPressed}
          />
        </div>
      )}
      <Typography
        size={1.25}
        font={"bold"}
        data-testid={testId ? `drawerTitle_${testId}` : "drawerTitle"}
      >
        {svgProps ? (
          <Svg
            className={styles.svg}
            size={1}
            icon={svgProps.icon}
            intent={svgProps.intent}
            brand={svgProps.brand}
            tone={svgProps.tone}
          ></Svg>
        ) : null}
        {title}
      </Typography>
      {!showBackButton && (
        <div className={styles.closeIcon} onClick={onClose}>
          <Svg
            size={1.25}
            font={"bold"}
            icon={"xmark-large"}
            data-testid={
              testId ? `drawerCloseButton_${testId}` : "drawerCloseButton"
            }
          />
        </div>
      )}
      <Divider className={styles.divider} />
    </Panel>
  );
};

export default DrawerHeader;
