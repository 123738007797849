import React, { useContext, useEffect, useState } from "react";

import { NavigationContext } from "Contexts/Navigation/NavigationContext";
import defer from "Utilities/defer";
import styled from "styled-components";

import useFeatureFlag from "../../../Hooks/useFeatureFlag/useFeatureFlag";
import SecondaryNavigation from "./Navigation/Common/SecondaryNavigation";
import { getMainMenu } from "./Navigation/Main/MainMenu";
import PrimaryNavigation from "./Navigation/Main/PrimaryNavigation";
import FilterableNavigation from "./Navigation/ShopParts/FilterableNavigation";
import {
  getShopByBrandSubMenu,
  getShopByCategoriesSubMenu,
  getShopByEngineSubMenu,
  getShopByMakeSubMenu,
  getShopByPlatformSubMenu,
  getShopByRearAxleSubMenu,
  getShopByServicesSubMenu,
  getShopByTransmissionSubMenu,
  getShopPartsMainMenu,
} from "./Navigation/ShopParts/ShopPartsMenu";
import FeaturedArticleNavigation from "./Navigation/Toolbox/FeaturedArticleNavigation";
import RecommendedArticleNavigation from "./Navigation/Toolbox/RecommendedArticleNavigation";
import {
  getBuildMenu,
  getNewsMenu,
  getPodcastMenu,
  getRecommendedArticlesMenu,
  getTechMenu,
  getToolBoxMainMenu,
  getVideosMenu,
} from "./Navigation/Toolbox/ToolboxMenu";
import ToolboxMenuItemNavigation from "./Navigation/Toolbox/ToolboxMenuItemNavigation";

const HeaderDrawerTransitionSpeed = 200; //This is how long it takes for a drawer to slide out/in in ms

const menuLookup = {
    mainMenu: getMainMenu,
    shopParts: getShopPartsMainMenu,
    shopByServices: getShopByServicesSubMenu,
    shopByCategory: getShopByCategoriesSubMenu,
    shopByMake: getShopByMakeSubMenu,
    shopByPlatform: getShopByPlatformSubMenu,
    shopByBrand: getShopByBrandSubMenu,
    shopByEngine: getShopByEngineSubMenu,
    shopByTransmission: getShopByTransmissionSubMenu,
    shopByRearAxle: getShopByRearAxleSubMenu,
    toolboxMainMenu: getToolBoxMainMenu,
    toolboxTech: getTechMenu,
    toolboxBuilds: getBuildMenu,
    toolboxVideos: getVideosMenu,
    toolboxRecommendedArticles: getRecommendedArticlesMenu,
    toolboxPodcasts: getPodcastMenu,
    toolboxNews: getNewsMenu
}

const componentLookup = {
    PrimaryNavigation,
    SecondaryNavigation,
    FilterableNavigation,
    FeaturedArticleNavigation,
    RecommendedArticleNavigation,
    ToolboxMenuItemNavigation
}

const HeaderDrawerManager = ({ services, url, isDarkModeEnabled }) => {
    const { dispatch: navigationDispatch, state: navigationState } = useContext(NavigationContext)
    let navigation = [...navigationState.drawer.navigation, ...(navigationState.drawer.isDrawerOpen ? [] : navigationState.drawer.prerenderedNavigation)]
    const [shift, setShift] = useState(0)
    const [navTransitionSpeed, setNavTransitionSpeed] = useState(HeaderDrawerTransitionSpeed)
    const [hasDrawerBeenOpened, setHasDrawerBeedOpened] = useState(false)


    const closeFunction = () => {
        setShift(shift + 100)
        setNavTransitionSpeed(HeaderDrawerTransitionSpeed)
        defer(() => {
            navigationDispatch({
                type: 'setDrawerState',
                payload: {
                    drawerState: {
                        isDrawerOpen: true
                    },
                    drawerAction: {
                        type: 'dropNavigation'
                    }
                }
            })
        }, navTransitionSpeed)
    }

    useEffect(() => {
        if (navigation.length === 1) {
            setShift(0)
        }

        if (!hasDrawerBeenOpened) {
            setNavTransitionSpeed(0) //On the first opening of the header drawer, we want the content transition to be instant incase we open to a deeper level initially (ex. shop parts)
            setHasDrawerBeedOpened(true)
        }
        else {
            setNavTransitionSpeed(HeaderDrawerTransitionSpeed)
        }
    }, [navigation])


    const flags = {
        isDarkModeEnabled: isDarkModeEnabled,
        isPlatformDisabled: !useFeatureFlag("Platform_Enabled"),
        linkToSitemapPages: useFeatureFlag("Header_Drawer_Link_To_Sitemap_Pages"),
        isPwaEnabled: useFeatureFlag("Frontend_PWA"),
        isEngineFitmentSeoEnabled: useFeatureFlag("Engine_Fitment_Seo"),
        isCustomerProjectDrawerEnabled: useFeatureFlag("FrontEnd_CustomerProject_Drawer_Enabled")
    }

    return (
        <StyledNavTransitionWrapper shift={shift} transitionSpeed={navTransitionSpeed}>
            {navigation.map((nav, index) => {
                const menuInfo = menuLookup[nav.menu](flags)

                const Navigation = componentLookup[nav.component]
                nav.props = {
                    ...nav.props,
                    darkControlBarEnabled: isDarkModeEnabled
                }

                let navComponent = <Navigation
                    {...nav.props}
                    menu={menuInfo}
                    services={services}
                    url={url}
                    closeFunction={closeFunction}
                />


                return (
                    <StyledNavWrapper key={index}>

                        <NavWrapper navComponent={navComponent} componentType={nav.component} shift={shift} setShift={setShift} />
                    </StyledNavWrapper>
                )
            })}
        </StyledNavTransitionWrapper>
    )
}

//This is a wrapper that allows us to use a useEffect to adjust the shift percentage for our nav menu transitions
const NavWrapper = ({ navComponent, componentType, shift, setShift }) => {

    useEffect(() => {
        if (componentType !== 'PrimaryNavigation') {
            setShift(shift - 100)
        }
    }, [])

    return (
        <>
            {navComponent}
        </>
    )
}

const StyledNavWrapper = styled.div`
    display: inline-block;
    vertical-align: top;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    ::-webkit-scrollbar {
        display: none;
    }
`
const StyledNavTransitionWrapper = styled.div`
     background-color: ${props => props.background_color};
     left: 0;
     width: 100%;
     height: 100%;
     white-space: nowrap;

     transition: transform ${props => props.transitionSpeed}ms ease;

     transform: translateX(${props => props.shift}%);
    `

export default HeaderDrawerManager
