import React from 'react'
import Script from 'next/script'

const MisoBeacon = ({misoApiUrl}) => {

    /**
     * todo: Add this back in to pushMisoInteractionData when we have internal customer data
        if (@Model.IsCustomerInternal.ToString().ToLower()) {
            console.log('Miso interaction not sent because customer is internal')
            return
        }
    */

    const misoBeaconScript = 
        `var onExitInterActionDataToSend = [];
        var timeOnPageStart = '${new Date().toISOString()}';
        
        window.sendOnExitInteractionData = () => {
            if (onExitInterActionDataToSend && onExitInterActionDataToSend.length > 0) {
                var timeOnPageEnd = '${new Date().toISOString()}';
                for (var i = 0, ilen = onExitInterActionDataToSend.length; i < ilen; i++) {
                    onExitInterActionDataToSend[i].duration = (timeOnPageEnd - timeOnPageStart) / 1000;
                }
                var interactionData = { data: onExitInterActionDataToSend };
                pushMisoInteractionData(interactionData);
                onExitInterActionDataToSend = [];
            }
        }
        
        
        window.pushMisoInteractionData = (interactionData) => {
            if (interactionData.data[0].type) {
                try {
                    if(interactionData.data[0].type === 'checkout') {
                        return
                    }
                    navigator.sendBeacon('${misoApiUrl}', JSON.stringify(interactionData));
                } catch(err) {
                    console.error(err)
                }
            }
        }`

    return (
        <Script
            id={'miso-beacon'}
            data-testid={'miso-beacon'}
            strategy={'beforeInteractive'}
            enableOnlyOnNextFrontEnd={true}
        >
            {misoBeaconScript}
        </Script>
    )
}

export default MisoBeacon