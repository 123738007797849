import React, { createContext, useContext, useEffect, useState } from "react";

import { NavigationContext } from "Contexts/Navigation/NavigationContext";
import useSegment from "Hooks/useSegment";
import defer from "Utilities/defer";

import { getDeviceInfo, isInApp } from "../../Hooks/useAppInstall/AppUtilities";
import {
  handleRoutinePushSubscriptionUpdate,
  handleSubscription,
} from "../../Hooks/useAppInstall/NotificationUtilities";
import {
  setIsEngagedSessionFired,
  trackEngagedSession,
} from "../../Utilities/EngagedSessionStorageUtility/EngagedSessionStorageUtility";
import {
  createClickedSegmentEvent,
  createViewedSegmentEvent,
} from "../../Utilities/Instrumentation/Impressions/impressionSegmentEvents";

const initialState = {
  googleAnalyticsReady: false,
  segmentReady: false,
  ga4SessionData: null,
  appInstallPrompt: null,
  ga4ContentGroup: null,
  misoId: null,
  pageAttribution: null
};

const TrackingContext = createContext(initialState);

const TrackingProvider = ({ children }) => {
  const { state: navigationState, dispatch: navigationDispatch } =
    useContext(NavigationContext);
  const [context, setContext] = useState(initialState);
  const { sendCustomSegmentTrackEvent } = useSegment();
  const [eventQueue, updateEventQueue] = useState([]);
  const [virtualPageEventQueue, updateVirtualPageEventQueue] = useState([]);

  //#region google analytics
  const setGoogleAnalyticsReady = () => {
    setContext((state) => {
      return {
        ...state,
        googleAnalyticsReady: true,
      };
    });
  };
  //#endregion

  const setSegmentReady = () => {
    setContext((state) => {
      return {
        ...state,
        segmentReady: true,
      };
    });
  };

  const setGa4SessionData = (ga4SessionData) => {
    setContext((state) => {
      return {
        ...state,
        ga4SessionData,
      };
    });
  };

  const setGa4ContentGroup = (ga4ContentGroup) => {
    setContext((state) => {
      return {
        ...state,
        ga4ContentGroup,
      };
    });
  };

  const setMisoId = (misoId) => {
    setContext((state) => {
      return {
        ...state,
        misoId,
      };
    });
  };

  const setPageAttribution = (pageAttribution) => {
    setContext((state) => {
      return {
        ...state,
        ...pageAttribution
      }
    })
  }

  const [isNavigating, setIsNavigating] = useState(false);

  const toggleNavigatingState = (forceState = null) => {
    if (!isInApp()) {
      return;
    }
    defer(() => {
      if (forceState !== null) {
        setIsNavigating(!!forceState);
        return;
      }
      setIsNavigating(!isNavigating);
    });
  };

  const fireIsInAppEvent = () => {
    if (isInApp()) {
      try {
        const launchText = `PWA in use`;
        const event = createViewedSegmentEvent(launchText);

        trackEngagedSession();

        analytics.track(event.event, event.properties);
        console.log(launchText);

        setIsEngagedSessionFired();
      } catch (err) {
        console.error(`error logging is in app event`, err);
      }
    }
  };

  const openPushOptInt = () => {
    navigationDispatch({
      type: "setPushNotePermissionPromptState",
      payload: {
        pushNotePermissionPromptState: {
          isOpen: true,
        },
      },
    });
  };

  const setupServiceWorker = async () => {
    try {
      if (!navigator.serviceWorker) {
        console.warn("Service workers are not supported in this browser.");
        return;
      }
      await navigator.serviceWorker.register(`/service-worker.js`);
      await handleSubscription(openPushOptInt);
    } catch (err) {
      console.error("Service worker setup failed", err);
    }
  };

  const prefix = `Native app install prompt`;
  const deviceDetails = getDeviceInfo();
  useEffect(() => {
    toggleNavigatingState(false);

    const beforeInstallPrompt = (e) => {
      e.preventDefault();

      if (deviceDetails.isDesktop) {
        return;
      }

      setContext((state) => {
        return {
          ...state,
          appInstallPrompt: e,
        };
      });

      e.userChoice.then((choiceResult) => {
        const message = `${prefix} ${choiceResult.outcome}`;
        const event = createClickedSegmentEvent(message);
        analytics.track(event.event, event.properties);
        console.log(message);
        setContext((state) => {
          return {
            ...state,
            appInstallPrompt: false,
          };
        });
      });
    };

    if (
      (!context.appInstallPrompt &&
        !isInApp() &&
        deviceDetails.isAppleDevice) ||
      deviceDetails.isDesktop
    ) {
      const iOSInstallPrompt = () => {
        navigationDispatch({
          type: "setIosInstallPromptState",
          payload: {
            iosInstallpromptState: {
              isOpen: true,
            },
          },
        });
      };
      setContext((state) => {
        return {
          ...state,
          appInstallPrompt: {
            prompt: iOSInstallPrompt,
          },
        };
      });
    }

    const hideNavigatingState = () => {
      toggleNavigatingState(false);
    };
    const showNavigatingState = () => {
      toggleNavigatingState(true);
    };

    setupServiceWorker();
    fireIsInAppEvent();

    window.addEventListener("beforeinstallprompt", beforeInstallPrompt);
    window.addEventListener("pageshow", hideNavigatingState);

    if (typeof navigation !== "undefined") {
      //only works on chrome
      navigation.onnavigate = (e) => {
        if (e.navigationType == "push") {
          showNavigatingState();
        }
      };
    }

    return () => {
      window.removeEventListener("beforeinstallprompt", beforeInstallPrompt);
      window.removeEventListener("pageshow", hideNavigatingState);
    };
  }, []);

  const promptAppInstall = () => {
    const eventElement = `${prefix} engaged`;
    const event = createClickedSegmentEvent(eventElement);
    analytics.track(event.event, event.properties);
    console.log(eventElement);
    context.appInstallPrompt?.prompt();
  };

  return (
    <TrackingContext.Provider
      value={{
        ...context,
        setGoogleAnalyticsReady,
        setSegmentReady,
        setGa4SessionData,
        setGa4ContentGroup,
        promptAppInstall,
        toggleNavigatingState,
        setMisoId,
        setPageAttribution,
        isNavigating,
      }}
    >
      <>{children}</>
    </TrackingContext.Provider>
  );
};

export { TrackingProvider, TrackingContext };
