import createZustandContext from "Utilities/Zustand/createZustandContext";
import UserSpecificProductDataStoreWiring from "./Wiring/UserSpecificProductDataStoreWiring";
import getFitmentDetailsFromSelectedFacets from "Utilities/CustomerProjectUtilities/getFitmentDetailsFromSelectedFacets";

const productCardContext = createZustandContext(
  ({ set, get, api, initialProps }) => {
    return {
      // the various sku bases that we're listening to in order to get data for
      trackedSkuBases: [],
      addTrackedSkuBases: (skuBases) => {
        set((state) => {
          const uniqueSkuBases = new Set([...state.trackedSkuBases, ...skuBases]);
          if(uniqueSkuBases.length == state.trackedSkuBases.length){
            return state // no change, we've got duplicate calls
          }else{
            return { trackedSkuBases: [...uniqueSkuBases] }
          }
        });
      },

      // useful for where we want to delay updates for some period of time
      // for example, it's wasteful to update products when a customer is selecting facets on mobile
      // so we disable updates for a period of time
      areUpdatesAllowed: true,
      setAreUpdatesAllowed: (areAllowed) => set({ areUpdatesAllowed: areAllowed }),

      // todo pricing data! (this is ALL over the card, so it's harder to decouple/rerender only a portion)
      isSkuBaseInCartDictionary: {},
      setCartDataForSkuBases: (skuBaseInCartDictionary) => {
        set({ isSkuBaseInCartDictionary: skuBaseInCartDictionary });
      },

      isSkuBaseInAnyListDictionary: {},
      setListDataForSkuBases: (skuBaseInListDictionary) => {
        set({ isSkuBaseInAnyListDictionary: skuBaseInListDictionary });
      },

      fitmentDataBySkuBase: {},
      addFitmentDataForSkuBase: (skuBase, fitmentData) => {
        set((state) => ({
          fitmentDataBySkuBase: {
            ...state.fitmentDataBySkuBase,
            [skuBase]: fitmentData,
          }
        }));
      },

      shippingDataBySkuBase: {},
      addShippingDataForSkuBase: (skuBase, shippingData) => {
        set((state) => ({
          shippingDataBySkuBase: {
            ...state.shippingDataBySkuBase,
            [skuBase]: shippingData,
          }
        }));
      },
      promoDataBySkuBase: {},
      addPromoDataForSkuBase: (skuBase, promo) => {
        set((state) => ({
          promoDataBySkuBase: {
            ...state.promoDataBySkuBase,
            [skuBase]: promo,
          }
        }));
      },

      flush: () => {
        // if you're navigating, we need to clear this out so it doesn't cause a memory leak
        set({
          trackedSkuBases: [],
          fitmentDataBySkuBase: {},
          shippingDataBySkuBase: {},
          promoDataBySkuBase: {},
        });
      },

      fitmentDetailsFromFitmentFacet: {},
      setFitmentDetailsFromFitmentFacet: (fitmentFacet) => {
        const fitmentDetails = fitmentFacet?.fitmentDetails;
        set(() => ({
          fitmentDetailsFromFitmentFacet: fitmentDetails
        }));
      }, 

      fitmentDetailsFromSelectedFacets: {},
      setFitmentDetailsFromSelectedFacets: (selectedFacets) => {
        const fitmentDetails = getFitmentDetailsFromSelectedFacets(selectedFacets)
        
        set(() => ({
          fitmentDetailsFromSelectedFacets: fitmentDetails
        }))
      }

    };
  },
  {
    name: "UserSpecificProductDataStore",
  }
);


// synchronize the carts, lists, and more!
productCardContext.Wiring = UserSpecificProductDataStoreWiring

export const UserSpecificProductDataProvider = productCardContext.Provider;

export const useUserSpecificProductDataStore = productCardContext.useStore;

export const useSkuBaseFitmentData = (skuBase) =>
  useUserSpecificProductDataStore(
    (state) => state.fitmentDataBySkuBase[skuBase]
  );

export const useSkuBaseShippingData = (skuBase) =>
  useUserSpecificProductDataStore(
    (state) => state.shippingDataBySkuBase[skuBase]
  );

  export const useSkuBasePromoData = (skuBase) =>
    useUserSpecificProductDataStore(
      (state) => state.promoDataBySkuBase[skuBase]
    );

export const useIsSkuBaseInAnyList = (skuBase) =>
  useUserSpecificProductDataStore(
    (state) => state.isSkuBaseInAnyListDictionary[skuBase] || false
  );

export const useIsSkuBaseInCart = (skuBase) =>
  useUserSpecificProductDataStore(
    (state) => state.isSkuBaseInCartDictionary[skuBase] || false
  );

