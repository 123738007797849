import React from "react";
import styles from "./RecommendationsVertical.module.scss";

import Expander from "Organisms/Expander/Expander";
import RecommendationsSkeleton from "Molecules/RecommendationsSkeleton";
import RecommendationCard from "Molecules/RecommendationCard";
import Typography from "Atoms/Typography";
import ErrorBoundary from "UtilityComponents/ErrorBoundary";
import Divider from "Atoms/Divider";
import { useLazyFeatureFlag } from 'Hooks/useFeatureFlag/useFeatureFlag'
import AppName from "AppName";
import AddToCartRecommendations from "../../Layout/drawers/recommendations/addToCartRecommendations";

const RecommendationsVertical = ({
  recBlock,
  dataTestId,
  isExpandable,
  isLoading,
  numberOfSkeletons,
  numberOfSkeletonSections,
  blockIndex,
  useDivider,
  useSubtitle,
  useItemCount,
  treatment,
  showAddToCart
}) => {
  return (
    <ErrorBoundary>
      {recBlock?.isExpandable || isExpandable ? (
        <Expander
          count={recBlock.recommendations?.length}
          closedHeight={"300px"}
          buttonProps={{ invert: true }}
          gradient={false}
          testid={`${dataTestId}_vertical_${blockIndex}_expander`}
        >
          <RecommendationsVerticalBase
            recBlock={recBlock}
            dataTestId={dataTestId}
            blockIndex={blockIndex}
            isLoading={isLoading}
            numberOfSkeletons={numberOfSkeletons}
            numberOfSkeletonSections={numberOfSkeletonSections}
            useDivider={useDivider}
            useSubtitle={useSubtitle}
            useItemCount={useItemCount}
            treatment={treatment}
            showAddToCart={showAddToCart}
          />
        </Expander>
      ) : (
        <RecommendationsVerticalBase
          recBlock={recBlock}
          dataTestId={dataTestId}
          blockIndex={blockIndex}
          isLoading={isLoading}
          numberOfSkeletons={numberOfSkeletons}
          numberOfSkeletonSections={numberOfSkeletonSections}
          useDivider={useDivider}
          useSubtitle={useSubtitle}
          useItemCount={useItemCount}
          treatment={treatment}
          showAddToCart={showAddToCart}
        />
      )}
    </ErrorBoundary>
  );
};

const RecommendationsVerticalBase = ({
  recBlock,
  dataTestId,
  blockIndex,
  isLoading,
  numberOfSkeletons,
  numberOfSkeletonSections,
  useDivider,
  useSubtitle,
  useItemCount,
  treatment,
  showAddToCart
}) => {

  const calculateShouldShowAddRecommendationsToCart = (recBlock) => {
    var allRecommendations = recBlock.recommendations
    if (allRecommendations.some((x) =>
      x.recommendationType !== 'Mandatory'
      || !x.publicSkuBaseDetails?.hasNoKitBaseComponents
    )) {
      return false
    }

    return showAddToCart && (recBlock.isMock || useLazyFeatureFlag("Show_Add_Mandatory_Recommendations_To_Cart"))
  }

  var shouldShowAddRecommendationsToCart = calculateShouldShowAddRecommendationsToCart(recBlock)

  const shouldShowTitle =
    !isLoading &&
    recBlock?.recommendations &&
    recBlock?.recommendations?.length > 0;
  return (
    <div data-testid={`${dataTestId}_vertical_recs_${blockIndex}`}>
      {isLoading ? (
        <RecommendationsSkeleton
          dataTestId={dataTestId}
          displayType={"Vertical"}
          numberOfSkeletons={numberOfSkeletons}
          numberOfSkeletonSections={numberOfSkeletonSections}
          blockIndex={blockIndex}
          useDivider={useDivider}
          useSubtitle={useSubtitle}
          useItemCount={useItemCount}
        />
      ) : (
        <>
          {shouldShowTitle && (
            <Typography
              as={"h2"}
              size={1.25}
              font={"heading"}
              className={styles.title}
            >
              {recBlock.title}
            </Typography>
          )}
          {useSubtitle ? (
            <Typography size={0.875} font={"regular"} className={styles.title}>
              {" "}
              {recBlock.subtitle}{" "}
            </Typography>
          ) : (
            false
          )}
          {useItemCount ? (
            <Typography size={0.875} font={"regular"} className={styles.title}>
              {recBlock.recommendations?.length != 1
                ? `${recBlock.recommendations?.length} items`
                : "1 item"}
            </Typography>
          ) : (
            false
          )}
          {useDivider ? (
            <Divider className={styles.divider} />
          ) : (
            <div className={styles.noDivider} />
          )}
          {
            shouldShowAddRecommendationsToCart ?
              <AddToCartRecommendations recBlock={recBlock} dataTestId={dataTestId} treatment={treatment} blockIndex={blockIndex} />
              :
              <div className={styles.recWrapper}>
                
                {recBlock.recommendations?.map((rec, index) => {
                  return (
                    <div
                      className={styles.rec}
                      data-testid={`${dataTestId}_vertical_${blockIndex}_rec_${index}`}
                      key={`vertical_rec_key_${index}`}
                    >
                      <RecommendationCard
                        recBlock={recBlock}
                        rec={rec}
                        index={index}
                        dataTestId={dataTestId}
                        treatment={treatment}
                        horizontal
                      />
                    </div>
                  );
                })}
              </div>
          }
        </>
      )}
    </div>
  );
};

export default RecommendationsVertical;
