import AppName from "AppName"

const SuperMarketPageRoutes = [
    '/street',
    '/race',
    '/truck'
]

const PageTypes = {  // we can add more page types if the AppName isn't specific enough. A good example is SuperMarketPages. They are in the Homepage MFE, but we'd like to have specific metrics for them.
    SuperMarket: 'SuperMarketPage'
}

const getCurrentPageTypeFromUrl = () => {
    try{
        if(SuperMarketPageRoutes.some(route => window.location.pathname == route)){
            return PageTypes.SuperMarket
        }

        return AppName
    }
    catch(err){
        console.error(err)
        return AppName
    }
}

export {getCurrentPageTypeFromUrl}