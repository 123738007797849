import React from 'react'
import MisoBeacon from './Beacon'

import MisoInteraction from './MisoInteraction'
import MisoPageView from './MisoPageView'
import MisoSearchImpression from './MisoSearchImpression'

const Miso = () => {
    var misoApiUrl = process.env.NEXT_PUBLIC_MISO_API_URL

    return (
        <>
            <MisoBeacon misoApiUrl={misoApiUrl} />
            <MisoInteraction misoApiUrl={misoApiUrl} />
            <MisoPageView misoApiUrl={misoApiUrl} />
            <MisoSearchImpression misoApiUrl={misoApiUrl} />
        </>
    )
}

export default Miso 