import React, { useContext, useState } from "react";

import Divider from "Atoms/Divider";
import TextLink from "Atoms/TextLink";
import Typography from "Atoms/Typography";
import { NavigationContext } from "Contexts/Navigation/NavigationContext";
import useFeatureFlag from "Hooks/useFeatureFlag/useFeatureFlag";
import Button from "Molecules/Button";
import List from "Molecules/List";
import { useVehicleStore } from "Stores/VehicleStore";
import ClassNames from "classnames";

import { GetUserGuid } from "../../../../Utilities/CookieUtilties/UserCookieUtilities";
import YourVehicles from "../YourVehicles";
import garageStyles from "./GarageDropdown.module.scss";


const GarageDropdown = ({ services, ...props }) => {
  try {
    const [showAllVehicles, setShowAllVehicles] = useState(false);
    const [showAllRaceTypes, setShowAllRaceTypes] = useState(false);
    const [showAllEngines, setShowAllEngines] = useState(false);
    const customerProjects = useVehicleStore((x) => x.context.customerProjects);
    const setCurrentTab = useVehicleStore((x) => x.setCurrentTab);
    const userGuid = GetUserGuid();

    const {
      setYmmModal: setModal,
      dispatch: navigationDispatch,
      state: navigationState,
    } = useContext(NavigationContext);
    const impressionDontDelete = useFeatureFlag("Engine_Fitment_Master_Flag")
    const frontendEngineFitmentUX = useFeatureFlag(
      "FrontEnd_Engine_Fitment_UX"
    );

    const vehicles = customerProjects?.filter(
      (customerProject) => customerProject?.vehicleBaseId
    );
    const raceTypes = customerProjects?.filter(
      (customerProject) => customerProject?.raceTypeId
    );
    const engines = customerProjects?.filter(
      (customerProject) =>
        !customerProject?.vehicleBaseId &&
        !customerProject?.raceTypeId &&
        customerProject?.engineDisplayName
    );

    const totalVehicles = vehicles ? vehicles.length : 0;
    const totalRaceTypes = raceTypes ? raceTypes.length : 0;
    const totalEngines = engines ? engines.length : 0;
    const totalAll = totalVehicles + totalRaceTypes + totalEngines;

    return (
      <div
        className={ClassNames(
          garageStyles.garageContainer,
          totalAll > 0 ? garageStyles.garageContainerWithVehicles : false
        )}
      >
        <GarageDropdownHeading
          type={"Vehicle"}
          total={totalVehicles}
          haveClearSelection={true}
        />
        <YourVehicles
          userGuid={userGuid}
          services={services}
          showAllState={showAllVehicles}
          customerProjects={vehicles}
        />
        {totalVehicles > 0 && (
          <List itemLayout="horizontal" className={garageStyles.dropdownLinks}>
            <ShowAll
              showAllState={showAllVehicles}
              setShowAll={setShowAllVehicles}
              customerProjects={vehicles}
              type={"Vehicle"}
            />
          </List>
        )}
        <GarageDropdownHeading type={"Race Car"} total={totalRaceTypes} />
        <YourVehicles
          userGuid={userGuid}
          services={services}
          showAllState={showAllRaceTypes}
          customerProjects={raceTypes}
        />
        {totalRaceTypes > 0 && (
          <List itemLayout="horizontal" className={garageStyles.dropdownLinks}>
            <ShowAll
              showAllState={showAllRaceTypes}
              setShowAll={setShowAllRaceTypes}
              customerProjects={raceTypes}
              type={"Race Car"}
            />
          </List>
        )}
        {frontendEngineFitmentUX && (
          <>
            <GarageDropdownHeading type={"Engine"} total={totalEngines} />
            <YourVehicles
              userGuid={userGuid}
              services={services}
              showAllState={showAllEngines}
              customerProjects={engines}
            />
            {totalEngines > 0 && (
              <List
                itemLayout="horizontal"
                className={garageStyles.dropdownLinks}
              >
                <ShowAll
                  showAllState={showAllEngines}
                  setShowAll={setShowAllEngines}
                  customerProjects={engines}
                  type={"Engine"}
                />
              </List>
            )}
          </>
        )}
        <Button
          icon={"garage"}
          intent={"action"}
          size={"small"}
          onClick={(event) => {
            event.stopPropagation();
            setModal(true);
            setCurrentTab("street");
          }}
          segmentEvent={{
            event: "Clicked",
            properties: { element: `Header Garage Dropdown: Add a Vehicle` },
          }}
          testingName={"Add a Vehicle"}
          type={"button"}
          text={"Add a Vehicle"}
          className={garageStyles.addButton}
          fill
        />

        <Button
          icon={"race"}
          intent={"action"}
          size={"small"}
          onClick={(event) => {
            event.stopPropagation();
            setModal(true);
            setCurrentTab("race");
          }}
          segmentEvent={{
            event: "Clicked",
            properties: { element: `Header Garage Dropdown: Add a Race Car` },
          }}
          testingName={"Add a Race Car"}
          type={"button"}
          text={"Add a Race Car"}
          className={garageStyles.addButton}
          fill
        />

        {frontendEngineFitmentUX && (
          <Button
            icon={"pistons"}
            intent={"action"}
            size={"small"}
            onClick={(event) => {
              event.stopPropagation();
              setModal(true);
              setCurrentTab("engine");
            }}
            segmentEvent={{
              event: "Clicked",
              properties: { element: `Header Garage Dropdown: Add an Engine` },
            }}
            testingName={"Add an Engine"}
            type={"button"}
            text={"Add an Engine"}
            className={garageStyles.addButton}
            fill
          />
        )}

        {totalAll > 0 && (
          <Button
            intent={"action"}
            invert
            href={"/CustomerVehicle"}
            size={"small"}
            segmentEvent={{
              event: "Clicked",
              properties: {
                element: `Header Garage Dropdown: View your Garage`,
              },
            }}
            type={"button"}
            testingName={"View your Garage"}
            text={"View your Garage"}
            className={garageStyles.garageButton}
            fill
          />
        )}
      </div>
    );
  } catch (e) {
    console.error(e);
    return false;
  }
};
const GarageDropdownHeading = ({ type, total, haveClearSelection = false }) => {
  const s = total > 1 ? "s" : "";
  const headingText =
    total > 0
      ? `${type}${s} (${total})`
      : type === "Vehicle"
      ? "Add a Vehicle"
      : null;

  if (headingText === null) return;

  return (
    <>
      <List itemLayout="horizontal" className={garageStyles.garageHeaderFlex}>
        <Typography
          className={garageStyles.garageHeading}
          tone={"normal"}
          size={1.25}
          font={"heading"}
        >
          {headingText}
        </Typography>
        {haveClearSelection && <ClearSelection />}
      </List>
      <Divider className={garageStyles.garageDivider} tone={"superfluous"} />
      {total == 0 && type === "Vehicle" ? (
        <div className={garageStyles.garageFlavorText}>
          <Typography tone={"normal"} size={0.9} font={"regular"}>
            Add vehicles to your garage for easy access to parts, accessories,
            and recommendations.
          </Typography>
        </div>
      ) : (
        false
      )}
    </>
  );
};

const ClearSelection = ({}) => {
  const getSelectedCustomerProjectTitle = useVehicleStore((x) => x.getSelectedCustomerProjectTitle);
  const selectedCustomerProjectTitle = getSelectedCustomerProjectTitle();
  const clearSelectedCustomerProject = useVehicleStore((x) => x.clearSelectedCustomerProject);
  if (!selectedCustomerProjectTitle) return false;


  return (
    <TextLink
      intent={"action"}
      onClick={async () => {
        clearSelectedCustomerProject("/");
      }}
      underlineHover
    >
      Clear Selection
    </TextLink>
  );
};
const ShowAll = ({
  showAllState,
  setShowAll,
  type = "Vehicles",
  customerProjects = null,
}) => {
  customerProjects = customerProjects
    ? customerProjects
    : useVehicleStore((x) => x.context.customerProjects);

  let buttonText = "";
  if (!customerProjects || customerProjects?.length <= 3) {
    return false;
  }

  if (showAllState) {
    buttonText = `Hide ${type}`;
  } else {
    buttonText = `Show all ${type}`;
  }

  return (
    <TextLink
      intent={"action"}
      onClick={() => {
        setShowAll(!showAllState);
      }}
      underlineHover
    >
      {buttonText}
    </TextLink>
  );
};

export default GarageDropdown;
