// VERY IMPORTANT for lazy loadable components. Allows a user
// who has a network failure in downloading a component to try
// and download it a few more times. This should solve a lot
// of loadable-component erros.
const BotFilter = (userAgent) => {
    userAgent = userAgent.toLowerCase()
    var botList = ["GoogleBot", "facebot", "DuckDuckBot", "Baiduspider", "YandexBot", "Bingbot", "Slurp", "AboutUsBot", "Adsbot", "AlphaBot", "ArchiveBot", "AspiegelBot", "AwarioSmartBot", "BLEXBot", "BingPreview", "BingPreview 1.0", "Cliqzbot", "Cocolyzebot", "Discordbot", "DomCopBot", "DomainStatsBot", "DuckDuckGo", "Exabot", "FullStoryBot", "IndeedBot", "KenshooBot", "KomodiaBot", "LinkedInBot", "LinkisBot", "LivelapBot", "MAZBot", "Mail.RU_Bot", "MauiBot", "MojeekBot", "NetpeakCheckerBot", "OnalyticaBot", "PREMSGO", "PaperLiBot", "PubMatic", "Quora-Bot", "RedirectBot", "ResearchBot", "SEMrushBot", "ScraperBot", "SemanticScholarBot", "SeobilityBot", "SerendeputyBot", "SeznamBot", "SurdotlyBot", "TelegramBot", "TurnitinBot", "TweetmemeBot", "Twitterbot", "WordChampBot", "Yandex", "YelpBot", "ZoomBot", "ZoominfoBot", "adsbot-google", "ahrefs.com", "aiHitBot", "apis-google", "applebot", "archive.org_bot", "bingpreview", "bitlybot", "bot@linkfluence", "caliperbot", "chimebot", "cincrawdata", "coccocbot", "deepcrawl", "dotbot", "duckduckbot", "facebookexternalhit/1.1", "google-read", "istellabot", "linguee.com", "mediapartners-google", "mj12bot", "msnbot", "niocBot", "petalbot", "pinterest.com/bot", "rogerbot", "seewithkids.com", "semrush.com", "seotoolset", "serpstatbot", "seznambot", "slack.com", "smtbot", "superpagesbot", "xforce-security", "yahoo ad monitoring", "yahoo link preview", "yahoocachesystem", "IMNTools"]
    for (var i = 0; i < botList.length; i++) {
        var value = userAgent.indexOf(botList[i].toLowerCase())
        if (value >= 0) {
            return true
        }
    }
    return false
}
export default BotFilter