import React, { useContext } from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'

import PanelContext from 'Contexts/PanelContext'

import Svg from '../Svg'

import styles from './Rating.module.scss'

const RANGE = [0., 5.]
const DEFAULT_VALUE = 0.
const propTypes = {
    ratingValue: PropTypes.number
}

function round5(x, up = false) {
    return up ? Math.ceil(x / 5) * 5 : Math.floor(x / 5) * 5
}

export function getNumFullHalfEmptyStarCount(ratingValue) {
    const _ratingValue = round5((ratingValue || 0) * 10)
    const fullStarCount = parseInt(_ratingValue / 10)
    const halfStar = !!(_ratingValue % 10) | 0
    const emptyStarCount = 5 - fullStarCount - halfStar
    return [fullStarCount, halfStar, emptyStarCount]
}

const Rating = ({ ratingValue, size }) => {

    const panelContext = useContext(PanelContext)

    if (typeof ratingValue !== 'number' || ratingValue < RANGE[0] || ratingValue > RANGE[1]) {
        ratingValue = DEFAULT_VALUE
    }

    const [
        fullStarCount,
        halfStar,
        emptyStarCount,
    ] = getNumFullHalfEmptyStarCount(ratingValue)
    return (
        <div className={styles.rating}>
            {[...Array(fullStarCount).keys()].map((_) => (
                <Svg key={'star-' + _}
                    size={size || 0.75}
                    icon={'star'}
                    brand={'secondary'}
                    className={_ !== 0 ? styles.star : styles.first_star} />
            ))}
            {[...Array(halfStar).keys()].map((_) => (
                <div className={styles.halfStar} key={'halfstar-' + _}>
                    <Svg
                        key={'emptystar-' + _}
                        size={size || 0.75}
                        icon={'star'}
                        layer={panelContext.layer + 2}
                        className={fullStarCount > 0 || halfStar > 0 || _ !== 0 ? styles.star : styles.first_star}
                    />
                    <Svg
                        key={'halfstar-' + _}
                        size={size || 0.75}
                        icon={'star-half'}
                        brand={'secondary'}
                        className={fullStarCount > 0 || _ !== 0 ? styles.star : styles.first_star}
                    />

                </div>
            ))}
            {[...Array(emptyStarCount).keys()].map((_) => (

                <Svg
                    key={'emptystar-' + _}
                    size={size || 0.75}
                    icon={'star'}
                    layer={panelContext.layer + 2}
                    className={fullStarCount > 0 || halfStar > 0 || _ !== 0 ? styles.star : styles.first_star}
                />


            ))}
        </div>
    )
}

Rating.propTypes = propTypes

export default Rating