import React, {createContext, useContext, useEffect, useState} from "react";

import {NotificationContext} from "Contexts/NotificationContext/NotificationContext";
import {TrackingContext} from "Contexts/TrackingContext/TrackingContext";
import {getSuperMarketAffinity} from "Utilities/Instrumentation/SegmentUtilities/segmentFunctions";
import Retry from "Utilities/Retry";
import defer from "Utilities/defer";

import {useUserStore} from "../../Stores/UserStore/UserStore";
import TextLink from "Atoms/TextLink";
import {GetUserGuid, IsUserBot,} from "../../Utilities/CookieUtilties/UserCookieUtilities";
import {
  addSessionProperties,
  incrementSessionStorageKeyEventCount,
  setIsEngagedSessionFired,
  trackEngagedSession,
} from "../../Utilities/EngagedSessionStorageUtility/EngagedSessionStorageUtility";
import {
  createWishListEvent,
  deleteWishListEvent,
} from "../../Utilities/Instrumentation/Wishlist/wishlistSegmentEvents";

const ListContext = createContext();

async function getListRequests() {
  return await Retry(() => import("Contexts/ListContext/ListRequests.js"));
}

const ListStateProvider = ({
  children,
  userGuid,
  services,
  updateUserGuid,
  value,
}) => {
  const { PushNotification } = useContext(NotificationContext);
  const { segmentReady } = useContext(TrackingContext);
  const emailAddress = useUserStore((x) => x.context.emailAddress);

  useEffect(() => {
    if (typeof window !== "undefined" && window.SMI) {
      // DELETE THIS WHEN THE NEW BUILDLIST GOES LIVE 100%
      window.SMI.updateDs9ListContext = () => {
        getUserLists(true);
      };

      // DELETE THIS WHEN WE REDO ARTICLE PAGES
      window.SMI.showListDrawer = (items, type) => showListDrawer(items, null, type, false);
    }
  }, [typeof window, userGuid]);

  const [listContext, setListContext] = useState({
    listCount: 0,
    hydratedListCount: false,
    fullyHydrated: false,
    lists: null,
    selectedListId: null,
    isListDrawerOpen: false,
    itemsToAddToList: [],
    listDrawerType: "product",
    sharedList: null,
    isIndexOptionErrorState: null,
    isBuildListDrawer: false,
    ...value,
  });

  const getUserListsCount = async () => {
    if (IsUserBot()) {
      setListContext((state) => {
        return {
          ...state,
          hydratedListCount: true,
          listCount: 0,
        };
      });
      return;
    }
    const module = await getListRequests();
    const webUserGuid = GetUserGuid();
    if (webUserGuid) {
      const listsResponse = await module.getListCount(
        webUserGuid,
        services.checkout.url
      );
      if (listsResponse) {
        setListContext((state) => {
          return {
            ...state,
            hydratedListCount: true,
            listCount: listsResponse.listWithProductsCount,
          };
        });
      }
    }
  };

  const getUserLists = async (
    includeSavedForLater = false,
    sharedListGuid = null
  ) => {
    if (IsUserBot()) {
      setListContext((state) => {
        return {
          ...state,
          hydrated: true,
          fullyHydrated: true,
          lists: [],
        };
      });
      return;
    }
    const module = await getListRequests();
    sharedListGuid =
      sharedListGuid ?? listContext?.sharedList?.shareGuid ?? null;
    const webUserGuid = GetUserGuid();

    const listsResponse = await module.getLists(
      webUserGuid,
      services.checkout.url,
      true,
      includeSavedForLater
    );

    if (listsResponse) {
      let sharedList = null;
      let listSharedWithSelf = null;
      if (sharedListGuid) {
        if (listsResponse.lists) {
          listSharedWithSelf = listsResponse.lists.filter(
            (x) => x.shareGuid === sharedListGuid
          )[0];
        }
        if (!listSharedWithSelf) {
          sharedList = await module.getListFromGuid(
            sharedListGuid,
            services.checkout.url
          );
        }
      }

      setListContext((state) => {
        let newSelectedListId =
          !state.sharedList && sharedList ? null : state.selectedListId;
        if (!newSelectedListId) {
          if (sharedList) {
            newSelectedListId = sharedList.id;
          } else if (listSharedWithSelf) {
            newSelectedListId = listSharedWithSelf.id;
          } else {
            newSelectedListId = listsResponse?.lists?.length
              ? listsResponse.lists[0].id
              : null;
          }
        }
        return {
          ...state,
          sharedList: sharedList,
          lists: listsResponse.lists,
          selectedListId: newSelectedListId,
          hydrated: true,
          fullyHydrated: true,
        };
      });

      if (!userGuid && listsResponse?.webUser?.userGuid) {
        updateUserGuid(listsResponse.webUser.userGuid);
      }
    }
  };

  const showListDrawer = async (itemsToAddToList, itemsToMoveToList = [], type = "product", isBuildList = false) => {
    setListContext({
      ...listContext,
      itemsToAddToList: itemsToAddToList,
      itemsToMoveToList: itemsToMoveToList,
      isListDrawerOpen: true,
      listDrawerType: type,
      isBuildListDrawer: isBuildList
    });
  };

  const hideListDrawer = async () => {
    setListContext({
      ...listContext,
      isListDrawerOpen: false,
      indexOptions: null,
      product: null,
      productPageId: null,
    });
  };

  const deleteList = async (listId) => {
    if (!userGuid) {
      return;
    }
    let listToDelete = listContext.lists?.find(
      (x) => x.id === listContext.selectedListId
    );
    const module = await getListRequests();
    const listsResponse = await module.deleteList(
      userGuid,
      listId,
      services.checkout.url
    );
    if (listsResponse?.success) {
      deleteWishListEvent(
        listToDelete?.id,
        listToDelete?.listName,
        listToDelete?.products,
        listToDelete?.articles
      );
      setListContext((state) => {
        return {
          ...state,
          lists: listsResponse.lists,
          listCount: listsResponse.listWithProductsCount,
          selectedListId:
            listsResponse.lists && listsResponse.lists.length > 0
              ? listsResponse.lists[0].id
              : null,
        };
      });
    }
    return listsResponse?.success;
  };

  const moveItemBetweenLists = async (listIdTo, listIdFrom, itemId) => {
    if (!userGuid) {
      return;
    }
    const module = await getListRequests();
    const listsResponse = await module.moveItem(
      userGuid,
      listIdTo,
      listIdFrom,
      itemId,
      services.checkout.url
    );
    if (listsResponse?.success) {
      setListContext((state) => {
        return {
          ...state,
          lists: listsResponse.lists,
          listCount: listsResponse.listWithProductsCount,
        };
      });
    }
    return listsResponse?.success;
  };

  const moveArticleBetweenLists = async (listIdTo, listIdFrom, articleId) => {
    if (!userGuid) {
      return;
    }
    const module = await getListRequests();
    const listsResponse = await module.moveArticle(
      userGuid,
      listIdTo,
      listIdFrom,
      articleId,
      services.checkout.url
    );
    if (listsResponse?.success) {
      setListContext((state) => {
        return {
          ...state,
          lists: listsResponse.lists,
          listCount: listsResponse.listWithProductsCount,
        };
      });
    }
    return listsResponse?.success;
  };

  const removeArticleFromList = async (listId, articleId) => {
    const module = await getListRequests();
    const listsResponse = await module.removeArticle(
      userGuid,
      listId,
      articleId,
      services.checkout.url
    );
    if (listsResponse?.success) {
      setListContext((state) => {
        return {
          ...state,
          lists: listsResponse.lists,
          listCount: listsResponse.listWithProductsCount,
        };
      });
    }
    return listsResponse?.success;
  };

  const removeItemFromList = async (listId, itemId) => {
    const module = await getListRequests();
    const listsResponse = await module.removeItem(
      userGuid,
      services.checkout.url,
      listId,
      itemId
    );
    if (listsResponse?.success) {
      setListContext((state) => {
        return {
          ...state,
          lists: listsResponse.lists,
          listCount: listsResponse.listWithProductsCount,
        };
      });
    }
    return listsResponse?.success;
  };

  const createNewList = async (
    listName,
    listItems,
    articleIds,
    checkoutUrl = services.checkout.url,
    webUserGuid = userGuid
  ) => {
    const module = await getListRequests();
    const listsResponse = await module.createList(
      listName,
      listItems,
      articleIds,
      checkoutUrl,
      webUserGuid
    );
    if (listsResponse) {
      let selectedListId =
        listsResponse.lists && listsResponse.lists.length > 0
          ? listsResponse.lists[0].id
          : null;
      createWishListEvent(selectedListId, listName);
      setListContext((state) => {
        return {
          ...state,
          lists: listsResponse.lists,
          listCount: listsResponse.listWithProductsCount,
          selectedListId: selectedListId,
        };
      });
    }

    if (listsResponse?.success) {
      try {
        let list =
          listsResponse.lists && listsResponse.lists.length > 0
            ? listsResponse.lists[0]
            : null;

        if (segmentReady && list) {
          articleIds.map((articleId, i) => {
            let article = list?.articles?.find((x) => x.id === articleId);
            analytics.track(
              "Article Added to Wishlist",
              {
                wishlist_id: list.id?.toString(),
                wishlist_name: listName ?? "",
                article_id: articleId?.toString(),
                author: article?.author ?? "",
                title: article?.title,
                position: i ?? 0,
                url: article?.url ?? "",
                image_url: article?.articleThumbnailUrl ?? "",
              },
              {
                traits: {
                  email: emailAddress,
                },
              }
            );
          });

          listItems.map((item, i) => {
            let properties = {
              wishlist_id: listId?.toString(),
              wishlist_name: listName,
              product_id: item?.skuBaseNumber,
              sku: item?.skuBaseNumber,
              variant: item?.skuVariantNumber,
              brand: item?.brand?.brandName ?? item?.brand,
              category: item?.segmentCategory,
              name: item?.title,
              price:
                typeof item.price === "string"
                  ? parseFloat(item.price.replace("$", ""))
                  : item.price,
              quantity: item?.quantityRequested,
              position: i,
              url: item?.url,
              image_url: item?.images?.length ? item.images[0] : null,
              availability: item.availabilityEnum ?? item.availability,
              rating: item.reviewCount ? item.rating : 0,
              reviews: item.reviewCount,
              audience_affinity: getSuperMarketAffinity(item),
            };

            properties = addSessionProperties(properties);

            incrementSessionStorageKeyEventCount("Product Added to Wishlist");
            trackEngagedSession();

            analytics.track("Product Added to Wishlist", properties, {
              traits: {
                email: emailAddress,
              },
            });

            setIsEngagedSessionFired();
          });
        }
      } catch (err) {
        console.error(err);
      }
    }

    if (listsResponse?.success) {
      if (listItems && listItems.length) {
        PushNotification(
          `List '${listName}' successfully created with ${listItems.length} ${
            listItems.length === 1 ? "item" : "items"
          }.`,
          null,
          5000,
          null,
          { icon: "check", intent: "positive" }
        );
      } else {
        PushNotification(
          `List '${listName}' successfully created.`,
          null,
          5000,
          null,
          { icon: "check", intent: "positive" }
        );
      }
    } else {
      PushNotification(
        `Unable to create list at this time.`,
        null,
        5000,
        null,
        { icon: "cross", intent: "negative" }
      );
    }

    return listsResponse?.success;
  };

  const addToList = async (
    items,
    listId,
    checkoutUrl = services.checkout.url
  ) => {
    const module = await getListRequests();
    const listsResponse = await module.addToList(
      items,
      userGuid,
      listId,
      checkoutUrl
    );
    if (listsResponse) {
      setListContext((state) => {
        return {
          ...state,
          lists: listsResponse.lists,
          listCount: listsResponse.listWithProductsCount,
        };
      });
    }

    if (listsResponse?.success) {
      try {
        if (window.gtag) {
          defer(() => {
            items.forEach((item) => {
              // task split each of these after they've been defered (and split originally)
              setTimeout(() => {
                window.gtag("event", "add_to_wishlist", {
                  currency: "USD",
                  value: item.priceDecimal,
                  items: [
                    {
                      id: item.skuVariantNumber,
                      google_business_vertical: "retail",
                    },
                  ],
                  send_to: "ads",
                });
              });
            });
          });
        }

        if (segmentReady) {
          let listName = listContext?.lists?.find(
            (x) => x.id === listId
          )?.listName;
          items.map((item, i) => {
            let properties = {
              wishlist_id: listId?.toString(),
              wishlist_name: listName,
              product_id: item?.skuBaseNumber,
              sku: item?.skuBaseNumber,
              variant: item?.skuVariantNumber,
              brand: item?.brand?.brandName ?? item?.brand,
              category: item?.segmentCategory,
              name: item?.title,
              price:
                typeof item.price === "string"
                  ? parseFloat(item.price.replace("$", ""))
                  : item.price,
              quantity: item?.quantityRequested,
              position: i,
              url: item?.url,
              image_url: item?.images?.length ? item.images[0] : null,
              availability: item.availabilityEnum ?? item.availability,
              rating: item.reviewCount ? item.rating : 0,
              reviews: item.reviewCount,
              audience_affinity: getSuperMarketAffinity(item),
            };

            properties = addSessionProperties(properties);

            let productAddedToWishlist = "Product Added to Wishlist";

            incrementSessionStorageKeyEventCount(productAddedToWishlist);
            trackEngagedSession();

            analytics.track(productAddedToWishlist, properties, {
              traits: {
                email: emailAddress,
              },
            });

            setIsEngagedSessionFired();
          });
        }
      } catch (err) {
        console.error(err);
      }
      PushNotification(
        `${items.length} ${
          items.length === 1 ? "item" : "items"
        } added to list.`,
        null,
        5000,
        <TextLink intent={'action'}
          href={'/buildlist'} 
          style={{
            'margin-left' : '2rem'
          }}
          >
            View your lists
        </TextLink>,
        { icon: "check", intent: "positive" }
      );
    } else {
      PushNotification(
        `Unable to add item to list at this time.`,
        null,
        5000,
        null,
        { icon: "cross", intent: "negative" }
      );
    }

    return listsResponse?.success;
  };

  const addArticlesToList = async (
    articleIds,
    listId,
    checkoutUrl = services.checkout.url
  ) => {
    const module = await getListRequests();
    const listsResponse = await module.addArticlesToList(
      articleIds,
      userGuid,
      listId,
      checkoutUrl
    );
    if (listsResponse) {
      setListContext((state) => {
        return {
          ...state,
          lists: listsResponse.lists,
          listCount: listsResponse.listWithProductsCount,
        };
      });
    }

    if (listsResponse?.success) {
      try {
        if (segmentReady) {
          let list = listContext?.lists?.find((x) => x.id === listId);
          let listName = list?.listName;

          articleIds.map((articleId, i) => {
            let article = list?.articles?.find((x) => x.id === articleId);
            analytics.track(
              "Article Added to Wishlist",
              {
                wishlist_id: listId?.toString(),
                wishlist_name: listName ?? "",
                article_id: articleId?.toString(),
                author: article?.author ?? "",
                title: article?.title,
                position: i ?? 0,
                url: article?.url ?? "",
                image_url: article?.articleThumbnailUrl ?? "",
              },
              {
                traits: {
                  email: emailAddress,
                },
              }
            );
          });
        }
      } catch (err) {
        console.error(err);
      }
    }

    if (listsResponse?.success) {
      PushNotification(`Article added to list.`, null, 5000, null, {
        icon: "check",
        intent: "positive",
      });
    } else {
      PushNotification(
        `Unable to add article at this time.`,
        null,
        5000,
        null,
        { icon: "cross", intent: "negative" }
      );
    }

    return listsResponse?.success;
  };

  const editList = async (
    listId,
    name,
    checkoutUrl = services.checkout.url
  ) => {
    const module = await getListRequests();
    const listsResponse = await module.editList(
      userGuid,
      listId,
      name,
      checkoutUrl
    );
    if (listsResponse) {
      setListContext((state) => {
        return {
          ...state,
          lists: listsResponse.lists,
          listCount: listsResponse.listWithProductsCount,
        };
      });
    }
    return listsResponse?.success;
  };

  const copyList = async (
    sharedListGuid,
    checkoutUrl = services.checkout.url
  ) => {
    const module = await getListRequests();
    const listsResponse = await module.copyListFromGuid(
      userGuid,
      sharedListGuid,
      checkoutUrl
    );
    if (listsResponse) {
      setListContext((state) => {
        return {
          ...state,
          lists: listsResponse.lists,
          listCount: listsResponse.listWithProductsCount,
        };
      });
    }
    return listsResponse?.success;
  };

  const setSelectedListId = (selectedListId) => {
    setListContext((state) => {
      return {
        ...state,
        selectedListId,
      };
    });
  };

  const setIsFetching = (isFetching) => {
    setListContext((state) => {
      return {
        ...state,
        isFetching,
      };
    });
  };

  return (
    <ListContext.Provider
      value={{
        listContext,
        getUserLists,
        moveItemBetweenLists,
        removeItemFromList,
        createNewList,
        addToList,
        addArticlesToList,
        editList,
        setIsFetching,
        setSelectedListId,
        deleteList,
        moveArticleBetweenLists,
        removeArticleFromList,
        showListDrawer,
        hideListDrawer,
        copyList,
        getUserListsCount,
      }}
    >
      {children}
    </ListContext.Provider>
  );
};

export { ListStateProvider, ListContext };
