import React, {useEffect, useState, useRef} from 'react';
import ClassNames from 'classnames'

import Svg from 'Atoms/Svg'
import Typography from 'Atoms/Typography'

import LayerColorFinder from 'Utilities/LayerColorFinder'
import ColorFinder from 'Utilities/ColorFinder'

import styles from './Quantity.module.scss'
import styled from 'styled-components'

const Quantity = (
    {
        id,
        className,
        defaultValue,
        minValue,
        maxValue,
        onChange
    }
) => {
    defaultValue = defaultValue || 1
    minValue = minValue || 1
    maxValue = maxValue || 999


    const [value, setValue] = useState(defaultValue)

    useEffect(() => {
        if(onChange) {
            onChange(value)
        }
    }, [value])

    useEffect(() => {
        setValue(defaultValue)
    }, [defaultValue])

    //=====================
    //https://stackoverflow.com/questions/57358640/cancel-wheel-event-with-e-preventdefault-in-react-event-bubbling
    //=====================
    const wheelTimeout = useRef()

    const onWheel = e => {
        // while wheel is moving, do not release the lock
        clearTimeout(wheelTimeout.current)

        // flag indicating to lock page scrolling (setTimeout returns a number)
        wheelTimeout.current = setTimeout(() => {
            wheelTimeout.current = false
        }, 300)
    }

    // block the body from scrolling (or any other element)
    useEffect(() => {
        const cancelWheel = e => wheelTimeout.current && e.preventDefault()
        document.body.addEventListener('wheel', cancelWheel, { passive: false })
        return () => document.body.removeEventListener('wheel', cancelWheel)
    }, [])
    //=====================

    const { color } = ColorFinder(null, 'superfluous', null)
    const hoverColor = LayerColorFinder(1)

    const backgroundColor = LayerColorFinder(0)

    return (
        <StyledQuantity id={id} className={ClassNames(className, styles.quantity)} borderColor={color} backgroundColor={backgroundColor}>
            <StyledButton 
                className={ClassNames(styles.leftButton, styles.button)}
                onClick={() => {
                    if(value != minValue)
                        setValue(parseInt(value) - 1)
                    }}
                hoverColor={value <= minValue ? 'transparent' : hoverColor}
                cursor={value <= minValue ? 'default' : 'pointer' }>
                <Typography className={styles.svg_wrapper} size={1}>
                    <Svg className={styles.svg} tone={value <= minValue ? 'superfluous' : 'contrast'} font={'bold'} size={1} icon={'minus'} />
                </Typography>
            </StyledButton>
            <Typography
                as={'input'} 
                className={ClassNames(styles.input)}
                type={'number'} 
                value={value} 
                max={maxValue}
                min={minValue}
                maxLength={`${maxValue}`.length}
                onChange={(e) => {
                    setValue(e.target.value <= maxValue ? 
                        e.target.value : 
                        parseInt(`${e.target.value}`.substring(0, `${maxValue}`.length)))
                }}
                onBlur={() => {
                    if(isNaN(value))
                        setValue(defaultValue)
                    if(value < minValue)
                        setValue(minValue)
                    if(value > maxValue)
                        setValue(maxValue)
                }}
                onWheel={onWheel}
                aria-label={'quantity'} />
            <StyledButton 
                className={ClassNames(styles.rightButton, styles.button)}
                onClick={() => {
                    if(value != maxValue)
                        setValue(parseInt(value) + 1)
                    }}
                hoverColor={value >= maxValue ? 'transparent' : hoverColor }
                cursor={value >= maxValue ? 'default' : 'pointer' }>
                <Typography className={styles.svg_wrapper} size={1}>
                    <Svg className={styles.svg} tone={value >= maxValue ? 'superfluous' : 'contrast'} font={'bold'} size={1} icon={'plus'} />
                </Typography>
            </StyledButton>
        </StyledQuantity>
    )
}

const StyledQuantity = styled.div`
    border-color: ${props => props.borderColor};
    background: ${props => props.backgroundColor};
`

const StyledButton = styled.div`
    cursor: ${props => props.cursor};
    &:hover {
        background-color: ${props => props.hoverColor};
    }
`

export default Quantity;