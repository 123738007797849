import { useEffect, useState } from 'react'
import useGlobalPrivacyControl from "Hooks/useGlobalPrivacyControl";

const useFacebook = (callback) => {
    const [queue, updateQueue] = useState([])
    const { isGpcEnabled } = useGlobalPrivacyControl()

    useEffect(() => {
        if (typeof window !== 'undefined' && typeof window.fbq == 'undefined' && !isGpcEnabled){
            if(callback){
                callback(window.fbq)
            }
        }
    }, [typeof window !== 'undefined' && typeof window.fbq == 'undefined' && !isGpcEnabled])

    useEffect(() => {
        if (typeof window !== 'undefined' && typeof window.fbq == 'undefined' && !isGpcEnabled){
            if(queue.length){
                queue.forEach(event => {
                    window.fbq(event.type, event.name, event.params)
                })
                updateQueue([])
            }
        }
    }, [typeof window !== 'undefined' && typeof window.fbq == 'undefined' && !isGpcEnabled, queue])

    const sendFacebookEvent = (event) => {
        if(!isGpcEnabled) {
            updateQueue(queue.concat(event))
        }
    }

    return sendFacebookEvent
}

export default useFacebook
