import React, { startTransition, useContext, useState } from 'react'

import { NavigationContext } from '../../Contexts/Navigation/NavigationContext';
import { getProperUrl } from 'Contexts/VehicleContext/CoreVehicleRequests'
import { useNavigationStore } from "Stores/NavigationStore";
import { useVehicleStore } from "Stores/VehicleStore";

import Drawer from 'Molecules/Drawer'
import Panel from 'Atoms/Panel'
import Typography from 'Atoms/Typography'
import TextLink from 'Atoms/TextLink'
import Form from 'Atoms/Form'
import CoveringSpinner from 'Atoms/CoveringSpinner'

import styles from './vehicleDrawer.module.scss'

const VehicleDrawer = () => {

    const [vehicleLoading, setVehicleLoading] = useState(false)
    const closeVehicleSelectDrawer = useNavigationStore(state => state.closeVehicleSelectDrawer);
    const vehicleSelectDrawerIsOpen = useNavigationStore(state => state.vehicleSelectDrawerIsOpen);
    const navigationContext = useContext(NavigationContext)
    const redirectWhenCustomerProjectSelected = useVehicleStore(state => state.redirectWhenCustomerProjectSelected)
    const selectedFacetOptions = useVehicleStore(state => state.context.selectedFacetData?.selectedFacetOptions)
    const closeVehicleDrawerCallback = useNavigationStore(state => state.closeVehicleDrawerCallback)
    const clearSelectedCustomerProject = useVehicleStore(state => state.clearSelectedCustomerProject)
    const selectCustomerProject = useVehicleStore(state => state.selectCustomerProject)
    const customerProjects = useVehicleStore(state => state.context.customerProjects)
    const selectedCustomerProject = useVehicleStore(state => state.context.selectedCustomerProject)
    
    const fitmentInfo = selectedFacetOptions?.filter(currentFacet => currentFacet.displayText === "Fitment" || currentFacet.displayText == "Race Type")[0];

    const vehicleOptions = (customerProjects || []).filter(x => x?.vehicleBaseId || x?.raceTypeId).map(vehicle => {
        return {
            value: vehicle?.vehicleBaseId || vehicle.raceTypeId,
            label: vehicle?.vehicleBaseId ? `${vehicle.year} ${vehicle.make} ${vehicle.model}` : vehicle.raceName
        }
    })

    const selectedCustomerProjectId = selectedCustomerProject?.vehicleBaseId || selectedCustomerProject?.raceTypeId
    vehicleOptions.filter(o => o.value == selectedCustomerProjectId && selectedFacetOptions?.find(facet => facet?.searchResponseFacetOptions?.find(option => option?.displayValue == o.label))).map(o => o.checked = true)

    const onVehicleChange = (e) => {
        try {
            setVehicleLoading(true)
            const vehicleId = e.target.value

            if (vehicleId == 'addnewvehicle') {
                navigationContext.setYmmModal(true)
                return
            }

            const desiredVehicle = (customerProjects || []).filter(vehicle => vehicle?.vehicleBaseId == vehicleId || vehicle.raceTypeId == vehicleId)[0]

            closeVehicleSelectDrawer()
            if (redirectWhenCustomerProjectSelected){
                selectCustomerProject(desiredVehicle.projectId, getProperUrl(desiredVehicle))
            } else {
                selectCustomerProject(desiredVehicle.projectId)
            }
            setVehicleLoading(false)
        }
        catch (error) {
            setVehicleLoading(false)
            console.error(error)
        }
    }

    return (
        <Drawer
            location={'right'}
            width={640}
            open={vehicleSelectDrawerIsOpen}
            closeFunction={closeVehicleSelectDrawer}
            drawerZIndex={201}>
            <div className={styles.vehicle_drawer}>
                <Panel inverseTheme className={styles.header}>
                    <Typography size={1.25} tone={'contrast'}>
                        {'Select Vehicle'}
                    </Typography>
                </Panel>
                <div className={styles.body}>
                    <Typography size={1} font={'bold'} className={styles.title}>
                        Your Garage:
                    </Typography>
                    <Typography size={0.875} tone={'subtle'} className={styles.selectVehicle}>
                        Choose a vehicle to help find the parts you’re looking for.
                    </Typography>

                    <div className={styles.vehicleButtons}>
                        <TextLink size={1} font={'bold'} data-testid={"addVehicleDrawerButton"} intent={'action'} chevron={'right'} className={styles.addVehicle} onClick={() => {
                            startTransition(() => {
                                closeVehicleSelectDrawer()
                                if (closeVehicleDrawerCallback){
                                    closeVehicleDrawerCallback()
                                }
                                navigationContext.setYmmModal(true)
                            })
                        }}>
                            Add a Vehicle
                        </TextLink>

                        {fitmentInfo &&
                            <TextLink size={1} font={'bold'} data-testid={"clearVehicleDrawerButton"} intent={'action'} className={styles.addVehicle}
                                onClick={() => {
                                    const redirectUrl = fitmentInfo?.searchResponseFacetOptions[0]?.facetOptionLink?.url
                                    clearSelectedCustomerProject(redirectUrl)
                                }}>
                                Clear Vehicle
                            </TextLink>
                        }
                    </div>

                    <CoveringSpinner isLoading={vehicleLoading}>

                        <Form.Radio id={'vehicle_drawer'} values={vehicleOptions} selectedValue={selectedCustomerProjectId} onChange={onVehicleChange} className={styles.customerProjects} />

                    </CoveringSpinner>
                </div>
            </div>
        </Drawer>
    )
}

export default VehicleDrawer