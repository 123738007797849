import React, { useState, useContext, useEffect } from "react";
import styles from "./ProductComparisonTable.module.scss";
import Typography from "Atoms/Typography";
import LayerColorFinder from "Utilities/LayerColorFinder";
import ColorFinder from "Utilities/ColorFinder";
import Image from "next/image";
import Rating from "Atoms/Rating";
import Button from "Molecules/Button";
import Panel from "Atoms/Panel";
import TextLink from "Atoms/TextLink";
import Skeleton from "Atoms/Skeleton"
import Svg from "Atoms/Svg"
import useRecentHistory from "Hooks/useRecentHistory.js";
import { AddToCartExperienceContext } from "Contexts/AddToCartExperienceContext/AddToCartExperienceContext";
import { useVehicleStore } from "../../Stores/VehicleStore";
import { getSuperMarketAffinity } from "../../Utilities/Instrumentation/SegmentUtilities/segmentFunctions";
import { createClickedSegmentEvent } from "../../Utilities/Instrumentation/Impressions/impressionSegmentEvents";
import { useSkuBaseFitmentData, useSkuBaseShippingData } from "../../Contexts/UserSpecificProductDataStore/UserSpecificProductDataStore";
import { PromotionRibbon } from "../PromotionRibbon/PromotionRibbon";
import { useSkuBasePromoData } from "Contexts/UserSpecificProductDataStore/UserSpecificProductDataStore";

const ProductComparisonTable = ({ recBlock, dataTestId, treatment }) => {
  const recommendations = recBlock.recommendations
  const style = {
    "--borderColor": LayerColorFinder(2),
    "--backgroundColor": LayerColorFinder(1),
    "--actionColor": ColorFinder("action").color.replace("1)", "0.2)"),
  };

  return (
    <Panel className={styles.scroller} layer={0}>
      <table style={style} cellSpacing={0} className={styles.compareTable}>
        <tbody>
          <ProductComparisonRibbonRow recommendations={recommendations} />
          <ProductComparisonImageAndTitleRow recommendations={recommendations} miso_id={recBlock.misoId} treatment={treatment} dataTestId={dataTestId} />
          <ProductComparisonRatingRow recommendations={recommendations} />
          <ProductComparisonPriceRow recommendations={recommendations} />
          <ProductComparisonBrandRow recommendations={recommendations} />
          <ProductComparisonFitmentRow recommendations={recommendations} expectFitmentInfo={recBlock.checkFitment} />
          <ProductComparisonAvailabilityRow recommendations={recommendations} />
          <ProductComparisonShippingRow recommendations={recommendations} expectShippingInfo={recBlock.getShippingEstimate} />
          <ProductComparisonPickupRow recommendations={recommendations} />
          <ProductComparisonKeyValues recommendations={recommendations} />
          <ProductComparisonATCRow recommendations={recommendations} treatment={treatment} dataTestId={dataTestId} />
        </tbody>
      </table>
    </Panel>
  );
};

const ProductComparisonRibbonRow = ({ recommendations }) => {
  let thisRow = [<th key={`comparison_product_ribbon_start`}></th>];
  for (let i = 1, ilen = recommendations.length; i < ilen; i++) {

    let ribbonText = recommendations[i].ribbonText;
    
    const promoData = useSkuBasePromoData(recommendations[i]?.skuBaseNumber);
    
    thisRow.push(
      <td key={`comparison_product_ribbon_${i}`}>
        <PromotionRibbon
          textToOveride={i == 1 ? "Current Item" : null}
          displayText={ribbonText}
          promotionPreview={promoData}
          fontSize={0.75}
          className={styles.ribbon}
        />
      </td>
    );
  }
  return <tr>{thisRow}</tr>;
};

const ProductComparisonFitmentRow = ({ recommendations, expectFitmentInfo }) => {
  const selectedCustomerProject = useVehicleStore(x => x.context.selectedCustomerProject)
  const customerProjects = useVehicleStore(x => x.context.customerProjects)

  const recsFitmentData = recommendations.map(x => useSkuBaseFitmentData(x.skuBaseNumber))
  const hasSomeFitmentData = recsFitmentData.some(x => x)

  let shouldRenderSkeletons = expectFitmentInfo && !hasSomeFitmentData && !recommendations.every(x => x.excludeFromThisPartFitsMyVehicle || x.comparisonLabels?.length)
  if ((!expectFitmentInfo && !recommendations.some(x => x.fitment)) || !(selectedCustomerProject?.vehicleBaseId || selectedCustomerProject?.raceTypeId)) {
    return false;
  }

  if (recommendations.slice(1).every(x => x.fitment === "none" || x.comparisonLabels?.length || x.excludeFromThisPartFitsMyVehicle === true)) {
    return false;
  }

  let vehiclePlaceholder = selectedCustomerProject?.hydrated
    ? selectedCustomerProject
    : customerProjects?.[0];

  let placeholder = "";

  if (vehiclePlaceholder?.raceDisplay) {
    placeholder = vehiclePlaceholder.raceDisplay;
  } else {
    placeholder = `${vehiclePlaceholder?.yearId ?? vehiclePlaceholder?.year} ${vehiclePlaceholder?.makeDisplay ?? vehiclePlaceholder?.make
      } ${vehiclePlaceholder?.modelDisplay ?? vehiclePlaceholder?.model}`;
  }

  if (placeholder.includes("null") || placeholder.includes("undefined")) {
    placeholder = "";
  }

  let firstRow = [<Typography as="th" key={`cell_fitment`} className={styles.hideMobile} size={0.875} font={"bold"}>Fitment{placeholder != "" ? `: ${placeholder}` : ""}</Typography>]
  firstRow.push(<Typography as="th" key={`cell_fitment_header`} className={styles.hideDesktop} size={0.875} font={"bold"}>Fitment</Typography>)

  for (let i = 1, ilen = recommendations.length; i < ilen; i++) {
      firstRow.push(<ProductComparisonFitmentColumn key={`cell_fitment_${i}`}skuBaseNumber={recommendations[i]?.skuBaseNumber} shouldRenderSkeletons={shouldRenderSkeletons}/>);
  }

  return <>
    <tr className={styles.dataRow}>{firstRow}</tr>
  </>;
}

const ProductComparisonFitmentColumn = ({skuBaseNumber, shouldRenderSkeletons}) => {

  const fitmentData = useSkuBaseFitmentData(skuBaseNumber)

  if(shouldRenderSkeletons){
    return (
      <td key={`comparison_fitment_skeletons_${skuBaseNumber}`}>
        <Skeleton height={16} fullWidth />
      </td>
    )
  }

  if (fitmentData == "none") {
    return (<td key={`empty_header_cell_fitment_${skuBaseNumber}`}></td>);
  }

  return (
    <td key={`comparison_fitment_${skuBaseNumber}`}>
        {fitmentData?.isDirectFit || fitmentData?.isDrivelineFit ?
          <div className={styles.fitment}>
            <Svg icon={'check'} intent={'positive'} size={0.875} />
            <Typography size={0.875}>Guaranteed To Fit</Typography>
          </div>
          : fitmentData?.isUniversalFit ?
            <div className={styles.universalFitment}>
              <div className={styles.fitmentSvg}>
                <Svg icon={'check'} intent={'action'} size={0.875} />
                <Typography size={0.875}>Universal Fit</Typography>
              </div>
              <Typography size={0.75} tone={'subtle'}>Modification may be required</Typography>
            </div> :
            !fitmentData?.fits ?
              <div className={styles.fitment}>
                <Svg icon={'unavailable'} intent={'negative'} size={0.875} />
                <Typography size={0.875}>May Not Fit</Typography>
              </div> : null
        }
      </td>
  )
}

const ProductComparisonShippingRow = ({ recommendations, expectShippingInfo }) => {

  const recsShippingData = recommendations.map(x => useSkuBaseShippingData(x.skuBaseNumber))
  const hasSomeShippingData = recsShippingData.some(x => x)

  let shouldRenderSkeletons = expectShippingInfo && !hasSomeShippingData
  if (!expectShippingInfo && !hasSomeShippingData) {
    return false;
  }

  let firstRow = [<Typography as="th"  key={`comparison_shipping_header_0`} size={0.875} font={"bold"}>Shipping</Typography>];
  for (let i = 1, ilen = recsShippingData.length; i < ilen; i++) {
    if (shouldRenderSkeletons) {
      firstRow.push(<td key={`comparison_shipping_skeletons_${i}`}>
        <Skeleton height={16} fullWidth />
        <Skeleton height={16} fullWidth />
      </td>)
      continue;
    }
    if (recsShippingData[i] == "none") {
      firstRow.push(<th key={`empty_header_cell_shipping_${i}`}></th>);
      continue;
    }

    firstRow.push(
      <td key={`comparison_shipping_${i}`}>
        <Typography size={0.875}>
          {recsShippingData[i]?.shippingDetailsString}
        </Typography>
        {recsShippingData[i]?.label &&
          <Typography size={0.875} tone={'subtle'} inline>
            {recsShippingData[i]?.label + " "}
          </Typography>}
        {recsShippingData[i]?.shippingDate &&
          <Typography size={0.875} font={'bold'} inline>
            {recsShippingData[i]?.shippingDate}
          </Typography>}
      </td>
    );
  }
  return <>
    <tr className={styles.dataRow}>{firstRow}</tr>

  </>;
}

const ProductComparisonPickupRow = ({ recommendations }) => {
  if (!recommendations.some(x => x.shippingEstimate?.suggestedPickup)) {
    return false;
  }
  let firstRow = [<Typography as="th" key="empty_header_cell_pickup" size={0.875} font={"bold"}>In-Store Pickup</Typography>];
  for (let i = 1, ilen = recommendations.length; i < ilen; i++) {
    firstRow.push(
      <Typography as={"td"} size={0.875} tone={"subtle"} key={`comparison_pickup_${i}`}>
        {recommendations[i].shippingEstimate?.suggestedPickup}
      </Typography>
    );
  }
  return <>
    <tr className={styles.dataRow}>{firstRow}</tr>

  </>;
}

const ProductComparisonAvailabilityRow = ({ recommendations }) => {
  if (!recommendations.some(x => x.availability)) {
    return false;
  }

  let firstRow = [<Typography as="th" key="empty_header_cell_availability" size={0.875} font={"bold"}>Availability</Typography>];
  for (let i = 1, ilen = recommendations.length; i < ilen; i++) {
    firstRow.push(
      <Typography as={"td"} size={0.875} tone={"subtle"} key={`comparison_pickup_${i}`}>
        {recommendations[i].availability}
      </Typography>
    );
  }
  return <>
    <tr className={styles.dataRow}>{firstRow}</tr>

  </>;
}

const ProductComparisonKeyValues = ({ recommendations }) => {
  const rows = [];

  for (let i = 0, ilen = recommendations[0].comparisonLabels.length; i < ilen; i++) {
    let thisRow = [];
    thisRow.push(
      <Typography as="th" key={`comparison_label_${i}`} size={0.875} font={"bold"}>{recommendations[0].comparisonLabels[i]}</Typography>
    );

    for (let j = 1, jlen = recommendations.length; j < jlen; j++) {
      thisRow.push(<td key={`comparison_value_rec_${j}_value_${i}`}>
        {recommendations[j].comparisonValues[i] === "Yes" ? <Svg icon={'check'} intent={'positive'} size={0.875} /> :
          recommendations[j].comparisonValues[i] === "No" ? <Svg icon={'unavailable'} intent={'negative'} size={0.875} /> :
            recommendations[j].comparisonValues[i] === "N/A" ? null :
              <Typography key={`comparison_value_rec_${j}_value_${i}`} size={0.875}>{recommendations[j].comparisonValues[i]}</Typography>
        }
      </td>
      );
    }
    thisRow = <tr key={`last_row_comparison_cell_${i}`} className={styles.dataRow}>{thisRow}</tr>;
    rows.push(thisRow);
  }

  return rows;
};

const ProductComparisonATCRow = ({ recommendations, dataTestId, treatment }) => {
  if (!recommendations.some(x => x.availability)) {
    return false;
  }

  let thisRow = [<th key="empty_header_cell_atc"></th>];
  for (let i = 1, ilen = recommendations.length; i < ilen; i++) {
    if(recommendations[i].availability != 'Not Available'){
      thisRow.push(
        <td key={`comparison_product_atc_${i}`}>
          <ProductComparisonATC recommendation={recommendations[i]} dataTestId={dataTestId} treatment={treatment} />
        </td>
      );
    } else {
      thisRow.push(
        <td></td>
      )
    }
  }
  return <tr>{thisRow}</tr>;
};

const ProductComparisonATC = ({ recommendation, dataTestId, treatment }) => {
  const [waiting, setWaiting] = useState();
  const {
    handleAddToCartPRP,
    isVariantDetailsDrawerOpen,
    isKitDetailsDrawerOpen,
    isAtcDrawerOpen,
  } = useContext(AddToCartExperienceContext);

  useEffect(() => {
    if (isVariantDetailsDrawerOpen || isKitDetailsDrawerOpen || isAtcDrawerOpen)
      setWaiting(false);
  }, [isVariantDetailsDrawerOpen, isKitDetailsDrawerOpen, isAtcDrawerOpen]);

  return <Button
    intent={"positive"}
    isWaiting={waiting}
    fill
    invert
    segmentEvent={createClickedSegmentEvent('CompareToolAddToCart')}
    onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
      setWaiting(true);
      useRecentHistory.SetMostRecentProductList("rec", `${dataTestId}_${treatment}`)
      handleAddToCartPRP(
        recommendation.productPageId,
        recommendation.skuBaseNumber,
        recommendation.productPageUrl
      );
    }}
    size={"small"}
  >
    <Typography className={styles.addToCart} size={0.875} font={"bold"}>
      Add to Cart
    </Typography>
  </Button>
}

const ProductComparisonBrandRow = ({ recommendations }) => {
  let thisRow = [
    <Typography as="th" key={"empty_header_cell_image"} size={0.875} font={"bold"}>
      Brand
    </Typography>,
  ];
  for (let i = 1, ilen = recommendations.length; i < ilen; i++) {
    thisRow.push(
      <Typography as="td" size={0.875} key={`comparison_brand_${i}`}>
        {recommendations[i].brand}
      </Typography>
    );
  }
  return <tr className={styles.dataRow}>{thisRow}</tr>;
};

const ProductComparisonPriceRow = ({ recommendations }) => {
  let thisRow = [<th key="empty_header_cell_price"></th>];
  for (let i = 1, ilen = recommendations.length; i < ilen; i++) {
    thisRow.push(
      <Typography as="td" size={0.875} font={"bold"} key={`comparison_pricing_${i}`}>
        {recommendations[i].pricing?.priceString}
        {recommendations[i].unit && recommendations[i].pricing?.priceString && (
          <Typography
            className={styles.units}
            size={0.75}
            tone={"subtle"}
            inline
          >
            {` /${recommendations[i].unit}`}
          </Typography>
        )}
        {recommendations[i].pricing?.strikeThroughPrice && (
          <Typography
            size={0.75}
            tone={"subtle"}
            strikethrough
            dataTesting={`strikethrough_price_${recommendations[i].skuBaseNumber}`}
          >
            {recommendations[i].pricing.strikeThroughPrice}
          </Typography>
        )}
      </Typography>
    );
  }
  return <tr className={styles.productInfoRow}>{thisRow}</tr>;
};

const ProductComparisonRatingRow = ({ recommendations }) => {
  let thisRow = [<th key="empty_header_cell_rating"></th>];
  for (let i = 1, ilen = recommendations.length; i < ilen; i++) {
    thisRow.push(
      <td key={`comparison_rating_${i}`}>
        {recommendations[i].reviewCount > 0 && (
          <>
            <Rating ratingValue={recommendations[i].rating} size={1} />
            <Typography
              size={0.75}
              tone={"subtle"}
              inline
              className={styles.review_count}
            >
              {` (${recommendations[i].reviewCount || 0})`}
            </Typography>
          </>
        )}
      </td>
    );
  }
  return <tr className={styles.productInfoRow}>{thisRow}</tr>;
};

const ProductComparisonImageAndTitleRow = ({ recommendations, dataTestId, treatment, miso_id }) => {
  let thisRow = [<th key="empty_header_cell_image"></th>];
  for (let i = 1, ilen = recommendations.length; i < ilen; i++) {
    let price = null
    try{
      price = parseFloat((recommendations[i].pricing?.priceString || 0).replace('$', ''))
    }catch(e){
      console.error(e)
    }
    thisRow.push(
      <td key={`comparison_image_and_title_${i}`}>
        <TextLink
          href={recommendations[i].productPageUrl}
          onClick={() => {
            useRecentHistory.SetMostRecentProductList("rec", `${dataTestId}_${treatment}`)

            if(miso_id){
              useRecentHistory.SetRecentMisoId(miso_id, recommendations[i].skuBaseNumber)
            }
          }}
          segmentEvent={{
            event: 'Product Clicked',
            properties: {
              list_id: dataTestId,
              product_id: recommendations[i].skuBaseNumber,
              sku: recommendations[i].skuBaseNumber,
              category: recommendations[i].segmentCategorization,
              name: recommendations[i].displayTitle,
              brand: recommendations[i].brand,
              //variant: null,
              price: price,
              position: i,
              url: recommendations[i].productPageUrl,
              image_url: recommendations[i].imageLink,
              availability: recommendations[i].availabilityEnum,
              rating: recommendations[i].reviewCount ? recommendations[i].rating : 0,
              reviews: recommendations[i].reviewCount,
              audience_affinity: getSuperMarketAffinity(recommendations[i]),
              miso: {
                miso_id: miso_id
              }
            }
          }}>
          <div className={styles.imageWrapper}>
            <Image
              src={`https:${recommendations[i].imageLink
                ? recommendations[i].imageLink
                : "//content.speedwaymotors.com/OtherImages/missingimage2.jpg"
                }`}
              alt={recommendations[i].displayTitle}
              width={124}
              height={124}
            />
          </div>
          <Typography
            size={0.875}
            tone={"subtle"}
            href={recommendations[i].productPageUrl}
          >
            {`#${recommendations[i].skuBaseNumber}`}
          </Typography>
          <Typography
            size={1}
            tone={"contrast"}
            font={"bold"}
            href={recommendations[i].productPageUrl}
            data-testid={`product_card_title_${recommendations[i].displayTitle}`}
          >
            {recommendations[i].displayTitle}
          </Typography>
        </TextLink>
      </td>
    );
  }
  return <tr className={styles.productImageRow}>{thisRow}</tr>;
};

export default ProductComparisonTable;
