
let cookieKey = "";
let cookieCache = {};

export default function CookieReader(cookieName) {
  // caches cookies on an object to speed up reads
  // whenever ANY cookie value changes, the cache is recalculated (on read)
  // there is a logrocket heartbeat that can kind of cause lots of invalidations
  // however it looks like that one only kicks off every 5-10 seconds... so this still gets us a lot of value

  if (typeof document !== "undefined") {
    if (cookieKey !== document.cookie) {
      if (cookieKey) {
        console.log("refreshing cookie cache");
      }else{
        console.log("initializing cookie cache")
      }
      const splitCookies = document.cookie.split(";");
      const cookiePairs = splitCookies.map((cookie) => {
        const [key, value] = cookie.split("=")
        return [key.trim(), value]
      });
      const cookieObject = Object.fromEntries(cookiePairs);
      cookieCache = cookieObject;
      window.Clutch = window.Clutch || {};
      window.Clutch.cookies = cookieObject;
      cookieKey = document.cookie;
    }
    return cookieCache[cookieName] || "";
  }
  return "";
}

export function GetBrowserUserValues() {
  const userVin = CookieReader("VIN");
  const userGuid = CookieReader("WebUserGuid");
  const isBot = (userVin || "").toLowerCase().startsWith("bot-");

  // Default isGpcEnabled to false, otherwise we will break all tracking services in Chrome. Ideally we'd
  // default to true if we don't know if they want GPC enabled but Chrome prevents us from doing so :(
  const isWindowGpcEnabled = navigator?.globalPrivacyControl ?? false;
  const isGpcEnabled =
    // This is used for Selenium testing. The tests should be able to manually set this in order
    // to assert if tracking happens or not
    CookieReader("EnableGpc") || isWindowGpcEnabled;

  const cookies = {
    userVin,
    userGuid,
    isBot,
    isEmployee:
      CookieReader("ExcludeIpFromAnalytics") ||
      CookieReader("ExcludeEmailFromAnalytics"),
    isGpcEnabled,
  };
  return cookies;
}

export function DeleteCookie(name) {
  if (typeof document !== "undefined") {
    document.cookie = `${name}=; path=/; domain=${document.location.host}; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  }
}
