import React from "react";

import useSegment from "Hooks/useSegment"

import EngineDropdown from "./EngineDropdown/EngineDropdown";
import { createViewedSegmentEvent } from "Utilities/Instrumentation/Impressions/impressionSegmentEvents";


const AddEngineView = () => {
  const { sendSegmentTrackEvent } = useSegment()

  const viewEvent = createViewedSegmentEvent("Add Engine View");
  sendSegmentTrackEvent(viewEvent)

  return (
    <EngineDropdown
      buttonText={"Add Engine"}
      buttonIcon={"v8-engine"}
      segmentEventText={"Add Engine"}
    />
  );
};

export default AddEngineView;
