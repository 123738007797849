import React, { useEffect, useRef, useState } from "react"
import AutoComplete from "Atoms/Form/Elements/AutoComplete/AutoComplete";
import Button from "Molecules/Button";

import { useVehicleStore } from "Stores/VehicleStore"
import { useThemeStore } from "Stores/ThemeStore"
import { getProperUrl } from "Contexts/VehicleContext/CoreVehicleRequests"
import { createClickedSegmentEvent, createViewedSegmentEvent } from "Utilities/Instrumentation/Impressions/impressionSegmentEvents";
import useSegment from "Hooks/useSegment";
import styles from "Organisms/CustomerProjectDrawer/CustomerProjectDrawer.module.scss";

const initialDropdownState = {
    engineMake: {
        value: null,
        label: null
    },
    engineFamily: {
        value: null,
        label: null
    },
    engineDisplacement: {
        value: null,
        label: null
    }
}

const EngineDropdown = ({
    buttonText,
    buttonIcon,
    segmentEventText,
    vehicleBaseId,
    raceTypeId,
    existingState
}) => {
    const [dropdownState, setDropdownState] = useState(initialDropdownState)
    const [submitHref, setSubmitHref] = useState(null)
    const [sentSegmentViewedEvent, setSentSegmentViewedEvent] = useState(false)

    const engineMakes = useVehicleStore((x) => x.context.engineMakes)
    const engineFamilies = useVehicleStore((x) => x.context.engineFamilies)
    const engineDisplacements = useVehicleStore((x) => x.context.engineDisplacements)
    const reloadCurrentLocation = useVehicleStore((x) => x.context.reloadCurrentLocation)
    
    const fetchEngineData = useVehicleStore((x) => x.fetchEngineData)
    const isFetching = useVehicleStore((x) => x.context.isFetching); 
    const setIsLoading = useThemeStore((x) => x.setIsLoading)
    const addCustomerProject = useVehicleStore((x) => x.addCustomerProject)
    const updateCustomerProjectData = useVehicleStore((x) => x.updateCustomerProjectData)
    const selectCustomerProject = useVehicleStore((x) => x.selectCustomerProject)
    const { sendSegmentTrackEvent } = useSegment()

    const engineMakeDropdownRef = useRef()
    const engineFamilyDropdownRef = useRef()
    const engineDisplacementDropdownRef = useRef()

    const isEngineMakeDisabled = isFetching || !engineMakes || engineMakes?.length === 0;
    const isEngineFamilyDisabled = isEngineMakeDisabled || isFetching || !engineFamilies || engineFamilies?.length === 0 || !dropdownState.engineMake.value;
    const isEngineDisplacementDisabled = isEngineFamilyDisabled || isFetching || !engineDisplacements || engineDisplacements?.length === 0 || !dropdownState.engineFamily.value;

    useEffect(() => {
        const { engineMake, engineFamily, engineDisplacement } = dropdownState
        if (engineMake.value && engineFamily.value && engineDisplacement.value)
            addOrUpdateEngine()
        else {
            fetchEngineData(engineMake.value, engineFamily.value, vehicleBaseId, raceTypeId)
            if (engineFamily.value) {
                const engineFamilyInfo = engineFamilies.find(x => x.optionId === engineFamily.value)
                if (engineFamilyInfo) {
                    const url = getProperUrl(engineFamilyInfo)
                    if (url) setSubmitHref(url)
                }
            }
        }
    }, [dropdownState])

    useEffect(() => {
        determineDropdownFocus(
            dropdownState,
            engineMakes,
            engineFamilies,
            engineMakeDropdownRef,
            engineFamilyDropdownRef,
            isEngineMakeDisabled,
            isEngineFamilyDisabled
        )
    }, [dropdownState, engineMakes, engineFamilies, engineDisplacements, isEngineMakeDisabled, isEngineFamilyDisabled])


    const addOrUpdateEngine = async () => {
        setIsLoading(true)
        try {
            const engineMakeKey = dropdownState.engineMake.value
            const engineFamilyKey = dropdownState.engineFamily.value
            const engineDisplacementKey = dropdownState.engineDisplacement.value

            let manufacturerId
            let makeId
            let seriesId
            let configurationId
            let displacementId
            let versionId
            let qualifierId

            if (engineDisplacementKey && engineDisplacementKey.length > 0){
                var engineDisplacementIds = engineDisplacementKey.split("-");
                manufacturerId = engineDisplacementIds[0]
                makeId = engineDisplacementIds[1]
                seriesId = engineDisplacementIds[2]
                configurationId = engineDisplacementIds[3]
                displacementId = engineDisplacementIds[4]
                if (engineDisplacementIds.length > 6) {
                    versionId = engineDisplacementIds[5]
                    qualifierId = engineDisplacementIds[6]
                }
            }
            else if (engineFamilyKey && engineFamilyKey.length > 0) {
                var engineFamilyIds = engineFamilyKey.split("-");
                manufacturerId = engineFamilyIds[0]
                makeId = engineFamilyIds[1]
                seriesId = engineFamilyIds[2]
                configurationId = engineFamilyIds[3]
            }
            else if (engineMakeKey && engineMakeKey.length > 0) {
                var engineMakeIds = engineMakeKey.split("-");
                manufacturerId = engineMakeIds[0]
                makeId = engineMakeIds[1]
            }

            const engineIds = {
                engineManufacturerId: parseInt(manufacturerId, 10),
                engineMakeId: parseInt(makeId, 10),
                engineSeriesId: parseInt(seriesId, 10),
                engineConfigurationId: parseInt(configurationId, 10)
            }

            if (displacementId) engineIds.engineDisplacementId = parseInt(displacementId, 10)
            if (versionId) engineIds.engineVersionId = parseInt(versionId, 10)
            if (qualifierId) engineIds.engineQualifierId = parseInt(qualifierId, 10)

            if (engineIds.engineDisplacementId === 1 && 
                engineIds.engineVersionId != null &&
                engineIds.engineQualifierId != null &&
                (engineIds.engineVersionId > 1 || engineIds.engineQualifierId > 1)) {
                    engineIds.engineDisplacementId = null
            }

            let response
            let addedOrUpdatedProjectId;
            if (existingState) {
                response = await updateCustomerProjectData({
                    year: existingState.year?.value,
                    makeId: existingState.make?.value,
                    make: existingState.make?.label,
                    modelId: existingState.model?.value,
                    model: existingState.model?.label,
                    raceTypeId: existingState.raceType?.value ?? raceTypeId,
                    raceTypeDisplayName: existingState.raceType?.label,
                    engineIds,
                    vehicleBaseId,
                    customerProjectId: existingState.customerProjectId
                })
                addedOrUpdatedProjectId = response.updatedCustomerProjectId
            }
            else {
                response = await addCustomerProject({
                    engineIds,
                    vehicleBaseId,
                    raceTypeId
                })
                addedOrUpdatedProjectId = response.addedCustomerProjectId
            }

            if (!response) {
                setIsLoading(false)
                return
            }

            const selectedProject = response.projects.find(project => project.projectId === addedOrUpdatedProjectId)
            const url = getProperUrl(selectedProject);       
            await selectCustomerProject(selectedProject.projectId, url)

            if (reloadCurrentLocation) {
                // return false or it skips to line below
                window.location.reload();
                return false;
            }
            window.location.href = url;
        } catch (ex) {
          console.error(ex);
        }
        setIsLoading(false);
    }


    const engineMakeOptions = engineMakes?.map((engineMake) => {
        return {
            label: engineMake.displayName,
            value: engineMake.optionId
        }
    })
    const engineFamilyOptions = engineFamilies?.map((engineFamily) => {
        return {
            label: engineFamily.displayName,
            value: engineFamily.optionId
        }
    })
    const engineDisplacementOptions = engineDisplacements?.map((engineDisplacement) => {
        return {
            label: engineDisplacement.displayName,
            value: engineDisplacement.optionId
        }
    })

    useEffect(() => {
        if (!sentSegmentViewedEvent) {
            console.log("New Engine Interaction") // for LR
            const viewEvent = createViewedSegmentEvent("Engine Dropdown");
            sendSegmentTrackEvent(viewEvent)
            setSentSegmentViewedEvent(true)
       }
    })

    return (
        <>
            <AutoComplete 
                options={engineMakeOptions}
                onChange={(option) => 
                    setDropdownState(() => ({
                        ...initialDropdownState,
                        engineMake: {
                            value: option.value,
                            label: option.label
                        }
                    }))}
                selectedValue={dropdownState.engineMake}
                isDisabled={isEngineMakeDisabled}
                label={"Engine Make"}
                boldLabel={true}
                placeholder={"Select an Engine Make"}
                useNativeSelect={false}
                ref={engineMakeDropdownRef}
            />
            <AutoComplete 
                options={engineFamilyOptions}
                onChange={(option) => 
                    setDropdownState((prevState) => ({
                        ...initialDropdownState,
                        engineMake: prevState.engineMake,
                        engineFamily: {
                            value: option.value,
                            label: option.label
                        }
                    }))}
                selectedValue={dropdownState.engineFamily}
                isDisabled={isEngineFamilyDisabled}
                label={"Engine Family"}
                boldLabel={true}              
                placeholder={"Select an Engine Family"}
                useNativeSelect={false}
                ref={engineFamilyDropdownRef}
            />
            <AutoComplete 
                options={engineDisplacementOptions}
                onChange={(option) => {
                    setDropdownState((prevState) => ({
                        ...initialDropdownState,
                        engineMake: prevState.engineMake,
                        engineFamily: prevState.engineFamily,
                        engineDisplacement: {
                            value: option.value,
                            label: option.label
                        }
                    }))
                    option.segmentEvent = createClickedSegmentEvent(segmentEventText + " - Displacement")
                }}
                selectedValue={dropdownState.engineDisplacement}
                isDisabled={isEngineDisplacementDisabled}
                label={"Engine Displacement"}
                addonLabel={'(Optional)'}
                boldLabel={true}
                placeholder={"Select an Engine Displacement"}
                useNativeSelect={false}
                ref={engineDisplacementDropdownRef}
            />
            <Button
                className={styles.dropdownAddButton}
                size={"small"}
                intent={"action"}
                type={"button"}
                icon={buttonIcon}
                text={buttonText}
                testingName={buttonText}
                fill
                onClick={() => {
                    addOrUpdateEngine()
                }}
            segmentEvent={createClickedSegmentEvent(segmentEventText + " - Family")}
                disabled={dropdownState.engineFamily.value === null || !submitHref}
            />
        </>
    )
}

const determineDropdownFocus = (
    dropdownState,
    engineMakes,
    engineFamilies,
    engineMakeDropdownRef,
    engineFamilyDropdownRef,
    isEngineMakeDisabled,
    isEngineFamilyDisabled,
) => {
    const { engineMake, engineFamily, engineDisplacement } = dropdownState
    
    const isEngineMakeSelected = engineMake.value
    const isEngineFamilySelected = engineFamily.value
    const isEngineDisplacementSelected = engineDisplacement.value

    if (
        engineMakes && 
        !isEngineMakeSelected && 
        !isEngineFamilySelected && 
        !isEngineDisplacementSelected && 
        engineMakeDropdownRef?.current?.focus &&
        !isEngineMakeDisabled
    )
        engineMakeDropdownRef.current.focus()
    else if (
        engineFamilies && 
        isEngineMakeSelected && 
        !isEngineFamilySelected && 
        !isEngineDisplacementSelected && 
        engineFamilyDropdownRef?.current?.focus && 
        !isEngineFamilyDisabled
    )   
        engineFamilyDropdownRef.current.focus()
}

export default EngineDropdown