import React from 'react'
import PropTypes from 'prop-types'
import ClassNames from 'classnames'

import Svg from 'Atoms/Svg'

import PanelContext from 'Contexts/PanelContext'

import ColorFinder from 'Utilities/ColorFinder'

import Brands from 'Constants/Brand'
import Intents from 'Constants/Intent'
import { Svgs } from "Constants/Svg";

import styles from './Area.module.scss'
import styled from 'styled-components'

const propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    icon: PropTypes.oneOf(Svgs),
    brand: PropTypes.oneOf(Brands),
    intent: PropTypes.oneOf(Intents),
    fill: PropTypes.bool,
    className: PropTypes.string,
    noBorder: PropTypes.bool,
}

const Area = ({
    children,
    icon,
    brand,
    intent,
    fill,
    noMargin = true,
    noPadding,
    className,
    noBorder = false,
    invertIcon = false,
    centerIcon = true,
    ...props
}) => {


    const { color } = ColorFinder(intent, null, brand)
    const hue = color.split(',')[0].replace('hsla(', '')
    const saturation = color.split(',')[1]
    const lightness = color.split(',')[2]
    const alpha = color.split(',')[3].replace(')', '')
    const backgroundColor = `hsla(${hue},${saturation},${lightness},${0.2 * alpha})`
    const borderColor = noBorder ? 'transparent' : color
    const iconBackgroundColor = invertIcon ? 'transparent' : color
    const iconBrand = invertIcon ? brand : null
    const iconIntent = invertIcon ? intent : null

    const classes = ClassNames(
        styles.area,
        noMargin && styles.area_no_margin,
        fill && styles.area_fill,
        noPadding && styles.area_no_padding,
        className,
        icon && styles.area_icon_wrapper
    );

    if (icon) {

        const panelContextValue = {
            brand: invertIcon ? null : brand,
            intent: invertIcon ? null : intent
        }

        return (
            <StyledArea className={classes} borderColor={borderColor} backgroundColor={backgroundColor} {...props}>
                <StyledAreaIcon backgroundColor={iconBackgroundColor} className={ClassNames(styles.area_icon, centerIcon && styles.area_icon_centered)}>
                    <PanelContext.Provider value={panelContextValue}>
                        <Svg icon={icon} brand={iconBrand} intent={iconIntent} size={1.5} className={styles.area_icon_svg} />
                    </PanelContext.Provider>
                </StyledAreaIcon>
                <span className={styles.area_content}>
                    {children}
                </span>
            </StyledArea>
        )
    }

    return (
        <StyledArea className={classes} borderColor={borderColor} backgroundColor={backgroundColor} {...props}>
            <div>
                {children}
            </div>
        </StyledArea>
    )
}

const StyledArea = styled.div`
    border-color: ${props => props.borderColor};
    background-color: ${props => props.backgroundColor};
`

const StyledAreaIcon = styled.div`
    background-color: ${props => props.backgroundColor};
    padding: 0.5rem;
    border-radius: 100%;
    margin-right: 1rem;
`

Area.propTypes = propTypes

export default Area