import React from 'react'
import Typography from "Atoms/Typography";
import Card from 'Atoms/Card'
import GetTimeAgoString from 'Utilities/DateTime/TimeAgo';
import Image from "next/image";
import styles from "./EventCard.module.scss";
const EventCard = ({ event, segmentEvent }) => {


    return <Card targetBlank className={styles.card} segmentEvent={segmentEvent} href={event.purchaseurl} hover>
        <div className={styles.image}>
            <Image
                src={event.image?.iconImageUrl}

                alt={event.name}
                width={96}
                sizes="(max-width: 640px) 100vw,
                (max-width: 1024px) 33vw,
                25vw"
            />
        </div>

        <Typography size={0.875} className={styles.timeAgo} tone={"subtle"}>{GetTimeAgoString(event.date)}</Typography>
        <Typography size={0.75} tone={"subtle"}>{event.displayDate}</Typography>
        <Typography size={1} font={"bold"}>{event.name}</Typography>
        <Typography size={0.875} tone={"subtle"}>{event.description}</Typography>
    </Card>

}

export default EventCard

