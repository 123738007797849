import React from 'react'
import styles from './Loader.module.scss'
import PropTypes from 'prop-types';

const Loader = ({isLoading}) => {
    return (
        <>
        {isLoading ? 
        <div className={styles.ds9LoadingIcon} data-testid={'loader'}>
            <div className={styles.ds9LoadingIconSpinner}></div>
        </div> 
        : null}
        </>
    )  
}

Loader.propTypes =  {
    isLoading: PropTypes.bool
}

export default Loader