import React, { createContext, useState, useContext, useEffect, useCallback } from "react";


const initialAlertState = {
  alertType: "", // alert, confirm, prompt
  isOpen: false,
  message: null,
  successCallbackClient: () => { },
  cancelCallbackClient: () => { },
  promptInputConfig: {
    inputValue: null,
    inputLabel: null,
    inputValidationPredicates: [],
    failedValidationMessages: [], // the results of inputValidationPredicates if validation fails
  },
};

const useAlert = () => {
  const [alertState, setAlertState] = useState(initialAlertState);

  const onConfirmClick = useCallback(() => {
    const inputValue = alertState?.promptInputConfig?.inputValue;
    // handle validation for "prompt" types of alerts
    if (alertState.alertType == "prompt") {
      try {
        const failedValidationMessages =
        alertState.promptInputConfig.inputValidationPredicates
            ?.map((p) => ({
              isValid: p?.isValidPredicate(inputValue) ?? true,
              message: p?.message ?? "",
            }))
            .filter((p) => !p.isValid)
            .map((p) => p.message) || [];

        if (failedValidationMessages.length) {
          setAlertState({
            ...alertState,
            promptInputConfig: {
              ...alertState.promptInputConfig,
              failedValidationMessages
            }
          });
          return;
        }
        
      } catch (e) {
        console.error(
          "Validation failed in NotificationContext.js ShowAlertInternal with",
          e
        );
      }
    }


    if (alertState.successCallbackClient) {
      alertState.successCallbackClient(inputValue);
    }


    ClearAlert();
  }, [alertState]);

  const ShowAlertInternal = (alertConfig) => {
    setAlertState({

      ...alertConfig,
      isOpen: true,
      
    });
  };

  const SetPromptAlertInputValue = (inputValue) => {
    setAlertState({
      ...alertState,
      promptInputConfig: {
        ...alertState.promptInputConfig,
        inputValue
      }
    });
  };

  const ClearAlert = () => {
    setAlertState({ ...initialAlertState });
  };

  const ShowAlert = (message) => {
    ShowAlertInternal({
      alertType: "alert",
      message,
    });
  };

  const ShowConfirmAlert = (message, successCallback) => {
    ShowAlertInternal({
      alertType: "confirm",
      message,
      successCallbackClient: successCallback,
    });
  };

  const ShowPromptAlert = (message, successCallback, promptInputConfig) => {
    ShowAlertInternal({
      alertType: "prompt",
      message,
      successCallbackClient: successCallback,
      promptInputConfig: {
        ...promptInputConfig,
      },
    }); 
  };



  return {
    SetPromptAlertInputValue,
    ClearAlert,
    ShowAlert,
    ShowConfirmAlert,
    ShowPromptAlert,
    alertState,
    onConfirmClick
  }
}

export default useAlert;

