'use client'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ClassNames from 'classnames'

import ColorFinder from 'Utilities/ColorFinder'
import LayerColorFinder from 'Utilities/LayerColorFinder'

import Brands from 'Constants/Brand'
import Intents from 'Constants/Intent'
import Tones from 'Constants/Tone'
import svgDict from 'Constants/Svg'
import { Svgs } from 'Constants/Svg'
import Sizes from 'Constants/Size'

import styles from './Svg.module.scss'
import styled from 'styled-components'

const propTypes = {
    className: PropTypes.string,
    icon: PropTypes.oneOf(Svgs),
    brand: PropTypes.oneOf(Brands),
    intent: PropTypes.oneOf(Intents),
    tone: PropTypes.oneOf(Tones),
    layer: PropTypes.number,
    size: PropTypes.oneOf(Sizes),
    href: PropTypes.string,
    title: PropTypes.string,
    disabled: PropTypes.bool,
    hoverable: PropTypes.bool
}

// IMPORTANT
// If you need to support IE 11 then please include the IESvg polyfill from the utilities folder in your index

const Svg = ({
    className,
    icon,
    tone,
    intent,
    brand,
    layer,
    size,
    href,
    title,
    children,
    disabled,
    hoverable,
    testId,
    targetBlank,
    ...props
}) => {
    const target = targetBlank ? '_blank' : undefined;
    const rel = targetBlank ? 'noopener noreferrer' : undefined;
    // Make sure that any new size values you add are sorted
    const sizes = [
        0.5,
        0.75,
        0.875,
        1,
        1.25,
        1.5,
        1.75,
        2,
        2.5,
        3,
        4,
        6,
        7,
        8,
        9,
        10,
        11,
        12
    ]

    if (size > sizes[sizes.length - 1]) {
        size = sizes[sizes.length - 1]
    } else {
        for (const potentialSize of sizes) {
            if (size <= potentialSize) {
                size = potentialSize
                break
            }
        }
    }

    if (icon == 'ios_share') {
        icon = 'share'
    }

    const [svg, setSvg] = useState(icon)

    useEffect(() => {
        setSvg(icon)
    }, [icon])

    tone = tone && tone.toLowerCase()
    intent = intent && intent.toLowerCase()
    brand = brand && brand.toLowerCase()

    let color
    let hoverColor

    if (brand || intent || tone) {
        const colorFinderColor = ColorFinder(intent, tone, brand, disabled)
        color = colorFinderColor.color
        hoverColor = colorFinderColor.hoverColor
    }
    else if (layer) {
        color = LayerColorFinder(layer)
        hoverColor = color
    } else {
        const colorFinderColor = ColorFinder(null, 'normal')
        color = colorFinderColor.color
        hoverColor = colorFinderColor.hoverColor
    }

    if(!icon){
        console.warn("SVG was used with falsey icon prop.");
        return false
    }

    const { onClick } = props
    const classnames = ClassNames(className, styles.svg, href && styles.svgLink, onClick && styles.svgLink)

    const onHover = icon == 'heart' ? () => { setSvg('heart-selected') } : () => { }
    const onMouseLeave = icon == 'heart' ? () => { setSvg('heart') } : () => { }

    const svgFile = svgDict[icon]
    if (!svgFile) {
        console.warn(`SVG for icon '${icon}' not found.`);
        return false;
    }

    const svgString = `${svgFile}#icon-`

    if (href || onClick || hoverable) {
        return (
            <StyledAnchor
                as={href ? 'a' : 'div'}
                href={href}
                target={target}
                rel={rel}
                className={styles.svgAnchor}
                title={title}
                color={color}
                size={size}
                onMouseEnter={onHover}
                onMouseLeave={onMouseLeave}
                data-testid={testId}
                {...props}
            >
                <StyledSvgLink
                    className={classnames}
                    color={color}
                    size={size}
                    hoverColor={hoverColor}
                >
                    <use xlinkHref={`${svgString}${svg}`} {...(svg == 'share' ? { className: ClassNames(styles.hide_apple) } : {})} />
                    {svg == 'share' ? <use xlinkHref={`${svgString}ios_share`} className={styles.show_apple} /> : false}
                </StyledSvgLink>
                {children}
            </StyledAnchor>
        )
    }

    return (
        <StyledSvg className={classnames} color={color} size={size} onMouseOver={onHover} onMouseLeave={onMouseLeave} data-testid={testId} {...props}>
            <use xlinkHref={`${svgString}${svg}`} {...(svg == 'share' ? { className: ClassNames(styles.hide_apple) } : {})} />
            {svg == 'share' ? <use xlinkHref={`${svgString}ios_share`} className={styles.show_apple} /> : false}
        </StyledSvg>
    )
}

const StyledSvg = styled.svg`
    fill: ${(props) => props.color};
    ${(props) => props.size ? `height: ${props.size}rem;` : ''}
    ${(props) => props.size ? `width: ${props.size}rem;` : ''}
`

const StyledSvgLink = styled.svg`
    fill: ${(props) => props.color};
    ${(props) => props.size ? `height: ${props.size}rem;` : ''}
    ${(props) => props.size ? `width: ${props.size}rem;` : ''}
    &:hover {
        fill: ${(props) => props.hoverColor};
    }
`

const StyledAnchor = styled.a`
    ${(props) => props.size ? `height: ${props.size}rem;` : ''}
    ${(props) => props.size ? `width: ${props.size}rem;` : ''}
`

Svg.propTypes = propTypes

export default Svg
