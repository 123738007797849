import React, { useContext, useEffect } from 'react'
import Modal from 'Components/Modal/ModalWithPortal'
import { NavigationContext } from 'Contexts/Navigation/NavigationContext'
import styles from './IosInstallPrompt.module.scss'
import Divider from 'Atoms/Divider'
import Typography from 'Atoms/Typography'
import Svg from 'Atoms/Svg'
import Image from 'next/image'
import { INSTALLPAGEURL, getDeviceInfo } from '../../Hooks/useAppInstall/AppUtilities'

const AppInstallPrompt = () => {
    const { state: navigationState, dispatch: navigationDispatch } = useContext(NavigationContext)


    const onClose = () => {
        navigationDispatch({
            type: 'setIosInstallPromptState',
            payload: {
                iosInstallpromptState: {
                    isOpen: false
                }
            }
        })
    }

    if (!navigationState?.iosInstallpromptState?.isOpen) {
        return;
    }

    const deviceInfo = getDeviceInfo();

    if (deviceInfo.isDesktop) {
        return <DivertDesktopToMobile onClose={onClose} />
    }


    if (!deviceInfo.isAppleDevice) {
        console.error(`Tried to show apple device specific install instructions to non-supported device`, deviceInfo)
        //window.location.href = INSTALLPAGEURL;
        return false;
    }

    const steps = [];
    if (deviceInfo.isIos) {
        if (!deviceInfo.isSafari && deviceInfo.preDatesPWASupport) {
            steps.push(<Typography className={styles.header} as={"li"}>Open our website in the <Typography font={"bold"} as={"span"}>Safari</Typography> web browser</Typography>)
        }
    }
    if (deviceInfo.isOsx) {
        if (deviceInfo.isSafari && deviceInfo.preDatesPWASupport) {
            steps.push(<Typography className={styles.header} as={"li"}>Open our website in the Chrome web browser</Typography>)
        }
    }
    steps.push(<Typography className={styles.header} as={"li"}>Tap on the Share (<Image
        className={styles.example}
        src={`https://content.speedwaymotors.com/OtherImages/share.png.85b6b1e91806c5893fb836ef2dfbb20d.png`}
        width={16}
        height={16} />) icon in your browser</Typography>)

    if (deviceInfo.isIos) {
        steps.push(<Typography className={styles.header} as={"li"}>Select <Typography size={0.875} className={styles.example} as={'span'}>"Add to Home Screen"</Typography> in the menu</Typography>);

    } else if (deviceInfo.isOsx) {
        steps.push(<Typography className={styles.header} as={"li"}>Select <Typography size={0.875} className={styles.example} as={'span'}>"Add to Dock"</Typography> in the menu</Typography>);

    }

    return <Modal
        id={"DivertToDesktopInstallPrompt"}
        outsideClick
        defaultOpened
        customOnClose={onClose}
    >
        <div className={styles.modal}>
            <Typography size={1} className={styles.superHeader}><Svg icon={'SpeedwayMotors_Wheel_Full_Color'} size={2}></Svg>  Install Speedway Motors</Typography>
            <Divider />
            <Typography tone={'subtle'} size={0.875} className={styles.subtitle}>Install our web app on your device to easily access it anytime. No app store. No hassle.</Typography>
            <ol>
                {steps.map(x => x)}
            </ol>


        </div>


    </Modal>
}


const DivertDesktopToMobile = ({ onClose }) => {

    return <Modal
        id={"IosInstallPrompt"}
        outsideClick
        defaultOpened
        customOnClose={onClose}
    >
        <div className={styles.modal}>
            <Typography size={1} className={styles.superHeader}><Svg icon={'SpeedwayMotors_Wheel_Full_Color'} size={2}></Svg>  Speedway Motors App</Typography>
            <Divider />
            <Typography tone={'subtle'} size={0.875} className={styles.subtitle}>Scan the code below with your phone's camera.</Typography>
            <div className={styles.qrCodeRow}>
                <Image
                    className={styles.qrCode}
                    src={'https://content.speedwaymotors.com/OtherImages/qr-code-appinstall.png'}
                    alt={"https://www.speedwaymotors.com/pages/appinstall?source=desktopAppInstallPromptQRcode"}
                    width={256}
                    height={256}
                />
            </div>

        </div>


    </Modal>
}

export default AppInstallPrompt