
"use client"
import createZustandContext from "Utilities/Zustand/createZustandContext";
import RestRequest from "Utilities/RestClient/RestClient";
import { StorageAccessor } from "../../Utilities/LocalStorageUtility/LocalStorageUtility";
import { GetUserGuid } from "../../Utilities/CookieUtilties/UserCookieUtilities";
import LoyaltyUserStatus from "../../Molecules/Loyalty/LoyaltyUserStatus";


const DEFAULTSTATE = {
    loyaltyLink: null,
    fetchingLoyaltyLink: false
}

const BuildStorageKey = () => {
    return `loyaltylink_${GetUserGuid()}`

}
const TryGetStaleLinkFromStorage = () => {
    const storageKey = BuildStorageKey();
    const staleFromStorage = StorageAccessor.sessionStorage.getObj(storageKey)
    if (staleFromStorage) {
        staleFromStorage.isStale = true;
    }
    return staleFromStorage;
}

const LoyaltyStore = createZustandContext(
    ({ set, get }) => {
        const getLoyaltyLink = () => {


            let result = get().loyaltyLink
            let staleResult = null;
            if (!result) {
                staleResult = TryGetStaleLinkFromStorage();
            }

            if (get().fetchingLoyaltyLink) {
                return result ?? staleResult;
            }

            if (!result) {
                set(state => {
                    return {
                        ...state,
                        loyaltyLink: staleResult,
                        fetchingLoyaltyLink: true
                    }

                })
                RestRequest(`/api/customer/loyalty/getloyaltylink`).then(x => {
                    if (!x || !x.userStatus || x.userStatus == LoyaltyUserStatus.unknown) {
                        console.error(`error getting loyalty link`);
                        return;
                    }
                    x.isStale = false;
                    set(state => {
                        return {
                            ...state,
                            loyaltyLink: x,
                            fetchingLoyaltyLink: false
                        }

                    })
                    StorageAccessor.sessionStorage.setObj(BuildStorageKey(), x, 10)
                })
            }

            return result;

        }

        return {
            getLoyaltyLink,
            ...DEFAULTSTATE
        }

    },
    {
        name: "LoyaltyStore",
        missingSelectorBehavior: "warn",
    }

)

export const useLoyaltyStore = LoyaltyStore.useStore;
export const LoyaltyStoreProvider = LoyaltyStore.Provider







