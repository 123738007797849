import { useVehicleStore } from "Stores/VehicleStore";

const useCustomerProjects = () => {
  const customerProjects = useVehicleStore((x) => x.context.customerProjects);

  const vehicles = customerProjects?.filter(
    (customerProject) => customerProject?.vehicleBaseId
  );
  const raceTypes = customerProjects?.filter(
    (customerProject) => customerProject?.raceTypeId
  );
  const engines = customerProjects?.filter(
    (customerProject) =>
      !customerProject?.vehicleBaseId &&
      !customerProject?.raceTypeId &&
      customerProject?.engineDisplayName
  );

  const totalVehicles = vehicles ? vehicles.length : 0;
  const totalRaceTypes = raceTypes ? raceTypes.length : 0;
  const totalEngines = engines ? engines.length : 0;
  const total = totalVehicles + totalRaceTypes + totalEngines;
  const hasCustomerProjects = total > 0;

  return {
    vehicles,
    raceTypes,
    engines,
    totalVehicles,
    totalRaceTypes,
    totalEngines,
    total,
    hasCustomerProjects
  };
};

export default useCustomerProjects;
