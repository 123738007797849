import React from "react";

import TextLink from "Atoms/TextLink";
import { useVehicleStore } from "Stores/VehicleStore";

const ShowAll = ({
  showAllState,
  setShowAll,
  type,
  customerProjects,
}) => {

  let buttonText = "";
  if (!customerProjects || customerProjects?.length <= 3) {
    return false;
  }

  if (showAllState) {
    buttonText = `Hide ${type}s`;
  } else {
    buttonText = `Show all ${type}s`;
  }

  return (
    <TextLink
      intent={"action"}
      onClick={() => {
        setShowAll(!showAllState);
      }}
      underlineHover
    >
      {buttonText}
    </TextLink>
  );
};

export default ShowAll;
