import React, { useEffect, useContext, useState } from 'react'
import Script from 'next/script'

import { useUserStore } from "Stores/UserStore/UserStore";

import { useVehicleStore } from "Stores/VehicleStore";

const MisoInteraction = ({misoApiUrl}) => {
    const userVin = useUserStore((x) => x.context.userVin);
    const userHydrated = useUserStore((x) => x.context.hydrated);
    const isBot = useUserStore((x) => x.context.isBot);
    const isCustomerInternal = useUserStore((x) => x.context.isCustomerInternal);
    const getGuid = useUserStore((x) => x.context.getGuid);
    const userGuid = useUserStore((x) => x.context.userGuid);

    const hydrated = useVehicleStore(x => x.context.hydrated)
    const selectedCustomerProject = useVehicleStore(x => x.context.selectedCustomerProject)

    const [shouldRenderScript, setShouldRenderScript] = useState(false)

    useEffect(() => {
        if ((userHydrated && userVin() && !isBot()) && hydrated) {
            setShouldRenderScript(true)
        }

    }, [hydrated && userHydrated])

    if (!shouldRenderScript) {
        return false;
    }

    if (!misoApiUrl) {
        return false
    }

    if (isCustomerInternal) {
        return false
    }

    let yearId = selectedCustomerProject?.yearId
    let makeId = selectedCustomerProject?.makeId
    let modelId = selectedCustomerProject?.modelId
    let raceTypeId = selectedCustomerProject?.raceTypeId


    let misoInteractionScript = `
    function fireMisoInteraction(interactionData, isArticle = false){

        if(!'${getGuid()}' || !'${userVin()}' || !interactionData){
            console.warn("Must provide a guid, vin, and interaction data for miso interaction.")
            return
        }
        if(${isCustomerInternal}){
            return
        }
        let productId = interactionData.prod_id
        if (isArticle) {
            productId = 'Article-' + interactionData.prod_id
        }

        var payload =
            {
                "data": [{
                    "product_ids": [productId],
                    "user_id": '${userGuid}',
                    "anonymous_id": '${userVin()}',
                    "timestamp": new Date(),
                }]
        }
        if (${raceTypeId}) {
            payload.data[0].context =
            {
                "custom_context":
                {
                    "raceTypeId": ${raceTypeId}
                }
            }
        }
        else if (${yearId} && ${makeId} && ${modelId}) {
            payload.data[0].context =
            {
                "custom_context":
                {
                    "yearId": ${yearId},
                    "makeId": ${makeId},
                    "modelId": ${modelId}
                }
            }
        }

        switch (interactionData.eventType) {
            case 'addToList':
                payload.data[0].type = 'add_to_collection';
                payload.data[0].product_ids = [interactionData.sku ? interactionData.sku : productId];
                payload.data[0].product_group_ids = [productId]
                break;
            case 'removeFromList':
                payload.data[0].type = 'remove_from_collection';
                payload.data[0].product_ids = [interactionData.sku ? interactionData.sku : productId];
                payload.data[0].product_group_ids = [productId]
                break;
            case 'addToCart':
                payload.data[0].type = 'add_to_cart';
                payload.data[0].product_ids = [interactionData.sku ? interactionData.sku : productId];
                payload.data[0].product_group_ids = [productId]
                break;
            case 'quickViewView':
                payload.data[0].type = 'product_detail_page_view'
                payload.data[0].product_ids = [interactionData.sku ? interactionData.sku : productId];
                payload.data[0].product_group_ids = [productId]
        }

        if (payload.data[0].type) {
            var xmlhttp = new XMLHttpRequest();   // new HttpRequest instance
            xmlhttp.open("POST", '${misoApiUrl}');
            xmlhttp.setRequestHeader("Content-Type", "application/json");
            xmlhttp.send(JSON.stringify(payload));
        }
    }
    `

    return (
        <Script
            enableOnlyOnNextFrontEnd={true}
            id="miso-interaction-init"
            data-testid="miso-interaction-init"
            strategy="afterInteractive"
        >
            {misoInteractionScript}
        </Script>
    )
}

export default MisoInteraction