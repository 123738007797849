import React, { Fragment, useEffect, useState, useContext } from "react";

import Divider from "Atoms/Divider";
import {
  AddUserVehicle,
  getProperUrl,
  RemoveUserVehicle,
} from "Contexts/VehicleContext/CoreVehicleRequests";
import List from "Molecules/List";
import ListItem from "Molecules/List/ListItem";
import ListItemIcon from "Molecules/List/ListItemIcon";
import ListItemText from "Molecules/List/ListItemText";
import { useThemeStore } from "Stores/ThemeStore";
import { useVehicleStore } from "Stores/VehicleStore";
import { NavigationContext } from 'Contexts/Navigation/NavigationContext'

import CoveringSpinner from "../../../../Atoms/CoveringSpinner";
import useSegment from "../../../../Hooks/useSegment/useSegment";
import { createFitmentRemovedSegmentEventFromCustomerProject } from "../../../../Utilities/Instrumentation/Fitment/fitmentSegmentEvents";
import styles from "./YourVehicles.module.scss";


const YourVehicles = ({ userGuid, services, showAllState, customerProjects=null, ...props }) => {
  const selectCustomerProject = useVehicleStore((x) => x.selectCustomerProject);
  const clearSelectedCustomerProject = useVehicleStore((x) => x.clearSelectedCustomerProject);
  const updateCustomerProjects = useVehicleStore(
    (x) => x.updateCustomerProjects
  );
  const customerProjectsState = useVehicleStore(
    (x) => x.context.customerProjects
  );
  const selectedCustomerProject = useVehicleStore((x) => x.context.selectedCustomerProject);
  const selectedFacetData = useVehicleStore((x) => x.context.selectedFacetData);

  const setIsLoading = useThemeStore((x) => x.setIsLoading);
  const { state: navigationState } = useContext(NavigationContext);

  const [clearSelected, setClearSelected] = useState(false);
  const { sendCustomSegmentTrackEvent } = useSegment();
  const [loadingIndex, setLoadingIndex] = useState(null);

  useEffect(() => {
    if (clearSelected) {
      let redirectUrl = "/";
      if (
        window.SMI &&
        SMI.getPageTypeClass() === "ProductPageTypePageContainer"
      ) {
        redirectUrl = window.location.href;
      }

      clearSelectedCustomerProject(redirectUrl);
      setClearSelected(false);
    }
  }, [clearSelected]);

  customerProjects = customerProjects ? customerProjects : customerProjectsState
  customerProjects = getOrderedCustomerProjects(
    customerProjects,
    selectedCustomerProject
  );

  if (!userGuid || !services?.customerProjects?.url) return false;
  if (customerProjects && customerProjects.length > 0) {
    if (!showAllState && customerProjects?.length > 3) {
      customerProjects = customerProjects.slice(0, 3);
    }
    return (
      <List
        itemLayout={"vertical"}
        className={styles.vehicleListWrapper}
        component={"div"}
      >
        {customerProjects.map((customerProject, i) => {
          let shopUrl = window.location;
          let displayName;
          let isSelected = false;

          if (customerProject?.raceTypeId) {
            shopUrl = getProperUrl(customerProject);
            displayName = customerProject.raceName;
            
            if (customerProject?.engineDisplayName)
              displayName += ` with a ${customerProject.engineDisplayName}`
          } else if (customerProject?.modelId) {
            shopUrl = getProperUrl(customerProject);
            
            displayName = `${customerProject.year} ${customerProject.make} ${customerProject.model}`;

            if (customerProject?.engineDisplayName)
              displayName += ` with a ${customerProject.engineDisplayName}`
          } else if (customerProject?.engineDisplayName) {
            shopUrl = getProperUrl(customerProject);
            displayName = customerProject.engineDisplayName;
          }

          if (selectedCustomerProject?.projectId === customerProject?.projectId) {
            isSelected = true;
          }
          
          return (
            <Fragment key={"garageItem" + displayName}>
              <CoveringSpinner isLoading={loadingIndex === i}>
                <ListItem
                  className={styles.vehicleContainer}
                  component={"div"}
                  underline
                  onClick={async () => {
                    if (navigationState.pageType === "PDP"){
                      selectCustomerProject(customerProject.projectId);
                    } else {
                      setIsLoading(true);
                      selectCustomerProject(customerProject.projectId, shopUrl);
                      let response = await AddUserVehicle(
                        userGuid,
                        services?.customerProjects?.url,
                        customerProject.year,
                        customerProject.makeId,
                        customerProject.modelId,
                        customerProject.raceTypeId,
                        customerProject.vehicleBaseId,
                      );
                      updateCustomerProjects(
                        response?.addNewProjectToGarage?.projects
                      );
                      setIsLoading(false);
                  }
                  }}
                  data-testid={displayName}
                >
                  {isSelected ? (
                    <ListItemIcon
                      className={styles.selectedCustomerProjectIcon}
                      icon={"savedCheckmark"}
                      tone={"normal"}
                      intent={"positive"}
                      size={1}
                      data-testid="selected-vehicle-check"
                    />
                  ) : (
                    <div className={styles.space} />
                  )}
                  <ListItemText
                    className={styles.vehicleTitleText}
                    primaryTypographyProps={{
                      size: 0.875,
                      underlineHover: true,
                      font: "bold",
                      className: styles.vehiclePrimaryText,
                    }}
                    primary={displayName}
                  />
                  <ListItemIcon
                    icon={"trash"}
                    tone={"normal"}
                    size={1}
                    className={styles.vehicleTrash}
                    data-testid={displayName + "-trash"}
                    onClick={async (e) => {
                      setLoadingIndex(i);

                      e.stopPropagation();
                      const segmentEvent = createFitmentRemovedSegmentEventFromCustomerProject(customerProject);
                      sendCustomSegmentTrackEvent(segmentEvent);

                      var response = await RemoveUserVehicle(
                        userGuid,
                        customerProject.projectId,
                        services?.customerProjects?.url
                      );

                      if (response) {
                        updateCustomerProjects(
                          response.removeProjectFromGarage.projects
                        );
                        setClearSelected(isSelected);
                      }

                      setLoadingIndex(null);
                    }}
                  />
                </ListItem>
              </CoveringSpinner>

              <Divider className={styles.vehicleDivider} tone={"superfluous"} />
            </Fragment>
          );
        })}
      </List>
    );
  }
  return false;
};

// WIFR-32634 Duplicate code in ExistingProjectList
function getOrderedCustomerProjects(customerProjects, selectedCustomerProject) {
  if (!selectedCustomerProject) return customerProjects;

  if (!customerProjects || customerProjects.length === 0) return null;

  let selectedCustomerProjectData = customerProjects.find(
    (x) => x.projectId === selectedCustomerProject.projectId
  );

  if (!selectedCustomerProjectData) return customerProjects;

  let filteredVechiles = customerProjects.filter(
    (x) => x.projectId !== selectedCustomerProject.projectId
  );

  return [selectedCustomerProjectData, ...filteredVechiles];
}

export default YourVehicles;
