import React from 'react';

import RecommendationLoader from './RecommendationLoader';
import ClientRecommendationLoader from './ClientRecommendationLoader';
import RecommendationImpressionHandler from './RecommendationImpressionHandler';

export default function RecommendationStoreWiring({ set }) {

    return (
        <>
            <RecommendationLoader set={set} />
            <ClientRecommendationLoader set={set} />
            <RecommendationImpressionHandler set={set} />
        </>
    )
}