import logrocket from 'logrocket'
import { func } from 'prop-types';
import CookieReader, { GetBrowserUserValues } from '../../Utilities/CookieUtilties/CookieReader';

function getNextDataFromBrowser(){
    try{
        return JSON.parse(document.getElementById("__NEXT_DATA__").innerHTML)
    }catch(e){
        console.error(e)
        return { }
    }
}

function getLogRocketInitConfig(){
    let enableLogRocket = false
    let appVersionNumber = "UNKNOWN"
    const logRocketAppId = getLogRocketAppId()

    try{
        const nextData = getNextDataFromBrowser()
        if(nextData){
            appVersionNumber = `${nextData.assetPrefix}/${nextData.buildId}`
            const edgeFlags = JSON.parse(nextData.query?.edgeFlags || "{}")
            enableLogRocket = edgeFlags.logRocketEnabled && !CookieReader("disable_lr")
        }else{
            appVersionNumber = "DS9/TNG"
            enableLogRocket = true && !CookieReader("disable_lr")
        }
    }catch(e){
        console.error(e)
    }

    return {
        enableLogRocket: enableLogRocket ||  window.location.hash == '#lr_early',
        appVersionNumber,
        logRocketAppId
    }
}

function getLogRocketAppId(){
    try{
        const host = window.location.hostname.toLowerCase();

        switch (host) {
          case "www.speedwaymotors.com":
            return "xi3hul/speedwaymotorscom-production";
          case "staging.speedway2.com":
            return "xi3hul/speedway-website-staging";
          case "qa.speedway2.com":
          default:
            return "xi3hul/speedway-website-qa";
        }
    }catch(e){
        console.error(e)
        return "xi3hul/speedwaymotorscom-production"
    }
}

function isRunningInBrowser(){
    if(typeof window === 'undefined'){
        return false
    }else {
        return true
    }
}

export function InitLogRocketv2(){
    if(!isRunningInBrowser())
    {
        return
    }    

    // give the window logrocket so we can play!
    window.LogRocket = logrocket
    
    if(window.location.hash != '#lr_early'){
        return
    }

    try{
        console.log('init logrocket v2')
    
        const { enableLogRocket, appVersionNumber, logRocketAppId } = getLogRocketInitConfig()
        const {isBot, isGpcEnabled } = GetBrowserUserValues()

        //todo: remove me
        if(!enableLogRocket || isBot || isGpcEnabled){
            console.log("log rocket disabled")
            return
        }

        logrocket.init(logRocketAppId, {
            release: appVersionNumber,
            console: {
                shouldAggregateConsoleErrors: true
            },
            network: {
                requestSanitizer: request => {
                    // if the body contains 'password'
                    if (request.body) {
                        // IE's request bodies are objects
                        // note that stringifying a string gets us to the same result
                        if (JSON.stringify(request.body).toLowerCase().indexOf('password') !== -1) {
                            // scrub out the body
                            request.body = null;
                        }
                    }
    
                    return request;
                },
            }
        })
        /*
            Once we have more data to identify users, use that data to identify users in LogRocket as shown in link below...
            https://app.logrocket.com/xi3hul/speedway-dev/settings/setup/
        */
    }catch(e){
        console.error(e)
    }
}

export async function InitLogRocket(logRocketAppId, appVersionNumber) {
    logrocket.init(logRocketAppId, {
        release: appVersionNumber,
        console: {
            shouldAggregateConsoleErrors: true
        },
        network: {
            requestSanitizer: request => {
                // if the body contains 'password'
                if (request.body) {
                    // IE's request bodies are objects
                    // note that stringifying a string gets us to the same result
                    if (JSON.stringify(request.body).toLowerCase().indexOf('password') !== -1) {
                        // scrub out the body
                        request.body = null;
                    }
                }

                return request;
            },
        }
    })
    /*
        Once we have more data to identify users, use that data to identify users in LogRocket as shown in link below...
        https://app.logrocket.com/xi3hul/speedway-dev/settings/setup/
    */
}

export async function IdentifyLogRocket(userGuid, userEmail, userVin, userCustomerNumber) {
    const idDeets = {
        email: userEmail,
        customerNumber: userCustomerNumber,
        userVIN: userVin
    }
    logrocket.identify(userGuid, idDeets)
}