import React, { useEffect } from "react";

import SetCookie from "Utilities/CookieUtilties/SetCookie";

import { StorageAccessor } from "../../../../../Utilities/LocalStorageUtility/LocalStorageUtility";
import getClientSideQueryParam from "../../../../../Utilities/UrlUtilities/getClientSideQueryParam";

// config.storageMethod can be either cookie or localStorage, if cookie is specified- each parameter gets its own cookie value
// config.expirationDays is self explanatory
// config.urlParameters takes an object, the keys are the keys of the URL parameters
// the values of this object have the properties "required"  and "storageKey"
// required is a truthy value- if true then this value must be set for the parameter to be stored
// if it's a string, then the value must match this string (case insensitive)
// if false then the value will be stored regardless as long as the other parameters conditions are met
// "storageKey" will be the key used to store the value in the storage method (an object for localStorage and a cookie name for cookie)
export default function useSaveClickParameterEffect(config) {
  useEffect(() => {
    try {
      const objectToStore = {};

      for (let [parameterKey, parameterConfig] of Object.entries(config.urlParameters)) {
        const parameterValue = getClientSideQueryParam(parameterKey);

        const storageKey = parameterConfig.storageKey || parameterKey;
        objectToStore[storageKey] = parameterValue;

        if (!isValidParameter(parameterValue, parameterConfig)) {
          return; // early bail if we find something invalid
        }
      }

      switch (config.storageMethod?.toLowerCase()) {
        case "cookie":
          for (let [key, value] of Object.entries(objectToStore)) {
            SetCookie(key, value, config.expirationDays);
          }
          break;
        default:
          const expirationMinutes = config.expirationDays * 24 * 60;
          StorageAccessor.localStorage.setObj(
            config.cacheKey,
            objectToStore,
            expirationMinutes
          );
      }
    } catch (err) {
      console.error("useSaveClickParameterEffect failed to save", err);
    }
  }, []);
}

function isValidParameter(parameterValue, parameterConfig) {
  const isRequired = parameterConfig.required; // can be a string if we want the value to exactly match this value (case insensitive)

  const optionalAndValid = isRequired === false;
  const requiredAndPresentAndValid = isRequired === true && parameterValue;
  const requiredAndExactMatchAndValid =
    isRequired &&
    parameterValue &&
    isRequired.toString().toLowerCase() === parameterValue.toLowerCase();
  const isValid = requiredAndPresentAndValid || requiredAndExactMatchAndValid || optionalAndValid;
  return isValid
}
