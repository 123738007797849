import React, { useContext, useEffect } from "react";

import { ListContext } from "Contexts/ListContext/ListContext";

import { useUserSpecificProductDataStore } from "../UserSpecificProductDataStore";

export default function SynchronizeListData() {
  const setListDataForSkuBases = useUserSpecificProductDataStore(
    (x) => x.setListDataForSkuBases
  );

  const { listContext } = useContext(ListContext);

  useEffect(() => {
    try {
      const skuBasesInLists = {};

      if (listContext?.lists) {
        listContext.lists.forEach((list) => {
          const products = list.products;
          products.forEach((product) => {
            skuBasesInLists[product.skuBaseNumber] = true;
          });
        });
      }

      setListDataForSkuBases(skuBasesInLists);
    } catch (ex) {
      console.error(ex);
    }
  }, [listContext?.lists]);

  return false

}
