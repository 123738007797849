import React from "react";

import Image from 'next/image'

import Panel from "Atoms/Panel";
import Divider from 'Atoms/Divider'

import LayerColorFinder from "Utilities/LayerColorFinder";

import styles from "./DrawerImage.module.scss";

const DrawerImage = ({ src, alt, excludeDivider = false }) => {
    return (
        <Panel
            style={{
                "--borderColor": LayerColorFinder(3),
                'height': '128px'
            }}
            className={styles.container}
            layer={0}
        >
            <Image
                src={src}
                alt={alt}
                width={128}
                height={128} />
            {excludeDivider ? false : <Divider className={styles.divider} />}
        </Panel>
    );
};

export default DrawerImage;
