import { useVehicleStore } from "Stores/VehicleStore";
import useSegment from "Hooks/useSegment"

import AddRaceTypeView from "../AddRaceTypeView/AddRaceTypeView";
import AddYmmView from "../AddYmmView/AddYmmView";

import { createViewedSegmentEvent } from "Utilities/Instrumentation/Impressions/impressionSegmentEvents";


const partialProjectStates = {
  none: "NONE",
  ymm: "YMM",
  raceType: "RACETYPE",
  engine: "ENGINE",
};

const UpdateProjectView = ({ promptFor }) => {
  const selectedCustomerProjectState = useVehicleStore(
    (x) => x.context.selectedCustomerProject
  );
  const { sendSegmentTrackEvent } = useSegment()

  let existingState;
  let selectedVehicleType = partialProjectStates.none;
  if (selectedCustomerProjectState?.raceTypeId) {
    selectedVehicleType = partialProjectStates.raceType;
    existingState = {
      raceType: {
        value: selectedCustomerProjectState?.raceTypeId,
        label: selectedCustomerProjectState?.raceDisplay,
      },
      stockEngine: {
        value: null,
        label: null,
      },
      customerProjectId: selectedCustomerProjectState?.projectId,
    };
  } else if (selectedCustomerProjectState?.makeId) {
    selectedVehicleType = partialProjectStates.ymm;
    existingState = {
      year: {
        value: selectedCustomerProjectState?.yearId,
        label: selectedCustomerProjectState?.yearId,
      },
      make: {
        value: selectedCustomerProjectState?.makeId,
        label: selectedCustomerProjectState?.makeDisplay,
      },
      model: {
        value: selectedCustomerProjectState?.modelId,
        label: selectedCustomerProjectState?.modelDisplay,
      },
      stockEngine: {
        value: null,
        label: null,
      },
      vehicleBaseId: selectedCustomerProjectState?.vehicleBaseId,
      customerProjectId: selectedCustomerProjectState?.projectId,
    };
  } else if (selectedCustomerProjectState?.engineDisplayName) {
    selectedVehicleType = partialProjectStates.engine;
    existingState = {
      ...selectedCustomerProjectState,
      customerProjectId: selectedCustomerProjectState.projectId,
    };
  }

  const viewEvent = createViewedSegmentEvent("Update Project View");
  sendSegmentTrackEvent(viewEvent)

  return (
    <>
      {selectedVehicleType === partialProjectStates.ymm && (
        <AddYmmView existingState={existingState} />
      )}
      {selectedVehicleType === partialProjectStates.raceType && (
        <AddRaceTypeView existingState={existingState} />
      )}
      {selectedVehicleType === partialProjectStates.engine &&
        promptFor === "YMM" && (
          <AddYmmView existingState={existingState} hasEngineSelected={true} />
        )}
      {selectedVehicleType === partialProjectStates.engine &&
        promptFor === "RACETYPE" && (
          <AddRaceTypeView existingState={existingState} hasEngineSelected={true} />
        )}
    </>
  );
};

export default UpdateProjectView;
