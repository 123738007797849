import VehicleRequestWrapper from "./VehicleRequestWrapper";

const projectDetailFragment = `
  year
  make
  makeId
  model
  modelId
  raceName
  raceTypeId
  raceImageUrl
  shopUrl
  searchUrl
  projectId
  customerProjectUrl
  customerProjectShopUrl
  customerProjectSearchUrl
  clearSelectionUrl
  marketId
  superMarketId
  vehicleBaseId
  engineManufacturer
  engineManufacturerId
  engineMake
  engineMakeId
  engineSeries
  engineSeriesId
  engineConfiguration
  engineConfigurationId
  engineVersion
  engineVersionId
  engineQualifier
  engineQualifierId
  engineDisplacement
  engineDisplacementId
  engineCode
  engineCodeId
  engineCamshaftType
  engineCamshaftTypeId
  engineFuelSystem
  engineFuelSystemId
  engineDisplayName
  isStockEngine
`

export const FetchCustomerProjects = async (
  webUserGuid,
  prevState,
  customerProjectUrl,
  selectedFacetData,
  forceRefresh = false
) => {
  if (!forceRefresh && prevState?.customerProjects) 
    return prevState;

  let variables = {
    webUserGuid: webUserGuid,
    selectedFacetData: transformFacetDataToTraitsFormat(selectedFacetData),
  };

  const query = `query GetCustomerProjects($webUserGuid: String = null, $selectedFacetData: SelectedFacetDataDMInput = null) {
        customerProjects(webUserGuid: $webUserGuid, selectedFacetData: $selectedFacetData) {
            ${projectDetailFragment}
      }
    }`;

  return await VehicleRequestWrapper(query, variables, customerProjectUrl);
};

//TODO: Deprecate
export const FetchRaceTypes = async (
  raceTypes,
  traitsUrl,
  selectedFacetData
) => {
  if (!raceTypes || raceTypes.length === 0) {
    let query = `query GetRaceTypeLinks($selectedFacetData: SelectedFacetDataDMInput = null){
              raceTypeLinks(selectedFacetData: $selectedFacetData){
                displayName
                imageUrl
                shopUrl
                searchUrl
                raceTypeId
                marketId
                superMarketId
                priority
                }
            }`;
    let variables = {
      selectedFacetData: transformFacetDataToTraitsFormat(selectedFacetData),
    };
    return await VehicleRequestWrapper(query, variables, traitsUrl);
  }
  return raceTypes;
};

export const FetchPartTypeLink = async (
  yearId,
  makeId,
  modelId,
  raceTypeId,
  partTypeId
) => {
  let query = `
    query BuildLink($linkRequest: LinkRequestInput) {
      buildLink(linkRequest: $linkRequest){
        url
        isValid
      }
    }`;

  let variables = {
    linkRequest: {
      year: yearId,
      make: makeId,
      model: modelId,
      raceType: raceTypeId
    }
  };

  if(partTypeId){
    variables.linkRequest.partTypes = [partTypeId];
  }

  return await VehicleRequestWrapper(query, variables, "/graphql/trait");
};

export const FetchYearMakeModel = async (
  state,
  yearId,
  makeId,
  modelId,
  traitsUrl,
  selectedFacetData
) => {
  if (state.allYears && state.allYears?.length !== 0 && !yearId) {
    return false;
  }

  let query = `query GetYmm($request: YmmRequestInput! = null){
         ymmData: ymm(request: $request){
            years{
                displayName
                optionId
            }
            prioritizedMakes{
                displayName
                optionId
            }
            makes{
                displayName
                optionId
            }
            models{
                displayName
                optionId
                shopUrl
                shopUrlWithSelectedFacets
                searchUrl
                searchUrlWithSelectedFacets
                marketId
                superMarketId
                vehicleBaseId
            }
            stockEngines {
              displayName
              optionId
              shopUrl
              searchUrl
              shopUrlWithSelectedFacets
              searchUrlWithSelectedFacets
              engineIds {
                engineManufacturerId
                engineMakeId
                engineSeriesId
                engineConfigurationId
                engineQualifierId
                engineVersionId
                engineDisplacementId
                engineCodeId
                engineCamshaftTypeId
                engineFuelSystemId
              }
            }
         }
    }`;

  let variables = {
    request: {
      year: yearId,
      make: makeId,
      model: modelId,
      selectedFacetData: transformFacetDataToTraitsFormat(selectedFacetData),
    },
  };

  return await VehicleRequestWrapper(query, variables, traitsUrl);
};

export const FetchEngine = async (
  state,
  engineManufacturerId,
  engineMakeId, 
  engineSeriesId, 
  engineConfigurationId,
  traitsUrl,
  selectedFacetData,
  vehicleBaseId,
  raceTypeId
) => {
  if (state.engineMakes && state.engineMakes?.length !== 0 && !engineMakeId){
    return false;
  }

  let query = `query GetEngineDropdown($request: EngineDropdownRequestInput) {
    engineData: engineDropdown (request: $request) {
      engineMakeDropdown {
        optionId
        displayName
      }
      engineFamilyDropdown {
        optionId
        displayName
        shopUrl
        searchUrl
      }
      engineDisplacementDropdown {
        optionId
        displayName
        shopUrl
        searchUrl
      }
    }
  }`

  let variables = {
    request: {
      engineManufacturerId,
      engineMakeId,
      engineSeriesId,
      engineConfigurationId,
      selectedFacetData: transformFacetDataToTraitsFormat(selectedFacetData, vehicleBaseId, raceTypeId)
    }
  }
  return await VehicleRequestWrapper(query, variables, traitsUrl);
}

export const FetchRaceType = async (
  state,
  raceTypeId,
  traitsUrl,
  selectedFacetData
) => {
  if (state.raceTypes && state.raceTypes?.length !== 0 && !raceTypeId) {
    return false;
  }

  let query = `query GetRaceTypeDropdown(
    $raceTypeId: Int
    $selectedFacetData: SelectedFacetDataDMInput = null
  ) {
    raceTypeData: raceTypeDropdown(
      raceTypeId: $raceTypeId
      selectedFacetData: $selectedFacetData
    ) {
      raceTypeDropdown {
        optionId
        displayName
        marketId
        superMarketId
        shopUrl
        searchUrl
      }
      stockEngineDropdown {
        optionId
        marketId
        superMarketId
        displayName
        shopUrl
        searchUrl
        engineIds {
          engineManufacturerId
          engineMakeId
          engineSeriesId
          engineConfigurationId
          engineQualifierId
          engineVersionId
          engineDisplacementId
          engineCodeId
          engineCamshaftTypeId
          engineFuelSystemId
        }
      }
    }
  }`;
  let variables = {
    raceTypeId,
    selectedFacetData: transformFacetDataToTraitsFormat(selectedFacetData),
  };
  return await VehicleRequestWrapper(query, variables, traitsUrl);
};

export const getProperUrl = (item) => {
  if (window.location.pathname.toLowerCase().includes("search")) {
    const params = new URLSearchParams(window.location.search);
    const searchTerm = params.get("query");
    if (!searchTerm) {
      console.error("search url didn't have a search query!");
      return item.shopUrl;
    }
    if (!item.searchUrl.includes("?query="))
      return item.searchUrl.replace("?", `?query=${searchTerm}&`);
    else
      return item.searchUrl;
  }
  else if(window.location.pathname.toLocaleLowerCase().includes('shop')) 
    return item.shopUrl;

  return item.customerProjectUrl ? item.customerProjectUrl : item.shopUrl;
};

function transformFacetDataToTraitsFormat(selectedFacetData, vehicleBaseId, raceTypeId) {
  if (!selectedFacetData) {
    return selectedFacetData;
  }
  if (vehicleBaseId) {
    return {
      ...selectedFacetData,
      vehicleBaseId,
      selectedFacetOptions:
        selectedFacetData?.selectedFacetOptions?.flatMap((facet) =>
          facet.searchResponseFacetOptions?.map(
            (option) =>
              ({
                valueId: option.id,
                facetType: facet.facetType,
                engineFacetType: facet.engineFacetType,
                displayText: facet.displayText,
                engineFitmentDetails: facet.engineFitmentDetails
              } ?? [])
          )
        ) ?? [],
    };
  }
  else if (raceTypeId) {
    return {
      ...selectedFacetData,
      raceTypeId,
      selectedFacetOptions:
        selectedFacetData?.selectedFacetOptions?.flatMap((facet) =>
          facet.searchResponseFacetOptions?.map(
            (option) =>
              ({
                valueId: option.id,
                facetType: facet.facetType,
                engineFacetType: facet.engineFacetType,
                displayText: facet.displayText,
                engineFitmentDetails: facet.engineFitmentDetails
              } ?? [])
          )
        ) ?? [],
    };
  }

  return {
    ...selectedFacetData,
    selectedFacetOptions:
      selectedFacetData?.selectedFacetOptions?.flatMap((facet) =>
        facet.searchResponseFacetOptions?.map(
          (option) =>
            ({
              valueId: option.id,
              facetType: facet.facetType,
              engineFacetType: facet.engineFacetType,
              displayText: facet.displayText,
              engineFitmentDetails: facet.engineFitmentDetails
              
            } ?? [])
        )
      ) ?? [],
  };
}

export const RemoveUserVehicle = async (
  webUserGuid,
  projectId,
  customerProjectUrl
) => {
  let query = `mutation RemoveProjectFromGarage($projectToRemove: ProjectRemoveRequestInput = null) {
    removeProjectFromGarage(projectToRemove: $projectToRemove) 
     {
      success
      projects {
          ${projectDetailFragment}
      }
      message
    }
    }`;

  let variables = {
    projectToRemove: {
      webUserGuid: webUserGuid,
      projectId: projectId,
    },
  };

  return await VehicleRequestWrapper(query, variables, customerProjectUrl);
};

export const AddUserVehicle = async (
  webUserGuid,
  customerProjectUrl,
  yearId,
  makeId,
  modelId,
  raceTypeId,
  vehicleBaseId,
  selectedFacetData
) => {
  let query = `mutation AddNewProjectToGarage($projectRequest: ProjectRequestInput = null) {
    addNewProjectToGarage(projectRequest: $projectRequest) 
     {
      success
      alreadyExisted
      projects {
       ${projectDetailFragment}
      }
      webUser {
        userGuid
      }
      message
      addedCustomerProjectId
    }
  }`;

  let variables = {
    projectRequest: {
      year: yearId,
      makeId: makeId,
      modelId: modelId,
      raceTypeId: raceTypeId,
      webUserGuid: webUserGuid,
      customerProjectSource: "CUSTOMER_ENTERED",
      vehicleBaseId: vehicleBaseId,
      selectedFacetData: transformFacetDataToTraitsFormat(selectedFacetData),
    },
  };

  return await VehicleRequestWrapper(query, variables, customerProjectUrl);
};

export const AddCustomerProject = async ({
  webUserGuid,
  customerProjectUrl,
  year,
  makeId,
  modelId,
  raceTypeId,
  vehicleBaseId,
  engineIds,
  selectedFacetData,
}) => {
  const query = `mutation AddNewProjectToGarage($projectRequest: ProjectRequestInput = null) {
    addNewProjectToGarage(projectRequest: $projectRequest) 
     {
      success
      alreadyExisted
      projects {
        ${projectDetailFragment}
      }
      webUser {
        userGuid
      }
      message
      addedCustomerProjectId
    }
  }`;

  let variables = {
    projectRequest: {
      year,
      makeId,
      modelId,
      raceTypeId,
      engineIds,
      webUserGuid,
      customerProjectSource: "CUSTOMER_ENTERED",
      vehicleBaseId,
      selectedFacetData: transformFacetDataToTraitsFormat(selectedFacetData),
    },
  };

  return await VehicleRequestWrapper(query, variables, customerProjectUrl);
};

export const UpdateCustomerProjectData = async ({
  webUserGuid,
  customerProjectUrl,
  year,
  makeId,
  modelId,
  raceTypeId,
  vehicleBaseId,
  engineIds,
  selectedFacetData,
  customerProjectId
}) => {
  const query = `mutation UpdateCustomerProject($projectRequest: ProjectRequestInput = null) {
    updateCustomerProject(projectRequest: $projectRequest) 
     {
      success
      updatedEngineIds
      projects {
        ${projectDetailFragment}
      }
      webUser {
        userGuid
      }
      message
      updatedCustomerProjectId
    }
  }`;

  let variables = {
    projectRequest: {
      year,
      makeId,
      modelId,
      raceTypeId,
      engineIds,
      webUserGuid,
      customerProjectSource: "CUSTOMER_ENTERED",
      customerProjectId,
      vehicleBaseId,
      selectedFacetData: transformFacetDataToTraitsFormat(selectedFacetData),
    },
  };

  return await VehicleRequestWrapper(query, variables, customerProjectUrl);
}

export const FetchMakes = async (traitsUrl) => {
  let query = `query AllMakes{
         allMakes {
          makeId
          make
         }
    }`;

  return await VehicleRequestWrapper(query, null, traitsUrl);
};

export const FetchModels = async (makeName, traitsUrl) => {
  let query = `query ($make: String) {
    models (make: $make) {
      makeId
      makeName
      models {
        modelId
        modelName
      }
    }
  }`;

  let variables = {
    make: makeName,
  };

  return await VehicleRequestWrapper(query, variables, traitsUrl);
};
