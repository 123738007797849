import React from 'react'
import SynchronizeCartData from './SynchronizeCartData'
import SynchronizeListData from './SynchronizeListData'
import LazyLoadFitmentData from './LazyLoadFitmentData'
import LazyLoadShippingData from './LazyLoadShippingData'
import LazyLoadPromoData from './LazyLoadPromoData'
import { useUserSpecificProductDataStore } from '../UserSpecificProductDataStore'

export default function UserSpecificProductDataStoreWiring(){
    const areUpdatesAllowed = useUserSpecificProductDataStore(x => x.areUpdatesAllowed)

    const disableUpdates = !areUpdatesAllowed

    return <>
        <LazyLoadFitmentData disable={disableUpdates}/>
        <LazyLoadShippingData disable={disableUpdates}/>
        <LazyLoadPromoData disable={disableUpdates}/>
        <SynchronizeCartData />
        <SynchronizeListData />
    </>
}