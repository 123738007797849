import React, { Fragment, useEffect, useState } from "react";

import GraphQLRequest from "Utilities/GraphQLClient/GraphQLClient";
import { StorageAccessor } from "Utilities/LocalStorageUtility";

import useFeatureFlag from "../../../Hooks/useFeatureFlag/useFeatureFlag";
import { IsUserBot } from "../../../Utilities/CookieUtilties/UserCookieUtilities";
import BannerHeaderBand from "./BannerHeaderBand";
import styles from "./Banners.module.scss";


const CACHENAME = "websiteBanners";
const CACHENAMEDISMISSED = "dismissedWebsiteBanners";
const Banners = ({ url }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dismissedBannerIds, setDismissedBannerIds] = useState([]);
  const [banners, setBanners] = useState([]);

  const dismissBannerEnabled = useFeatureFlag("Frontend_DismissableBanners");

  const getBannersToShow = () => {
    if (dismissBannerEnabled == null || !banners?.length) {
      return [];
    }
    if (!dismissBannerEnabled) {
      return banners;
    }
    return banners.filter((x) => !dismissedBannerIds.some((y) => y == x.id));
  };

  const dismissBanner = (banner) => {
    const newlist = [...dismissedBannerIds, banner.id];
    setDismissedBannerIds(newlist);
    StorageAccessor.localStorage.setObj(CACHENAMEDISMISSED, newlist, 43800);
  };

  const deconflictBannerSpaceStyleTag = () => {
    if (!dismissBannerEnabled) {
      return null;
    }
    const bannersToDisplay = getBannersToShow();
    if (!bannersToDisplay.length) {
      return null;
    }

    const bannerHeights = bannersToDisplay.length * 1.5;
    let styleString = `
        
        #pdp_atc_sticky_container 
        { margin-bottom: ${3.75 + bannerHeights}rem; }

        .crisp-client .cc-tlyw[data-full-view="true"] .cc-kxkl a.cc-nsge, 
        .crisp-client .cc-tlyw[data-full-view="false"] .cc-kxkl a.cc-nsge
        { margin-bottom: ${4 + bannerHeights}rem !important; }    

        @media screen and (min-width: 1024px){
            #pdp_atc_sticky_container 
            { margin-bottom: ${1 + bannerHeights}rem; }

            .crisp-client .cc-tlyw[data-full-view="true"] .cc-kxkl a.cc-nsge, 
            .crisp-client .cc-tlyw[data-full-view="false"] .cc-kxkl a.cc-nsge
            { margin-bottom: ${1 + bannerHeights}rem !important; }  
    
        }
                
        `;
    return (
      <style
        id={"banner_deconfliction_styles"}
        dangerouslySetInnerHTML={{ __html: styleString }}
      ></style>
    );
  };

  useEffect(() => {
    async function FetchBanners() {
      try {
        const dismissed =
          StorageAccessor.localStorage.getObj(CACHENAMEDISMISSED) ?? [];
        setDismissedBannerIds(dismissed);

        let cachedBanners = StorageAccessor.sessionStorage.getObj(CACHENAME);
        if (!cachedBanners && !IsUserBot()) {
          const query = `query GetBanners{
                        banners: activeBanners {
                            id
                            title
                            description
                            mobileTitle
                            promoCode
                            promoCodeDescription
                            startDate
                            endDate
                            url
                            linkText
                            bannerType {
                                id
                                name
                            }
                        }
                    }`;
          const response = await GraphQLRequest(url, query, null);
          cachedBanners = response?.banners ?? banners;

          StorageAccessor.sessionStorage.setObj(CACHENAME, cachedBanners, 5);
        } else if (!cachedBanners) {
          cachedBanners = [];
        }
        setBanners(cachedBanners);
      } catch (err) {
        console.error(err);
        setBanners([]);
      }
    }

    FetchBanners();
  }, []);

  const bannersToDisplay = getBannersToShow();

  if (!bannersToDisplay?.length) {
    return null;
  }

  return (
    <div
      className={`${styles.bannerContainer} ${
        dismissBannerEnabled ? styles.bannerBottom : null
      }`}
    >
      {deconflictBannerSpaceStyleTag()}
      {bannersToDisplay.map((banner, i) => {
        return (
          <Fragment key={banner.id}>
            <Banner
              {...banner}
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              dismissCallback={
                dismissBannerEnabled ? () => dismissBanner(banner) : null
              }
              index={i}
            />
          </Fragment>
        );
      })}
    </div>
  );
};

const Banner = ({
  dismissCallback,
  isModalOpen,
  setIsModalOpen,
  id,
  title,
  description,
  promoCode,
  promoCodeDescription,
  bannerType,
  startDate,
  endDate,
  mobileTitle,
  url,
  linkText,
  index,
}) => {
  switch (bannerType.name) {
    case "placeholder":
      {
        return <BannerHeaderBand.PlaceholderBanner />;
      }
      break;
    case "promotion":
      {
        return (
          <BannerHeaderBand.PromotionBanner
            id={id}
            title={title}
            mobileTitle={mobileTitle}
            description={description}
            promoCode={promoCode}
            promoCodeDescription={promoCodeDescription}
            isModalOpen={isModalOpen}
            url={url}
            linkText={linkText}
            setIsModalOpen={setIsModalOpen}
            startDate={startDate}
            endDate={endDate}
            index={index}
            dismissCallback={dismissCallback}
          />
        );
      }
      break;
    case "maintenance":
      return (
        <BannerHeaderBand.MaintenanceBanner
          id={id}
          title={title}
          description={description}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          startDate={startDate}
          endDate={endDate}
          mobileTitle={mobileTitle}
          index={index}
          dismissCallback={dismissCallback}
        />
      );
      break;
    default:
      return false;
      break;
  }
};
export default Banners;
