import React, { useContext } from "react";

import TextLink from "Atoms/TextLink";
import { NavigationContext } from "Contexts/Navigation/NavigationContext";
import { useVehicleStore } from "Stores/VehicleStore";

const ClearSelection = ({}) => {
  const getSelectedCustomerProjectTitle = useVehicleStore(
    (x) => x.getSelectedCustomerProjectTitle
  );
  const selectedCustomerProjectTitle = getSelectedCustomerProjectTitle();
  const clearSelectedCustomerProject = useVehicleStore(
    (x) => x.clearSelectedCustomerProject
  );
  const selectedCustomerProject = useVehicleStore(
    (x) => x.context.selectedCustomerProject
  );
  const { state: navigationState } = useContext(NavigationContext);

  if (!selectedCustomerProjectTitle) return false;

  return (
    <TextLink
      intent={"action"}
      onClick={async () => {
        let url = "/";
        if (
          navigationState?.pageType === "PRP" &&
          selectedCustomerProject?.clearSelectionUrl
        )
          url = selectedCustomerProject.clearSelectionUrl;
        clearSelectedCustomerProject(url);
      }}
      underlineHover
    >
      Clear Selection
    </TextLink>
  );
};
export default ClearSelection;
