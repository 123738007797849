import React, { useContext, useState, useEffect } from 'react'
import Image from "next/image"
import ClassNames from 'classnames'

import Typography from 'Atoms/Typography'
import Area from 'Molecules/Area'
import Panel from 'Atoms/Panel'
import Button from 'Molecules/Button'
import Drawer from 'Molecules/NewDrawer/Drawer'
import IndexOptions from '../../Components/IndexOptions/indexoptions.js'
import styles from './VariantDetailsDrawer.module.scss'
import { AddToCartExperienceContext } from '../../Contexts/AddToCartExperienceContext/AddToCartExperienceContext.js'
import { createClickedSegmentEvent } from '../../Utilities/Instrumentation/Impressions/impressionSegmentEvents.js'

const VariantDetailsDrawer = () => {
    const { product,
        isVariantDetailsDrawerOpen,
        isVariantDetailsDrawerAtc,
        hideVariantDetailsDrawer,
        handleIndexOptionSelectionVDD,
        isIndexOptionErrorState,
        handleAddToListVDD,
        handleAddToCartVDD
    } = useContext(AddToCartExperienceContext)

    const [waiting, setWaiting] = useState(false)

    useEffect(() => {
        if (!isVariantDetailsDrawerOpen || isIndexOptionErrorState)
            setWaiting(false)
    }, [isVariantDetailsDrawerOpen, isIndexOptionErrorState])

    if (!product) return null

    const { productPageId, indexOptions, title, imageUrl } = product

    const filteredIndexOptions = indexOptions.map(option => ({
        ...option,
        indexOptionValues: option.indexOptionValues.filter(value => value.isPurchaseable)
    })).filter(option => option.indexOptionValues.length > 0)

    const notPurchaseable = isVariantDetailsDrawerAtc && product.isPurchaseable == false

    const footer = (<>
        {notPurchaseable ?
            <Typography
                size={1}
                intent={'negative'}
                className={styles.notAvailable}>
                This item is currently not available.
            </Typography> : false}
        <Button
            intent={'positive'}
            disabled={notPurchaseable || isIndexOptionErrorState}
            text={isVariantDetailsDrawerAtc ? 'Add to Cart' : 'Add to a List'}
            size={'small'}
            fill
            isWaiting={waiting}
            testingName={isVariantDetailsDrawerAtc ? 'ATC_drawer_button' : 'ATL_drawer_button'}
            onClick={(e) => {
                e.preventDefault();
                setWaiting(true);
                isVariantDetailsDrawerAtc ? handleAddToCartVDD() : handleAddToListVDD()
            }}
            segmentEvent={createClickedSegmentEvent('VariantDetailsDrawerAction')}
        ></Button>
    </>)  

    return (
        <Drawer
            portalId={'variantDetailsDrawer'}
            location={'right'}
            isOpen={isVariantDetailsDrawerOpen}
            onClose={hideVariantDetailsDrawer}
            title={isVariantDetailsDrawerAtc ? "Add to Cart" : "Add to List"}
            image={`https:${imageUrl ? imageUrl : "//content.speedwaymotors.com/OtherImages/missingimage2.jpg"}`}
            footer={footer}
        >
            {indexOptions && indexOptions.length > 0 ?
                <IndexOptions
                    selectIndexOption={handleIndexOptionSelectionVDD}
                    indexOptions={filteredIndexOptions}
                    productPageId={productPageId}
                    buttonCuttoffValue={5}
                    isIndexOptionErrorState={isIndexOptionErrorState}
                />
                : false}
        </Drawer>
    )
}

export default VariantDetailsDrawer
