import { useEffect } from "react";
import { useLoyaltyStore } from "./LoyaltyStore";

const useLoyaltyLinkLazy = () => {

    const loyaltyLink = useLoyaltyStore(x => x.loyaltyLink);
    const getLoyaltyLink = useLoyaltyStore(x => x.getLoyaltyLink);

    useEffect(() => {
        if (!loyaltyLink) {
            getLoyaltyLink()
        }
    }, [loyaltyLink])

    return loyaltyLink
}

export default useLoyaltyLinkLazy