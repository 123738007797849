import React, { Fragment, useState } from 'react'
import { InView } from 'react-intersection-observer'
import PropTypes from 'prop-types';

const DeferredLoadingWrapper = ({    
    loadPadding, // number of pixels away from the component to load. Ex: '500px'
    onLoad, //This function should return the component being deferred
    ...props //Props of the component to be deffered
}) => {
    const [ComponentToRender, setComponent] = useState(null);
    const [ableToDefer, setAbleToDefer] = useState(true)

    if(typeof window !== 'undefined' && !window.IntersectionObserver && ableToDefer){
        setAbleToDefer(false)
        renderComponent()
    }

    async function renderComponent(){
        var component = await onLoad
        setComponent(component)
    }

    return (
        ableToDefer ?
        <>
            <InView rootMargin={loadPadding} onChange={async (inView, entry) => {
                if (inView === true) {                    
                    renderComponent()
                }
            }}>
                {ComponentToRender && <ComponentToRender {...props} />}
            </InView>
        </>:
        <>
            {ComponentToRender && <ComponentToRender {...props} />}
        </>
    )
}

DeferredLoadingWrapper.propTypes = {
    loadPadding: PropTypes.string,
    onLoad: PropTypes.func.isRequired
}

export default DeferredLoadingWrapper