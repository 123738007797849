import React, { Fragment, useState, useContext } from "react";

import CoveringSpinner from "Atoms/CoveringSpinner";
import Divider from "Atoms/Divider";
import { NavigationContext } from "Contexts/Navigation/NavigationContext";
import { getProperUrl } from "Contexts/VehicleContext/CoreVehicleRequests";
import useSegment from "Hooks/useSegment/useSegment";
import List from "Molecules/List";
import ListItem from "Molecules/List/ListItem";
import ListItemIcon from "Molecules/List/ListItemIcon";
import ListItemText from "Molecules/List/ListItemText";
import { useThemeStore } from "Stores/ThemeStore";
import { useVehicleStore } from "Stores/VehicleStore";
import { createFitmentRemovedSegmentEventFromCustomerProject } from "Utilities/Instrumentation/Fitment/fitmentSegmentEvents";

import styles from "./ExistingProjectList.module.scss";

const ExistingProjectList = ({ showAllState, customerProjects = null }) => {
  const [loadingIndex, setLoadingIndex] = useState(null);

  const customerProjectsState = useVehicleStore(
    (x) => x.context.customerProjects
  );
  const selectedCustomerProject = useVehicleStore(
    (x) => x.context.selectedCustomerProject
  );
  const { state: navigationState, dispatch: navigationDispatch } = useContext(NavigationContext);

  const selectCustomerProject = useVehicleStore((x) => x.selectCustomerProject);
  const clearSelectedCustomerProject = useVehicleStore(
    (x) => x.clearSelectedCustomerProject
  );
  const removeCustomerProject = useVehicleStore((x) => x.removeCustomerProject);
  const setIsLoading = useThemeStore((x) => x.setIsLoading);
  const { sendCustomSegmentTrackEvent } = useSegment();

  customerProjects = customerProjects
    ? customerProjects
    : customerProjectsState;
  customerProjects = getOrderedCustomerProjects(
    customerProjects,
    selectedCustomerProject
  );

  if (customerProjects && customerProjects.length > 0) {
    if (!showAllState && customerProjects?.length > 3) {
      customerProjects = customerProjects.slice(0, 3);
    }
    return (
      <List
        itemLayout={"vertical"}
        className={styles.vehicleListWrapper}
        component={"div"}
      >
        {customerProjects.map((customerProject, i) => {
          let shopUrl = window.location;
          let displayName;
          let isSelected = false;

          if (customerProject?.raceTypeId) {
            shopUrl = getProperUrl(customerProject);
            displayName = customerProject.raceName;

            if (customerProject?.engineDisplayName)
              displayName += ` - ${customerProject.engineDisplayName}`;
          } else if (customerProject?.modelId) {
            shopUrl = getProperUrl(customerProject);

            displayName = `${customerProject.year} ${customerProject.make} ${customerProject.model}`;

            if (customerProject?.engineDisplayName)
              displayName += ` - ${customerProject.engineDisplayName}`;
          } else if (customerProject?.engineDisplayName) {
            shopUrl = getProperUrl(customerProject);
            displayName = customerProject.engineDisplayName;
          }

          if (
            selectedCustomerProject?.projectId === customerProject?.projectId
          ) {
            isSelected = true;
          }

          return (
            <Fragment key={`garageItem-${customerProject.projectId}`}>
              <CoveringSpinner isLoading={loadingIndex === i}>
                <ListItem
                  className={styles.vehicleContainer}
                  component={"div"}
                  underline
                  onClick={async () => {
                    if (navigationState.pageType === "PDP") {
                      selectCustomerProject(customerProject.projectId);
                      navigationDispatch({
                        type: "setCustomerProjectDrawerState",
                        payload: {
                          garageState: {
                            isCustomerProjectDrawerOpen: false,
                          },
                        },
                      });
                    } else {
                      setIsLoading(true);
                      selectCustomerProject(customerProject.projectId, shopUrl);
                      setIsLoading(false);
                    }
                  }}
                  data-testid={displayName}
                >
                  {isSelected ? (
                    <ListItemIcon
                      className={styles.selectedCustomerProjectIcon}
                      icon={"savedCheckmark"}
                      tone={"normal"}
                      intent={"positive"}
                      size={1}
                      data-testid="selected-vehicle-check"
                    />
                  ) : (
                    <div className={styles.space} />
                  )}
                  <ListItemText
                    className={styles.vehicleTitleText}
                    primaryTypographyProps={{
                      size: 0.875,
                      underlineHover: true,
                      font: "bold",
                      className: styles.vehiclePrimaryText,
                    }}
                    primary={displayName}
                  />
                  <ListItemIcon
                    icon={"trash"}
                    tone={"normal"}
                    size={1}
                    className={styles.vehicleTrash}
                    data-testid={displayName + "-trash"}
                    onClick={async (e) => {
                      setLoadingIndex(i);

                      e.stopPropagation();
                      const segmentEvent =
                        createFitmentRemovedSegmentEventFromCustomerProject(
                          customerProject
                        );
                      sendCustomSegmentTrackEvent(segmentEvent);

                      await removeCustomerProject(customerProject.projectId);

                      let redirectUrl = "/";
                      if (
                        window.SMI &&
                        SMI.getPageTypeClass() ===
                          "ProductPageTypePageContainer"
                      )
                        redirectUrl = window.location.href;
                      if (isSelected) {
                        if (
                          navigationState?.pageType === "PRP" &&
                          selectedCustomerProject?.clearSelectionUrl
                        )
                          redirectUrl =
                            selectedCustomerProject.clearSelectionUrl;
                        clearSelectedCustomerProject(redirectUrl);
                      }

                      setLoadingIndex(null);
                    }}
                  />
                </ListItem>
              </CoveringSpinner>

              <Divider className={styles.vehicleDivider} tone={"superfluous"} />
            </Fragment>
          );
        })}
      </List>
    );
  }
  return false;
};

// WIFR-32634 Duplicate code in YourVehicles
function getOrderedCustomerProjects(customerProjects, selectedCustomerProject) {
  if (!selectedCustomerProject) return customerProjects;

  if (!customerProjects || customerProjects.length === 0) return null;

  let selectedCustomerProjectData = customerProjects.find(
    (x) => x.projectId === selectedCustomerProject.projectId
  );

  if (!selectedCustomerProjectData) return customerProjects;

  let filteredVehicles = customerProjects.filter(
    (x) => x.projectId !== selectedCustomerProject.projectId
  );

  return [selectedCustomerProjectData, ...filteredVehicles];
}

export default ExistingProjectList;
