const ecommerceSpecNames =
    [
        "Products Searched",
        "Product List Viewed",
        "Product List Filtered",
        "Promotion Viewed",
        "Promotion Clicked",
        "Product Clicked",
        "Product Viewed",
        "Product Added",
        "Product Removed",
        "Cart Viewed",
        "Checkout Started",
        "Checkout Step Viewed",
        "Checkout Step Completed",
        "Payment Info Entered",
        "Order Completed",
        "Order Updated",
        "Order Refunded",
        "Order Cancelled",
        "Coupon Entered",
        "Coupon Applied",
        "Coupon Denied",
        "Coupon Removed",
        "Product Added to Wishlist",
        "Product Removed from Wishlist",
        "Wishlist Product Added to Cart",
        "Product Shared",
        "Cart Shared",
        "Product Reviewed",
        "Product Add Started"
    ]

const otherEventNames =
    [
        "Clicked",
        "Viewed",
        "Accordian Interaction",
        "Gallery Image Selection",
        "Lead Capture",
        "Fitment Added",
        "Fitment Removed",
        "Fitment Updated",
        "Return Blocked",
        "Return Step Viewed",
    ]

const segmentTrackEvents =
    [].concat(ecommerceSpecNames)
        .concat(otherEventNames)

export default segmentTrackEvents
