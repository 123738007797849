

const getToolBoxMainMenu = () => {

    let toolboxMainMenu = {
        primary: 'Toolbox',
        secondary: 'Articles',
        showCallNow: false,
        navigation: [
            {
                id: 'Tech',
                primary: 'Toolbox',
                secondary: 'Tech',
                simplified: true,
                onClick: {
                    type: 'setDrawerState',
                    payload: {
                        drawerState: {
                            isDrawerOpen: true
                        },
                        drawerAction: {
                            type: 'addNavigation',
                            payload: {
                                component: 'ToolboxMenuItemNavigation',
                                menu: 'toolboxTech'
                            }
                        }
                    }
                },
                event: { category: "Header", action: "Header Drawer Toolbox Tech Opened" }
            },
            {
                id: 'Builds',
                primary: 'Toolbox',
                secondary: 'Builds',
                simplified: true,
                onClick: {
                    type: 'setDrawerState',
                    payload: {
                        drawerState: {
                            isDrawerOpen: true
                        },
                        drawerAction: {
                            type: 'addNavigation',
                            payload: {
                                component: 'ToolboxMenuItemNavigation',
                                menu: 'toolboxBuilds'
                            }
                        }
                    }
                },
                event: { category: "Header", action: "Header Drawer Toolbox Builds Opened" }
            },
            {
                id: 'News',
                primary: 'Toolbox',
                secondary: 'News',
                simplified: true,
                onClick: {
                    type: 'setDrawerState',
                    payload: {
                        drawerState: {
                            isDrawerOpen: true
                        },
                        drawerAction: {
                            type: 'addNavigation',
                            payload: {
                                component: 'ToolboxMenuItemNavigation',
                                menu: 'toolboxNews'
                            }
                        }
                    }
                },
                event: { category: "Header", action: "Header Drawer Toolbox News Opened" }
            },
            {
                id: 'Videos',
                primary: 'Toolbox',
                secondary: 'Videos',
                simplified: true,
                href: '/the-toolbox/videos~2-2',
                event: { category: "Header", action: "Header Drawer Toolbox Videos Opened" }
            },
            {
                id: 'Podcasts',
                primary: 'Toolbox',
                secondary: 'Podcasts',
                simplified: true,
                href: '/the-toolbox/what-moves-you-podcast~512-156',
                event: { category: "Header", action: "Header Drawer Toolbox Podcasts Opened" }
            },
            {
                id: 'AllArticles',
                primary: 'Toolbox',
                secondary: 'See all Toolbox Articles',
                simplified: true,
                href: '/the-toolbox',
                event: { category: "Header", action: "Header Drawer See All Toolbox Articles Clicked" }
            }
        ]
    }


    toolboxMainMenu.navigation.unshift({
        id: 'Recommended',
        primary: 'Toolbox',
        secondary: 'Recommended Articles',
        simplified: true,
        onClick: {
            type: 'setDrawerState',
            payload: {
                drawerState: {
                    isDrawerOpen: true
                },
                drawerAction: {
                    type: 'addNavigation',
                    payload: {
                        component: 'RecommendedArticleNavigation',
                        menu: 'toolboxRecommendedArticles',
                    }
                }
            }
        },
        event: { category: "Header", action: "Header Drawer Toolbox Recommended Articles Opened" }
    })


    return toolboxMainMenu
}

const getRecommendedArticlesMenu = () => {
    return {
        primary: 'Toolbox',
        secondary: 'Recommended',
        showCallNow: false,
        navigation: [
            {
                id: 'See All Toolbox Articles',
                primary: 'Toolbox',
                secondary: 'See All Toolbox Articles',
                simplified: true,
                href: '/the-toolbox',
                event: { category: "Header", action: "Header Drawer Recommended Articles See All Toolbox Articles Clicked" }
            }
        ]
    }
}

const getVideosMenu = () => {
    return {
        primary: 'Toolbox',
        secondary: 'Videos',
        showCallNow: false,
    }
}

const getPodcastMenu = () => {
    return {
        primary: 'Toolbox',
        secondary: 'Podcasts',
        showCallNow: false,
    }
}
const getTechMenu = () => {
    return {
        primary: 'Toolbox',
        secondary: 'Tech',
        showCallNow: false,
    }
}

const getBuildMenu = () => {
    return {
        primary: 'Toolbox',
        secondary: 'Builds',
        showCallNow: false,
    }
}

const getNewsMenu = () => {
    return {
        primary: 'Toolbox',
        secondary: 'News',
        showCallNow: false,
    }
}

export {
    getToolBoxMainMenu,
    getVideosMenu,
    getPodcastMenu,
    getRecommendedArticlesMenu,
    getTechMenu,
    getBuildMenu,
    getNewsMenu
}
