import { useContext } from "react";

import { NavigationContext } from "Contexts/Navigation/NavigationContext";
import { FetchPartTypeLink } from "Contexts/VehicleContext/CoreVehicleRequests";
import {
  FetchYearMakeModel,
  FetchRaceTypes
} from "Contexts/VehicleContext/CoreVehicleRequests";
import useSegment from "Hooks/useSegment/useSegment";
import { useNavigationStore } from "Stores/NavigationStore";
import VehicleStoreWiring from "Stores/VehicleStoreWiring";
import SetCookie from "Utilities/CookieUtilties/SetCookie";
import { GetUserGuid } from "Utilities/CookieUtilties/UserCookieUtilities";
import { createFitmentAddedSegmentEventFromCustomerProject, createFitmentUpdatedSegmentEventFromCustomerProject } from "../Utilities/Instrumentation/Fitment/fitmentSegmentEvents";
import { getItem, removeItem, setItem } from "Utilities/LocalStorageUtility";
import Retry from "Utilities/Retry";
import createZustandContext from "Utilities/Zustand/createZustandContext";

import { NOT_FOUND_COOKIE } from "../Constants/Cookies";
import { IsUserBot } from "../Utilities/CookieUtilties/UserCookieUtilities";

const customerProjectsLocalStorageKey = "customerVehicles";
const hasCustomerProjectsCookie = "hasCustomerProjects";

const VehicleContext = createZustandContext(
  ({ set, get, hardCodedPropsForTesting }) => {
    const filterByVehicleAndPartType = async (partTypeId) => {
      const selectedCustomerProject = get().context?.selectedCustomerProject;
      if (
        selectedCustomerProject?.hydrated &&
        ((selectedCustomerProject.yearId &&
          selectedCustomerProject.makeId &&
          selectedCustomerProject.modelId) ||
          selectedCustomerProject.raceTypeId)
      ) {
        const response = await FetchPartTypeLink(
          selectedCustomerProject.yearId,
          selectedCustomerProject.makeId,
          selectedCustomerProject.modelId,
          selectedCustomerProject.raceTypeId,
          partTypeId
        );
        window.location.href = response.buildLink.url;
      } else {
        get()._dependencies.dispatch({
          type: "setCustomerProjectDrawerState",
          payload: {
            garageState: {
              isCustomerProjectDrawerOpen: true
            },
          },
        });
      }
    };

    const setSelectedCustomerProjectFromCookie = async (
      setFitmentCookie,
      customerProjects,
      userGuid,
      url
    ) => {
      try {
        const module = await Retry(() =>
          import(
            "Contexts/VehicleContext/Dynamic/SetSelectedCustomerProjectFromCookie"
          )
        );
        const response = await module.SetSelectedCustomerProjectFromCookie(
          customerProjects,
          userGuid,
          url,
          get().updateCustomerProjects,
          get().findMatchingProjects
        );
        if (response?.cookie) {
          setFitmentCookie(response.cookie);
        }
      } catch (err) {
        console.error(err);
      }
    };

    const defaultState = {
      redirectWhenCustomerProjectSelected: false,
      currentTab: "street",
      fitmentCookie: null,
      context: {
        allYears: [],
        years: [],
        makes: [],
        models: [],
        raceTypes: [],
        stockEngines: [],
        engineMakes: [], 
        engineFamilies: [],
        engineDisplacements: [],
        prioritizedMakes: [],
        existingState: null,
        // selectedCustomerProject doesn't have the same naming scheme as customerProjects
        selectedCustomerProject: {
          yearId: null,
          makeId: null,
          makeDisplay: null,
          modelId: null,
          modelDisplay: null,
          raceTypeId: null,
          raceDisplay: null,
          shopUrl: null,
          customerProjectUrl: null,
          searchUrl: null,
          projectId: null,
          vehicleBaseId: null,
          marketId: null,
          superMarketId: null,
          hydrated: false,
        },
        customerProjects: null,
        reloadCurrentLocation: false,
        hydrated: false,
        selectedFacetData: null,
        isFetchingDataType: null,
        isFetching: false
      },
    };

    const initialState = hardCodedPropsForTesting || defaultState;

    return {
      ...initialState,

      getSelectedCustomerProjectTitle: () => {
        const selectedCustomerProject = get().context.selectedCustomerProject;
        if (!selectedCustomerProject) {
          return null;
        }

        if (selectedCustomerProject.raceDisplay) {
          return selectedCustomerProject.raceDisplay;
        }
        if (
          selectedCustomerProject.yearId &&
          selectedCustomerProject.modelDisplay
        ) {
          const year = selectedCustomerProject.yearId.toString();
          const model = selectedCustomerProject.modelDisplay;
          return `${year} ${model}`;
        }
        if (selectedCustomerProject.engineDisplayName) {
          return selectedCustomerProject.engineDisplayName;
        }

        return null;
      },

      setFitmentCookie: (fitmentCookie) => set({ fitmentCookie }),

      setCurrentTab: (currentTab) => set({ currentTab }),

      setSelectedCustomerProjectHydratedStatus: (hydrated) => {
        set((state) => {
          return {
            ...state,
            context: {
              ...state.context,
              selectedCustomerProject: {
                ...state.context?.selectedCustomerProject,
                hydrated: hydrated,
              },
            },
          };
        });
      },

      openAddOrSelectCustomerProjectExperience: (
        redirectWhenCustomerProjectSelected = false,
        closeVehicleDrawerCallback = () => {}
      ) => {
        const hasVehiclesInGarage =
          get().context.customerProjects &&
          get().context.customerProjects.length > 0;

        set({ redirectWhenCustomerProjectSelected });

        if (hasVehiclesInGarage) {
          get()._dependencies.openVehicleSelectDrawer(
            closeVehicleDrawerCallback
          );
        } else {
          get()._dependencies.setYmmModal(true);
          closeVehicleDrawerCallback()
        }
      },

      selectCustomerProject: async (projectId, url) => {
        try {
          const module = await Retry(() =>
            import("Contexts/VehicleContext/Dynamic/SelectCustomerProject")
          );
          const response = module.SelectCustomerProject(
            get().context?.customerProjects,
            get().context?.reloadCurrentLocation,
            projectId,
            url,
            get().context?.selectedCustomerProject
          );

          if (response !== null) {
            set((state) => {
              return {
                ...state,
                context: {
                  ...state.context,
                  ...response,
                },
              };
            });
          }
        } catch (err) {
          console.error(err);
        }
      },

      getCustomerProjectsLocalStorage: () => {
        return JSON.parse(getItem(customerProjectsLocalStorageKey));
      },

      removeCustomerProjectsLocalStorage: () => {
        return removeItem(customerProjectsLocalStorageKey);
      },

      findMatchingProjects: (cookieToParse, customerProjects) => {
        try {
          if (
            !cookieToParse ||
            cookieToParse === NOT_FOUND_COOKIE ||
            !(customerProjects?.length > 0)
          ) {
            return [];
          }

          const safeCookieToParse = cookieToParse.split(";")[0];
          const cookieObject = JSON.parse(safeCookieToParse.split("=")[1]);
          const cookieProjectId = cookieObject?.ProjectId;
          // WIFR-32654 REMOVE AFTER YMM MODAL IS DEPRECATED. THAT DINOSAUR NEEDS TO GO
          const cookieVehicleBaseId = cookieObject?.VehicleBaseId;
          const cookieRaceTypeId = cookieObject?.RaceTypeId;
          
          let matchingProjects = customerProjects?.filter((project) => project?.projectId !== null && project?.projectId === cookieProjectId);

          // WIFR-32654 REMOVE AFTER YMM MODAL IS DEPRECATED. THAT DINOSAUR NEEDS TO GO
          if(matchingProjects.length == 0) {
            matchingProjects = customerProjects?.filter(
              (project) =>
                (project?.vehicleBaseId !== null &&
                  project?.vehicleBaseId === cookieVehicleBaseId) ||
                (project.raceTypeId !== null &&
                  project.raceTypeId === cookieRaceTypeId)
            );
          }

          return matchingProjects;
        } catch (err) {
          console.error(err, customerProjects, cookieToParse);
          return [];
        }
      },

      updateYearMakeModelData: (ymmData) => {
        const years =
          get().context?.allYears?.length > 0
            ? get().context.allYears
            : ymmData?.years;
        set((state) => ({
          ...state,
          context: {
            ...state.context,
            years: years,
            makes: ymmData?.makes,
            models: ymmData?.models,
            stockEngines: ymmData?.stockEngines,
            prioritizedMakes: ymmData?.prioritizedMakes,
            allYears: years,
            hydrated: true
          },
        }));
      },

      updateRaceTypeData: (raceTypeData) => {
        set((state) => ({
          ...state, 
          context: {
            ...state.context,
            raceTypes: raceTypeData?.raceTypeDropdown,
            stockEngines: raceTypeData?.stockEngineDropdown,
            hydrated: true
          }
        }))
      },

      updateEngineData: (engineData) => {
        set((state) => ({
          ...state,
          context: {
            ...state.context,
            engineMakes: engineData?.engineMakeDropdown,
            engineFamilies: engineData?.engineFamilyDropdown,
            engineDisplacements: engineData?.engineDisplacementDropdown
          }
        }))
      },

      // TODO: Remove once WIFR-31997 / WIFR-32524 (Garage Drawer) takes over @Jon Sherman
      updateEnginesData: (engineData, type) => {
        const engineFamilies =
          type === "engineFamilies" ? engineData : get().context.engineFamilies;
        const engines = type === "engines" ? engineData : null;
        set((state) => ({
          ...state,
          context: {
            ...state.context,
            engineFamilies: engineFamilies,
            engines: engines,
            hydrated: true,
          },
        }));
      },

      setSelectedFacets: (selectedFacets) => {
        set((state) => ({
          ...state,
          context: {
            ...state.context,
            selectedFacetData: selectedFacets,
          },
        }));
      },

      // since the YMM dropdown functions are bound to the SMI object and reloadCurrentLocation is an argument, this needs to be a regular function
      executeGarageReload: (reloadCurrentLocation) => {
        set((state) => ({
          ...state,
          context: {
            ...state.context,
            reloadCurrentLocation: reloadCurrentLocation,
          },
        }));
      },

      selectYearMakeModel: async (year, make, model, vehicles) => {
        try {
          const module = await Retry(() =>
            import("Contexts/VehicleContext/Dynamic/SelectYearMakeModel")
          );

          const response = module.SelectYearMakeModel(
            get().context.models,
            get().context.makes,
            get().context.customerProjects,
            year,
            make,
            model,
            vehicles
          );
          setItem(
            customerProjectsLocalStorageKey,
            JSON.stringify({
              customerProjects: response?.customerProjectSource,
              userGuid: GetUserGuid(),
            })
          );
          SetCookie(
            hasCustomerProjectsCookie,
            response?.customerProjectSource?.length > 0,
            365
          );
          set((state) => ({
            ...state,
            context: {
              ...state.context,
              ...response,
            },
          }));
        } catch (err) {
          console.error(err);
        }
      },

      //TODO: Deprecate
      updateRaceTypes: async (raceTypes, selectedRaceType, vehicles) => {
        try {
          const module = await Retry(() =>
            import("Contexts/VehicleContext/Dynamic/UpdateRaceTypes")
          );
          const response = module.UpdateRaceTypes(
            get().context,
            raceTypes,
            selectedRaceType,
            vehicles,
            get().setFitmentCookie
          );
          setItem(
            customerProjectsLocalStorageKey,
            JSON.stringify({
              customerProjects: response?.customerProjects,
              userGuid: GetUserGuid(),
            })
          );
          SetCookie(
            hasCustomerProjectsCookie,
            response?.customerProjects?.length > 0,
            365
          );
          set((state) => ({
            ...state,
            context: {
              ...state.context,
              ...response,
            },
          }));
        } catch (err) {
          console.error(err);
        }
      },

      updateCustomerProjects: (customerProjects) => {
        setItem(
          customerProjectsLocalStorageKey,
          JSON.stringify({
            customerProjects: customerProjects,
            userGuid: GetUserGuid(),
          })
        );
        SetCookie(hasCustomerProjectsCookie, customerProjects?.length > 0, 365);
        set((state) => ({
          ...state,
          context: {
            ...state.context,
            customerProjects,
            hydrated: true,
          },
        }));
      },

      clearSelectedCustomerProject: async (redirectUrl) => {
        try {
          const module = await Retry(() =>
            import(
              "Contexts/VehicleContext/Dynamic/ClearSelectedCustomerProject"
            )
          );
          const response = module.ClearSelectedCustomerProject(
            get().context?.reloadCurrentLocation,
            redirectUrl
          );
          set((state) => ({
            ...state,
            context: {
              ...state.context,
              ...response,
            },
          }));
        } catch (err) {
          console.error(err);
        }
      },

      setMakerVehiclePartTypeFunction: async (partTypeId) => {
        window.maker = {
          ...window.maker,
          filterByVehicleAndPartTypeFunction: () => {
            filterByVehicleAndPartType(partTypeId);
          },
        };
      },
      removeCustomerProject: async (projectId) => {
        try {
          const userGuid = GetUserGuid();
          const module = await Retry(() =>
            import("Contexts/VehicleContext/CoreVehicleRequests")
          );
          const response = await module.RemoveUserVehicle(
            userGuid,
            projectId,
            "/graphql/customerproject"
          );
          if (response)
            get().updateCustomerProjects(
              response.removeProjectFromGarage.projects
            );
        } catch (err) {
          console.error(err);
        }
      },
      addCustomerProject: async ({
        year,
        makeId,
        make,
        modelId,
        model,
        raceTypeId,
        raceTypeDisplayName,
        vehicleBaseId,
        engineIds,
      }) => {
        try {
          const isBot = IsUserBot();
          if (isBot) return null;
          const userGuid = GetUserGuid();
          const module = await Retry(() =>
            import("Contexts/VehicleContext/CoreVehicleRequests")
          );
          // TODO: Remove this once no more events coming through
          if (year || makeId || modelId) {
            if (!(year && makeId && modelId)) {
              console.error("INVALID-ATTEMPT-YMM")
            }
            if (raceTypeId) {
              console.error("INVALID-ATTEMPT-YMM+RT")
            }
          }
          const response = await module.AddCustomerProject({
            webUserGuid: userGuid,
            customerProjectUrl: "/graphql/customerproject",
            year,
            makeId,
            modelId,
            raceTypeId,
            vehicleBaseId,
            engineIds,
            selectedFacetData: get().context.selectedFacetData,
          });
          const newCustomerProjects = response?.addNewProjectToGarage?.projects;
          if (
            response?.addNewProjectToGarage?.success &&
            !response?.addNewProjectToGarage?.alreadyExisted
          ) {
            const projectId = response?.addNewProjectToGarage?.addedCustomerProjectId;
            const customerProject = newCustomerProjects?.find(x => x.projectId === projectId);
            const segmentEvent = createFitmentAddedSegmentEventFromCustomerProject(customerProject);
            get()._dependencies.sendCustomSegmentTrackEvent(segmentEvent, false);

          } 
          
          if (response?.addNewProjectToGarage?.success && newCustomerProjects?.length > 0) {
            get().updateCustomerProjects(newCustomerProjects);
          }
          
          return response?.addNewProjectToGarage;
        } catch (err) {
          console.error(err);
        }
      },
      updateCustomerProjectData: async ({
        year,
        makeId,
        make,
        modelId,
        model,
        raceTypeId,
        raceTypeDisplayName,
        vehicleBaseId,
        engineIds,
        customerProjectId
      }) => {
        try {
          const userGuid = GetUserGuid();
          const module = await Retry(() =>
            import("Contexts/VehicleContext/CoreVehicleRequests")
          );

          const response = await module.UpdateCustomerProjectData({
            webUserGuid: userGuid,
            customerProjectUrl: "/graphql/customerproject",
            year,
            makeId,
            modelId,
            raceTypeId,
            vehicleBaseId,
            engineIds,
            selectedFacetData: get().context.selectedFacetData,
            customerProjectId
          });

          const newCustomerProjects = response?.updateCustomerProject?.projects;
          const customerProject = newCustomerProjects?.find(x => x.projectId === customerProjectId);
          const updatedEngineIds = response?.updateCustomerProject?.updatedEngineIds;

          if (response?.updateCustomerProject?.success) {
            const segmentEvent = createFitmentUpdatedSegmentEventFromCustomerProject(
              customerProject, updatedEngineIds
            );
            get()._dependencies.sendCustomSegmentTrackEvent(segmentEvent, false);

            get().updateCustomerProjects(newCustomerProjects);
          }
          return response?.updateCustomerProject;
        } catch (err) {
          console.error(err);
        }
      },
      fetchCustomerProjects: async (forceRefresh = false) => {
        let response;
        const userGuid = GetUserGuid();

        const locallyStoredCustomerProjects =
          get().getCustomerProjectsLocalStorage();
        const hasCustomerProjects =
          locallyStoredCustomerProjects?.customerProjects?.length;
        const isUserGuidMatching =
          locallyStoredCustomerProjects?.userGuid === userGuid;

        const isBot = IsUserBot();
        if (isBot) {
          response = null;
        } else if (!forceRefresh && hasCustomerProjects && isUserGuidMatching) {
          response = {
            customerProjects: locallyStoredCustomerProjects.customerProjects,
          };
        } else {
          const module = await Retry(() =>
            import("Contexts/VehicleContext/CoreVehicleRequests")
          );
          response = await module.FetchCustomerProjects(
            userGuid,
            get().context,
            "/graphql/customerproject",
            get().context?.selectedFacetData,
            forceRefresh
          );
        }

        if (response?.customerProjects) {
          get().updateCustomerProjects(response?.customerProjects);
          SetCookie(
            hasCustomerProjectsCookie,
            response?.customerProjects.length > 0,
            365
          );
        }

        await setSelectedCustomerProjectFromCookie(
          get().setFitmentCookie,
          response?.customerProjects || [],
          userGuid,
          "/graphql/customerproject"
        );
      },
      fetchYmmData: async (year, makeId, modelId) => {
        set((state) => ({
          ...state, 
          context: {
            ...state.context,
            isFetching: true
          }
        }))

        const module = await Retry(() =>
          import("Contexts/VehicleContext/CoreVehicleRequests")
        );

        var response = await module.FetchYearMakeModel(
          get().context,
          year,
          makeId,
          modelId,
          "/graphql/trait",
          get().context?.selectedFacetData
        );

        if (response?.ymmData)  
          get().updateYearMakeModelData(response.ymmData);

        set((state) => ({
          ...state, 
          context: {
            ...state.context,
            isFetching: false
          }
        }))

      },
      //TODO: Deprecate
      fetchRaceTypes: async () => {
        var response = await FetchRaceTypes(
          get().context.raceTypes,
          "/graphql/trait",
          get().context?.selectedFacetData
        );

        if (response)
          get().updateRaceTypes(
            response,
            get().context.selectCustomerProject?.raceTypeId
          );
      },
      fetchRaceTypeData: async (raceTypeId) => {
        set((state) => ({
          ...state, 
          context: {
            ...state.context,
            isFetching: true
          }
        }))

        const module = await Retry(() =>
          import("Contexts/VehicleContext/CoreVehicleRequests")
        );
        
        const response = await module.FetchRaceType(
          get().context,
          raceTypeId,
          "/graphql/trait",
          get().context?.selectedFacetData
        );

        if (response?.raceTypeData) get().updateRaceTypeData(response.raceTypeData);

        set((state) => ({
          ...state, 
          context: {
            ...state.context,
            isFetching: false
          }
        }))
      },
      fetchEngineData: async (engineMakeKey, engineFamilyKey, vehicleBaseId, raceTypeId) => {
        set((state) => ({
          ...state, 
          context: {
            ...state.context,
            isFetching: true
          }
        }))
        
        let manufacturerId
        let makeId 
        let seriesId
        let configurationId
        if (engineFamilyKey && engineFamilyKey.length > 0) {
          var engineFamilyIds = engineFamilyKey.split("-");
          manufacturerId = parseInt(engineFamilyIds[0], 10)
          makeId = parseInt(engineFamilyIds[1], 10)
          seriesId = parseInt(engineFamilyIds[2], 10)
          configurationId = parseInt(engineFamilyIds[3], 10)
        }
        else if (engineMakeKey && engineMakeKey.length > 0){
          var engineMakeIds = engineMakeKey.split("-");
          manufacturerId = parseInt(engineMakeIds[0], 10)
          makeId = parseInt(engineMakeIds[1], 10)
        }
   
        const module = await Retry(() =>
            import("Contexts/VehicleContext/CoreVehicleRequests")
          );
          
        const response = await module.FetchEngine(
          get().context,
          manufacturerId,
          makeId,
          seriesId,
          configurationId,
          "/graphql/trait",
          get().context?.selectedFacetData,
          vehicleBaseId,
          raceTypeId
        )

        if (response?.engineData) get().updateEngineData(response.engineData)

        set((state) => ({
          ...state, 
          context: {
            ...state.context,
            isFetching: false
          }
        }))
      },
      setExistingCustomerProjectState: (existingState) => {
        set((state) => {
          return {
            ...state,
            context: {
              ...state.context,
              existingState: existingState
            },
          };
        });
      }
    };
  },
  {
    name: "VehicleStore",
  }
);

VehicleContext.useSynchronizedState = () => {
  const openVehicleSelectDrawer = useNavigationStore(
    (x) => x.openVehicleSelectDrawer
  );
  const setYmmModal = useNavigationStore((x) => x.setYmmModal);
  const { dispatch } = useContext(NavigationContext);
  const { sendCustomSegmentTrackEvent } = useSegment();

  return {
    _dependencies: {
      openVehicleSelectDrawer,
      setYmmModal,
      dispatch,
      sendCustomSegmentTrackEvent,
    },
  };
};

VehicleContext.Wiring = VehicleStoreWiring;

export const useVehicleStore = VehicleContext.useStore;
export const VehicleProvider = VehicleContext.Provider;
