import { useVehicleStore } from "Stores/VehicleStore";
import useSWRImmutable from "swr/immutable";

import { useServicesContext } from "../../Contexts/ServicesContext/ServicesContext";
import {
  GetUserGuid,
  GetUserVin,
} from "../../Utilities/CookieUtilties/UserCookieUtilities";
import {
  fetchBuilds,
  fetchFeaturedArticle,
  fetchNews,
  fetchPodcasts,
  fetchRecommendedArticles,
  fetchTech,
  fetchVideos,
} from "./contentRequests";

const buildRecArticleRequest = (selectedCustomerProject) => {
  return {
    userVin: GetUserVin(),
    webUserGuid: GetUserGuid(),
    ymmData: {
      year: selectedCustomerProject?.yearId,
      make: selectedCustomerProject?.makeId,
      model: selectedCustomerProject?.modelId,
      raceTypeId: selectedCustomerProject?.raceTypeId,
    },
  };
};

const useContent = (shouldFetch) => {
  const services = useServicesContext();
  const selectedCustomerProject = useVehicleStore((x) => x.context.selectedCustomerProject);
  const contentServiceUrl = services?.content.url;
  const recommendationsServiceUrl = services?.recommendations.url;
  const recArticleRequest = buildRecArticleRequest(selectedCustomerProject);
  const { data: featuredArticle } = useSWRImmutable(
    shouldFetch ? "getFeaturedArticle" : null,
    () => fetchFeaturedArticle(contentServiceUrl)
  );

  const { data: recommendedArticles } = useSWRImmutable(
    shouldFetch ? ["getRecommendedArticles", recArticleRequest] : null,
    (_, recArticleRequest) =>
      fetchRecommendedArticles(recommendationsServiceUrl, recArticleRequest)
  );

  return {
    featuredArticle,
    recommendedArticles: recommendedArticles,
    tech: fetchTech(),
    builds: fetchBuilds(),
    news: fetchNews(),
    videos: fetchVideos(),
    podcasts: fetchPodcasts(),
  };
};

export default useContent;
