import React, { Fragment } from 'react'

import Image from 'next/image'

import Typography from 'Atoms/Typography'
import Card from 'Atoms/Card'
import Area from 'Molecules/Area'
import ErrorBoundry from 'UtilityComponents/ErrorBoundary'
import IndexOptions from 'Components/IndexOptions'

import styles from './itemsInSelectIndexOptionsDrawer.module.scss'

const ItemsInSelectIndexOptionsDrawer = ({ items, selectIndexOption, isIndexOptionErrorState }) => {
  
  let filteredItems = [...items].filter((x) => x.indexOptions?.length)

  return (
    <ErrorBoundry>
      {filteredItems
        .map((item, i) =>
          <Fragment key={`item_${item.skuBase}_${i}`}>
            <Typography size={0.875} className={styles.item_count}>
              {`Item ${i + 1} of ${filteredItems.length}`}
            </Typography>
            <Card fill>
              <div className={styles.item_wrapper}>
                {/* Left Side */}
                <span className={styles.item_image_wrapper}>
                  <Image
                    src={`https:${item.imageUrl}`}
                    width={72}
                    height={72} />
                </span>
                {/* Right Side */}
                <span data-testid={`drawer_item_${i + 1}`}>
                  <Typography tone={'contrast'} font={'bold'}>
                    {item.title}
                  </Typography>
                  <Typography size={1}>
                    {`\$${item.price.toFixed(2)}`}
                  </Typography>
                  {(item.indexOptions[0].availability && item.indexOptions[0].availability != 'InStock') &&
                    <Area
                      className={styles.avail}
                      intent={item.indexOptions[0].isPurchaseable ? 'warning' : 'negative'}
                      data-testid={item.indexOptions[0].isPurchaseable ? 'avail_warn' : 'avail_neg'}>
                      <Typography size={0.875}>
                        {item.indexOptions[0].availabilityDisplayCopy || item.indexOptions[0].availability}
                      </Typography>
                    </Area>
                  }
                  <IndexOptions
                    indexOptions={item.indexOptions}
                    selectIndexOption={(productPageId, _, selectedIndexOptions, mostRecentlySelectedIndexOption) => selectIndexOption(item, productPageId, selectedIndexOptions, mostRecentlySelectedIndexOption)}
                    productPageId={item.productPageId}
                    buttonCuttoffValue={4} 
                    isIndexOptionErrorState={isIndexOptionErrorState}
                    />
                </span>
              </div>
            </Card>
          </Fragment>
        )}
    </ErrorBoundry>
  )
}

export default ItemsInSelectIndexOptionsDrawer