
import CookieReader from '../CookieUtilties/CookieReader';
import SetCookie from '../CookieUtilties/SetCookie';

import useLogRocket from 'Hooks/useLogRocket';


const LOCATIONKEY = "location"

export const saveLocation = (zipCode = null, isInternational = null) => {
    let location = TryGetLocationFromCookie() ?? { zipCode: null, isInternational: null };
    location.zipCode = zipCode == null ? location?.zipCode : zipCode;
    location.isInternational = isInternational == null ? location?.isInternational : isInternational;
    SetCookie(LOCATIONKEY, `${location.zipCode}_${location.isInternational}`, 30);

}

const TryGetLocationFromCookie = () => {
    try {
        let rawString = CookieReader(LOCATIONKEY);
        if (!rawString) {
            return null;
        }
        let arr = rawString.split("_")
        if (arr.length !== 2) {
            throw `incorrectly formatted location cookie: ${rawString}`
        }
        if (!arr[0] || arr[0] === "null" || arr[0] === "undefined") {
            return null;
        }
        return { zipCode: arr[0], isInternational: arr[1].toLowerCase() === 'true' }

    } catch (err) {
        console.error("Error reading location cookie", err)
        return null;
    }

}



export const tryUpdateLocation = (userProfile) => {
    let location = TryGetLocationFromCookie();
    userProfile = userProfile ?? {};
    if (location) {
        userProfile = { ...userProfile, ...location };
    }

    SetCookie(LOCATIONKEY, `${userProfile?.zipCode}_${userProfile?.isInternational}`, 30);
    return userProfile
}

export const validateZip = (value) => {
    if (!value) {
        return false;
    }

    const invalidZipCodePrefixes = [
        '000',
        '001',
        '002',
        '003',
        '004',
        '213',
        '343',
        '345',
        '348',
        '353',
        '419',
        '428',
        '429',
        '517',
        '518',
        '519',
        '529',
        '533',
        '536',
        '552',
        '568',
        '578',
        '579',
        '589',
        '621',
        '632',
        '642',
        '643',
        '659',
        '663',
        '682',
        '694',
        '695',
        '696',
        '697',
        '698',
        '699',
        '702',
        '709',
        '715',
        '732',
        '742',
        '771',
        '817',
        '818',
        '819',
        '839',
        '848',
        '849',
        '854',
        '858',
        '861',
        '862',
        '866',
        '867',
        '868',
        '869',
        '872',
        '876',
        '886',
        '887',
        '888',
        '892',
        '896',
        '899',
        '909',
        '929',
        '987'];

    if (invalidZipCodePrefixes.some((x) => value.startsWith(x))) {
        return false;
    }

    return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value)
}

