import React, { useEffect, useRef } from 'react'
import styles from './FocusTrap.module.scss'
const FocusTrap = ({ isActive, children }) => {
    const topTabTrap = useRef()
    const bottomTabTrap = useRef()
    const container = useRef()

    useEffect(() => {
        const selectInputSelector = ['input', 'select']
        const inputSelectElements = getFocusableElements(selectInputSelector)
        if (inputSelectElements && inputSelectElements.length > 0) {
            inputSelectElements[0].focus()
        } else {
            topTabTrap.current.focus()
        }

        document.addEventListener('focusin', trapFocus)
        function trapFocus(event) {
            if (!isActive) return
            const focusableSelector = [
                'button',
                'a[href]',
                'input',
                'select',
                'textarea',
                '[tabindex]',
                '[contenteditable]'
            ]
            let elements
            if (event.target === topTabTrap.current) {
                elements = getFocusableElements(focusableSelector)
                if (elements.length > 0) {
                    const lastElement = elements[elements.length - 1]
                    lastElement.focus()
                }
            }

            if (event.target === bottomTabTrap.current) {
                elements = getFocusableElements(focusableSelector)
                if (elements.length > 0) {
                    const firstElement = elements[0]
                    firstElement.focus()
                }
            }
        }

        function getFocusableElements(focusableSelector) {
            if (!container.current) return []
            focusableSelector = focusableSelector
                .map(
                    (selector) =>
                        `${selector}:not([tabindex="-1"]):not([disabled])`
                )
                .join(', ')
            return Array.from(
                container.current.querySelectorAll(focusableSelector)
            )
                .filter((element) => element !== topTabTrap.current)
                .filter((element) => element !== bottomTabTrap.current)
        }

        return () => document.removeEventListener('focusin', trapFocus)
    }, [isActive, topTabTrap, bottomTabTrap, container])

    return (
        <div ref={container}>
            {isActive && <span ref={topTabTrap} tabIndex="0" className={styles.focusTrap}/>}
            {children}
            {isActive && <span ref={bottomTabTrap} tabIndex="0" className={styles.focusTrap}/>}
        </div>
    )
}

export default FocusTrap
