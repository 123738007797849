import { useContext, useEffect, useState } from "react";

import { TrackingContext } from "Contexts/TrackingContext/TrackingContext";
import validateSegmentTrackEvent from "Hooks/useSegment/segmentValidators";
import { sanitize } from "Utilities/ObjectUtilities";
import defer from "Utilities/defer";

import {
  addSessionProperties,
  incrementSessionStorageKeyEventCount,
  incrementSessionStoragePageViewCount,
  setIsEngagedSessionFired,
  trackEngagedSession,
} from "../../Utilities/EngagedSessionStorageUtility/EngagedSessionStorageUtility";
import { validateSegmentEvent } from "../../Utilities/Instrumentation/SegmentUtilities/segmentFunctions";

const useSegment = () => {

  const trackEvent = (event) => {
    try {
      // Remove properties that are null or undefined
      const sanitizedEvent = sanitize(event);
      const name = sanitizedEvent.name;
      const customEventName = sanitizedEvent.event;
      const properties = addSessionProperties(sanitizedEvent.properties);

      validateSegmentTrackEvent(name || customEventName);

      validateSegmentEvent(name || customEventName, properties);

      incrementSessionStorageKeyEventCount(name || customEventName);
      trackEngagedSession();

      if (name) {
        analytics.track(name, properties);
      } else if (customEventName) {
        analytics.track(customEventName, properties);
      } else {
        analytics.track(properties);
      }

      setIsEngagedSessionFired();
    } catch (err) {
      console.error(`failed to send track event`, err);
    }
  }

  const pageEvent = (event) => {
    try {
      event.properties = addSessionProperties(event.properties);
      analytics.page(event);

      incrementSessionStoragePageViewCount();
    } catch (err) {
      console.error(`failed to send page event`, err);
    }
  }

  const identifyEvent = (event) => {
    try {
      analytics.identify(event);
    } catch (err) {
      console.error(err);
    }
  }


  const sendSegmentTrackEvent = (event, shouldDefer = true) => {
    if (shouldDefer) {
      defer(() => { trackEvent(event) })
      return;
    }
    trackEvent(event)
  };

  const sendSegmentTrackEventWithName = (
    eventName,
    eventProperties,
    shouldDefer = true
  ) => {

    let event = {
      name: eventName,
      properties: eventProperties,
    };

    sendSegmentTrackEvent(event, shouldDefer)
  };

  const sendCustomSegmentTrackEvent = (event, shouldDefer = true) => {
    sendSegmentTrackEvent(event, shouldDefer)
  };

  const sendSegmentPageEvent = (event, shouldDefer = true) => {
    if (shouldDefer) {
      defer(() => { pageEvent(event) })
      return;
    }
    pageEvent(event)
  };

  const sendSegmentIdentifyEvent = (event, shouldDefer = true) => {
    if (shouldDefer) {
      defer(() => { identifyEvent(event) })
      return;
    }
    identifyEvent(event)
  };

  return {
    sendSegmentTrackEvent,
    sendSegmentTrackEventWithName,
    sendSegmentPageEvent,
    sendSegmentIdentifyEvent,
    sendCustomSegmentTrackEvent,
  };
};

export default useSegment;
