import React, { forwardRef } from "react";
import Select, { components } from "react-select";

import LayerColorFinder from 'Utilities/LayerColorFinder';
import ColorFinder from 'Utilities/ColorFinder';

import Form from "Atoms/Form";
import selectStyles from "Atoms/Form/Elements/Select.module.scss";
import Typography from "Atoms/Typography";
import Palette from "Constants/Palette";
import useSegment from "Hooks/useSegment";

import styles from "./AutoComplete.module.scss";

const getCustomStyles = (placeholderStyles) => {
  const disabled = ColorFinder(null, null, null, true);
  const darkGray = "rgba(0,0,0,0.4)";

  const backgroundColor = LayerColorFinder(0);

  return {
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: "none",
    }),
    control: (provided, state) => {
      return {
        ...provided,
        borderColor: state.isDisabled ? disabled : darkGray,
        backgroundColor: backgroundColor,
      };
    },
    placeholder: (provided, state) => {
      return {
        ...provided,
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        display: "block",
        overflow: "clip",
        maxWidth: placeholderStyles?.maxWidth,
      };
    },
  };
};

//This an "override" for the react options so we can add data-testid for selenium tests
const CustomOption = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <div data-testid={props.label}>{children}</div>
    </components.Option>
  );
};

const AutoComplete = forwardRef(
  (
    {
      options,
      onChange,
      label,
      addonLabel,
      selectedValue,
      isDisabled,
      placeholder,
      placeholderStyles,
      displayLabel = true,
      boldLabel = false,
      isErrorState,
      useNativeSelect = true,
      openMenuOnFocus = true,
    },
    ref
  ) => {
    const selectedOption = selectedValue?.value ? selectedValue : null;
    const { sendSegmentTrackEvent } = useSegment();

    const handleOnChange = (option) => {
      if (onChange) onChange(option);

      if (option.segmentEvent) {
        sendSegmentTrackEvent(option.segmentEvent);
      }

      if (option.href && typeof window !== "undefined") {
        window.location.href = option.href;
      }
    };

    const nonNativeStyles = useNativeSelect ? styles.nonNativeSelect : styles.nonNativeSelectAlwaysEnabled;

    return (
      <div data-testid={label} aria-disabled={isDisabled}>
        {displayLabel && (
          <label
            className={nonNativeStyles}
            htmlFor={label}
            id={`label_${label}`}
          >
            <Typography
              size={1}
              tone={"contrast"}
              className={selectStyles.label}
              font={boldLabel ? "bold" : "regular"}
              inline={addonLabel ? true : false}
            >
              {label}
            </Typography>
            {addonLabel && <Typography inline size={1}>{' '}{addonLabel}</Typography>}
          </label>
        )}
        <Select
          styles={getCustomStyles(placeholderStyles)}
          components={{ Option: CustomOption }}
          classNamePrefix={"nonNativeSelect"}
          className={nonNativeStyles}
          options={options}
          openMenuOnFocus={openMenuOnFocus}
          onChange={(option) => {
            handleOnChange(option);
          }}
          isDisabled={isDisabled}
          label={label}
          aria-label={label}
          aria-labelledby={displayLabel ? `label_${label}` : null}
          placeholder={placeholder}
          value={selectedOption}
          ref={ref}
        />
        {useNativeSelect && (
          <div className={styles.nativeSelect}>
              <Form.Select
                options={options}
                value={selectedOption?.value ?? ""}
                onChange={(event) => {
                  let selected = options.find(
                    (option) => option.value == event.target.value
                  );
                  handleOnChange(selected);
                }}
                size={1}
                isDisabled={isDisabled}
                placeholder={placeholder}
                label={label}
                id={label}
                displayLabel={displayLabel}
                borderIntent={isErrorState ? "negative" : undefined}
              />
          </div>
        )}
      </div>
    );
  }
);

export default AutoComplete;
