import React, { useContext, useState, useEffect } from 'react'
import ClassNames from 'classnames'
import Modal from 'Components/Modal'
import Typography from 'Atoms/Typography'
import Svg from 'Atoms/Svg'
import Tab from 'Components/Tab'
import TabGroup from 'Components/TabGroup'
import Panel from 'Atoms/Panel'
import FeatureFlagWrapper from 'UtilityComponents/FeatureFlagWrapper'

import { NavigationContext } from 'Contexts/Navigation/NavigationContext'
import { useVehicleStore } from "Stores/VehicleStore";
import PanelContext from 'Contexts/PanelContext'

import StreetTab from './Tabs/Street'
import RaceTypeTab from './Tabs/Race'
import styles from './YmmModal.module.scss'

//We're importing these here so they are loading with the YmmComponent instead of dynamically when they're called - prevents chunk load failures
import 'Contexts/VehicleContext/Dynamic/SelectYearMakeModel'
import 'Contexts/VehicleContext/Dynamic/UpdateRaceTypes'

//throw 'Error'
const YmmModal = ({ services }) => {
    const { dispatch: navigationDispatch, state: navigationState, setYmmModal: setModal } = useContext(
        NavigationContext
    )
    const setCurrentTab = useVehicleStore(x => x.setCurrentTab)
    const executeGarageReload = useVehicleStore(x => x.executeGarageReload)
    const reloadCurrentLocation = useVehicleStore(x => x.context.reloadCurrentLocation)
    const currentTab = useVehicleStore(x => x.currentTab)
    const [isProductPage, setIsProductPage] = useState(false)
    const { garage } = navigationState
    const { isYmmModalOpen } = garage



    useEffect(() => {
        try {
            if (window.SMI) {
                setIsProductPage(
                    SMI.getPageTypeClass() === 'ProductPageTypePageContainer'
                )
            }

            if (navigationState.pageType === "PDP") {
                setIsProductPage(true)
            }
        } catch (err) {
            console.error('getPageTypeClass likely undefined:', err)
            setIsProductPage(false)
        }
        // keep tab in sync with global choice from TNG until we can call a function directly and remove this weird pass through
        // if (garage.market !== currentTab) {
        //     setCurrentTab(garage.market)
        // }
        // keep reload in sync with global choice from TNG until we can call a function directly and remove this weird pass through
        if (
            isProductPage ||
            garage?.reloadCurrentLocation !==
            reloadCurrentLocation
        ) {
            executeGarageReload(
                isProductPage || garage?.reloadCurrentLocation
            )
        }
    }, [garage, typeof window, isProductPage])

    const fakePanelContext = {
        brand: undefined,
        intent: undefined,
        inverseTheme: true,
        layer: 0
    }

    var closeButton = (
        <div className={styles.closeButtonWrapper}>
            <Panel className={styles.modalClose} onClick={() => setModal(false)}>
                <Svg size={0.75} icon={'close'} data-testid={"modalCloseButton"} />
            </Panel>
        </div>
    )

    try {
        return (
            <Modal
                toggleOpen={(isOpen) => setModal(isOpen)}
                open={isYmmModalOpen}
                contentAboveModal={closeButton}
                allowOutsideClickClose={true}
                className={ClassNames(
                    styles.ymmModalWrapper,
                    currentTab === 'street' ? styles.streetWrapper : null
                )}
                alignItems={'flex-start'}
            >
                <TabGroup>
                    <Tab
                        className={ClassNames(
                            currentTab === 'street' ? styles.tabSelected : '',
                            styles.tab
                        )}
                        onClick={() => setCurrentTab('street')}
                        testingName={'StreetTab'}
                    >
                        <Svg
                            icon={'street'}
                            intent={currentTab === 'street' ? 'action' : null}
                            size={1.25}
                            className={styles.tabSvg}
                        />
                        <Typography
                            tone={'contrast'}
                            size={1.25}
                            intent={currentTab === 'street' ? 'action' : null}
                            font={'heading'}
                            inline
                        >
                            STREET
                        </Typography>
                    </Tab>
                    <Tab
                        className={ClassNames(
                            currentTab === 'race' ? styles.tabSelected : '',
                            styles.tab
                        )}
                        onClick={() => setCurrentTab('race')}
                        testingName={'RaceTab'}
                    >
                        <Svg
                            icon={'race'}
                            intent={currentTab === 'race' ? 'action' : null}
                            size={1.25}
                            className={styles.tabSvg}
                        />
                        <Typography
                            tone={'contrast'}
                            size={1.25}
                            intent={currentTab === 'race' ? 'action' : null}
                            font={'heading'}
                            inline
                        >
                            RACE
                        </Typography>
                    </Tab>
                    <FeatureFlagWrapper
                        ff={"Engine_Selection_Modal"}
                        childrenIfFalse={false}
                    >
                    
                    </FeatureFlagWrapper>
                </TabGroup>
                <StreetTab
                    isEnabled={currentTab === 'street'}
                    services={services}
                />
                <RaceTypeTab
                    isEnabled={currentTab === 'race'}
                    services={services}
                    isProductPage={isProductPage}
                />
            </Modal>
        )
    } catch (e) {
        console.error(e)
        return false
    }
}

export default YmmModal
