import { useEffect } from 'react'

const useNetwork = (callback, dependencies) => {
    useEffect(() => {
        if (typeof window !== 'undefined' && window.navigator) {
            const connection =
                window.navigator.connection ||
                window.navigator.mozConnection ||
                window.navigator.webkitConnection
            if (connection) {
                const type = connection.effectiveType
                const saveData = connection.saveData
                const speed =
                    type === 'slow-2g' || type === '2g' || saveData
                        ? 'slow'
                        : type === '3g'
                        ? 'medium'
                        : 'fast'
                const memory = window.navigator.deviceMemory
                const cpuCores = window.navigator.hardwareConcurrency
                callback({
                    speed: speed,
                    network: type, //one of [slow-2g, 2g, 3g, 4g, 5g]
                    cpu: cpuCores, // number of cpu cores
                    memory: memory, // gigs of RAM
                    dataSaver: saveData
                })
            } else {
                callback({
                    speed: 'fast',
                })
            }
        } else {
            //intentionally leave this blank. This ensures that the callback will not be ran until window is defined
        }
    }, [typeof window, JSON.stringify(dependencies)])
}

export default useNetwork
