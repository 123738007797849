import React from 'react'
import ClassNames from 'classnames'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import styles from './List.module.scss'

const propTypes = {
    component: PropTypes.string,
    className: PropTypes.string,
    itemLayout: PropTypes.oneOf(['horizontal', 'vertical', undefined]),
    display: PropTypes.oneOf(['grid', 'flex', undefined])
}

const List = ({
    component,
    className,
    children,
    itemLayout,
    display,
    ...props
}) => {
    const displayStyle =
        display === 'grid' ? styles.listGridLayout : styles.listFlexLayout
    const classnames = ClassNames(className, styles.list, displayStyle)
    component = component ? component : 'ul'
    itemLayout = itemLayout ? itemLayout : 'vertical'

    return (
        <>
            <StyledList
                as={component}
                className={classnames}
                itemLayout={itemLayout}
                {...props}
            >
                {children}
            </StyledList>
        </>
    )
}

const StyledList = styled.div`
    flex-direction: ${(props) =>
        props.itemLayout === 'horizontal' ? 'row' : 'column'};
`

List.propTypes = propTypes

export default List
